import React, { useState } from 'react'
import Groups from './Groups'
import SimpleAtb from './SimpleAtb'

const Atb = ({ startDate, endDate }) => {

    const [atbType, setAtbType] = useState('simple')

    return (
        <div>
            <h1>ATB</h1>
            <div className='atb-component'>
                <div className='h2-wrap'>
                    <h2 className={atbType === 'simple' ? 'active-h2' : ''} onClick={() => setAtbType('simple')}>Kod TN VED</h2>
                    <h2 className={atbType === 'groups' ? 'active-h2' : ''} onClick={() => setAtbType('groups')}>Guruhlab</h2>
                </div>

                {
                    atbType === "simple" ? <SimpleAtb startDate={startDate} endDate={endDate} /> : <Groups startDate={startDate} endDate={endDate} />
                }
            </div>
        </div>
    )
}

export default Atb