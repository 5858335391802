import React, { useEffect, useState } from 'react'
import fileDownload from 'js-file-download'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { MdAdd, MdClose, MdDelete, MdEdit } from 'react-icons/md'
import { Empty, Modal } from 'antd'
import { alltnvcodes } from '../../constants/tnv-codes'
import Loader from '../loader/Loader'
import http from '../../utils/axiosinterceptors'
import EditGroupModal from './EditGroupModal'

const Groups = ({ endDate, startDate }) => {

    const [withStat, setWithStat] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalOpen2, setIsModalOpen2] = useState(false)
    const [groupLoading, setGroupLoading] = useState(true)
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const [tog, setTog] = useState('fcc')
    const [name, setName] = useState('')
    const [filteredTnvCodes, setFilteredTnvCodes] = useState([])
    const [searchCode, setSearchCode] = useState('')
    const [codesArr, setCodesArr] = useState([]) // added tnv codes
    const [groups, setGroups] = useState([])
    const [tableCodes, setTableCodes] = useState([])

    const addCodesArr = (code) => {
        if (!codesArr.includes(code)) {
            setCodesArr(prev => [...prev, code])
            setFilteredTnvCodes(filteredTnvCodes.map(i => i.code === code ? { ...i, status: false } : { ...i }))
        } else {
            // setCodesArr(codesArr.filter(item => item !== code))
        }
    }
    const deleteCodesArrItem = (code) => {
        setCodesArr(codesArr.filter(item => item !== code))
        setFilteredTnvCodes(filteredTnvCodes.map(i => i.code === code ? { ...i, status: true } : { ...i }))
    }
    const clearCodesArray = () => {
        setCodesArr([])
        setFilteredTnvCodes(filteredTnvCodes.map(item => true ? { ...item, status: true } : { ...item }))
    }


    // GROUPS
    const getGroup = async () => {
        setGroupLoading(true)
        await http.get('api/product/list')
            .then(res => {
                setGroups(res.data.products)
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setGroupLoading(false)
            })
    }
    const addGroup = () => {
        setLoading1(true)
        http.post('api/product/create', {
            title: name,
            product_codes: [...codesArr]
        }).then(res => {
            setIsModalOpen2(false)
            toast.success(res.data, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            setName('')
            setCodesArr([])
            setFilteredTnvCodes([...alltnvcodes])
            getGroup()
        }).catch(err => {
            toast.error(err.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }).finally(() => {
            setLoading1(false)
        })
    }
    const deleteGroup = (id) => {
        http.delete(`api/product/${id}`)
            .then(res => {
                toast.success("O'chirildi", {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
                getGroup()
            }).catch(err => {
                toast.error(err.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            })
    }



    // TABLE
    const addTableCodes = (item) => {
        if (!tableCodes.includes(item)) {
            setTableCodes(prev => [...prev, item])
        } else {
            setTableCodes(tableCodes.filter(i => i !== item))
        }
    }


    const submitForm = (e) => {
        e.preventDefault()
        let arr = []
        tableCodes?.forEach(i => {
            arr.push(i.id)
        })
        // console.log(arr)

        if (tableCodes?.length > 0) {
            setLoading(true)
            http.post(`api/report/group_product_code`, [...arr], {
                params: {
                    save: withStat,
                    mode: tog === "fcc" ? "import" : "export",
                    from_date: startDate ? startDate : null,
                    end_date: endDate ? endDate : null
                },
                responseType: "blob"
            })
                .then(res => {
                    fileDownload(res.data, 'hisobot.zip')
                }).catch(err => {
                    toast.error("Xatolik", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                }).finally(() => {
                    setLoading(false)
                })
        } else {
            toast.error("Iltimos kodni tanlang", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }
    const showNextModal = () => {
        if (name.length > 0) {
            setIsModalOpen(false)
            setIsModalOpen2(true)
        }
    }
    const searchTnv = () => {
        setFilteredTnvCodes(alltnvcodes.filter(item => item.code.toString().includes(searchCode) || item.description.includes(searchCode)))
    }

    useEffect(() => {
        searchTnv()
        // eslint-disable-next-line
    }, [searchCode])

    useEffect(() => {
        getGroup()
    }, [])



    // EDIT GROUP
    const [editedId, setEditedId] = useState('')
    const [editedName, setEditedName] = useState('')
    const [editedCodes, setEditedCodes] = useState([])

    const getById = (id) => {
        setIsEditModalOpen(true)

        http.get(`/api/product/${id}`)
            .then(res => {
                setEditedId(res.data.products[0].id)
                setEditedName(res.data.products[0].title)
                setEditedCodes(
                    res.data?.products[0]?.product_codes?.map(i => {
                        return i.code
                    })
                )
            }).catch(err => {
                console.error(err)
            })
    }

    const deleteCode = (code) => {
        setEditedCodes(editedCodes.filter(c => c !== code))
    }

    const submitEditedForm = (e) => {
        e.preventDefault()

        http.put(`/api/product/${editedId}`, {
            title: editedName,
            product_codes: editedCodes
        })
            .then(res => {
                toast.success(res.data, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
                setIsEditModalOpen(false)
                getGroup()
            }).catch(err => {
                console.log(err)
            })
    }

    return (
        <>
            <EditGroupModal
                isEditModalOpen={isEditModalOpen}
                setIsEditModalOpen={setIsEditModalOpen}
                editedName={editedName}
                setEditedName={setEditedName}
                editedCodes={editedCodes}
                setEditedCodes={setEditedCodes}
                submitEditedForm={submitEditedForm}
                deleteCode={deleteCode}
            />
            <form onSubmit={submitForm}>

                <Modal
                    open={isModalOpen}
                    onOk={() => setIsModalOpen(false)}
                    onCancel={() => setIsModalOpen(false)}
                    closeIcon={<MdClose />}
                    footer={null}
                >
                    <div className='label-input-wrap'>
                        <label>Nomi</label>
                        <input type='text' value={name} onChange={(e) => setName(e.target.value)} required />
                    </div>
                    <div className='next-div' onClick={showNextModal}>Keyingi</div>
                </Modal>

                <Modal
                    open={isModalOpen2}
                    onOk={() => setIsModalOpen2(false)}
                    onCancel={() => setIsModalOpen2(false)}
                    closeIcon={<MdClose />}
                    footer={null}
                    width={800}
                >
                    <h5>{name}</h5>
                    <div className='modal-search-wrap'>
                        <input type='text' className='search-input' placeholder='search' value={searchCode} onChange={(e) => setSearchCode(e.target.value)} />
                    </div>
                    <div className='modal-table-wrap'>
                        <table className='modal-table'>
                            <tbody>
                                {
                                    filteredTnvCodes?.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.code}</td>
                                                <td>{item.description ? item.description : '-'}</td>
                                                <td>
                                                    <div className='next-div' style={{ backgroundColor: item.status ? "#036323" : "#03632380" }}><MdAdd onClick={() => addCodesArr(item.code)} /></div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    <p className='clear-btn' onClick={clearCodesArray} style={{ display: codesArr.length === 0 ? 'none' : 'flex' }}>Tozalash</p>
                    <div className='added-items'>
                        {
                            codesArr?.map((item, index) => {
                                return (
                                    <div key={index} onClick={() => deleteCodesArrItem(item)}>{item}</div>
                                )
                            })
                        }
                    </div>

                    <div className='next-div' onClick={addGroup}>
                        {loading1 ? <Spinner animation="border" /> : null}
                        {loading1 ? null : 'Saqlash'}
                    </div>
                </Modal>



                <div className='add-icon' onClick={() => setIsModalOpen(true)}>
                    <MdAdd />
                </div>
                <div className='table-wrap table-wrap-second'>
                    {groupLoading ? <Loader /> : null}
                    {groups?.length === 0 ? <Empty /> : null}

                    <table>
                        <tbody>
                            {
                                groups?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className='id-wrap'>
                                                <p>{item.id}</p>
                                                <input type='checkbox' value={item?.product_codes} onChange={() => addTableCodes(item)} />
                                            </td>
                                            <td>{item.title}</td>
                                            <td className='codes'>
                                                <div>
                                                    {
                                                        item.product_codes?.map((p, i) => {
                                                            return (
                                                                <p key={i}>{p.code}</p>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </td>
                                            <td className='icons-wrap'>
                                                <div className='icon-wrap' onClick={() => deleteGroup(item.id)}>
                                                    <MdDelete />
                                                </div>
                                                <div className='icon-wrap' onClick={() => getById(item.id)}>
                                                    <MdEdit />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>

                <div className='toggle'>
                    <div className={tog === 'fcc' ? 'active-tog' : ''} onClick={() => setTog('fcc')}>FSS (export)</div>
                    <div className={tog === 'akd' ? 'active-tog' : ''} onClick={() => setTog('akd')}>AKD (import)</div>
                </div>

                <div className='btn-wrap'>
                    <input type='checkbox' title='Statistika bilan' checked={withStat} value={withStat} onChange={() => setWithStat(!withStat)} />
                    <button type='submit' className='load' disabled={loading}>
                        {loading ? <Spinner animation="border" /> : null}
                        {loading ? null : 'Yuklab olish'}
                    </button>
                </div>
            </form>
        </>
    )
}

export default Groups