import React, { useContext, useEffect, useState } from 'react'
import Atb from '../atb/Atb'
import HisobotNumber from '../hisobot-number/HisobotNumber'
import logoPng from '../../assets/images/logo.svg'
import './home.css'
import { RoleContext } from '../../App'
import Fond from '../fond/Fond'
import Fumigatsiya from '../fumigatsiya/Fumigatsiya'
import { ACCOUNTANT, ADMIN, ATB, COUNT, FOND, FUMEGATSIYA, STATISTIK_HISOBOT } from '../../constants/roles'
import Accountant from '../accountant/Accountant'
import StatistikHisobot from '../statistik-hisobot/StatistikHisobot'

const Home = () => {

    const role = useContext(RoleContext)
    // const role = "fond"

    const [show, setShow] = useState(role === FOND ? FOND : ATB)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const isAtb = (role === ADMIN || role === ATB) && show === 'atb' ? true : false
    const isHisobot = (role === ADMIN || role === COUNT) && show === 'number' ? true : false
    const isFond = (role === ADMIN || role === FOND) && (show === 'fond') ? true : false
    const isFumigat = (role === ADMIN || role === FUMEGATSIYA) && show === 'fumigat' ? true : false
    const isAccountant = (role === ADMIN || role === ACCOUNTANT || role === FOND) && show === 'accountant' ? true : false
    const isStatistikHisobot = (role === ADMIN || role === STATISTIK_HISOBOT) && show === 'statistikHisobot' ? true : false

    useEffect(() => {
        if (role === FOND) {
            setShow('fond')
        } else if (role === FUMEGATSIYA) {
            setShow('fumigat')
        } else if (role === ACCOUNTANT) {
            setShow('accountant')
        } else if (role === COUNT) {
            setShow('number')
        } else if (role === STATISTIK_HISOBOT) {
            setShow('statistikHisobot')
        } else {
            setShow(ATB)
        }
    }, [role])

    return (
        <div className='home-component'>
            <div className='nav-link-wrap'>
                <div className='logo-wrap'>
                    <img src={logoPng} alt='' />
                    <h1>O'ZBEKISTON RESPUBLIKASI <br />O'SIMLIKLAR KARANTINI VA <br />HIMOYASI AGENTLIGI</h1>
                </div>
                <div className='navs'>
                    {
                        isStatistikHisobot || role === ADMIN ? (
                            <div className={show === 'statistikHisobot' ? 'active' : ''} style={{ width: '300px' }} onClick={() => setShow('statistikHisobot')}>Statistik hisobot</div>
                        ) : null
                    }

                    {
                        isAccountant || isFond || role === ADMIN ? (
                            <div className={show === 'accountant' ? 'active' : ''} onClick={() => setShow('accountant')}>Buxgalteriya</div>
                        ) : null
                    }

                    {
                        isFumigat || role === ADMIN ? (
                            <div className={show === 'fumigat' ? 'active' : ''} onClick={() => setShow('fumigat')}>Fumigatsiya</div>
                        ) : null
                    }

                    {
                        isFond || role === ADMIN || show === 'accountant' ? (
                            <div className={show === 'fond' ? 'active' : ''} onClick={() => setShow('fond')} style={{ display: role === ACCOUNTANT ? 'none' : 'flex' }}>Fond</div>
                        ) : null
                    }

                    {
                        isHisobot || role === ADMIN ? (
                            <div className={show === 'number' ? 'active' : ''} onClick={() => setShow('number')}>Hisobot soni</div>
                        ) : null
                    }

                    {
                        isAtb || role === ADMIN ? (
                            <div className={show === 'atb' ? 'active' : ''} onClick={() => setShow('atb')} >ATB hisoboti</div>
                        ) : null
                    }
                </div>
            </div>

            <div className='topp'>
                <div></div>
                <div className='inp-wrap'>
                    <input type='date' value={startDate} onChange={e => setStartDate(e.target.value)} />
                    <input type='date' value={endDate} onChange={e => setEndDate(e.target.value)} />
                </div>
            </div>

            {isAtb ? <Atb startDate={startDate} endDate={endDate} /> : null}
            {isHisobot ? <HisobotNumber startDate={startDate} endDate={endDate} /> : null}
            {isFond ? <Fond startDate={startDate} endDate={endDate} /> : null}
            {isFumigat ? <Fumigatsiya startDate={startDate} endDate={endDate} /> : null}
            {isAccountant ? <Accountant startDate={startDate} endDate={endDate} /> : null}
            {isStatistikHisobot ? <StatistikHisobot startDate={startDate} endDate={endDate} /> : null}

        </div>
    )
}

export default Home