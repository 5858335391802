import React from 'react'
import { Modal } from 'antd'
import { MdAdd, MdClose } from 'react-icons/md'
import './editModal.css'
import { useState } from 'react'
import AddCodeEditedModal from './AddCodeEditedModal'

const EditGroupModal = (props) => {

    const { setIsEditModalOpen, isEditModalOpen, editedName, setEditedName, editedCodes, setEditedCodes, submitEditedForm, deleteCode } = props

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [codesArr, setCodesArr] = useState([]) // added tnv codes
    const [filteredTnvCodes, setFilteredTnvCodes] = useState([])

    const clearCodesArray = () => {
        setCodesArr([])
        setFilteredTnvCodes(filteredTnvCodes.map(item => true ? { ...item, status: true } : { ...item }))
    }

    const addCodes = () => {
        setIsModalOpen(false)
        setEditedCodes(prev => [...prev, ...codesArr])
        clearCodesArray()
    }

    return (
        <>
            <Modal
                open={isEditModalOpen}
                onOk={() => setIsEditModalOpen(false)}
                onCancel={() => setIsEditModalOpen(false)}
                closeIcon={<MdClose />}
                footer={null}
                centered
                title="Tahrirlash"
                className='edit-modal'
            >
                <form onSubmit={submitEditedForm}>
                    <div>
                        <input type='text' value={editedName} onChange={(e) => setEditedName(e.target.value)} required />
                    </div>
                    <div className='add-code-btn' onClick={() => setIsModalOpen(true)}>
                        <MdAdd />
                    </div>
                    <ul>
                        {
                            editedCodes?.map((item, index) => {
                                return (
                                    <li key={index} onClick={() => deleteCode(item)}>{item}</li>
                                )
                            })
                        }
                    </ul>
                    <button type='submit'>O'zgartirish</button>
                </form>
            </Modal>

            <AddCodeEditedModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                addCodes={addCodes}
                codesArr={codesArr}
                setCodesArr={setCodesArr}
                filteredTnvCodes={filteredTnvCodes}
                setFilteredTnvCodes={setFilteredTnvCodes}
                clearCodesArray={clearCodesArray}
            />
        </>
    )
}

export default EditGroupModal