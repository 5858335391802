import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { MdAdd, MdClose } from 'react-icons/md'
import { alltnvcodes } from '../../constants/tnv-codes'

const AddCodeEditedModal = (props) => {

    const { isModalOpen, setIsModalOpen, addCodes, codesArr, setCodesArr, filteredTnvCodes, setFilteredTnvCodes, clearCodesArray } = props

    const [searchCode, setSearchCode] = useState('')

    const searchTnv = () => {
        setFilteredTnvCodes(alltnvcodes.filter(item => item.code.toString().includes(searchCode) || item.description.includes(searchCode)))
    }
    const addCodesArr = (code) => {
        if (!codesArr.includes(code)) {
            setCodesArr(prev => [...prev, code])
            setFilteredTnvCodes(filteredTnvCodes.map(i => i.code === code ? { ...i, status: false } : { ...i }))
        }
    }
    const deleteCodesArrItem = (code) => {
        setCodesArr(codesArr.filter(item => item !== code))
        setFilteredTnvCodes(filteredTnvCodes.map(i => i.code === code ? { ...i, status: true } : { ...i }))
    }



    useEffect(() => {
        searchTnv()
        // eslint-disable-next-line
    }, [searchCode])

    return (
        <Modal
            open={isModalOpen}
            onOk={() => setIsModalOpen(false)}
            onCancel={() => setIsModalOpen(false)}
            closeIcon={<MdClose />}
            footer={null}
            width={800}
            centered
        >
            <div className='modal-search-wrap'>
                <input type='text' className='search-input' placeholder='search' value={searchCode} onChange={(e) => setSearchCode(e.target.value)} />
            </div>
            <div className='modal-table-wrap'>
                <table className='modal-table'>
                    <tbody>
                        {
                            filteredTnvCodes?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.code}</td>
                                        <td>{item.description ? item.description : '-'}</td>
                                        <td>
                                            <div className='next-div' style={{ backgroundColor: item.status ? "#036323" : "#03632380" }}><MdAdd onClick={() => addCodesArr(item.code)} /></div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            <p className='clear-btn' onClick={clearCodesArray} style={{ display: codesArr.length === 0 ? 'none' : 'flex' }}>Tozalash</p>
            <div className='added-items'>
                {
                    codesArr?.map((item, index) => {
                        return (
                            <div key={index} onClick={() => deleteCodesArrItem(item)}>{item}</div>
                        )
                    })
                }
            </div>

            <div className='next-div' onClick={addCodes}>Qo'shish</div>
        </Modal>
    )
}

export default AddCodeEditedModal