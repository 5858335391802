import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import fileDownload from 'js-file-download'
import '../atb/atb.css'
import http from '../../utils/axiosinterceptors'
import { BASE_URL } from '../../constants/constants'
// import Chart from "react-apexcharts";
// import Loader from '../loader/Loader'
// import { reFormat } from '../../utils/numberFormatter'

const Accountant = ({ startDate, endDate }) => {

    // const [withStat, setWithStat] = useState(false)
    const [loading, setLoading] = useState(false)

    const submitForm = (e) => {
        e.preventDefault()
        setLoading(true)

        http.post(`${BASE_URL}api/report/accountant`, {}, {
            params: {
                // save: withStat,
                from_date: startDate ? startDate : null,
                end_date: endDate ? endDate : null
            },
            responseType: "blob"
        })
            .then(res => {
                fileDownload(res.data, 'hisobot.zip')
            }).catch(err => {
                toast.error("Xatolik", {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }).finally(() => {
                setLoading(false)
            })
    }


    // const [loadingDashboard, setLoadingDashboard] = useState(true)
    // const [type, setType] = useState("price")
    // const [regions, setRegions] = useState([])
    // const [akdCount, setAkdCount] = useState([])
    // const [akdPrice, setAkdPrice] = useState([])
    // const [interFssCount, setInterFssCount] = useState([])
    // const [interFssPrice, setInterFssPrice] = useState([])
    // const [labCount, setLabCount] = useState([])
    // const [labPrice, setLabPrice] = useState([])
    // const [outFssCount, setOutFssCount] = useState([])
    // const [outFssPrice, setOutFssPrice] = useState([])
    // const [ikrCount, setIkrCount] = useState([])
    // const [ikrPrice, setIkrPrice] = useState([])

    // const getDashboard = async () => {

    //     setLoadingDashboard(true)

    //     await http.get(`${BASE_URL}api/report/accountant/dashboard`, {
    //         params: {
    //             from_date: startDate ? startDate : null,
    //             end_date: endDate ? endDate : null
    //         }
    //     }).then(res => {
    //         setRegions(res.data.region_data)

    //         setAkdCount(res.data.akd_data.count_data)
    //         setAkdPrice(res.data.akd_data.price_data)

    //         setInterFssCount(res.data.inter_fss_data.count_data)
    //         setInterFssPrice(res.data.inter_fss_data.price_data)

    //         setLabCount(res.data.lab_data.count_data)
    //         setLabPrice(res.data.lab_data.price_data)

    //         setOutFssCount(res.data.out_fss_data.count_data)
    //         setOutFssPrice(res.data.out_fss_data.price_data)

    //         setIkrCount(res.data.tr_ikr_data.count_data)
    //         setIkrPrice(res.data.tr_ikr_data.price_data)
    //     }).catch(err => {
    //         console.log(err)
    //     }).finally(() => {
    //         setLoadingDashboard(false)
    //     })
    // }

    // const [regionId, setRegionId] = useState(-1)
    // const changeRegion = (id) => {
    //     setRegionId(id)
    // }


    // const seriesCount = [
    //     {
    //         name: 'AKD Count (ta)',
    //         data: regionId >= 0 ? [akdCount[regionId]] : akdCount
    //     },
    //     {
    //         name: 'Inter FSS Count (ta)',
    //         data: regionId >= 0 ? [interFssCount[regionId]] : interFssCount
    //     },
    //     {
    //         name: 'LAB Count (ta)',
    //         data: regionId >= 0 ? [labCount[regionId]] : labCount
    //     },
    //     {
    //         name: 'Out FSS Count (ta)',
    //         data: regionId >= 0 ? [outFssCount[regionId]] : outFssCount
    //     },
    //     {
    //         name: 'IKR Count (ta)',
    //         data: regionId >= 0 ? [ikrCount[regionId]] : ikrCount
    //     }
    // ]

    // const seriesPrice = [
    //     {
    //         name: "AKD Price (so'm)",
    //         data: regionId >= 0 ? [akdPrice[regionId]] : akdPrice
    //     },
    //     {
    //         name: "Inter FSS Price (so'm)",
    //         data: regionId >= 0 ? [interFssPrice[regionId]] : interFssPrice
    //     },
    //     {
    //         name: "LAB Price (so'm)",
    //         data: regionId >= 0 ? [labPrice[regionId]] : labPrice
    //     },
    //     {
    //         name: "Out FSS Price (so'm)",
    //         data: regionId >= 0 ? [outFssPrice[regionId]] : outFssPrice
    //     },
    //     {
    //         name: "IKR Price (so'm)",
    //         data: regionId >= 0 ? [ikrPrice[regionId]] : ikrPrice
    //     }
    // ]

    // const barOptions = {
    //     chart: {
    //         type: 'bar',
    //     },
    //     plotOptions: {
    //         bar: {
    //             horizontal: false,
    //             columnWidth: '80%',
    //             endingShape: 'rounded'
    //         },
    //     },
    //     dataLabels: {
    //         enabled: false
    //     },
    //     stroke: {
    //         show: true,
    //         width: 4,
    //         colors: ['transparent']
    //     },
    //     xaxis: {
    //         categories: regionId >= 0 ? [regions[regionId]] : regions,
    //     },
    //     tooltip: {
    //         y: {
    //             formatter: function (val) {
    //                 return reFormat(val)
    //             }
    //         }
    //     },
    //     yaxis: {
    //         labels: {
    //             formatter: val => { return reFormat(val) }
    //         }
    //     },
    //     fill: {
    //         opacity: 1
    //     }
    // }


    // useEffect(() => {
    //     getDashboard()
    //     // eslint-disable-next-line
    // }, [endDate])

    return (
        <div>
            <h1>Buxgalteriya</h1>
            {/* <div className='selects-wrap'>
                <select className='select-option' value={type} onChange={(e) => setType(e.target.value)}>
                    <option value="count">Son bo'yicha</option>
                    <option value="price">Narx bo'yicha</option>
                </select>
                <select className='select-option' onChange={(e) => changeRegion(e.target.value)}>
                    <option value={-1}>Barchasi</option>
                    {
                        regions.map((item, index) => {
                            return (
                                <option key={index} value={index}>{item}</option>
                            )
                        })
                    }
                </select>
            </div> */}

            {/* <div className='row'>
                {
                    !loadingDashboard ? (
                        // <div className='col-xl-7 col-lg-10 col-md-12 m-auto'>
                        <div className='col-12'>
                            {
                                type === "count" ? (
                                    <Chart
                                        options={barOptions}
                                        series={seriesCount}
                                        type="bar"
                                        width="100%"
                                        height="600px"
                                    />
                                ) : (
                                    <Chart
                                        options={barOptions}
                                        series={seriesPrice}
                                        type="bar"
                                        width="100%"
                                        height="600px"
                                    />
                                )
                            }
                        </div>
                    ) : <Loader />
                }
            </div> */}

            <div className='atb-component'>
                <form onSubmit={submitForm}>
                    <div className='btn-wrap'>
                        {/* <input type='checkbox' title='Statistika bilan' checked={withStat} value={withStat} onChange={() => setWithStat(!withStat)} /> */}
                        <button type='submit' className='load' disabled={loading}>
                            {loading ? <Spinner animation="border" /> : null}
                            {loading ? null : 'Yuklab olish'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Accountant