import React, { createContext, useEffect } from 'react'
import './App.css'
import Home from './components/home/Home'
import { ToastContainer } from 'react-toastify'
import Login from './components/login/Login'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import DashboardAccountant from './components/accountant/DashboardAccountant'

export const RoleContext = createContext()

const App = () => {

  const role = document.cookie?.split(';')[0]?.split('=')[1]
  const token = document.cookie?.split(';')[1]?.split('=')[1]
  const auth = token ? true : false

  useEffect(() => {
  }, [])

  return (
    <RoleContext.Provider value={role}>
      <BrowserRouter>
        <ToastContainer />
        {
          auth ? (
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/dashboard-accountant' element={<DashboardAccountant />} />
            </Routes>
          ) : <Login />
        }
      </BrowserRouter>
    </RoleContext.Provider>
  )
}

export default App  