import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import fileDownload from 'js-file-download'
import Select from 'react-select'
import '../atb/atb.css'
import http from '../../utils/axiosinterceptors'

const Fumigatsiya = ({ startDate, endDate }) => {

    const [selectedValues, setSelectedValues] = useState(false)
    const [selectedValues2, setSelectedValues2] = useState(false)
    const [selectedValues3, setSelectedValues3] = useState(false)
    const [selectedValues4, setSelectedValues4] = useState(false)
    const [selectedValues5, setSelectedValues5] = useState(false)
    const [withStat, setWithStat] = useState(false)
    const [loading, setLoading] = useState(false)

    const options = [
        { value: 'Bлажная обработка пустых помещений, территорий, площадок и навесов', label: 'Bлажная обработка пустых помещений, территорий, площадок и навесов' },
        { value: 'Cкладские помещения', label: 'Cкладские помещения' },
        { value: 'Cаженцы', label: 'Cаженцы' },
        { value: 'Pазные грузы/ поставки', label: 'Pазные грузы/ поставки' },
        { value: 'Tехническая продукция', label: 'Tехническая продукция' },
        { value: 'Tранспортные средства', label: 'Tранспортные средства' },
        { value: 'Xлопковое волокно', label: 'Xлопковое волокно' },
        { value: 'Лекарственная продукция', label: 'Лекарственная продукция' },
        { value: 'Лесоматериал', label: 'Лесоматериал' },
        { value: 'Продукты питани', label: 'Продукты питани' },
        { value: 'Шелковая продукция (пилла)', label: 'Шелковая продукция (пилла)' }
    ]

    const options2 = [
        { value: 'Экспорт', label: 'Экспорт' },
        { value: 'Внутренний', label: 'Внутренний' },
        { value: 'Импорт', label: 'Импорт' }
    ]

    const options3 = [
        { value: 'Метилбромид', label: 'Метилбромид' },
        { value: 'Магнафос 66%', label: 'Магнафос 66%' },
        { value: 'Карбофос 50%', label: 'Карбофос 50%' },
        { value: 'Квикфос', label: 'Квикфос' },
        { value: 'Титаниум 10%', label: 'Титаниум 10%' },
    ]

    const options4 = [
        { value: 'Фумигация', label: 'Фумигация' },
        { value: 'Аэрозольная обработка', label: 'Аэрозольная обработка' },
        { value: 'Влажная Обработка', label: 'Влажная Обработка' },
        { value: 'Термическая обработка', label: 'Термическая обработка' },
    ]

    const options5 = [
        { value: 'Андижанская область', label: 'Андижанская область' },
        { value: 'Бухарская область', label: 'Бухарская область' },
        { value: 'г.Ташкент', label: 'г.Ташкент' },
        { value: 'Джизакская область', label: 'Джизакская область' },
        { value: 'Кашкадарьинская область', label: 'Кашкадарьинская область' },
        { value: 'Навоийская область', label: 'Навоийская область' },
        { value: 'Наманганская область', label: 'Наманганская область' },
        { value: 'Республики Каракалпакстан', label: 'Республики Каракалпакстан' },
        { value: 'Самаркандская область', label: 'Самаркандская область' },
        { value: 'Сурхандарьинская область', label: 'Сурхандарьинская область' },
        { value: 'Сырдарьинская область', label: 'Сырдарьинская область' },
        { value: 'Ташкентская область', label: 'Ташкентская область' },
        { value: 'Ферганская область', label: 'Ферганская область' },
        { value: 'Хорезмская  область', label: 'Хорезмская  область' },
    ]

    const submitForm = (e) => {
        e.preventDefault()
        setLoading(true)

        let arr = []
        for (let i = 0; i < selectedValues.length; i++) {
            arr.push(selectedValues[i].value)
        }

        let arr2 = []
        for (let i = 0; i < selectedValues2.length; i++) {
            arr2.push(selectedValues2[i].value)
        }

        let arr3 = []
        for (let i = 0; i < selectedValues3.length; i++) {
            arr3.push(selectedValues3[i].value)
        }

        let arr4 = []
        for (let i = 0; i < selectedValues4.length; i++) {
            arr4.push(selectedValues4[i].value)
        }

        let arr5 = []
        for (let i = 0; i < selectedValues5.length; i++) {
            arr5.push(selectedValues5[i].value)
        }

        http.post(`api/report/fumegatsiya`, {
            decontaminated_objects: arr,
            importer_exporters: arr2,
            drug_names: arr3,
            method_names: arr4,
            region_names: arr5
        },
            {
                params: {
                    save: withStat,
                    from_date: startDate ? startDate : null,
                    end_date: endDate ? endDate : null
                },
                responseType: "blob"
            })
            .then(res => {
                fileDownload(res.data, 'hisobot.zip')
            }).catch(err => {
                toast.error("Xatolik", {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }).finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h1>Fumigatsiya</h1>
            <div className='atb-component'>
                <form onSubmit={submitForm}>
                    <div className='btn-wrap btn-wrap2'>
                        <div className='checkbox-wrapper'>

                            <div className='react-select-wrap'>
                                <Select
                                    options={options}
                                    className='react-select-inp basic-multi-select'
                                    isMulti
                                    name="colors"
                                    classNamePrefix="select"
                                    onChange={(e) => setSelectedValues(e)}
                                    placeholder="Barchasi"
                                />
                                <Select
                                    options={options2}
                                    className='react-select-inp basic-multi-select'
                                    isMulti
                                    name="colors"
                                    classNamePrefix="select"
                                    onChange={(e) => setSelectedValues2(e)}
                                    placeholder="Barchasi"
                                />
                            </div>

                            <div className='react-select-wrap'>
                                <Select
                                    options={options3}
                                    className='react-select-inp basic-multi-select'
                                    isMulti
                                    name="colors"
                                    classNamePrefix="select"
                                    onChange={(e) => setSelectedValues3(e)}
                                    placeholder="Barchasi"
                                />
                                <Select
                                    options={options4}
                                    className='react-select-inp basic-multi-select'
                                    isMulti
                                    name="colors"
                                    classNamePrefix="select"
                                    onChange={(e) => setSelectedValues4(e)}
                                    placeholder="Barchasi"
                                />
                            </div>

                            <div className='react-select-wrap'>
                                <Select
                                    options={options5}
                                    className='react-select-inp react-select-inp-w basic-multi-select'
                                    isMulti
                                    name="colors"
                                    classNamePrefix="select"
                                    onChange={(e) => setSelectedValues5(e)}
                                    placeholder="Barchasi"
                                />
                            </div>

                        </div>

                        <div className='btn-wrap'>
                            <input type='checkbox' title='Dalil bilan' checked={withStat} value={withStat} onChange={() => setWithStat(!withStat)} />
                            <button type='submit' className='load' disabled={loading}>
                                {loading ? <Spinner animation="border" /> : null}
                                {loading ? null : 'Yuklab olish'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Fumigatsiya