import React from 'react'
import '../home/dashboard.css'
import { Link } from 'react-router-dom'
import Chart from "react-apexcharts";

const DashboardAccountant = () => {

    const series = [
        {
            name: 'ATB',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 12, 3, 43, 100]
        }, {
            name: 'AKD',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 12, 3, 23, 30]
        }, {
            name: 'FSS',
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 22, 10, 55, 69]
        }
    ]

    const barOptions = {
        chart: {
            type: 'bar',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Andijan', 'Bukhara', 'Fergana', 'Jizzakh', 'Xorazm', 'Namangan', 'Navoiy', 'Qashqadaryo', 'Samarqand', 'Sirdaryo', 'Surxondaryo', 'Tashkent', 'Karakalpakstan'],
        },
        yaxis: {
            title: {
                text: '$ (thousands)'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$ " + val + " thousands"
                }
            }
        }
    }

    var lineOptions = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Product Trends by Month',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Andijan', 'Bukhara', 'Fergana', 'Jizzakh', 'Xorazm', 'Namangan', 'Navoiy', 'Qashqadaryo', 'Samarqand', 'Sirdaryo', 'Surxondaryo', 'Tashkent', 'Karakalpakstan'],
        }
    };


    return (
        <div className='dashboard-page'>
            <div className='top'>
                <Link to='/'>
                    <button>Orqaga</button>
                </Link>
            </div>

            <div className='row'>
                <div className='col-6'>
                    <Chart
                        options={barOptions}
                        series={series}
                        type="bar"
                    />
                </div>
                <div className='col-6'>
                    <Chart
                        options={lineOptions}
                        series={series}
                        type="line"
                    />
                </div>
            </div>
        </div>
    )
}

export default DashboardAccountant