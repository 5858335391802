import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { alltnvcodes } from '../../constants/tnv-codes'
import fileDownload from 'js-file-download'
import { MdClose } from 'react-icons/md'
import http from '../../utils/axiosinterceptors'

const SimpleAtb = ({ startDate, endDate }) => {


    const [withStat, setWithStat] = useState(false)
    const [loading, setLoading] = useState(false)
    const [tog, setTog] = useState('fcc')
    const [searchCode, setSearchCode] = useState('')
    const [filteredTnvCodes, setFilteredTnvCodes] = useState([])
    const [codesArr, setCodesArr] = useState([]) // added tnv codes

    const addCodesArr = (code) => {
        if (!codesArr.includes(code)) {
            setCodesArr(prev => [...prev, code])
            setFilteredTnvCodes(filteredTnvCodes.map(i => i.code === code ? { ...i, status: false } : { ...i }))
        } else {
            // setCodesArr(codesArr.filter(item => item !== code))
        }
    }

    const deleteCodesArrItem = (code) => {
        setCodesArr(codesArr.filter(item => item !== code))
        setFilteredTnvCodes(filteredTnvCodes.map(i => i.code === code ? { ...i, status: true } : { ...i }))
    }


    const submitForm = (e) => {
        e.preventDefault()

        if (codesArr.length === 0) {
            toast.error("TNV kodlardan kamida bittasini tanlang", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } else {
            setLoading(true)

            http.post(`api/report/product_code`, [...codesArr], {
                params: {
                    save: withStat,
                    mode: tog === "fcc" ? "import" : "export",
                    from_date: startDate ? startDate : null,
                    end_date: endDate ? endDate : null
                },
                responseType: "blob"
            })
                .then(res => {
                    fileDownload(res.data, 'hisobot.zip')
                }).catch(err => {
                    toast.error("Xatolik", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    })
                }).finally(() => {
                    setLoading(false)
                })
        }
    }


    // SEARCH FUNCTIONS
    const searchTnv = () => {
        setFilteredTnvCodes(alltnvcodes.filter(item => item.code.toString().includes(searchCode) || item.description.includes(searchCode)))
    }

    useEffect(() => {
        searchTnv()
        // eslint-disable-next-line
    }, [searchCode])


    return (
        <form onSubmit={submitForm}>
            <div className='search-wrap'>
                <input type='text' value={searchCode} onChange={e => setSearchCode(e.target.value)} placeholder="search" />
            </div>

            <div className='added-codes'>
                {
                    codesArr?.map((item, index) => {
                        return (
                            <div key={index}>
                                <p>{item}</p>
                                <MdClose className='icon' onClick={() => deleteCodesArrItem(item)} />
                            </div>
                        )
                    })
                }
            </div>

            <div className='table-wrap'>
                <table>
                    <tbody>
                        {
                            filteredTnvCodes?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.code}</td>
                                        <td>{item.description ? item.description : '-'}</td>
                                        <td><div className='add-div' style={{ backgroundColor: item.status ? '#036323' : '#03632380' }} onClick={() => addCodesArr(item.code)}>{item.status ? "Qo'shish" : "Qo'shildi"}</div></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            <div className='toggle'>
                <div className={tog === 'fcc' ? 'active-tog' : ''} onClick={() => setTog('fcc')}>FSS (export)</div>
                <div className={tog === 'akd' ? 'active-tog' : ''} onClick={() => setTog('akd')}>AKD (import)</div>
            </div>

            <div className='btn-wrap'>
                <input type='checkbox' title='Statistika bilan' checked={withStat} value={withStat} onChange={() => setWithStat(!withStat)} />
                <button type='submit' className='load' disabled={loading}>
                    {loading ? <Spinner animation="border" /> : null}
                    {loading ? null : 'Yuklab olish'}
                </button>
            </div>
        </form>
    )
}

export default SimpleAtb