export const alltnvcodes = [
  {
    "id": 1775,
    "status": true,
    "code": "0",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-26 21:07:14.386898",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1190,
    "status": true,
    "code": "0101210000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1473,
    "status": true,
    "code": "0102219000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-30 12:09:34.809699",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1451,
    "status": true,
    "code": "0102291000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:11:18.558372",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1481,
    "status": true,
    "code": "0106410002",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-03 18:59:48.662140",
    "updated_at": "2021-04-21 13:40:32.397662"
  },
  {
    "id": 1562,
    "status": true,
    "code": "0106410003",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-08 20:08:53.170369",
    "updated_at": "2021-04-21 13:40:32.400389"
  },
  {
    "id": 2227,
    "status": true,
    "code": "0106410008",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-09-16 11:47:23.831812",
    "updated_at": "2022-09-16 11:47:23.831824"
  },
  {
    "id": 2121,
    "status": true,
    "code": "0106490001",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-01-27 16:08:47.978337",
    "updated_at": "2022-01-27 16:08:47.978350"
  },
  {
    "id": 1,
    "status": true,
    "code": "0305100000",
    "name": 0,
    "description": "nan",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:09:10.230755"
  },
  {
    "id": 2216,
    "status": true,
    "code": "0305430000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-11 17:15:03.389320",
    "updated_at": "2022-08-11 17:15:03.389330"
  },
  {
    "id": 2217,
    "status": true,
    "code": "0305498010",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-11 17:15:03.439617",
    "updated_at": "2022-08-11 17:15:03.439626"
  },
  {
    "id": 1613,
    "status": true,
    "code": "0402101100",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-24 10:18:42.696910",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1499,
    "status": true,
    "code": "0402101900",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-02 16:08:56.542626",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2054,
    "status": true,
    "code": "0402109900",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-09 17:48:25.566607",
    "updated_at": "2021-09-09 17:48:25.566619"
  },
  {
    "id": 2195,
    "status": true,
    "code": "0402991000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-14 14:19:47.215116",
    "updated_at": "2022-06-14 14:19:47.215128"
  },
  {
    "id": 1534,
    "status": true,
    "code": "0403905102",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-23 00:39:09.982585",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2259,
    "status": true,
    "code": "0403909100",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-12-27 11:59:39.870706",
    "updated_at": "2022-12-27 11:59:39.870715"
  },
  {
    "id": 1575,
    "status": true,
    "code": "0404902300",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-12 13:08:50.351367",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1753,
    "status": true,
    "code": "0404908100",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-15 12:05:47.776349",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2137,
    "status": true,
    "code": "0405101900",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-02-28 12:53:12.029388",
    "updated_at": "2022-02-28 12:53:12.029397"
  },
  {
    "id": 2182,
    "status": true,
    "code": "0405105000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-05-26 17:31:39.091146",
    "updated_at": "2022-05-26 17:31:39.091157"
  },
  {
    "id": 1496,
    "status": true,
    "code": "0406909900",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-30 22:19:19.089088",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1706,
    "status": true,
    "code": "0407110000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-12 10:21:24.269834",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1263,
    "status": true,
    "code": "0407210000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:40:08.343085",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1312,
    "status": true,
    "code": "0409000000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:43:39.567362",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2097,
    "status": true,
    "code": "0410000000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-09 14:43:25.738555",
    "updated_at": "2021-12-09 14:43:25.738565"
  },
  {
    "id": 1643,
    "status": true,
    "code": "0501000000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-06 11:23:51.964131",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2211,
    "status": true,
    "code": "0504000000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-04 18:59:54.976698",
    "updated_at": "2022-08-04 18:59:54.976714"
  },
  {
    "id": 2174,
    "status": true,
    "code": "0511991000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-05-14 19:14:03.856089",
    "updated_at": "2022-05-14 19:14:03.856105"
  },
  {
    "id": 2024,
    "status": true,
    "code": "0511998510",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-07-06 16:23:54.056493",
    "updated_at": "2021-07-06 16:23:54.056506"
  },
  {
    "id": 1978,
    "status": true,
    "code": "0511998592",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-20 11:51:39.721437",
    "updated_at": "2021-04-20 11:51:39.721447"
  },
  {
    "id": 1825,
    "status": true,
    "code": "0511998599",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-10 17:01:42.709352",
    "updated_at": "2020-11-10 17:01:42.709361"
  },
  {
    "id": 2,
    "status": true,
    "code": "0601101000",
    "name": 1,
    "description": "Луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетативного покоя, вегетации или цветения; растения и корни цикория, кроме морских водорослей:->луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетативного покоя",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.417970"
  },
  {
    "id": 3,
    "status": true,
    "code": "0601102000",
    "name": 2,
    "description": "Луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетативного покоя, вегетации или цветения; растения и корни цикория, кроме морских водорослей:->луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетативного покоя",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.427437"
  },
  {
    "id": 4,
    "status": true,
    "code": "0601103000",
    "name": 3,
    "description": "Луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетативного покоя, вегетации или цветения; растения и корни цикория, кроме морских водорослей:->луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетативного покоя",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:08:57.889837"
  },
  {
    "id": 5,
    "status": true,
    "code": "0601104000",
    "name": 4,
    "description": "Луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетативного покоя, вегетации или цветения; растения и корни цикория, кроме морских водорослей:->луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетативного покоя",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:07:22.797989"
  },
  {
    "id": 6,
    "status": true,
    "code": "0601109000",
    "name": 5,
    "description": "Луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетативного покоя, вегетации или цветения; растения и корни цикория, кроме морских водорослей:->луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетативного покоя",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:08:49.073330"
  },
  {
    "id": 7,
    "status": true,
    "code": "0601201000",
    "name": 6,
    "description": "Луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетативного покоя, вегетации или цветения; растения и корни цикория, кроме морских водорослей:->луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетации или цветения; растения и корни цикория:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:07:36.404953"
  },
  {
    "id": 8,
    "status": true,
    "code": "0601203000",
    "name": 7,
    "description": "Луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетативного покоя, вегетации или цветения; растения и корни цикория, кроме морских водорослей:->луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетации или цветения; растения и корни цикория:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:07:46.727728"
  },
  {
    "id": 9,
    "status": true,
    "code": "0601209000",
    "name": 8,
    "description": "Луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетативного покоя, вегетации или цветения; растения и корни цикория, кроме морских водорослей:->луковицы, клубни, клубневидные корни, клубнелуковицы, корневища, включая разветвленные, находящиеся в состоянии вегетации или цветения; растения и корни цикория:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:07:54.926460"
  },
  {
    "id": 10,
    "status": true,
    "code": "0602101000",
    "name": 9,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:->неукорененные черенки и отводки:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:08:03.139169"
  },
  {
    "id": 11,
    "status": true,
    "code": "0602109000",
    "name": 10,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:->неукорененные черенки и отводки:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:08:11.809908"
  },
  {
    "id": 12,
    "status": true,
    "code": "0602201000",
    "name": 11,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:->деревья, кустарники и кустарнички, привитые или непривитые, приносящие съедобные плоды или орехи:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:08:18.188029"
  },
  {
    "id": 13,
    "status": true,
    "code": "0602202000",
    "name": 12,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:->деревья, кустарники и кустарнички, привитые или непривитые, приносящие съедобные плоды или орехи:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:08:27.299656"
  },
  {
    "id": 14,
    "status": true,
    "code": "0602203000",
    "name": 13,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:->деревья, кустарники и кустарнички, привитые или непривитые, приносящие съедобные плоды или орехи:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:08:36.294352"
  },
  {
    "id": 15,
    "status": true,
    "code": "0602208000",
    "name": 14,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:->деревья, кустарники и кустарнички, привитые или непривитые, приносящие съедобные плоды или орехи:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:08:42.388407"
  },
  {
    "id": 16,
    "status": true,
    "code": "0602300000",
    "name": 15,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:->деревья, кустарники и кустарнички, привитые или непривитые, приносящие съедобные плоды или орехи:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.472600"
  },
  {
    "id": 2336,
    "status": true,
    "code": "060240000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-19 14:48:01.319509",
    "updated_at": "2023-01-19 14:48:01.319520"
  },
  {
    "id": 17,
    "status": true,
    "code": "0602400000",
    "name": 16,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:->деревья, кустарники и кустарнички, привитые или непривитые, приносящие съедобные плоды или орехи:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.474846"
  },
  {
    "id": 18,
    "status": true,
    "code": "0602901000",
    "name": 17,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:-> c",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:30:54.217970"
  },
  {
    "id": 19,
    "status": true,
    "code": "0602902000",
    "name": 18,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:-> c",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:09:59.755832"
  },
  {
    "id": 1678,
    "status": true,
    "code": "060290300",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-23 12:52:59.142013",
    "updated_at": "2021-04-21 13:40:32.470430"
  },
  {
    "id": 20,
    "status": true,
    "code": "0602903000",
    "name": 19,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:-> c",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:12:04.974259"
  },
  {
    "id": 21,
    "status": true,
    "code": "0602904100",
    "name": 20,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:-> c->деревья, кустарники и кустарнички:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-19 23:19:42.165677"
  },
  {
    "id": 22,
    "status": true,
    "code": "0602904500",
    "name": 21,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:-> c->деревья, кустарники и кустарнички:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:09:44.330162"
  },
  {
    "id": 23,
    "status": true,
    "code": "0602904600",
    "name": 22,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:-> c->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:10:38.472716"
  },
  {
    "id": 24,
    "status": true,
    "code": "0602904700",
    "name": 23,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:-> c->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:10:44.384250"
  },
  {
    "id": 25,
    "status": true,
    "code": "0602904800",
    "name": 24,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:-> c->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:10:50.322837"
  },
  {
    "id": 26,
    "status": true,
    "code": "0602905000",
    "name": 25,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:-> c->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:10:59.215509"
  },
  {
    "id": 27,
    "status": true,
    "code": "0602907000",
    "name": 26,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:-> c->растения для защищенного грунта:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:11:08.336620"
  },
  {
    "id": 28,
    "status": true,
    "code": "0602909100",
    "name": 27,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:-> c->растения для защищенного грунта:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:11:16.031427"
  },
  {
    "id": 2134,
    "status": true,
    "code": "06029099",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-02-15 18:07:18.800483",
    "updated_at": "2022-02-15 18:07:18.800501"
  },
  {
    "id": 29,
    "status": true,
    "code": "0602909900",
    "name": 28,
    "description": "Прочие живые растения (включая их корни), черенки и отводки; мицелий гриба:-> c->растения для защищенного грунта:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:11:22.123927"
  },
  {
    "id": 30,
    "status": true,
    "code": "0603110000",
    "name": 29,
    "description": "Срезанные цветы и бутоны, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->свежие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 31,
    "status": true,
    "code": "0603120000",
    "name": 30,
    "description": "Срезанные цветы и бутоны, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->свежие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 32,
    "status": true,
    "code": "0603130000",
    "name": 31,
    "description": "Срезанные цветы и бутоны, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->свежие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 33,
    "status": true,
    "code": "0603140000",
    "name": 32,
    "description": "Срезанные цветы и бутоны, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->свежие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 34,
    "status": true,
    "code": "0603150000",
    "name": 33,
    "description": "Срезанные цветы и бутоны, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->свежие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 35,
    "status": true,
    "code": "0603191000",
    "name": 34,
    "description": "Срезанные цветы и бутоны, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 36,
    "status": true,
    "code": "0603192000",
    "name": 35,
    "description": "Срезанные цветы и бутоны, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 37,
    "status": true,
    "code": "0603197000",
    "name": 36,
    "description": "Срезанные цветы и бутоны, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 38,
    "status": true,
    "code": "0603900000",
    "name": 37,
    "description": "Срезанные цветы и бутоны, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 39,
    "status": true,
    "code": "0604201100",
    "name": 38,
    "description": "Листья, ветки и другие части растений без цветков или бутонов, травы, мхи и лишайники, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->свежие:->мхи и лишайники:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.478417"
  },
  {
    "id": 40,
    "status": true,
    "code": "0604201900",
    "name": 39,
    "description": "Листья, ветки и другие части растений без цветков или бутонов, травы, мхи и лишайники, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->свежие:->мхи и лишайники:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.480677"
  },
  {
    "id": 41,
    "status": true,
    "code": "0604202000",
    "name": 40,
    "description": "Листья, ветки и другие части растений без цветков или бутонов, травы, мхи и лишайники, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->свежие:->мхи и лишайники:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.482887"
  },
  {
    "id": 42,
    "status": true,
    "code": "0604204000",
    "name": 41,
    "description": "Листья, ветки и другие части растений без цветков или бутонов, травы, мхи и лишайники, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->свежие:->мхи и лишайники:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.485031"
  },
  {
    "id": 43,
    "status": true,
    "code": "0604209000",
    "name": 42,
    "description": "Листья, ветки и другие части растений без цветков или бутонов, травы, мхи и лишайники, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->свежие:->мхи и лишайники:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.487203"
  },
  {
    "id": 44,
    "status": true,
    "code": "0604901100",
    "name": 43,
    "description": "Листья, ветки и другие части растений без цветков или бутонов, травы, мхи и лишайники, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->прочие:->мхи и лишайники:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.489396"
  },
  {
    "id": 1177,
    "status": true,
    "code": "0604901900",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.491594"
  },
  {
    "id": 45,
    "status": true,
    "code": "0604909100",
    "name": 44,
    "description": "Листья, ветки и другие части растений без цветков или бутонов, травы, мхи и лишайники, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->прочие:->мхи и лишайники:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.493879"
  },
  {
    "id": 46,
    "status": true,
    "code": "0604909900",
    "name": 45,
    "description": "Листья, ветки и другие части растений без цветков или бутонов, травы, мхи и лишайники, пригодные для составления букетов или для декоративных целей, свежие, засушенные, окрашенные, отбеленные, пропитанные или подготовленные другими способами:->прочие:->мхи и лишайники:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.496366"
  },
  {
    "id": 47,
    "status": true,
    "code": "0701100000",
    "name": 46,
    "description": "Картофель свежий или охлажденный:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:32:20.903285"
  },
  {
    "id": 48,
    "status": true,
    "code": "0701901000",
    "name": 47,
    "description": "Картофель свежий или охлажденный:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:31:55.748981"
  },
  {
    "id": 49,
    "status": true,
    "code": "0701905000",
    "name": 48,
    "description": "Картофель свежий или охлажденный:->прочий:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:35:27.008488"
  },
  {
    "id": 50,
    "status": true,
    "code": "0701909000",
    "name": 49,
    "description": "Картофель свежий или охлажденный:->прочий:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:29:25.170982"
  },
  {
    "id": 51,
    "status": true,
    "code": "0702000001",
    "name": 50,
    "description": "Картофель свежий или охлажденный:->прочий:->прочий:->Томаты свежие или охлажденные",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-16 16:43:30.630574"
  },
  {
    "id": 52,
    "status": true,
    "code": "0702000002",
    "name": 51,
    "description": "Картофель свежий или охлажденный:->прочий:->прочий:->Томаты свежие или охлажденные",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-16 16:43:02.021418"
  },
  {
    "id": 53,
    "status": true,
    "code": "0702000003",
    "name": 52,
    "description": "Картофель свежий или охлажденный:->прочий:->прочий:->Томаты свежие или охлажденные",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-12 17:45:27.041665"
  },
  {
    "id": 54,
    "status": true,
    "code": "0702000004",
    "name": 53,
    "description": "Картофель свежий или охлажденный:->прочий:->прочий:->Томаты свежие или охлажденные",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-16 16:42:37.193526"
  },
  {
    "id": 55,
    "status": true,
    "code": "0702000005",
    "name": 54,
    "description": "Картофель свежий или охлажденный:->прочий:->прочий:->Томаты свежие или охлажденные",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:10:30.520993"
  },
  {
    "id": 56,
    "status": true,
    "code": "0702000006",
    "name": 55,
    "description": "Картофель свежий или охлажденный:->прочий:->прочий:->Томаты свежие или охлажденные",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-16 16:43:23.286754"
  },
  {
    "id": 57,
    "status": true,
    "code": "0702000007",
    "name": 56,
    "description": "Картофель свежий или охлажденный:->прочий:->прочий:->Томаты свежие или охлажденные",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-16 16:43:36.814423"
  },
  {
    "id": 58,
    "status": true,
    "code": "0702000009",
    "name": 57,
    "description": "Картофель свежий или охлажденный:->прочий:->прочий:->Томаты свежие или охлажденные",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-16 16:43:44.634791"
  },
  {
    "id": 59,
    "status": true,
    "code": "0703101100",
    "name": 58,
    "description": "Лук репчатый, лук шалот, чеснок, лук-порей и прочие луковичные овощи, свежие или охлажденные:->лук репчатый и лук шалот:->лук репчатый:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:32:03.922619"
  },
  {
    "id": 2198,
    "status": true,
    "code": "070310190",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-25 13:42:42.280045",
    "updated_at": "2022-06-25 13:42:42.280059"
  },
  {
    "id": 60,
    "status": true,
    "code": "0703101900",
    "name": 59,
    "description": "Лук репчатый, лук шалот, чеснок, лук-порей и прочие луковичные овощи, свежие или охлажденные:->лук репчатый и лук шалот:->лук репчатый:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:29:47.636029"
  },
  {
    "id": 1985,
    "status": true,
    "code": "0703101901",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-04 10:54:38.709600",
    "updated_at": "2021-05-04 10:54:38.709614"
  },
  {
    "id": 61,
    "status": true,
    "code": "0703109000",
    "name": 60,
    "description": "Лук репчатый, лук шалот, чеснок, лук-порей и прочие луковичные овощи, свежие или охлажденные:->лук репчатый и лук шалот:->лук репчатый:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:31:21.773318"
  },
  {
    "id": 62,
    "status": true,
    "code": "0703200000",
    "name": 61,
    "description": "Лук репчатый, лук шалот, чеснок, лук-порей и прочие луковичные овощи, свежие или охлажденные:->лук репчатый и лук шалот:->лук репчатый:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:31:41.199215"
  },
  {
    "id": 63,
    "status": true,
    "code": "0703900000",
    "name": 62,
    "description": "Лук репчатый, лук шалот, чеснок, лук-порей и прочие луковичные овощи, свежие или охлажденные:->лук репчатый и лук шалот:->лук репчатый:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:31:48.323847"
  },
  {
    "id": 64,
    "status": true,
    "code": "0704100000",
    "name": 63,
    "description": "Капуста кочанная, капуста цветная, кольраби, капуста листовая и аналогичные съедобные овощи из рода Brassica, свежие или охлажденные:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:32:28.174162"
  },
  {
    "id": 65,
    "status": true,
    "code": "0704200000",
    "name": 64,
    "description": "Капуста кочанная, капуста цветная, кольраби, капуста листовая и аналогичные съедобные овощи из рода Brassica, свежие или охлажденные:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:30:39.717354"
  },
  {
    "id": 66,
    "status": true,
    "code": "0704901001",
    "name": 65,
    "description": "Капуста кочанная, капуста цветная, кольраби, капуста листовая и аналогичные съедобные овощи из рода Brassica, свежие или охлажденные:->прочие:->белокочанная и краснокочанная капуста",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:31:04.628714"
  },
  {
    "id": 67,
    "status": true,
    "code": "0704901009",
    "name": 66,
    "description": "Капуста кочанная, капуста цветная, кольраби, капуста листовая и аналогичные съедобные овощи из рода Brassica, свежие или охлажденные:->прочие:->белокочанная и краснокочанная капуста",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:33:30.045196"
  },
  {
    "id": 2272,
    "status": true,
    "code": "0704908000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 14:12:26.437833",
    "updated_at": "2023-02-10 15:00:21.403026"
  },
  {
    "id": 2162,
    "status": true,
    "code": "070490900",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-08 11:44:01.988742",
    "updated_at": "2022-04-08 11:44:01.988752"
  },
  {
    "id": 68,
    "status": true,
    "code": "0704909000",
    "name": 67,
    "description": "Капуста кочанная, капуста цветная, кольраби, капуста листовая и аналогичные съедобные овощи из рода Brassica, свежие или охлажденные:->прочие:->белокочанная и краснокочанная капуста",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:33:12.387218"
  },
  {
    "id": 69,
    "status": true,
    "code": "0705110000",
    "name": 68,
    "description": "Салат-латук (Lactuca sativa) и цикорий (Cichorium spp.), свежие или охлажденные:->салат-латук:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:33:05.856742"
  },
  {
    "id": 70,
    "status": true,
    "code": "0705190000",
    "name": 69,
    "description": "Салат-латук (Lactuca sativa) и цикорий (Cichorium spp.), свежие или охлажденные:->салат-латук:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:13:20.040991"
  },
  {
    "id": 71,
    "status": true,
    "code": "0705210000",
    "name": 70,
    "description": "Салат-латук (Lactuca sativa) и цикорий (Cichorium spp.), свежие или охлажденные:->цикорий:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:13:36.529520"
  },
  {
    "id": 72,
    "status": true,
    "code": "0705290000",
    "name": 71,
    "description": "Салат-латук (Lactuca sativa) и цикорий (Cichorium spp.), свежие или охлажденные:->цикорий:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:13:26.582724"
  },
  {
    "id": 73,
    "status": true,
    "code": "0706100001",
    "name": 72,
    "description": "Морковь, репа, свекла столовая, козлобородник, сельдерей корневой, редис и прочие аналогичные съедобные корнеплоды, свежие или охлажденные:->морковь и репа",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:32:51.357557"
  },
  {
    "id": 74,
    "status": true,
    "code": "0706100009",
    "name": 73,
    "description": "Морковь, репа, свекла столовая, козлобородник, сельдерей корневой, редис и прочие аналогичные съедобные корнеплоды, свежие или охлажденные:->морковь и репа",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:33:43.439394"
  },
  {
    "id": 75,
    "status": true,
    "code": "0706901000",
    "name": 74,
    "description": "Морковь, репа, свекла столовая, козлобородник, сельдерей корневой, редис и прочие аналогичные съедобные корнеплоды, свежие или охлажденные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:13:49.296345"
  },
  {
    "id": 76,
    "status": true,
    "code": "0706903000",
    "name": 75,
    "description": "Морковь, репа, свекла столовая, козлобородник, сельдерей корневой, редис и прочие аналогичные съедобные корнеплоды, свежие или охлажденные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.552529"
  },
  {
    "id": 77,
    "status": true,
    "code": "0706909001",
    "name": 76,
    "description": "Морковь, репа, свекла столовая, козлобородник, сельдерей корневой, редис и прочие аналогичные съедобные корнеплоды, свежие или охлажденные:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.557307"
  },
  {
    "id": 78,
    "status": true,
    "code": "0706909009",
    "name": 77,
    "description": "Морковь, репа, свекла столовая, козлобородник, сельдерей корневой, редис и прочие аналогичные съедобные корнеплоды, свежие или охлажденные:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:13:55.506677"
  },
  {
    "id": 2166,
    "status": true,
    "code": "070700050",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-15 18:36:39.656038",
    "updated_at": "2022-08-04 22:54:24.448911"
  },
  {
    "id": 79,
    "status": true,
    "code": "0707000501",
    "name": 78,
    "description": "Морковь, репа, свекла столовая, козлобородник, сельдерей корневой, редис и прочие аналогичные съедобные корнеплоды, свежие или охлажденные:->Огурцы и корнишоны, свежие или охлажденные:->огурцы\nс 1 января по конец февраля",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.560866"
  },
  {
    "id": 80,
    "status": true,
    "code": "0707000502",
    "name": 79,
    "description": "Морковь, репа, свекла столовая, козлобородник, сельдерей корневой, редис и прочие аналогичные съедобные корнеплоды, свежие или охлажденные:->Огурцы и корнишоны, свежие или охлажденные:->огурцы\nс 1 марта по 30 апреля",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.563107"
  },
  {
    "id": 81,
    "status": true,
    "code": "0707000503",
    "name": 80,
    "description": "Морковь, репа, свекла столовая, козлобородник, сельдерей корневой, редис и прочие аналогичные съедобные корнеплоды, свежие или охлажденные:->Огурцы и корнишоны, свежие или охлажденные:->огурцы\n с 1 мая по 15 мая",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:14:02.681405"
  },
  {
    "id": 82,
    "status": true,
    "code": "0707000504",
    "name": 81,
    "description": "Морковь, репа, свекла столовая, козлобородник, сельдерей корневой, редис и прочие аналогичные съедобные корнеплоды, свежие или охлажденные:->Огурцы и корнишоны, свежие или охлажденные:->огурцы\nс 16 мая по 30 сентября",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:14:08.205306"
  },
  {
    "id": 83,
    "status": true,
    "code": "0707000505",
    "name": 82,
    "description": "Морковь, репа, свекла столовая, козлобородник, сельдерей корневой, редис и прочие аналогичные съедобные корнеплоды, свежие или охлажденные:->Огурцы и корнишоны, свежие или охлажденные:->огурцы\nс 1 октября по 31 октября",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:12:55.665285"
  },
  {
    "id": 84,
    "status": true,
    "code": "0707000506",
    "name": 83,
    "description": "Морковь, репа, свекла столовая, козлобородник, сельдерей корневой, редис и прочие аналогичные съедобные корнеплоды, свежие или охлажденные:->Огурцы и корнишоны, свежие или охлажденные:->огурцы\nс 1 ноября по 10 ноября",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:13:01.513659"
  },
  {
    "id": 85,
    "status": true,
    "code": "0707000509",
    "name": 84,
    "description": "Морковь, репа, свекла столовая, козлобородник, сельдерей корневой, редис и прочие аналогичные съедобные корнеплоды, свежие или охлажденные:->Огурцы и корнишоны, свежие или охлажденные:->огурцы\nс 11 ноября по 31 декабря",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:13:08.354073"
  },
  {
    "id": 86,
    "status": true,
    "code": "0707009000",
    "name": 85,
    "description": "Морковь, репа, свекла столовая, козлобородник, сельдерей корневой, редис и прочие аналогичные съедобные корнеплоды, свежие или охлажденные:->Огурцы и корнишоны, свежие или охлажденные:->огурцы",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:13:13.964993"
  },
  {
    "id": 87,
    "status": true,
    "code": "0708100000",
    "name": 86,
    "description": "Бобовые овощи, лущеные или нелущеные, свежие или охлажденные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.579406"
  },
  {
    "id": 88,
    "status": true,
    "code": "0708200000",
    "name": 87,
    "description": "Бобовые овощи, лущеные или нелущеные, свежие или охлажденные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:33:20.598616"
  },
  {
    "id": 89,
    "status": true,
    "code": "0708900000",
    "name": 88,
    "description": "Бобовые овощи, лущеные или нелущеные, свежие или охлажденные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.583678"
  },
  {
    "id": 90,
    "status": true,
    "code": "0709200000",
    "name": 89,
    "description": "Овощи прочие, свежие или охлажденные:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:34:09.232120"
  },
  {
    "id": 91,
    "status": true,
    "code": "0709300000",
    "name": 90,
    "description": "Овощи прочие, свежие или охлажденные:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:33:27.504214"
  },
  {
    "id": 92,
    "status": true,
    "code": "0709400000",
    "name": 91,
    "description": "Овощи прочие, свежие или охлажденные:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:32:58.601830"
  },
  {
    "id": 1438,
    "status": true,
    "code": "0709400001",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:07:45.038819",
    "updated_at": "2021-04-21 13:40:32.643444"
  },
  {
    "id": 93,
    "status": true,
    "code": "0709510000",
    "name": 92,
    "description": "Овощи прочие, свежие или охлажденные:->грибы и трюфели:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:33:33.488633"
  },
  {
    "id": 94,
    "status": true,
    "code": "0709591000",
    "name": 93,
    "description": "Овощи прочие, свежие или охлажденные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-11-01 18:34:01.002025"
  },
  {
    "id": 95,
    "status": true,
    "code": "0709593000",
    "name": 94,
    "description": "Овощи прочие, свежие или охлажденные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:10:21.076229"
  },
  {
    "id": 96,
    "status": true,
    "code": "0709595000",
    "name": 95,
    "description": "Овощи прочие, свежие или охлажденные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-08-04 19:01:21.424343"
  },
  {
    "id": 97,
    "status": true,
    "code": "0709599000",
    "name": 96,
    "description": "Овощи прочие, свежие или охлажденные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:31:52.685479"
  },
  {
    "id": 98,
    "status": true,
    "code": "0709601000",
    "name": 97,
    "description": "Овощи прочие, свежие или охлажденные:->плоды рода Capsicum или рода Pimenta:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-21 00:32:48.951544"
  },
  {
    "id": 2013,
    "status": true,
    "code": "0709601001",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-15 13:54:01.969794",
    "updated_at": "2023-01-26 23:46:09.035362"
  },
  {
    "id": 2167,
    "status": true,
    "code": "0709601002",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-15 21:35:12.844019",
    "updated_at": "2023-01-31 12:24:42.539507"
  },
  {
    "id": 99,
    "status": true,
    "code": "0709609100",
    "name": 98,
    "description": "Овощи прочие, свежие или охлажденные:->плоды рода Capsicum или рода Pimenta:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:17:45.723401"
  },
  {
    "id": 100,
    "status": true,
    "code": "0709609500",
    "name": 99,
    "description": "Овощи прочие, свежие или охлажденные:->плоды рода Capsicum или рода Pimenta:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:17:54.268588"
  },
  {
    "id": 101,
    "status": true,
    "code": "0709609900",
    "name": 100,
    "description": "Овощи прочие, свежие или охлажденные:->плоды рода Capsicum или рода Pimenta:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-02-24 19:02:27.112331"
  },
  {
    "id": 102,
    "status": true,
    "code": "0709700000",
    "name": 101,
    "description": "Овощи прочие, свежие или охлажденные:->плоды рода Capsicum или рода Pimenta:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:12:47.368153"
  },
  {
    "id": 103,
    "status": true,
    "code": "0709910000",
    "name": 102,
    "description": "Овощи прочие, свежие или охлажденные:->плоды рода Capsicum или рода Pimenta:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:12:55.770215"
  },
  {
    "id": 104,
    "status": true,
    "code": "0709921000",
    "name": 103,
    "description": "Овощи прочие, свежие или охлажденные:->маслины, или оливки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-01-17 17:41:56.584095"
  },
  {
    "id": 105,
    "status": true,
    "code": "0709929000",
    "name": 104,
    "description": "Овощи прочие, свежие или охлажденные:->маслины, или оливки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:20:44.852400"
  },
  {
    "id": 106,
    "status": true,
    "code": "0709931000",
    "name": 105,
    "description": "Овощи прочие, свежие или охлажденные:->тыквы, кабачки и прочие овощи рода тыква (Cucurbitaspp.):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:35:08.160384"
  },
  {
    "id": 107,
    "status": true,
    "code": "0709939000",
    "name": 106,
    "description": "Овощи прочие, свежие или охлажденные:->тыквы, кабачки и прочие овощи рода тыква (Cucurbitaspp.):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:21:01.138307"
  },
  {
    "id": 108,
    "status": true,
    "code": "0709991000",
    "name": 107,
    "description": "Овощи прочие, свежие или охлажденные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:13:34.959783"
  },
  {
    "id": 109,
    "status": true,
    "code": "0709992000",
    "name": 108,
    "description": "Овощи прочие, свежие или охлажденные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:13:04.444008"
  },
  {
    "id": 110,
    "status": true,
    "code": "0709994000",
    "name": 109,
    "description": "Овощи прочие, свежие или охлажденные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:13:22.304094"
  },
  {
    "id": 111,
    "status": true,
    "code": "0709995000",
    "name": 110,
    "description": "Овощи прочие, свежие или охлажденные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:13:28.784917"
  },
  {
    "id": 112,
    "status": true,
    "code": "0709996000",
    "name": 111,
    "description": "Овощи прочие, свежие или охлажденные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.638547"
  },
  {
    "id": 113,
    "status": true,
    "code": "0709999000",
    "name": 112,
    "description": "Овощи прочие, свежие или охлажденные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:21:20.253202"
  },
  {
    "id": 114,
    "status": true,
    "code": "0710100000",
    "name": 113,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-03-05 12:59:10.030535"
  },
  {
    "id": 115,
    "status": true,
    "code": "0710210000",
    "name": 114,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->бобовые овощи, лущеные или нелущеные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 116,
    "status": true,
    "code": "0710220000",
    "name": 115,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->бобовые овощи, лущеные или нелущеные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 117,
    "status": true,
    "code": "0710290000",
    "name": 116,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->бобовые овощи, лущеные или нелущеные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 118,
    "status": true,
    "code": "0710300000",
    "name": 117,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->бобовые овощи, лущеные или нелущеные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 119,
    "status": true,
    "code": "0710400000",
    "name": 118,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->бобовые овощи, лущеные или нелущеные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 120,
    "status": true,
    "code": "0710801000",
    "name": 119,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->прочие овощи:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 121,
    "status": true,
    "code": "0710805100",
    "name": 120,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->прочие овощи:->плоды рода Capsicum или рода Pimenta:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 122,
    "status": true,
    "code": "0710805900",
    "name": 121,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->прочие овощи:->плоды рода Capsicum или рода Pimenta:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 123,
    "status": true,
    "code": "0710806100",
    "name": 122,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->прочие овощи:->грибы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 124,
    "status": true,
    "code": "0710806900",
    "name": 123,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->прочие овощи:->грибы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 125,
    "status": true,
    "code": "0710807000",
    "name": 124,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->прочие овощи:->грибы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 126,
    "status": true,
    "code": "0710808000",
    "name": 125,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->прочие овощи:->грибы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 127,
    "status": true,
    "code": "0710808500",
    "name": 126,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->прочие овощи:->грибы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 128,
    "status": true,
    "code": "0710809500",
    "name": 127,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->прочие овощи:->грибы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 129,
    "status": true,
    "code": "0710900000",
    "name": 128,
    "description": "Овощи (сырые или сваренные в воде или на пару) замороженные:->прочие овощи:->грибы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 130,
    "status": true,
    "code": "0711201000",
    "name": 129,
    "description": "Овощи консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->маслины, или оливки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 131,
    "status": true,
    "code": "0711209000",
    "name": 130,
    "description": "Овощи консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->маслины, или оливки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 132,
    "status": true,
    "code": "0711400000",
    "name": 131,
    "description": "Овощи консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->маслины, или оливки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 133,
    "status": true,
    "code": "0711510000",
    "name": 132,
    "description": "Овощи консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->маслины, или оливки:->грибы и трюфели:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 134,
    "status": true,
    "code": "0711590000",
    "name": 133,
    "description": "Овощи консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->маслины, или оливки:->грибы и трюфели:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 135,
    "status": true,
    "code": "0711901000",
    "name": 134,
    "description": "Овощи консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->овощи прочие; овощные смеси:->овощи:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 136,
    "status": true,
    "code": "0711903000",
    "name": 135,
    "description": "Овощи консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->овощи прочие; овощные смеси:->овощи:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 137,
    "status": true,
    "code": "0711905000",
    "name": 136,
    "description": "Овощи консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->овощи прочие; овощные смеси:->овощи:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1307,
    "status": true,
    "code": "0711907000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:43:14.635320",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 138,
    "status": true,
    "code": "0711908000",
    "name": 137,
    "description": "Овощи консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->овощи прочие; овощные смеси:->овощи:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 139,
    "status": true,
    "code": "0711909000",
    "name": 138,
    "description": "Овощи консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->овощи прочие; овощные смеси:->овощи:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 140,
    "status": true,
    "code": "0712200000",
    "name": 139,
    "description": "Овощи сушеные, целые, нарезанные кусками, ломтиками, измельченные или в виде порошка, но не подвергнутые дальнейшей обработке:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.279679"
  },
  {
    "id": 141,
    "status": true,
    "code": "0712310000",
    "name": 140,
    "description": "Овощи сушеные, целые, нарезанные кусками, ломтиками, измельченные или в виде порошка, но не подвергнутые дальнейшей обработке:->грибы, древесные уши, или аурикулярии (Auricularia spp.), дрожалковые грибы (Tremella spp.) и трюфели:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.302489"
  },
  {
    "id": 142,
    "status": true,
    "code": "0712320000",
    "name": 141,
    "description": "Овощи сушеные, целые, нарезанные кусками, ломтиками, измельченные или в виде порошка, но не подвергнутые дальнейшей обработке:->грибы, древесные уши, или аурикулярии (Auricularia spp.), дрожалковые грибы (Tremella spp.) и трюфели:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.281770"
  },
  {
    "id": 143,
    "status": true,
    "code": "0712330000",
    "name": 142,
    "description": "Овощи сушеные, целые, нарезанные кусками, ломтиками, измельченные или в виде порошка, но не подвергнутые дальнейшей обработке:->грибы, древесные уши, или аурикулярии (Auricularia spp.), дрожалковые грибы (Tremella spp.) и трюфели:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.283849"
  },
  {
    "id": 144,
    "status": true,
    "code": "0712390000",
    "name": 143,
    "description": "Овощи сушеные, целые, нарезанные кусками, ломтиками, измельченные или в виде порошка, но не подвергнутые дальнейшей обработке:->грибы, древесные уши, или аурикулярии (Auricularia spp.), дрожалковые грибы (Tremella spp.) и трюфели:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.287490"
  },
  {
    "id": 145,
    "status": true,
    "code": "0712900500",
    "name": 144,
    "description": "Овощи сушеные, целые, нарезанные кусками, ломтиками, измельченные или в виде порошка, но не подвергнутые дальнейшей обработке:->овощи прочие; овощные смеси:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.289698"
  },
  {
    "id": 146,
    "status": true,
    "code": "0712901100",
    "name": 145,
    "description": "Овощи сушеные, целые, нарезанные кусками, ломтиками, измельченные или в виде порошка, но не подвергнутые дальнейшей обработке:->овощи прочие; овощные смеси:->кукурузасахарная (Zea mays var. saccharata):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:35:01.545026"
  },
  {
    "id": 147,
    "status": true,
    "code": "0712901900",
    "name": 146,
    "description": "Овощи сушеные, целые, нарезанные кусками, ломтиками, измельченные или в виде порошка, но не подвергнутые дальнейшей обработке:->овощи прочие; овощные смеси:->кукурузасахарная (Zea mays var. saccharata):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:21:40.567577"
  },
  {
    "id": 148,
    "status": true,
    "code": "0712903000",
    "name": 147,
    "description": "Овощи сушеные, целые, нарезанные кусками, ломтиками, измельченные или в виде порошка, но не подвергнутые дальнейшей обработке:->овощи прочие; овощные смеси:->кукурузасахарная (Zea mays var. saccharata):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.293925"
  },
  {
    "id": 149,
    "status": true,
    "code": "0712905000",
    "name": 148,
    "description": "Овощи сушеные, целые, нарезанные кусками, ломтиками, измельченные или в виде порошка, но не подвергнутые дальнейшей обработке:->овощи прочие; овощные смеси:->кукурузасахарная (Zea mays var. saccharata):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.296017"
  },
  {
    "id": 150,
    "status": true,
    "code": "0712909000",
    "name": 149,
    "description": "Овощи сушеные, целые, нарезанные кусками, ломтиками, измельченные или в виде порошка, но не подвергнутые дальнейшей обработке:->овощи прочие; овощные смеси:->кукурузасахарная (Zea mays var. saccharata):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.298142"
  },
  {
    "id": 1384,
    "status": true,
    "code": "0713100001",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:56:05.600274",
    "updated_at": "2021-04-21 13:40:32.719785"
  },
  {
    "id": 151,
    "status": true,
    "code": "0713101000",
    "name": 150,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->горох (Pisum sativum):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:31:40.088875"
  },
  {
    "id": 152,
    "status": true,
    "code": "0713109000",
    "name": 151,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->горох (Pisum sativum):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:35:36.049816"
  },
  {
    "id": 2271,
    "status": true,
    "code": "0713109009",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 12:52:22.661750",
    "updated_at": "2023-01-09 23:49:12.658277"
  },
  {
    "id": 153,
    "status": true,
    "code": "0713200000",
    "name": 152,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->горох (Pisum sativum):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:22:43.667261"
  },
  {
    "id": 1363,
    "status": true,
    "code": "0713310000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:51:32.950894",
    "updated_at": "2021-04-21 13:40:32.717634"
  },
  {
    "id": 154,
    "status": true,
    "code": "0713310001",
    "name": 153,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->горох (Pisum sativum):->фасоль (Vigna spp., Phaseolus spp.):->фасоль видов Vigna mungo (L.) Hepper или Vigna radiata (L.) Wilczek:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-19 17:32:54.756822"
  },
  {
    "id": 1874,
    "status": true,
    "code": "0713310002",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 16:13:07.993278",
    "updated_at": "2021-04-21 13:40:32.764134"
  },
  {
    "id": 1873,
    "status": true,
    "code": "0713310003",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 16:11:35.672029",
    "updated_at": "2021-04-21 13:40:32.762025"
  },
  {
    "id": 1872,
    "status": true,
    "code": "0713310004",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 16:09:56.148795",
    "updated_at": "2021-04-21 13:40:32.759852"
  },
  {
    "id": 1871,
    "status": true,
    "code": "0713310005",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 16:08:35.247247",
    "updated_at": "2021-04-21 13:40:32.757714"
  },
  {
    "id": 1870,
    "status": true,
    "code": "0713310006",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 16:07:12.456771",
    "updated_at": "2021-04-21 13:40:32.755558"
  },
  {
    "id": 1869,
    "status": true,
    "code": "0713310007",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 16:05:40.224579",
    "updated_at": "2021-04-21 13:40:32.753465"
  },
  {
    "id": 1868,
    "status": true,
    "code": "0713310008",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 16:01:15.791120",
    "updated_at": "2021-04-21 13:40:32.751418"
  },
  {
    "id": 155,
    "status": true,
    "code": "0713310009",
    "name": 154,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->горох (Pisum sativum):->фасоль (Vigna spp., Phaseolus spp.):->фасоль видов Vigna mungo (L.) Hepper или Vigna radiata (L.) Wilczek:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:24:20.911619"
  },
  {
    "id": 1867,
    "status": true,
    "code": "0713310010",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 15:58:08.575885",
    "updated_at": "2021-04-21 13:40:32.749335"
  },
  {
    "id": 1866,
    "status": true,
    "code": "0713310011",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 15:51:59.144435",
    "updated_at": "2021-04-21 13:40:32.747267"
  },
  {
    "id": 1865,
    "status": true,
    "code": "0713310012",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 15:48:37.737759",
    "updated_at": "2021-04-21 13:40:32.745190"
  },
  {
    "id": 1864,
    "status": true,
    "code": "0713310013",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 15:47:07.307790",
    "updated_at": "2021-04-21 13:40:32.743109"
  },
  {
    "id": 1863,
    "status": true,
    "code": "0713310014",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 15:45:34.816981",
    "updated_at": "2021-04-21 13:40:32.740858"
  },
  {
    "id": 1862,
    "status": true,
    "code": "0713310015",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 15:43:57.522493",
    "updated_at": "2021-04-21 13:40:32.738638"
  },
  {
    "id": 1861,
    "status": true,
    "code": "0713310016",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 15:42:14.290187",
    "updated_at": "2021-04-21 13:40:32.736515"
  },
  {
    "id": 1860,
    "status": true,
    "code": "0713310017",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 15:40:22.792165",
    "updated_at": "2021-04-21 13:40:32.734398"
  },
  {
    "id": 1859,
    "status": true,
    "code": "0713310018",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 15:37:30.739272",
    "updated_at": "2021-04-21 13:40:32.732301"
  },
  {
    "id": 1857,
    "status": true,
    "code": "0713310019",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 15:28:07.766190",
    "updated_at": "2021-04-21 13:40:32.728134"
  },
  {
    "id": 1858,
    "status": true,
    "code": "0713310020",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-11 15:35:43.603023",
    "updated_at": "2021-04-21 13:40:32.730230"
  },
  {
    "id": 156,
    "status": true,
    "code": "0713320000",
    "name": 155,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->горох (Pisum sativum):->фасоль (Vigna spp., Phaseolus spp.):->фасоль видов Vigna mungo (L.) Hepper или Vigna radiata (L.) Wilczek:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:22:16.468506"
  },
  {
    "id": 157,
    "status": true,
    "code": "0713331000",
    "name": 156,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->фасоль обыкновенная, включая белую мелкосеменную фасоль (Phaseolus vulgaris):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-13 20:14:32.403164"
  },
  {
    "id": 158,
    "status": true,
    "code": "0713339000",
    "name": 157,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->фасоль обыкновенная, включая белую мелкосеменную фасоль (Phaseolus vulgaris):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:22:23.386626"
  },
  {
    "id": 159,
    "status": true,
    "code": "0713340000",
    "name": 158,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->фасоль обыкновенная, включая белую мелкосеменную фасоль (Phaseolus vulgaris):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.704984"
  },
  {
    "id": 160,
    "status": true,
    "code": "0713350000",
    "name": 159,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->фасоль обыкновенная, включая белую мелкосеменную фасоль (Phaseolus vulgaris):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.707091"
  },
  {
    "id": 161,
    "status": true,
    "code": "0713390000",
    "name": 160,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->фасоль обыкновенная, включая белую мелкосеменную фасоль (Phaseolus vulgaris):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:24:49.649176"
  },
  {
    "id": 2352,
    "status": true,
    "code": "0713390001",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-30 16:02:35.920593",
    "updated_at": "2023-01-30 16:02:35.920604"
  },
  {
    "id": 2275,
    "status": true,
    "code": "0713390009",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 15:07:36.283592",
    "updated_at": "2023-01-05 15:07:36.283603"
  },
  {
    "id": 162,
    "status": true,
    "code": "0713400000",
    "name": 161,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->фасоль обыкновенная, включая белую мелкосеменную фасоль (Phaseolus vulgaris):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:35:14.499182"
  },
  {
    "id": 163,
    "status": true,
    "code": "0713500000",
    "name": 162,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->фасоль обыкновенная, включая белую мелкосеменную фасоль (Phaseolus vulgaris):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:24:36.251825"
  },
  {
    "id": 164,
    "status": true,
    "code": "0713600000",
    "name": 163,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->фасоль обыкновенная, включая белую мелкосеменную фасоль (Phaseolus vulgaris):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:22:30.400155"
  },
  {
    "id": 165,
    "status": true,
    "code": "0713900000",
    "name": 164,
    "description": "Овощи бобовые сушеные, лущеные, очищенные от семенной кожуры или неочищенные, колотые или неколотые:->фасоль обыкновенная, включая белую мелкосеменную фасоль (Phaseolus vulgaris):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:22:37.923798"
  },
  {
    "id": 166,
    "status": true,
    "code": "0714100001",
    "name": 165,
    "description": "Маниок, маранта, салеп, земляная груша, или топинамбур, сладкий картофель, или батат, и аналогичные корнеплоды и клубнеплоды с высоким содержанием крахмала или инулина, свежие, охлажденные, замороженные или сушеные, целые или нарезанные ломтиками или в виде гранул; сердцевина саговой пальмы:->маниок (кассава)",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:24:28.490103"
  },
  {
    "id": 167,
    "status": true,
    "code": "0714100009",
    "name": 166,
    "description": "Маниок, маранта, салеп, земляная груша, или топинамбур, сладкий картофель, или батат, и аналогичные корнеплоды и клубнеплоды с высоким содержанием крахмала или инулина, свежие, охлажденные, замороженные или сушеные, целые или нарезанные ломтиками или в виде гранул; сердцевина саговой пальмы:->маниок (кассава)",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:24:56.383758"
  },
  {
    "id": 168,
    "status": true,
    "code": "0714201000",
    "name": 167,
    "description": "Маниок, маранта, салеп, земляная груша, или топинамбур, сладкий картофель, или батат, и аналогичные корнеплоды и клубнеплоды с высоким содержанием крахмала или инулина, свежие, охлажденные, замороженные или сушеные, целые или нарезанные ломтиками или в виде гранул; сердцевина саговой пальмы:->сладкий картофель, или батат:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:33:36.398321"
  },
  {
    "id": 169,
    "status": true,
    "code": "0714209000",
    "name": 168,
    "description": "Маниок, маранта, салеп, земляная груша, или топинамбур, сладкий картофель, или батат, и аналогичные корнеплоды и клубнеплоды с высоким содержанием крахмала или инулина, свежие, охлажденные, замороженные или сушеные, целые или нарезанные ломтиками или в виде гранул; сердцевина саговой пальмы:->сладкий картофель, или батат:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:29:55.413655"
  },
  {
    "id": 170,
    "status": true,
    "code": "0714300000",
    "name": 169,
    "description": "Маниок, маранта, салеп, земляная груша, или топинамбур, сладкий картофель, или батат, и аналогичные корнеплоды и клубнеплоды с высоким содержанием крахмала или инулина, свежие, охлажденные, замороженные или сушеные, целые или нарезанные ломтиками или в виде гранул; сердцевина саговой пальмы:->сладкий картофель, или батат:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:23:57.743933"
  },
  {
    "id": 171,
    "status": true,
    "code": "0714400000",
    "name": 170,
    "description": "Маниок, маранта, салеп, земляная груша, или топинамбур, сладкий картофель, или батат, и аналогичные корнеплоды и клубнеплоды с высоким содержанием крахмала или инулина, свежие, охлажденные, замороженные или сушеные, целые или нарезанные ломтиками или в виде гранул; сердцевина саговой пальмы:->сладкий картофель, или батат:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:35:20.836291"
  },
  {
    "id": 172,
    "status": true,
    "code": "0714500000",
    "name": 171,
    "description": "Маниок, маранта, салеп, земляная груша, или топинамбур, сладкий картофель, или батат, и аналогичные корнеплоды и клубнеплоды с высоким содержанием крахмала или инулина, свежие, охлажденные, замороженные или сушеные, целые или нарезанные ломтиками или в виде гранул; сердцевина саговой пальмы:->сладкий картофель, или батат:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:23:46.458299"
  },
  {
    "id": 173,
    "status": true,
    "code": "0714902000",
    "name": 172,
    "description": "Маниок, маранта, салеп, земляная груша, или топинамбур, сладкий картофель, или батат, и аналогичные корнеплоды и клубнеплоды с высоким содержанием крахмала или инулина, свежие, охлажденные, замороженные или сушеные, целые или нарезанные ломтиками или в виде гранул; сердцевина саговой пальмы:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:24:12.169900"
  },
  {
    "id": 174,
    "status": true,
    "code": "0714909000",
    "name": 173,
    "description": "Маниок, маранта, салеп, земляная груша, или топинамбур, сладкий картофель, или батат, и аналогичные корнеплоды и клубнеплоды с высоким содержанием крахмала или инулина, свежие, охлажденные, замороженные или сушеные, целые или нарезанные ломтиками или в виде гранул; сердцевина саговой пальмы:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:24:05.248513"
  },
  {
    "id": 1712,
    "status": true,
    "code": "080100000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-17 10:52:09.667958",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 175,
    "status": true,
    "code": "0801110000",
    "name": 174,
    "description": "Орехи кокосовые, орехи бразильские и орехи кешью, свежие или сушеные, очищенные от скорлупы или не очищенные, с кожурой или без кожуры:->орехи кокосовые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 89639.5,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-18 09:17:46.381904"
  },
  {
    "id": 176,
    "status": true,
    "code": "0801120000",
    "name": 175,
    "description": "Орехи кокосовые, орехи бразильские и орехи кешью, свежие или сушеные, очищенные от скорлупы или не очищенные, с кожурой или без кожуры:->орехи кокосовые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 89639.5,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-18 09:18:04.361192"
  },
  {
    "id": 177,
    "status": true,
    "code": "0801190000",
    "name": 176,
    "description": "Орехи кокосовые, орехи бразильские и орехи кешью, свежие или сушеные, очищенные от скорлупы или не очищенные, с кожурой или без кожуры:->орехи кокосовые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:23:52.309277"
  },
  {
    "id": 178,
    "status": true,
    "code": "0801210000",
    "name": 177,
    "description": "Орехи кокосовые, орехи бразильские и орехи кешью, свежие или сушеные, очищенные от скорлупы или не очищенные, с кожурой или без кожуры:->орехи бразильские:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 89639.5,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-18 09:18:43.867149"
  },
  {
    "id": 179,
    "status": true,
    "code": "0801220000",
    "name": 178,
    "description": "Орехи кокосовые, орехи бразильские и орехи кешью, свежие или сушеные, очищенные от скорлупы или не очищенные, с кожурой или без кожуры:->орехи бразильские:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 89639.5,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-18 09:20:43.041414"
  },
  {
    "id": 180,
    "status": true,
    "code": "0801310000",
    "name": 179,
    "description": "Орехи кокосовые, орехи бразильские и орехи кешью, свежие или сушеные, очищенные от скорлупы или не очищенные, с кожурой или без кожуры:->орехи кешью:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 89639.5,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-18 09:21:11.679025"
  },
  {
    "id": 181,
    "status": true,
    "code": "0801320000",
    "name": 180,
    "description": "Орехи кокосовые, орехи бразильские и орехи кешью, свежие или сушеные, очищенные от скорлупы или не очищенные, с кожурой или без кожуры:->орехи кешью:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 89639.5,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-18 09:21:28.956401"
  },
  {
    "id": 182,
    "status": true,
    "code": "0802111000",
    "name": 181,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->в скорлупе:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:03:06.939626"
  },
  {
    "id": 183,
    "status": true,
    "code": "0802119000",
    "name": 182,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->в скорлупе:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:02:55.552979"
  },
  {
    "id": 184,
    "status": true,
    "code": "0802121000",
    "name": 183,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->очищенный от скорлупы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:03:24.379489"
  },
  {
    "id": 185,
    "status": true,
    "code": "0802129000",
    "name": 184,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->очищенный от скорлупы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:03:17.119472"
  },
  {
    "id": 186,
    "status": true,
    "code": "0802210000",
    "name": 185,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->очищенный от скорлупы:->орех лесной, или лещина (Corylus spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:03:35.580671"
  },
  {
    "id": 187,
    "status": true,
    "code": "0802220000",
    "name": 186,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->очищенный от скорлупы:->орех лесной, или лещина (Corylus spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:03:42.369889"
  },
  {
    "id": 188,
    "status": true,
    "code": "0802310000",
    "name": 187,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->очищенный от скорлупы:->орехи грецкие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-08-04 19:25:30.404477"
  },
  {
    "id": 189,
    "status": true,
    "code": "0802320000",
    "name": 188,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->очищенный от скорлупы:->орехи грецкие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:02:30.893894"
  },
  {
    "id": 190,
    "status": true,
    "code": "0802410000",
    "name": 189,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->очищенный от скорлупы:->каштаны (Castanea spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:02:45.759386"
  },
  {
    "id": 191,
    "status": true,
    "code": "0802420000",
    "name": 190,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->очищенный от скорлупы:->каштаны (Castanea spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:05:46.902343"
  },
  {
    "id": 192,
    "status": true,
    "code": "0802510000",
    "name": 191,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->очищенный от скорлупы:->фисташки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-07-22 10:52:42.109372"
  },
  {
    "id": 193,
    "status": true,
    "code": "0802520000",
    "name": 192,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->очищенный от скорлупы:->фисташки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:05:35.119746"
  },
  {
    "id": 194,
    "status": true,
    "code": "0802610000",
    "name": 193,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->очищенный от скорлупы:->орехи макадамии:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:04:43.305567"
  },
  {
    "id": 195,
    "status": true,
    "code": "0802620000",
    "name": 194,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->очищенный от скорлупы:->орехи макадамии:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:07:46.106267"
  },
  {
    "id": 196,
    "status": true,
    "code": "0802700000",
    "name": 195,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->очищенный от скорлупы:->орехи макадамии:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:03:49.506210"
  },
  {
    "id": 197,
    "status": true,
    "code": "0802800000",
    "name": 196,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->очищенный от скорлупы:->орехи макадамии:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:04:50.703802"
  },
  {
    "id": 198,
    "status": true,
    "code": "0802901000",
    "name": 197,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:04:22.883471"
  },
  {
    "id": 199,
    "status": true,
    "code": "0802905000",
    "name": 198,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:04:05.077784"
  },
  {
    "id": 200,
    "status": true,
    "code": "0802908500",
    "name": 199,
    "description": "Прочие орехи, свежие или сушеные, очищенные от скорлупы или неочищенные, с кожурой или без кожуры:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:04:13.836581"
  },
  {
    "id": 2298,
    "status": true,
    "code": "0802920000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-06 16:53:14.887982",
    "updated_at": "2023-01-06 16:53:14.887992"
  },
  {
    "id": 2341,
    "status": true,
    "code": "0802991000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-24 16:14:53.905595",
    "updated_at": "2023-01-24 16:14:53.905605"
  },
  {
    "id": 2289,
    "status": true,
    "code": "0802999000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 20:42:04.564151",
    "updated_at": "2023-01-05 20:42:04.564161"
  },
  {
    "id": 201,
    "status": true,
    "code": "0803101000",
    "name": 200,
    "description": "Бананы, включая плантайны, свежие или сушеные:->плантайны:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:23:40.461166"
  },
  {
    "id": 202,
    "status": true,
    "code": "0803109000",
    "name": 201,
    "description": "Бананы, включая плантайны, свежие или сушеные:->плантайны:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:26:32.636748"
  },
  {
    "id": 203,
    "status": true,
    "code": "0803901000",
    "name": 202,
    "description": "Бананы, включая плантайны, свежие или сушеные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:26:24.844079"
  },
  {
    "id": 204,
    "status": true,
    "code": "0803909000",
    "name": 203,
    "description": "Бананы, включая плантайны, свежие или сушеные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:23:22.073718"
  },
  {
    "id": 205,
    "status": true,
    "code": "0804100001",
    "name": 204,
    "description": "Финики, инжир, ананасы, авокадо, гуайява, манго и мангостан, или гарциния, свежие или сушеные:->финики",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:23:34.177449"
  },
  {
    "id": 206,
    "status": true,
    "code": "0804100009",
    "name": 205,
    "description": "Финики, инжир, ананасы, авокадо, гуайява, манго и мангостан, или гарциния, свежие или сушеные:->финики",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 16:47:41.600545"
  },
  {
    "id": 1723,
    "status": true,
    "code": "080420100",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-21 10:47:01.846239",
    "updated_at": "2021-04-21 13:40:32.825064"
  },
  {
    "id": 207,
    "status": true,
    "code": "0804201000",
    "name": 206,
    "description": "Финики, инжир, ананасы, авокадо, гуайява, манго и мангостан, или гарциния, свежие или сушеные:->инжир:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:23:28.058117"
  },
  {
    "id": 208,
    "status": true,
    "code": "0804209000",
    "name": 207,
    "description": "Финики, инжир, ананасы, авокадо, гуайява, манго и мангостан, или гарциния, свежие или сушеные:->инжир:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.812300"
  },
  {
    "id": 209,
    "status": true,
    "code": "0804300001",
    "name": 208,
    "description": "Финики, инжир, ананасы, авокадо, гуайява, манго и мангостан, или гарциния, свежие или сушеные:->инжир:->ананасы:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:26:39.567684"
  },
  {
    "id": 210,
    "status": true,
    "code": "0804300009",
    "name": 209,
    "description": "Финики, инжир, ананасы, авокадо, гуайява, манго и мангостан, или гарциния, свежие или сушеные:->инжир:->ананасы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.816496"
  },
  {
    "id": 211,
    "status": true,
    "code": "0804400000",
    "name": 210,
    "description": "Финики, инжир, ананасы, авокадо, гуайява, манго и мангостан, или гарциния, свежие или сушеные:->инжир:->ананасы:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:26:46.648811"
  },
  {
    "id": 212,
    "status": true,
    "code": "0804500001",
    "name": 211,
    "description": "Финики, инжир, ананасы, авокадо, гуайява, манго и мангостан, или гарциния, свежие или сушеные:->инжир:->гуайява, манго и мангостан, или гарциния:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:23:12.326259"
  },
  {
    "id": 213,
    "status": true,
    "code": "0804500009",
    "name": 212,
    "description": "Финики, инжир, ананасы, авокадо, гуайява, манго и мангостан, или гарциния, свежие или сушеные:->инжир:->гуайява, манго и мангостан, или гарциния:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.822995"
  },
  {
    "id": 214,
    "status": true,
    "code": "0805102000",
    "name": 213,
    "description": "Цитрусовые плоды, свежие или сушеные:->апельсины:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:23:00.678405"
  },
  {
    "id": 215,
    "status": true,
    "code": "0805108000",
    "name": 214,
    "description": "Цитрусовые плоды, свежие или сушеные:->апельсины:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:26:59.292257"
  },
  {
    "id": 216,
    "status": true,
    "code": "0805210000",
    "name": 215,
    "description": "Цитрусовые плоды, свежие или сушеные:->апельсины:->мандарины (включая танжерины и сатсума); клементины, вилкинги и аналогичные гибриды цитрусовых:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:35:45.680694"
  },
  {
    "id": 217,
    "status": true,
    "code": "0805220000",
    "name": 216,
    "description": "Цитрусовые плоды, свежие или сушеные:->апельсины:->мандарины (включая танжерины и сатсума); клементины, вилкинги и аналогичные гибриды цитрусовых:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:25:58.702112"
  },
  {
    "id": 218,
    "status": true,
    "code": "0805290000",
    "name": 217,
    "description": "Цитрусовые плоды, свежие или сушеные:->апельсины:->мандарины (включая танжерины и сатсума); клементины, вилкинги и аналогичные гибриды цитрусовых:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:26:03.124420"
  },
  {
    "id": 219,
    "status": true,
    "code": "0805400000",
    "name": 218,
    "description": "Цитрусовые плоды, свежие или сушеные:->апельсины:->мандарины (включая танжерины и сатсума); клементины, вилкинги и аналогичные гибриды цитрусовых:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:26:07.846998"
  },
  {
    "id": 220,
    "status": true,
    "code": "0805501000",
    "name": 219,
    "description": "Цитрусовые плоды, свежие или сушеные:->лимоны (Citrus limon, Citrus limonum) и лаймы (Citrus aurantifolia, Citrus latifolia):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:26:12.346338"
  },
  {
    "id": 221,
    "status": true,
    "code": "0805509000",
    "name": 220,
    "description": "Цитрусовые плоды, свежие или сушеные:->лимоны (Citrus limon, Citrus limonum) и лаймы (Citrus aurantifolia, Citrus latifolia):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:26:19.164732"
  },
  {
    "id": 222,
    "status": true,
    "code": "0805900000",
    "name": 221,
    "description": "Цитрусовые плоды, свежие или сушеные:->лимоны (Citrus limon, Citrus limonum) и лаймы (Citrus aurantifolia, Citrus latifolia):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:34:54.922698"
  },
  {
    "id": 2204,
    "status": true,
    "code": "080610100",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-07-08 21:12:10.445276",
    "updated_at": "2022-07-08 21:12:10.445286"
  },
  {
    "id": 223,
    "status": true,
    "code": "0806101000",
    "name": 222,
    "description": "Виноград, свежий или сушеный:->свежий:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:45:18.797248"
  },
  {
    "id": 2223,
    "status": true,
    "code": "0806101001",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-29 14:47:35.604620",
    "updated_at": "2022-08-29 14:47:35.604630"
  },
  {
    "id": 224,
    "status": true,
    "code": "0806109000",
    "name": 223,
    "description": "Виноград, свежий или сушеный:->свежий:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:31:58.207444"
  },
  {
    "id": 225,
    "status": true,
    "code": "0806201000",
    "name": 224,
    "description": "Виноград, свежий или сушеный:->сушеный:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:43:47.735847"
  },
  {
    "id": 226,
    "status": true,
    "code": "0806203000",
    "name": 225,
    "description": "Виноград, свежий или сушеный:->сушеный:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:27:07.228651"
  },
  {
    "id": 227,
    "status": true,
    "code": "0806209000",
    "name": 226,
    "description": "Виноград, свежий или сушеный:->сушеный:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:27:13.788383"
  },
  {
    "id": 2222,
    "status": true,
    "code": "0807100000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-28 10:44:41.721737",
    "updated_at": "2022-08-28 10:44:41.721747"
  },
  {
    "id": 228,
    "status": true,
    "code": "0807110000",
    "name": 227,
    "description": "Дыни (включая арбузы) и папайя, свежие:->дыни (включая арбузы):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:27:30.085945"
  },
  {
    "id": 229,
    "status": true,
    "code": "0807190000",
    "name": 228,
    "description": "Дыни (включая арбузы) и папайя, свежие:->дыни (включая арбузы):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:30:02.409441"
  },
  {
    "id": 230,
    "status": true,
    "code": "0807200000",
    "name": 229,
    "description": "Дыни (включая арбузы) и папайя, свежие:->дыни (включая арбузы):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:43:54.840459"
  },
  {
    "id": 231,
    "status": true,
    "code": "0808101000",
    "name": 230,
    "description": "Яблоки, груши и айва, свежие:->яблоки:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:33:55.564371"
  },
  {
    "id": 1486,
    "status": true,
    "code": "0808108000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-20 18:19:17.967622",
    "updated_at": "2023-01-16 22:19:46.530430"
  },
  {
    "id": 232,
    "status": true,
    "code": "0808108001",
    "name": 231,
    "description": "Яблоки, груши и айва, свежие:->яблоки:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:27:36.839527"
  },
  {
    "id": 233,
    "status": true,
    "code": "0808108002",
    "name": 232,
    "description": "Яблоки, груши и айва, свежие:->яблоки:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:32:30.046592"
  },
  {
    "id": 234,
    "status": true,
    "code": "0808108003",
    "name": 233,
    "description": "Яблоки, груши и айва, свежие:->яблоки:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:27:43.232808"
  },
  {
    "id": 235,
    "status": true,
    "code": "0808108004",
    "name": 234,
    "description": "Яблоки, груши и айва, свежие:->яблоки:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:27:49.995783"
  },
  {
    "id": 2326,
    "status": true,
    "code": "0808108005",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-16 12:56:32.463039",
    "updated_at": "2023-01-16 22:18:27.418278"
  },
  {
    "id": 2327,
    "status": true,
    "code": "0808108006",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-16 12:56:32.491604",
    "updated_at": "2023-01-16 22:18:41.938646"
  },
  {
    "id": 2344,
    "status": true,
    "code": "0808108008",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-27 10:22:47.123862",
    "updated_at": "2023-02-01 12:43:30.198222"
  },
  {
    "id": 236,
    "status": true,
    "code": "0808301000",
    "name": 235,
    "description": "Яблоки, груши и айва, свежие:->груши:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:27:56.101953"
  },
  {
    "id": 237,
    "status": true,
    "code": "0808309000",
    "name": 236,
    "description": "Яблоки, груши и айва, свежие:->груши:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:28:02.920569"
  },
  {
    "id": 238,
    "status": true,
    "code": "0808400000",
    "name": 237,
    "description": "Яблоки, груши и айва, свежие:->груши:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:28:10.170443"
  },
  {
    "id": 239,
    "status": true,
    "code": "0809100000",
    "name": 238,
    "description": "Абрикосы, вишня и черешня, персики (включая нектарины), сливы и терн, свежие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:34:28.886593"
  },
  {
    "id": 1608,
    "status": true,
    "code": "0809109000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-24 02:48:47.767042",
    "updated_at": "2021-04-21 13:40:32.893735"
  },
  {
    "id": 2003,
    "status": true,
    "code": "0809200000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-29 14:10:53.913592",
    "updated_at": "2021-05-29 14:10:53.913646"
  },
  {
    "id": 240,
    "status": true,
    "code": "0809210000",
    "name": 239,
    "description": "Абрикосы, вишня и черешня, персики (включая нектарины), сливы и терн, свежие:->вишня и черешня:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:32:04.567398"
  },
  {
    "id": 241,
    "status": true,
    "code": "0809290000",
    "name": 240,
    "description": "Абрикосы, вишня и черешня, персики (включая нектарины), сливы и терн, свежие:->вишня и черешня:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:33:24.121794"
  },
  {
    "id": 1669,
    "status": true,
    "code": "080930100",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-18 04:51:46.325653",
    "updated_at": "2021-04-21 13:40:32.900241"
  },
  {
    "id": 242,
    "status": true,
    "code": "0809301000",
    "name": 241,
    "description": "Абрикосы, вишня и черешня, персики (включая нектарины), сливы и терн, свежие:->персики, включая нектарины:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:27:20.398302"
  },
  {
    "id": 243,
    "status": true,
    "code": "0809309000",
    "name": 242,
    "description": "Абрикосы, вишня и черешня, персики (включая нектарины), сливы и терн, свежие:->персики, включая нектарины:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:45:23.678518"
  },
  {
    "id": 244,
    "status": true,
    "code": "0809400500",
    "name": 243,
    "description": "Абрикосы, вишня и черешня, персики (включая нектарины), сливы и терн, свежие:->сливы и терн:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:45:34.008155"
  },
  {
    "id": 245,
    "status": true,
    "code": "0809409000",
    "name": 244,
    "description": "Абрикосы, вишня и черешня, персики (включая нектарины), сливы и терн, свежие:->сливы и терн:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:45:29.189206"
  },
  {
    "id": 246,
    "status": true,
    "code": "0810100000",
    "name": 245,
    "description": "Прочие фрукты, свежие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:43:39.715622"
  },
  {
    "id": 247,
    "status": true,
    "code": "0810201000",
    "name": 246,
    "description": "Прочие фрукты, свежие:->малина, ежевика, тутовая ягода, или шелковица, и логанова ягода:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:34:22.523596"
  },
  {
    "id": 248,
    "status": true,
    "code": "0810209000",
    "name": 247,
    "description": "Прочие фрукты, свежие:->малина, ежевика, тутовая ягода, или шелковица, и логанова ягода:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:44:42.347347"
  },
  {
    "id": 249,
    "status": true,
    "code": "0810301000",
    "name": 248,
    "description": "Прочие фрукты, свежие:->смородина черная, белая или красная и крыжовник:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:44:29.807655"
  },
  {
    "id": 250,
    "status": true,
    "code": "0810303000",
    "name": 249,
    "description": "Прочие фрукты, свежие:->смородина черная, белая или красная и крыжовник:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:44:48.006146"
  },
  {
    "id": 251,
    "status": true,
    "code": "0810309000",
    "name": 250,
    "description": "Прочие фрукты, свежие:->смородина черная, белая или красная и крыжовник:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:45:00.359345"
  },
  {
    "id": 252,
    "status": true,
    "code": "0810401000",
    "name": 251,
    "description": "Прочие фрукты, свежие:->клюква, черника и прочие ягоды рода Vaccinium:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:45:05.236260"
  },
  {
    "id": 253,
    "status": true,
    "code": "0810403000",
    "name": 252,
    "description": "Прочие фрукты, свежие:->клюква, черника и прочие ягоды рода Vaccinium:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:45:10.937956"
  },
  {
    "id": 254,
    "status": true,
    "code": "0810405000",
    "name": 253,
    "description": "Прочие фрукты, свежие:->клюква, черника и прочие ягоды рода Vaccinium:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:44:17.671985"
  },
  {
    "id": 255,
    "status": true,
    "code": "0810409000",
    "name": 254,
    "description": "Прочие фрукты, свежие:->клюква, черника и прочие ягоды рода Vaccinium:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:44:53.854719"
  },
  {
    "id": 256,
    "status": true,
    "code": "0810500000",
    "name": 255,
    "description": "Прочие фрукты, свежие:->клюква, черника и прочие ягоды рода Vaccinium:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:44:06.018382"
  },
  {
    "id": 257,
    "status": true,
    "code": "0810600000",
    "name": 256,
    "description": "Прочие фрукты, свежие:->клюква, черника и прочие ягоды рода Vaccinium:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:44:23.614023"
  },
  {
    "id": 258,
    "status": true,
    "code": "0810700000",
    "name": 257,
    "description": "Прочие фрукты, свежие:->клюква, черника и прочие ягоды рода Vaccinium:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:44:12.439606"
  },
  {
    "id": 259,
    "status": true,
    "code": "0810902000",
    "name": 258,
    "description": "Прочие фрукты, свежие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:44:00.591182"
  },
  {
    "id": 260,
    "status": true,
    "code": "0810907500",
    "name": 259,
    "description": "Прочие фрукты, свежие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:44:37.039261"
  },
  {
    "id": 2260,
    "status": true,
    "code": "0810907501",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-02 20:22:37.285938",
    "updated_at": "2023-01-02 20:22:37.285948"
  },
  {
    "id": 2274,
    "status": true,
    "code": "0810907509",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 14:58:06.909360",
    "updated_at": "2023-02-08 22:39:28.015247"
  },
  {
    "id": 261,
    "status": true,
    "code": "0811101100",
    "name": 260,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->земляника (клубника):->с добавлением сахара или других подслащивающих веществ:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 262,
    "status": true,
    "code": "0811101900",
    "name": 261,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->земляника (клубника):->с добавлением сахара или других подслащивающих веществ:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 263,
    "status": true,
    "code": "0811109000",
    "name": 262,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->земляника (клубника):->с добавлением сахара или других подслащивающих веществ:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 264,
    "status": true,
    "code": "0811201100",
    "name": 263,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->малина, ежевика, тутовая ягода, или шелковица, логанова ягода, смородина черная, белая или красная и крыжовник:->с добавлением сахара или других подслащивающих веществ:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 265,
    "status": true,
    "code": "0811201900",
    "name": 264,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->малина, ежевика, тутовая ягода, или шелковица, логанова ягода, смородина черная, белая или красная и крыжовник:->с добавлением сахара или других подслащивающих веществ:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 266,
    "status": true,
    "code": "0811203100",
    "name": 265,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->малина, ежевика, тутовая ягода, или шелковица, логанова ягода, смородина черная, белая или красная и крыжовник:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 267,
    "status": true,
    "code": "0811203900",
    "name": 266,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->малина, ежевика, тутовая ягода, или шелковица, логанова ягода, смородина черная, белая или красная и крыжовник:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 268,
    "status": true,
    "code": "0811205100",
    "name": 267,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->малина, ежевика, тутовая ягода, или шелковица, логанова ягода, смородина черная, белая или красная и крыжовник:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 269,
    "status": true,
    "code": "0811205900",
    "name": 268,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->малина, ежевика, тутовая ягода, или шелковица, логанова ягода, смородина черная, белая или красная и крыжовник:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 270,
    "status": true,
    "code": "0811209000",
    "name": 269,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->малина, ежевика, тутовая ягода, или шелковица, логанова ягода, смородина черная, белая или красная и крыжовник:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 271,
    "status": true,
    "code": "0811901100",
    "name": 270,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->прочие:->с содержанием сахара более 13 мас.%:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 272,
    "status": true,
    "code": "0811901900",
    "name": 271,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->прочие:->с содержанием сахара более 13 мас.%:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 273,
    "status": true,
    "code": "0811903100",
    "name": 272,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 274,
    "status": true,
    "code": "0811903900",
    "name": 273,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 275,
    "status": true,
    "code": "0811905000",
    "name": 274,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 276,
    "status": true,
    "code": "0811907000",
    "name": 275,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 277,
    "status": true,
    "code": "0811907500",
    "name": 276,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->прочие:->вишня и черешня:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 278,
    "status": true,
    "code": "0811908000",
    "name": 277,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->прочие:->вишня и черешня:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 279,
    "status": true,
    "code": "0811908500",
    "name": 278,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->прочие:->вишня и черешня:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 280,
    "status": true,
    "code": "0811909500",
    "name": 279,
    "description": "Фрукты и орехи, подвергнутые или не подвергнутые тепловой обработке в кипящей воде или на пару, замороженные, с добавлением или без добавления сахара или других подслащивающих веществ:->прочие:->вишня и черешня:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 281,
    "status": true,
    "code": "0812100000",
    "name": 280,
    "description": "Фрукты и орехи, консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 282,
    "status": true,
    "code": "0812902500",
    "name": 281,
    "description": "Фрукты и орехи, консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 283,
    "status": true,
    "code": "0812903000",
    "name": 282,
    "description": "Фрукты и орехи, консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 284,
    "status": true,
    "code": "0812904000",
    "name": 283,
    "description": "Фрукты и орехи, консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 285,
    "status": true,
    "code": "0812907000",
    "name": 284,
    "description": "Фрукты и орехи, консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 286,
    "status": true,
    "code": "0812909800",
    "name": 285,
    "description": "Фрукты и орехи, консервированные для кратковременного хранения (например, диоксидом серы, в рассоле, сернистой воде или в другом временно консервирующем растворе), но в таком виде непригодные для непосредственного употребления в пищу:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 287,
    "status": true,
    "code": "0813100000",
    "name": 286,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.945726"
  },
  {
    "id": 288,
    "status": true,
    "code": "0813200000",
    "name": 287,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.948059"
  },
  {
    "id": 289,
    "status": true,
    "code": "0813300000",
    "name": 288,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.950140"
  },
  {
    "id": 1793,
    "status": true,
    "code": "0813310001",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-08 14:10:43.042678",
    "updated_at": "2021-04-21 13:40:32.977995"
  },
  {
    "id": 290,
    "status": true,
    "code": "0813401000",
    "name": 289,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:->прочие фрукты:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.952216"
  },
  {
    "id": 291,
    "status": true,
    "code": "0813403000",
    "name": 290,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:->прочие фрукты:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.954297"
  },
  {
    "id": 292,
    "status": true,
    "code": "0813405000",
    "name": 291,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:->прочие фрукты:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.956879"
  },
  {
    "id": 293,
    "status": true,
    "code": "0813406500",
    "name": 292,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:->прочие фрукты:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.959022"
  },
  {
    "id": 294,
    "status": true,
    "code": "0813409500",
    "name": 293,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:->прочие фрукты:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.961096"
  },
  {
    "id": 295,
    "status": true,
    "code": "0813501200",
    "name": 294,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:->смеси орехов или сушеных плодов данной группы:->без чернослива:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.963147"
  },
  {
    "id": 296,
    "status": true,
    "code": "0813501500",
    "name": 295,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:->смеси орехов или сушеных плодов данной группы:->без чернослива:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.965209"
  },
  {
    "id": 297,
    "status": true,
    "code": "0813501900",
    "name": 296,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:->смеси орехов или сушеных плодов данной группы:->без чернослива:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.967277"
  },
  {
    "id": 298,
    "status": true,
    "code": "0813503100",
    "name": 297,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:->смеси орехов или сушеных плодов данной группы:->смеси, состоящие исключительно из сушеных орехов товарных позиций 0801 и 0802:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.969382"
  },
  {
    "id": 299,
    "status": true,
    "code": "0813503900",
    "name": 298,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:->смеси орехов или сушеных плодов данной группы:->смеси, состоящие исключительно из сушеных орехов товарных позиций 0801 и 0802:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.971474"
  },
  {
    "id": 300,
    "status": true,
    "code": "0813509100",
    "name": 299,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:->смеси орехов или сушеных плодов данной группы:->смеси прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.973582"
  },
  {
    "id": 301,
    "status": true,
    "code": "0813509900",
    "name": 300,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:->смеси орехов или сушеных плодов данной группы:->смеси прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:32.975857"
  },
  {
    "id": 302,
    "status": true,
    "code": "0814000000",
    "name": 301,
    "description": "Фрукты сушеные, кроме плодов товарных позиций 0801 - 0806; смеси орехов или сушеных плодов данной группы:->смеси орехов или сушеных плодов данной группы:->смеси прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1988,
    "status": true,
    "code": "089290000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-10 10:41:38.396334",
    "updated_at": "2021-05-10 10:41:38.396345"
  },
  {
    "id": 303,
    "status": true,
    "code": "0901110001",
    "name": 302,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->кофе нежареный:->с кофеином:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91295.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 15:45:08.021368"
  },
  {
    "id": 304,
    "status": true,
    "code": "0901110002",
    "name": 303,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->кофе нежареный:->с кофеином:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91295.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 15:48:33.599264"
  },
  {
    "id": 305,
    "status": true,
    "code": "0901110009",
    "name": 304,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->кофе нежареный:->с кофеином:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91295.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 15:51:33.103620"
  },
  {
    "id": 306,
    "status": true,
    "code": "0901120001",
    "name": 305,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->кофе нежареный:->без кофеина:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 307,
    "status": true,
    "code": "0901120002",
    "name": 306,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->кофе нежареный:->без кофеина:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91295.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 15:56:40.881728"
  },
  {
    "id": 308,
    "status": true,
    "code": "0901120009",
    "name": 307,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->кофе нежареный:->без кофеина:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91295.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 15:59:25.983180"
  },
  {
    "id": 309,
    "status": true,
    "code": "0901210001",
    "name": 308,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->в зернах:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 310,
    "status": true,
    "code": "0901210002",
    "name": 309,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->в зернах:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 311,
    "status": true,
    "code": "0901210008",
    "name": 310,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->в зернах:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 312,
    "status": true,
    "code": "0901210009",
    "name": 311,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->в зернах:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 313,
    "status": true,
    "code": "0901220001",
    "name": 312,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->в зернах:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 314,
    "status": true,
    "code": "0901220002",
    "name": 313,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->в зернах:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 315,
    "status": true,
    "code": "0901220008",
    "name": 314,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->в зернах:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 316,
    "status": true,
    "code": "0901220009",
    "name": 315,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->в зернах:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 317,
    "status": true,
    "code": "0901901000",
    "name": 316,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 318,
    "status": true,
    "code": "0901909000",
    "name": 317,
    "description": "Кофе, жареный или нежареный, с кофеином или без кофеина; кофейная шелуха и оболочки зерен кофе; заменители кофе, содержащие кофе в любой пропорции:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 319,
    "status": true,
    "code": "0902100001",
    "name": 318,
    "description": "Чай со вкусо-ароматическими добавками или без них:->чай зеленый (неферментированный), в первичных упаковках нетто-массой не более 3 кг:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 320,
    "status": true,
    "code": "0902100009",
    "name": 319,
    "description": "Чай со вкусо-ароматическими добавками или без них:->чай зеленый (неферментированный), в первичных упаковках нетто-массой не более 3 кг:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 321,
    "status": true,
    "code": "0902200000",
    "name": 320,
    "description": "Чай со вкусо-ароматическими добавками или без них:->чай зеленый (неферментированный), в первичных упаковках нетто-массой не более 3 кг:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 322,
    "status": true,
    "code": "0902300001",
    "name": 321,
    "description": "Чай со вкусо-ароматическими добавками или без них:->чай черный (ферментированный) и частично ферментированный, в первичных упаковках нетто-массой не более 3 кг:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 323,
    "status": true,
    "code": "0902300009",
    "name": 322,
    "description": "Чай со вкусо-ароматическими добавками или без них:->чай черный (ферментированный) и частично ферментированный, в первичных упаковках нетто-массой не более 3 кг:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 324,
    "status": true,
    "code": "0902400000",
    "name": 323,
    "description": "Чай со вкусо-ароматическими добавками или без них:->чай черный (ферментированный) и частично ферментированный, в первичных упаковках нетто-массой не более 3 кг:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1830,
    "status": true,
    "code": "0902400009",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-14 15:16:32.617201",
    "updated_at": "2020-11-14 15:16:32.617209"
  },
  {
    "id": 325,
    "status": true,
    "code": "0903000000",
    "name": 324,
    "description": "Чай со вкусо-ароматическими добавками или без них:->чай черный (ферментированный) и частично ферментированный, в первичных упаковках нетто-массой не более 3 кг:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 326,
    "status": true,
    "code": "0904110000",
    "name": 325,
    "description": "Перец рода Piper; плоды рода Capsicum или рода Pimenta, сушеные или дробленые, или молотые:->перец рода Piper:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.305940"
  },
  {
    "id": 327,
    "status": true,
    "code": "0904120000",
    "name": 326,
    "description": "Перец рода Piper; плоды рода Capsicum или рода Pimenta, сушеные или дробленые, или молотые:->перец рода Piper:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.307999"
  },
  {
    "id": 328,
    "status": true,
    "code": "0904211000",
    "name": 327,
    "description": "Перец рода Piper; плоды рода Capsicum или рода Pimenta, сушеные или дробленые, или молотые:->сушеные, недробленые и немолотые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.310092"
  },
  {
    "id": 329,
    "status": true,
    "code": "0904219000",
    "name": 328,
    "description": "Перец рода Piper; плоды рода Capsicum или рода Pimenta, сушеные или дробленые, или молотые:->сушеные, недробленые и немолотые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.312222"
  },
  {
    "id": 330,
    "status": true,
    "code": "0904220000",
    "name": 329,
    "description": "Перец рода Piper; плоды рода Capsicum или рода Pimenta, сушеные или дробленые, или молотые:->сушеные, недробленые и немолотые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.314375"
  },
  {
    "id": 331,
    "status": true,
    "code": "0905100000",
    "name": 330,
    "description": "Ваниль:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 332,
    "status": true,
    "code": "0905200000",
    "name": 331,
    "description": "Ваниль:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 333,
    "status": true,
    "code": "0906110000",
    "name": 332,
    "description": "Корица и цветки коричного дерева:->недробленые и немолотые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 334,
    "status": true,
    "code": "0906190000",
    "name": 333,
    "description": "Корица и цветки коричного дерева:->недробленые и немолотые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 335,
    "status": true,
    "code": "0906200000",
    "name": 334,
    "description": "Корица и цветки коричного дерева:->недробленые и немолотые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 336,
    "status": true,
    "code": "0907100000",
    "name": 335,
    "description": "Гвоздика (целые плоды, цветы и цветоножки):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 337,
    "status": true,
    "code": "0907200000",
    "name": 336,
    "description": "Гвоздика (целые плоды, цветы и цветоножки):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 338,
    "status": true,
    "code": "0908110000",
    "name": 337,
    "description": "Мускатный орех, мацис и кардамон:->мускатный орех:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 339,
    "status": true,
    "code": "0908120000",
    "name": 338,
    "description": "Мускатный орех, мацис и кардамон:->мускатный орех:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 340,
    "status": true,
    "code": "0908210000",
    "name": 339,
    "description": "Мускатный орех, мацис и кардамон:->мацис:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 341,
    "status": true,
    "code": "0908220000",
    "name": 340,
    "description": "Мускатный орех, мацис и кардамон:->мацис:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 342,
    "status": true,
    "code": "0908310000",
    "name": 341,
    "description": "Мускатный орех, мацис и кардамон:->кардамон:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 343,
    "status": true,
    "code": "0908320000",
    "name": 342,
    "description": "Мускатный орех, мацис и кардамон:->кардамон:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 344,
    "status": true,
    "code": "0909210000",
    "name": 343,
    "description": "Семена аниса, бадьяна, фенхеля, кориандра, тмина римского, или тмина волошского, или тмина; ягоды можжевельника:->семена кориандра:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:33:17.999244"
  },
  {
    "id": 345,
    "status": true,
    "code": "0909220000",
    "name": 344,
    "description": "Семена аниса, бадьяна, фенхеля, кориандра, тмина римского, или тмина волошского, или тмина; ягоды можжевельника:->семена кориандра:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.320295"
  },
  {
    "id": 346,
    "status": true,
    "code": "0909310000",
    "name": 345,
    "description": "Семена аниса, бадьяна, фенхеля, кориандра, тмина римского, или тмина волошского, или тмина; ягоды можжевельника:->семена тмина римского, или тмина волошского:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:30:25.043025"
  },
  {
    "id": 347,
    "status": true,
    "code": "0909320000",
    "name": 346,
    "description": "Семена аниса, бадьяна, фенхеля, кориандра, тмина римского, или тмина волошского, или тмина; ягоды можжевельника:->семена тмина римского, или тмина волошского:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.325596"
  },
  {
    "id": 1762,
    "status": true,
    "code": "0909500000",
    "name": "Арча кучати",
    "description": "Арча кучати",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-20 10:47:50.791629",
    "updated_at": "2021-04-21 13:40:33.327744"
  },
  {
    "id": 348,
    "status": true,
    "code": "0909610000",
    "name": 347,
    "description": "Семена аниса, бадьяна, фенхеля, кориандра, тмина римского, или тмина волошского, или тмина; ягоды можжевельника:->семена аниса, бадьяна, тмина или фенхеля; ягоды можжевельника:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:43:15.662865"
  },
  {
    "id": 2280,
    "status": true,
    "code": "0909610001",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 16:49:42.767949",
    "updated_at": "2023-01-05 16:49:42.767959"
  },
  {
    "id": 2281,
    "status": true,
    "code": "0909610002",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 16:49:42.777393",
    "updated_at": "2023-01-05 16:49:42.777402"
  },
  {
    "id": 349,
    "status": true,
    "code": "0909620000",
    "name": 348,
    "description": "Семена аниса, бадьяна, фенхеля, кориандра, тмина римского, или тмина волошского, или тмина; ягоды можжевельника:->семена аниса, бадьяна, тмина или фенхеля; ягоды можжевельника:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.331884"
  },
  {
    "id": 2282,
    "status": true,
    "code": "0909620001",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 16:49:42.783499",
    "updated_at": "2023-01-05 16:49:42.783508"
  },
  {
    "id": 2283,
    "status": true,
    "code": "0909620002",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 16:49:42.789573",
    "updated_at": "2023-01-05 16:49:42.789582"
  },
  {
    "id": 350,
    "status": true,
    "code": "0910110000",
    "name": 349,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->имбирь:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:05:03.610768"
  },
  {
    "id": 351,
    "status": true,
    "code": "0910120000",
    "name": 350,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->имбирь:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 352,
    "status": true,
    "code": "0910201000",
    "name": 351,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->шафран:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:05:12.183304"
  },
  {
    "id": 353,
    "status": true,
    "code": "0910209000",
    "name": 352,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->шафран:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 354,
    "status": true,
    "code": "0910300000",
    "name": 353,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->шафран:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 355,
    "status": true,
    "code": "0910910500",
    "name": 354,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->смеси, упомянутые в примечании 1 (б) к данной группе:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 356,
    "status": true,
    "code": "0910911000",
    "name": 355,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->смеси, упомянутые в примечании 1 (б) к данной группе:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 357,
    "status": true,
    "code": "0910919000",
    "name": 356,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->смеси, упомянутые в примечании 1 (б) к данной группе:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 358,
    "status": true,
    "code": "0910991000",
    "name": 357,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91295.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 16:12:15.270890"
  },
  {
    "id": 359,
    "status": true,
    "code": "0910993100",
    "name": 358,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->прочие:->недробленый и немолотый:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-20 17:08:03.750028"
  },
  {
    "id": 360,
    "status": true,
    "code": "0910993300",
    "name": 359,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->прочие:->недробленый и немолотый:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 361,
    "status": true,
    "code": "0910993900",
    "name": 360,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->прочие:->недробленый и немолотый:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 362,
    "status": true,
    "code": "0910995000",
    "name": 361,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->прочие:->недробленый и немолотый:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2258,
    "status": true,
    "code": "0910999000",
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-12-27 11:59:39.842360",
    "updated_at": "2022-12-27 11:59:39.842369"
  },
  {
    "id": 363,
    "status": true,
    "code": "0910999100",
    "name": 362,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 364,
    "status": true,
    "code": "0910999900",
    "name": 363,
    "description": "Имбирь, шафран, турмерик (куркума), тимьян, или чабрец, лавровый лист, карри и прочие пряности:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 365,
    "status": true,
    "code": 1001110000,
    "name": 364,
    "description": "Пшеница и меслин:->пшеница твердая:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:41:34.252412"
  },
  {
    "id": 366,
    "status": true,
    "code": 1001190000,
    "name": 365,
    "description": "Пшеница и меслин:->пшеница твердая:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-12-07 15:04:59.642553"
  },
  {
    "id": 367,
    "status": true,
    "code": 1001911000,
    "name": 366,
    "description": "Пшеница и меслин:->семенные:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:43:10.300976"
  },
  {
    "id": 368,
    "status": true,
    "code": 1001912000,
    "name": 367,
    "description": "Пшеница и меслин:->семенные:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:42:54.511748"
  },
  {
    "id": 369,
    "status": true,
    "code": 1001919000,
    "name": 368,
    "description": "Пшеница и меслин:->семенные:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:32:10.728834"
  },
  {
    "id": 370,
    "status": true,
    "code": 1001990000,
    "name": 369,
    "description": "Пшеница и меслин:->семенные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-12-07 15:05:07.850060"
  },
  {
    "id": 371,
    "status": true,
    "code": 1002100000,
    "name": 370,
    "description": "Рожь:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:43:04.047879"
  },
  {
    "id": 372,
    "status": true,
    "code": 1002900000,
    "name": 371,
    "description": "Рожь:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-12-05 15:35:22.482320"
  },
  {
    "id": 373,
    "status": true,
    "code": 1003100000,
    "name": 372,
    "description": "Ячмень:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:42:59.280494"
  },
  {
    "id": 374,
    "status": true,
    "code": 1003900000,
    "name": 373,
    "description": "Ячмень:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-12-05 15:35:30.624557"
  },
  {
    "id": 375,
    "status": true,
    "code": 1004100000,
    "name": 374,
    "description": "Овес:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:42:38.514991"
  },
  {
    "id": 376,
    "status": true,
    "code": 1004900000,
    "name": 375,
    "description": "Овес:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-12-05 15:35:37.866358"
  },
  {
    "id": 377,
    "status": true,
    "code": 1005101300,
    "name": 376,
    "description": "Кукуруза:->семенная:->гибридная:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:42:25.570204"
  },
  {
    "id": 378,
    "status": true,
    "code": 1005101500,
    "name": 377,
    "description": "Кукуруза:->семенная:->гибридная:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:42:31.850648"
  },
  {
    "id": 379,
    "status": true,
    "code": 1005101801,
    "name": 378,
    "description": "Кукуруза:->семенная:->гибридная:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:42:18.408709"
  },
  {
    "id": 380,
    "status": true,
    "code": 1005101809,
    "name": 379,
    "description": "Кукуруза:->семенная:->гибридная:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:32:24.600106"
  },
  {
    "id": 381,
    "status": true,
    "code": 1005109000,
    "name": 380,
    "description": "Кукуруза:->семенная:->гибридная:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:42:13.045658"
  },
  {
    "id": 382,
    "status": true,
    "code": 1005900000,
    "name": 381,
    "description": "Кукуруза:->семенная:->гибридная:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-10-12 14:15:01.316381"
  },
  {
    "id": 383,
    "status": true,
    "code": 1006101000,
    "name": 382,
    "description": "Рис:->нешелушеный рис (рис-сырец):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:42:48.443869"
  },
  {
    "id": 384,
    "status": true,
    "code": 1006102100,
    "name": 383,
    "description": "Рис:->нешелушеный рис (рис-сырец):->пропаренный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:42:01.745953"
  },
  {
    "id": 385,
    "status": true,
    "code": 1006102300,
    "name": 384,
    "description": "Рис:->нешелушеный рис (рис-сырец):->пропаренный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:33:01.400771"
  },
  {
    "id": 386,
    "status": true,
    "code": 1006102500,
    "name": 385,
    "description": "Рис:->нешелушеный рис (рис-сырец):->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:30:38.430760"
  },
  {
    "id": 387,
    "status": true,
    "code": 1006102700,
    "name": 386,
    "description": "Рис:->нешелушеный рис (рис-сырец):->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:42:07.492072"
  },
  {
    "id": 388,
    "status": true,
    "code": 1006109200,
    "name": 387,
    "description": "Рис:->нешелушеный рис (рис-сырец):->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:41:49.276270"
  },
  {
    "id": 389,
    "status": true,
    "code": 1006109400,
    "name": 388,
    "description": "Рис:->нешелушеный рис (рис-сырец):->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:41:56.183311"
  },
  {
    "id": 390,
    "status": true,
    "code": 1006109600,
    "name": 389,
    "description": "Рис:->нешелушеный рис (рис-сырец):->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:32:18.404291"
  },
  {
    "id": 391,
    "status": true,
    "code": 1006109800,
    "name": 390,
    "description": "Рис:->нешелушеный рис (рис-сырец):->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:41:44.103518"
  },
  {
    "id": 392,
    "status": true,
    "code": 1006201100,
    "name": 391,
    "description": "Рис:->шелушеный рис (неполированный):->пропаренный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.037364"
  },
  {
    "id": 393,
    "status": true,
    "code": 1006201300,
    "name": 392,
    "description": "Рис:->шелушеный рис (неполированный):->пропаренный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.039442"
  },
  {
    "id": 394,
    "status": true,
    "code": 1006201500,
    "name": 393,
    "description": "Рис:->шелушеный рис (неполированный):->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.041533"
  },
  {
    "id": 395,
    "status": true,
    "code": 1006201700,
    "name": 394,
    "description": "Рис:->шелушеный рис (неполированный):->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.043609"
  },
  {
    "id": 396,
    "status": true,
    "code": 1006209200,
    "name": 395,
    "description": "Рис:->шелушеный рис (неполированный):->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.045671"
  },
  {
    "id": 397,
    "status": true,
    "code": 1006209400,
    "name": 396,
    "description": "Рис:->шелушеный рис (неполированный):->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.047958"
  },
  {
    "id": 398,
    "status": true,
    "code": 1006209600,
    "name": 397,
    "description": "Рис:->шелушеный рис (неполированный):->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.050086"
  },
  {
    "id": 399,
    "status": true,
    "code": 1006209800,
    "name": 398,
    "description": "Рис:->шелушеный рис (неполированный):->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.052154"
  },
  {
    "id": 400,
    "status": true,
    "code": 1006302100,
    "name": 399,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->полуобрушенный рис:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.054206"
  },
  {
    "id": 401,
    "status": true,
    "code": 1006302300,
    "name": 400,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->полуобрушенный рис:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.056361"
  },
  {
    "id": 402,
    "status": true,
    "code": 1006302500,
    "name": 401,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.058458"
  },
  {
    "id": 403,
    "status": true,
    "code": 1006302700,
    "name": 402,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.060555"
  },
  {
    "id": 404,
    "status": true,
    "code": 1006304200,
    "name": 403,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.062659"
  },
  {
    "id": 405,
    "status": true,
    "code": 1006304400,
    "name": 404,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.064844"
  },
  {
    "id": 406,
    "status": true,
    "code": 1006304600,
    "name": 405,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.070242"
  },
  {
    "id": 407,
    "status": true,
    "code": 1006304800,
    "name": 406,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.072322"
  },
  {
    "id": 408,
    "status": true,
    "code": 1006306100,
    "name": 407,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->пропаренный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.074553"
  },
  {
    "id": 409,
    "status": true,
    "code": 1006306300,
    "name": 408,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->пропаренный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.076778"
  },
  {
    "id": 410,
    "status": true,
    "code": 1006306500,
    "name": 409,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.078957"
  },
  {
    "id": 411,
    "status": true,
    "code": 1006306700,
    "name": 410,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.081184"
  },
  {
    "id": 412,
    "status": true,
    "code": 1006309200,
    "name": 411,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.083310"
  },
  {
    "id": 413,
    "status": true,
    "code": 1006309400,
    "name": 412,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.085444"
  },
  {
    "id": 414,
    "status": true,
    "code": 1006309600,
    "name": 413,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.087547"
  },
  {
    "id": 415,
    "status": true,
    "code": 1006309800,
    "name": 414,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.089714"
  },
  {
    "id": 416,
    "status": true,
    "code": 1006400000,
    "name": 415,
    "description": "Рис:->полуобрушенный или полностью обрушенный рис, полированный или неполированный, глазированный или неглазированный:->длиннозерный:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.091870"
  },
  {
    "id": 417,
    "status": true,
    "code": 1007101000,
    "name": 416,
    "description": "Сорго зерновое:->семенное:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:31:16.466043"
  },
  {
    "id": 418,
    "status": true,
    "code": 1007109000,
    "name": 417,
    "description": "Сорго зерновое:->семенное:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:34:41.813797"
  },
  {
    "id": 419,
    "status": true,
    "code": 1007900000,
    "name": 418,
    "description": "Сорго зерновое:->семенное:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:41:05.538728"
  },
  {
    "id": 420,
    "status": true,
    "code": 1008100001,
    "name": 419,
    "description": "Гречиха, просо и семена канареечника; прочие злаки:->гречиха:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:32:53.089145"
  },
  {
    "id": 421,
    "status": true,
    "code": 1008100009,
    "name": 420,
    "description": "Гречиха, просо и семена канареечника; прочие злаки:->гречиха:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91614.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 17:04:49.535436"
  },
  {
    "id": 422,
    "status": true,
    "code": 1008210000,
    "name": 421,
    "description": "Гречиха, просо и семена канареечника; прочие злаки:->просо:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:40:59.510818"
  },
  {
    "id": 423,
    "status": true,
    "code": 1008290000,
    "name": 422,
    "description": "Гречиха, просо и семена канареечника; прочие злаки:->просо:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91614.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 17:11:13.933039"
  },
  {
    "id": 424,
    "status": true,
    "code": 1008300000,
    "name": 423,
    "description": "Гречиха, просо и семена канареечника; прочие злаки:->просо:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91614.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 17:13:19.059925"
  },
  {
    "id": 425,
    "status": true,
    "code": 1008400000,
    "name": 424,
    "description": "Гречиха, просо и семена канареечника; прочие злаки:->просо:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91614.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 17:16:03.464444"
  },
  {
    "id": 426,
    "status": true,
    "code": 1008500000,
    "name": 425,
    "description": "Гречиха, просо и семена канареечника; прочие злаки:->просо:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91614.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 17:16:47.741470"
  },
  {
    "id": 427,
    "status": true,
    "code": 1008600000,
    "name": 426,
    "description": "Гречиха, просо и семена канареечника; прочие злаки:->просо:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:41:11.270251"
  },
  {
    "id": 428,
    "status": true,
    "code": 1008900000,
    "name": 427,
    "description": "Гречиха, просо и семена канареечника; прочие злаки:->просо:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91614.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 17:17:05.298895"
  },
  {
    "id": 429,
    "status": true,
    "code": 1101001100,
    "name": 428,
    "description": "Гречиха, просо и семена канареечника; прочие злаки:->Мука пшеничная или пшенично-ржаная:->мука пшеничная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2264,
    "status": true,
    "code": 1101001101,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 11:17:05.303279",
    "updated_at": "2023-01-05 11:17:05.303294"
  },
  {
    "id": 2273,
    "status": true,
    "code": 1101001109,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 14:12:31.541886",
    "updated_at": "2023-01-05 14:12:31.541899"
  },
  {
    "id": 430,
    "status": true,
    "code": 1101001500,
    "name": 429,
    "description": "Гречиха, просо и семена канареечника; прочие злаки:->Мука пшеничная или пшенично-ржаная:->мука пшеничная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2262,
    "status": true,
    "code": 1101001501,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-04 23:44:49.301493",
    "updated_at": "2023-01-04 23:44:49.301506"
  },
  {
    "id": 2261,
    "status": true,
    "code": 1101001509,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-04 22:49:50.311224",
    "updated_at": "2023-01-04 22:49:50.311239"
  },
  {
    "id": 431,
    "status": true,
    "code": 1101009000,
    "name": 430,
    "description": "Гречиха, просо и семена канареечника; прочие злаки:->Мука пшеничная или пшенично-ржаная:->мука пшеничная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 432,
    "status": true,
    "code": 1102201000,
    "name": 431,
    "description": "Мука из зерна прочих злаков, кроме пшеничной или пшенично- ржаной:->мука кукурузная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 433,
    "status": true,
    "code": 1102209000,
    "name": 432,
    "description": "Мука из зерна прочих злаков, кроме пшеничной или пшенично- ржаной:->мука кукурузная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 434,
    "status": true,
    "code": 1102901000,
    "name": 433,
    "description": "Мука из зерна прочих злаков, кроме пшеничной или пшенично- ржаной:->прочая:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 435,
    "status": true,
    "code": 1102903000,
    "name": 434,
    "description": "Мука из зерна прочих злаков, кроме пшеничной или пшенично- ржаной:->прочая:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 436,
    "status": true,
    "code": 1102905000,
    "name": 435,
    "description": "Мука из зерна прочих злаков, кроме пшеничной или пшенично- ржаной:->прочая:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 437,
    "status": true,
    "code": 1102907000,
    "name": 436,
    "description": "Мука из зерна прочих злаков, кроме пшеничной или пшенично- ржаной:->прочая:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 438,
    "status": true,
    "code": 1102909001,
    "name": 437,
    "description": "Мука из зерна прочих злаков, кроме пшеничной или пшенично- ржаной:->прочая:->прочая",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 439,
    "status": true,
    "code": 1102909009,
    "name": 438,
    "description": "Мука из зерна прочих злаков, кроме пшеничной или пшенично- ржаной:->прочая:->прочая",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 440,
    "status": true,
    "code": 1103111000,
    "name": 439,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->из пшеницы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 441,
    "status": true,
    "code": 1103119000,
    "name": 440,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->из пшеницы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 442,
    "status": true,
    "code": 1103131000,
    "name": 441,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->из кукурузы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 443,
    "status": true,
    "code": 1103139000,
    "name": 442,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->из кукурузы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 444,
    "status": true,
    "code": 1103192000,
    "name": 443,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->из зерна прочих злаков:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 445,
    "status": true,
    "code": 1103194000,
    "name": 444,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->из зерна прочих злаков:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 446,
    "status": true,
    "code": 1103195000,
    "name": 445,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->из зерна прочих злаков:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 447,
    "status": true,
    "code": 1103199001,
    "name": 446,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->из зерна прочих злаков:->прочая",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 448,
    "status": true,
    "code": 1103199009,
    "name": 447,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->из зерна прочих злаков:->прочая",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 449,
    "status": true,
    "code": 1103202500,
    "name": 448,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->гранулы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 450,
    "status": true,
    "code": 1103203000,
    "name": 449,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->гранулы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 451,
    "status": true,
    "code": 1103204000,
    "name": 450,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->гранулы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 452,
    "status": true,
    "code": 1103205000,
    "name": 451,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->гранулы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 453,
    "status": true,
    "code": 1103206000,
    "name": 452,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->гранулы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 454,
    "status": true,
    "code": 1103209000,
    "name": 453,
    "description": "Крупа, мука грубого помола и гранулы из зерна злаков:->гранулы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 455,
    "status": true,
    "code": 1104121000,
    "name": 454,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->овса:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 456,
    "status": true,
    "code": 1104129000,
    "name": 455,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->овса:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 457,
    "status": true,
    "code": 1104191000,
    "name": 456,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 458,
    "status": true,
    "code": 1104193000,
    "name": 457,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 459,
    "status": true,
    "code": 1104195000,
    "name": 458,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 460,
    "status": true,
    "code": 1104196100,
    "name": 459,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->ячменя:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 461,
    "status": true,
    "code": 1104196900,
    "name": 460,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->ячменя:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 462,
    "status": true,
    "code": 1104199100,
    "name": 461,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->прочее:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 463,
    "status": true,
    "code": 1104199900,
    "name": 462,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->прочее:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 464,
    "status": true,
    "code": 1104224000,
    "name": 463,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->овса:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 465,
    "status": true,
    "code": 1104225000,
    "name": 464,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->овса:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 466,
    "status": true,
    "code": 1104229500,
    "name": 465,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->овса:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 467,
    "status": true,
    "code": 1104234000,
    "name": 466,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->кукурузы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 468,
    "status": true,
    "code": 1104239800,
    "name": 467,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->кукурузы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 469,
    "status": true,
    "code": 1104290400,
    "name": 468,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->ячменя:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 470,
    "status": true,
    "code": 1104290500,
    "name": 469,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->ячменя:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 471,
    "status": true,
    "code": 1104290800,
    "name": 470,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->ячменя:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 472,
    "status": true,
    "code": 1104291700,
    "name": 471,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->прочее:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 473,
    "status": true,
    "code": 1104293000,
    "name": 472,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->прочее:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 474,
    "status": true,
    "code": 1104295100,
    "name": 473,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->дробленое без какой-либо иной обработки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 475,
    "status": true,
    "code": 1104295500,
    "name": 474,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->дробленое без какой-либо иной обработки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 476,
    "status": true,
    "code": 1104295900,
    "name": 475,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->дробленое без какой-либо иной обработки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 477,
    "status": true,
    "code": 1104298100,
    "name": 476,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->прочее:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 478,
    "status": true,
    "code": 1104298500,
    "name": 477,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->прочее:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 479,
    "status": true,
    "code": 1104298900,
    "name": 478,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->прочих злаков:->прочее:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 480,
    "status": true,
    "code": 1104301000,
    "name": 479,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 481,
    "status": true,
    "code": 1104309000,
    "name": 480,
    "description": "Зерно злаков, обработанное другими способами (например, шелушеное, плющеное, переработанное в хлопья, обрушенное, в виде сечки или дробленое), кроме риса товарной позиции 1006; зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:->зародыши зерна злаков, целые, плющеные, в виде хлопьев или молотые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 482,
    "status": true,
    "code": 1105100000,
    "name": 481,
    "description": "Мука тонкого и грубого помола, порошок, хлопья, гранулы картофельные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 483,
    "status": true,
    "code": 1105200000,
    "name": 482,
    "description": "Мука тонкого и грубого помола, порошок, хлопья, гранулы картофельные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 484,
    "status": true,
    "code": 1106100000,
    "name": 483,
    "description": "Мука тонкого и грубого помола и порошок из сушеных бобовых овощей товарной позиции 0713, из сердцевины саговой пальмы, из корнеплодов или клубнеплодов товарной позиции 0714 или продуктов группы 08:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.115591"
  },
  {
    "id": 485,
    "status": true,
    "code": 1106201000,
    "name": 484,
    "description": "Мука тонкого и грубого помола и порошок из сушеных бобовых овощей товарной позиции 0713, из сердцевины саговой пальмы, из корнеплодов или клубнеплодов товарной позиции 0714 или продуктов группы 08:->из сердцевины саговой пальмы, из корнеплодов или клубнеплодов товарной позиции 0714:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.117985"
  },
  {
    "id": 486,
    "status": true,
    "code": 1106209000,
    "name": 485,
    "description": "Мука тонкого и грубого помола и порошок из сушеных бобовых овощей товарной позиции 0713, из сердцевины саговой пальмы, из корнеплодов или клубнеплодов товарной позиции 0714 или продуктов группы 08:->из сердцевины саговой пальмы, из корнеплодов или клубнеплодов товарной позиции 0714:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.120127"
  },
  {
    "id": 487,
    "status": true,
    "code": 1106301000,
    "name": 486,
    "description": "Мука тонкого и грубого помола и порошок из сушеных бобовых овощей товарной позиции 0713, из сердцевины саговой пальмы, из корнеплодов или клубнеплодов товарной позиции 0714 или продуктов группы 08:->из продуктов группы 08:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.122269"
  },
  {
    "id": 488,
    "status": true,
    "code": 1106309000,
    "name": 487,
    "description": "Мука тонкого и грубого помола и порошок из сушеных бобовых овощей товарной позиции 0713, из сердцевины саговой пальмы, из корнеплодов или клубнеплодов товарной позиции 0714 или продуктов группы 08:->из продуктов группы 08:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.124423"
  },
  {
    "id": 489,
    "status": true,
    "code": 1107101100,
    "name": 488,
    "description": "Солод, поджаренный или неподжаренный:->неподжаренный:->из пшеницы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91614.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 17:18:05.162472"
  },
  {
    "id": 490,
    "status": true,
    "code": 1107101900,
    "name": 489,
    "description": "Солод, поджаренный или неподжаренный:->неподжаренный:->из пшеницы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91614.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 17:18:34.570225"
  },
  {
    "id": 491,
    "status": true,
    "code": 1107109100,
    "name": 490,
    "description": "Солод, поджаренный или неподжаренный:->неподжаренный:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91614.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 17:18:52.136553"
  },
  {
    "id": 492,
    "status": true,
    "code": 1107109900,
    "name": 491,
    "description": "Солод, поджаренный или неподжаренный:->неподжаренный:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 91614.7,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 17:19:05.000042"
  },
  {
    "id": 493,
    "status": true,
    "code": 1107200000,
    "name": 492,
    "description": "Солод, поджаренный или неподжаренный:->неподжаренный:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 494,
    "status": true,
    "code": 1108110000,
    "name": 493,
    "description": "Крахмал; инулин:->крахмал:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 495,
    "status": true,
    "code": 1108120000,
    "name": 494,
    "description": "Крахмал; инулин:->крахмал:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 496,
    "status": true,
    "code": 1108130000,
    "name": 495,
    "description": "Крахмал; инулин:->крахмал:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 497,
    "status": true,
    "code": 1108140000,
    "name": 496,
    "description": "Крахмал; инулин:->крахмал:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 498,
    "status": true,
    "code": 1108191000,
    "name": 497,
    "description": "Крахмал; инулин:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 499,
    "status": true,
    "code": 1108199000,
    "name": 498,
    "description": "Крахмал; инулин:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 500,
    "status": true,
    "code": 1108200000,
    "name": 499,
    "description": "Крахмал; инулин:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 501,
    "status": true,
    "code": 1109000000,
    "name": 500,
    "description": "Крахмал; инулин:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1743,
    "status": true,
    "code": 1155,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-04 23:29:05.855051",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 502,
    "status": true,
    "code": 1201100000,
    "name": 501,
    "description": "Соевые бобы, дробленые или недробленые:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:31:23.670434"
  },
  {
    "id": 503,
    "status": true,
    "code": 1201900000,
    "name": 502,
    "description": "Соевые бобы, дробленые или недробленые:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:40:24.559346"
  },
  {
    "id": 504,
    "status": true,
    "code": 1202300000,
    "name": 503,
    "description": "Арахис, нежареный или не приготовленный каким-либо другим способом, лущеный или нелущеный, дробленый или недробленый:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:30:58.062347"
  },
  {
    "id": 505,
    "status": true,
    "code": 1202410000,
    "name": 504,
    "description": "Арахис, нежареный или не приготовленный каким-либо другим способом, лущеный или нелущеный, дробленый или недробленый:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:40:42.285047"
  },
  {
    "id": 2180,
    "status": true,
    "code": 120242000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-05-23 19:07:44.308778",
    "updated_at": "2022-05-23 19:07:44.308788"
  },
  {
    "id": 506,
    "status": true,
    "code": 1202420000,
    "name": 505,
    "description": "Арахис, нежареный или не приготовленный каким-либо другим способом, лущеный или нелущеный, дробленый или недробленый:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:39:07.249323"
  },
  {
    "id": 507,
    "status": true,
    "code": 1203000000,
    "name": 506,
    "description": "Арахис, нежареный или не приготовленный каким-либо другим способом, лущеный или нелущеный, дробленый или недробленый:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:40:18.579104"
  },
  {
    "id": 508,
    "status": true,
    "code": 1204001001,
    "name": 507,
    "description": "Арахис, нежареный или не приготовленный каким-либо другим способом, лущеный или нелущеный, дробленый или недробленый:->Семена льна, дробленые или недробленые:->для посева",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:30:50.629205"
  },
  {
    "id": 509,
    "status": true,
    "code": 1204001009,
    "name": 508,
    "description": "Арахис, нежареный или не приготовленный каким-либо другим способом, лущеный или нелущеный, дробленый или недробленый:->Семена льна, дробленые или недробленые:->для посева",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:40:29.653357"
  },
  {
    "id": 510,
    "status": true,
    "code": 1204009000,
    "name": 509,
    "description": "Арахис, нежареный или не приготовленный каким-либо другим способом, лущеный или нелущеный, дробленый или недробленый:->Семена льна, дробленые или недробленые:->для посева",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:31:09.583559"
  },
  {
    "id": 511,
    "status": true,
    "code": 1205101000,
    "name": 510,
    "description": "Семена рапса, или кользы, дробленые или недробленые:->семена рапса, или кользы, с низким содержанием эруковой кислоты:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:40:35.333477"
  },
  {
    "id": 512,
    "status": true,
    "code": 1205109000,
    "name": 511,
    "description": "Семена рапса, или кользы, дробленые или недробленые:->семена рапса, или кользы, с низким содержанием эруковой кислоты:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 89639.5,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 10:54:11.860436"
  },
  {
    "id": 513,
    "status": true,
    "code": 1205900001,
    "name": 512,
    "description": "Семена рапса, или кользы, дробленые или недробленые:->семена рапса, или кользы, с низким содержанием эруковой кислоты:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:30:44.573431"
  },
  {
    "id": 514,
    "status": true,
    "code": 1205900009,
    "name": 513,
    "description": "Семена рапса, или кользы, дробленые или недробленые:->семена рапса, или кользы, с низким содержанием эруковой кислоты:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 89639.5,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 10:54:52.970579"
  },
  {
    "id": 515,
    "status": true,
    "code": 1206001000,
    "name": 514,
    "description": "Семена рапса, или кользы, дробленые или недробленые:->Семена подсолнечника, дробленые или недробленые:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:39:27.795450"
  },
  {
    "id": 516,
    "status": true,
    "code": 1206009100,
    "name": 515,
    "description": "Семена рапса, или кользы, дробленые или недробленые:->Семена подсолнечника, дробленые или недробленые:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 117641.04,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 10:48:27.836869"
  },
  {
    "id": 517,
    "status": true,
    "code": 1206009900,
    "name": 516,
    "description": "Семена рапса, или кользы, дробленые или недробленые:->Семена подсолнечника, дробленые или недробленые:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 117641.04,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-17 10:50:25.345289"
  },
  {
    "id": 518,
    "status": true,
    "code": 1207100000,
    "name": 517,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:38:33.762411"
  },
  {
    "id": 519,
    "status": true,
    "code": 1207210000,
    "name": 518,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:->семена хлопчатника:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:39:33.633287"
  },
  {
    "id": 520,
    "status": true,
    "code": 1207290000,
    "name": 519,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:->семена хлопчатника:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:33:49.250601"
  },
  {
    "id": 521,
    "status": true,
    "code": 1207300000,
    "name": 520,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:->семена хлопчатника:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:39:21.405887"
  },
  {
    "id": 522,
    "status": true,
    "code": 1207401000,
    "name": 521,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:->семена кунжута:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:39:40.164141"
  },
  {
    "id": 523,
    "status": true,
    "code": 1207409000,
    "name": 522,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:->семена кунжута:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:39:45.468349"
  },
  {
    "id": 524,
    "status": true,
    "code": 1207501000,
    "name": 523,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:->семена горчицы:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:39:50.585675"
  },
  {
    "id": 525,
    "status": true,
    "code": 1207509000,
    "name": 524,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:->семена горчицы:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:39:56.489914"
  },
  {
    "id": 526,
    "status": true,
    "code": 1207600000,
    "name": 525,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:->семена горчицы:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:40:03.025033"
  },
  {
    "id": 527,
    "status": true,
    "code": 1207700000,
    "name": 526,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:->семена горчицы:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:37:49.068508"
  },
  {
    "id": 528,
    "status": true,
    "code": 1207911000,
    "name": 527,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:->семена мака:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:37:55.966484"
  },
  {
    "id": 529,
    "status": true,
    "code": 1207919000,
    "name": 528,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:->семена мака:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:38:05.709296"
  },
  {
    "id": 530,
    "status": true,
    "code": 1207992000,
    "name": 529,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:38:11.978616"
  },
  {
    "id": 531,
    "status": true,
    "code": 1207999100,
    "name": 530,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:34:47.360061"
  },
  {
    "id": 532,
    "status": true,
    "code": 1207999600,
    "name": 531,
    "description": "Семена и плоды прочих масличных культур, дробленые или недробленые:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:33:43.174357"
  },
  {
    "id": 533,
    "status": true,
    "code": 1208100000,
    "name": 532,
    "description": "Мука тонкого и грубого помола из семян или плодов масличных культур, кроме семян горчицы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 534,
    "status": true,
    "code": 1208900000,
    "name": 533,
    "description": "Мука тонкого и грубого помола из семян или плодов масличных культур, кроме семян горчицы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 535,
    "status": true,
    "code": 1209100000,
    "name": 534,
    "description": "Семена, плоды и споры для посева:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:38:18.114755"
  },
  {
    "id": 536,
    "status": true,
    "code": 1209210000,
    "name": 535,
    "description": "Семена, плоды и споры для посева:->семена кормовых растений:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:39:13.841822"
  },
  {
    "id": 537,
    "status": true,
    "code": 1209221000,
    "name": 536,
    "description": "Семена, плоды и споры для посева:->семена клевера (Trifolium spp.):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:38:39.889897"
  },
  {
    "id": 538,
    "status": true,
    "code": 1209228000,
    "name": 537,
    "description": "Семена, плоды и споры для посева:->семена клевера (Trifolium spp.):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:38:28.106751"
  },
  {
    "id": 539,
    "status": true,
    "code": 1209231100,
    "name": 538,
    "description": "Семена, плоды и споры для посева:->семена овсяницы:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:37:39.071039"
  },
  {
    "id": 540,
    "status": true,
    "code": 1209231500,
    "name": 539,
    "description": "Семена, плоды и споры для посева:->семена овсяницы:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:37:32.207028"
  },
  {
    "id": 541,
    "status": true,
    "code": 1209238000,
    "name": 540,
    "description": "Семена, плоды и споры для посева:->семена овсяницы:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:37:17.001483"
  },
  {
    "id": 542,
    "status": true,
    "code": 1209240000,
    "name": 541,
    "description": "Семена, плоды и споры для посева:->семена овсяницы:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:37:09.738408"
  },
  {
    "id": 543,
    "status": true,
    "code": 1209251000,
    "name": 542,
    "description": "Семена, плоды и споры для посева:->семена райграса (Lolium multiflorum Lam., Lolium perenne L.):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:37:03.793786"
  },
  {
    "id": 544,
    "status": true,
    "code": 1209259000,
    "name": 543,
    "description": "Семена, плоды и споры для посева:->семена райграса (Lolium multiflorum Lam., Lolium perenne L.):",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:33:07.762592"
  },
  {
    "id": 545,
    "status": true,
    "code": 1209294500,
    "name": 544,
    "description": "Семена, плоды и споры для посева:->прочих:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:36:56.241367"
  },
  {
    "id": 546,
    "status": true,
    "code": 1209295000,
    "name": 545,
    "description": "Семена, плоды и споры для посева:->прочих:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:36:50.223888"
  },
  {
    "id": 547,
    "status": true,
    "code": 1209296000,
    "name": 546,
    "description": "Семена, плоды и споры для посева:->прочих:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:36:42.566684"
  },
  {
    "id": 548,
    "status": true,
    "code": 1209298000,
    "name": 547,
    "description": "Семена, плоды и споры для посева:->прочих:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:36:36.843888"
  },
  {
    "id": 549,
    "status": true,
    "code": 1209300000,
    "name": 548,
    "description": "Семена, плоды и споры для посева:->прочих:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:30:31.679152"
  },
  {
    "id": 550,
    "status": true,
    "code": 1209913000,
    "name": 549,
    "description": "Семена, плоды и споры для посева:->семена овощных культур:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:30:10.308290"
  },
  {
    "id": 551,
    "status": true,
    "code": 1209918000,
    "name": 550,
    "description": "Семена, плоды и споры для посева:->семена овощных культур:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.232149"
  },
  {
    "id": 552,
    "status": true,
    "code": 1209991010,
    "name": 551,
    "description": "Семена, плоды и споры для посева:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:29:36.684471"
  },
  {
    "id": 553,
    "status": true,
    "code": 1209991090,
    "name": 552,
    "description": "Семена, плоды и споры для посева:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:38:47.740996"
  },
  {
    "id": 554,
    "status": true,
    "code": 1209999100,
    "name": 553,
    "description": "Семена, плоды и споры для посева:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:34:16.333374"
  },
  {
    "id": 555,
    "status": true,
    "code": 1209999900,
    "name": 554,
    "description": "Семена, плоды и споры для посева:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:28:57.013173"
  },
  {
    "id": 556,
    "status": true,
    "code": 1210100000,
    "name": 555,
    "description": "Шишки хмеля, свежие или сушеные, дробленые или недробленые, в порошкообразном виде или в виде гранул; лупулин:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 557,
    "status": true,
    "code": 1210201000,
    "name": 556,
    "description": "Шишки хмеля, свежие или сушеные, дробленые или недробленые, в порошкообразном виде или в виде гранул; лупулин:->шишки хмеля дробленые, в порошкообразном виде или в виде гранул; лупулин:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 558,
    "status": true,
    "code": 1210209000,
    "name": 557,
    "description": "Шишки хмеля, свежие или сушеные, дробленые или недробленые, в порошкообразном виде или в виде гранул; лупулин:->шишки хмеля дробленые, в порошкообразном виде или в виде гранул; лупулин:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 559,
    "status": true,
    "code": 1211200000,
    "name": 558,
    "description": "Растения и их части (включая семена и плоды), используемые в основном в парфюмерии, фармации или инсектицидных, фунгицидных или аналогичных целях, свежие, охлажденные, мороженные или сушеные, целые или измельченные, дробленые или молотые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 560,
    "status": true,
    "code": 1211300000,
    "name": 559,
    "description": "Растения и их части (включая семена и плоды), используемые в основном в парфюмерии, фармации или инсектицидных, фунгицидных или аналогичных целях, свежие, охлажденные, мороженные или сушеные, целые или измельченные, дробленые или молотые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 561,
    "status": true,
    "code": 1211400000,
    "name": 560,
    "description": "Растения и их части (включая семена и плоды), используемые в основном в парфюмерии, фармации или инсектицидных, фунгицидных или аналогичных целях, свежие, охлажденные, мороженные или сушеные, целые или измельченные, дробленые или молотые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 562,
    "status": true,
    "code": 1211500000,
    "name": 561,
    "description": "Растения и их части (включая семена и плоды), используемые в основном в парфюмерии, фармации или инсектицидных, фунгицидных или аналогичных целях, свежие, охлажденные, мороженные или сушеные, целые или измельченные, дробленые или молотые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 563,
    "status": true,
    "code": 1211904000,
    "name": 562,
    "description": "Растения и их части (включая семена и плоды), используемые в основном в парфюмерии, фармации или инсектицидных, фунгицидных или аналогичных целях, свежие, охлажденные, мороженные или сушеные, целые или измельченные, дробленые или молотые:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 564,
    "status": true,
    "code": 1211908601,
    "name": 563,
    "description": "Растения и их части (включая семена и плоды), используемые в основном в парфюмерии, фармации или инсектицидных, фунгицидных или аналогичных целях, свежие, охлажденные, мороженные или сушеные, целые или измельченные, дробленые или молотые:->прочие:->корни солодки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 565,
    "status": true,
    "code": 1211908603,
    "name": 564,
    "description": "Растения и их части (включая семена и плоды), используемые в основном в парфюмерии, фармации или инсектицидных, фунгицидных или аналогичных целях, свежие, охлажденные, мороженные или сушеные, целые или измельченные, дробленые или молотые:->прочие:->корни солодки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 566,
    "status": true,
    "code": 1211908605,
    "name": 565,
    "description": "Растения и их части (включая семена и плоды), используемые в основном в парфюмерии, фармации или инсектицидных, фунгицидных или аналогичных целях, свежие, охлажденные, мороженные или сушеные, целые или измельченные, дробленые или молотые:->прочие:->корни солодки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 567,
    "status": true,
    "code": 1211908607,
    "name": 566,
    "description": "Растения и их части (включая семена и плоды), используемые в основном в парфюмерии, фармации или инсектицидных, фунгицидных или аналогичных целях, свежие, охлажденные, мороженные или сушеные, целые или измельченные, дробленые или молотые:->прочие:->корни солодки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 568,
    "status": true,
    "code": 1211908608,
    "name": 567,
    "description": "Растения и их части (включая семена и плоды), используемые в основном в парфюмерии, фармации или инсектицидных, фунгицидных или аналогичных целях, свежие, охлажденные, мороженные или сушеные, целые или измельченные, дробленые или молотые:->прочие:->корни солодки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 569,
    "status": true,
    "code": 1211908609,
    "name": 568,
    "description": "Растения и их части (включая семена и плоды), используемые в основном в парфюмерии, фармации или инсектицидных, фунгицидных или аналогичных целях, свежие, охлажденные, мороженные или сушеные, целые или измельченные, дробленые или молотые:->прочие:->корни солодки:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-02-26 18:44:57.247999"
  },
  {
    "id": 570,
    "status": true,
    "code": 1212210000,
    "name": 569,
    "description": "Плоды рожкового дерева, морские и прочие водоросли, свекла сахарная и сахарный тростник, свежие, охлажденные, мороженые или сушеные, дробленые или недробленые; косточки плодов и их ядра, прочие продукты растительного происхождения (включая необжаренные корни цикория вида Cichorium intybus sativum), используемые главным образом для пищевых целей, в другом месте не поименованные или не включенные:->морские и прочие водоросли:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 571,
    "status": true,
    "code": 1212290000,
    "name": 570,
    "description": "Плоды рожкового дерева, морские и прочие водоросли, свекла сахарная и сахарный тростник, свежие, охлажденные, мороженые или сушеные, дробленые или недробленые; косточки плодов и их ядра, прочие продукты растительного происхождения (включая необжаренные корни цикория вида Cichorium intybus sativum), используемые главным образом для пищевых целей, в другом месте не поименованные или не включенные:->морские и прочие водоросли:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 572,
    "status": true,
    "code": 1212912000,
    "name": 571,
    "description": "Плоды рожкового дерева, морские и прочие водоросли, свекла сахарная и сахарный тростник, свежие, охлажденные, мороженые или сушеные, дробленые или недробленые; косточки плодов и их ядра, прочие продукты растительного происхождения (включая необжаренные корни цикория вида Cichorium intybus sativum), используемые главным образом для пищевых целей, в другом месте не поименованные или не включенные:->свекла сахарная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 573,
    "status": true,
    "code": 1212918000,
    "name": 572,
    "description": "Плоды рожкового дерева, морские и прочие водоросли, свекла сахарная и сахарный тростник, свежие, охлажденные, мороженые или сушеные, дробленые или недробленые; косточки плодов и их ядра, прочие продукты растительного происхождения (включая необжаренные корни цикория вида Cichorium intybus sativum), используемые главным образом для пищевых целей, в другом месте не поименованные или не включенные:->свекла сахарная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 574,
    "status": true,
    "code": 1212920000,
    "name": 573,
    "description": "Плоды рожкового дерева, морские и прочие водоросли, свекла сахарная и сахарный тростник, свежие, охлажденные, мороженые или сушеные, дробленые или недробленые; косточки плодов и их ядра, прочие продукты растительного происхождения (включая необжаренные корни цикория вида Cichorium intybus sativum), используемые главным образом для пищевых целей, в другом месте не поименованные или не включенные:->свекла сахарная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 89639.5,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-18 09:15:48.143351"
  },
  {
    "id": 575,
    "status": true,
    "code": 1212930000,
    "name": 574,
    "description": "Плоды рожкового дерева, морские и прочие водоросли, свекла сахарная и сахарный тростник, свежие, охлажденные, мороженые или сушеные, дробленые или недробленые; косточки плодов и их ядра, прочие продукты растительного происхождения (включая необжаренные корни цикория вида Cichorium intybus sativum), используемые главным образом для пищевых целей, в другом месте не поименованные или не включенные:->свекла сахарная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 576,
    "status": true,
    "code": 1212940000,
    "name": 575,
    "description": "Плоды рожкового дерева, морские и прочие водоросли, свекла сахарная и сахарный тростник, свежие, охлажденные, мороженые или сушеные, дробленые или недробленые; косточки плодов и их ядра, прочие продукты растительного происхождения (включая необжаренные корни цикория вида Cichorium intybus sativum), используемые главным образом для пищевых целей, в другом месте не поименованные или не включенные:->свекла сахарная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 577,
    "status": true,
    "code": 1212994100,
    "name": 576,
    "description": "Плоды рожкового дерева, морские и прочие водоросли, свекла сахарная и сахарный тростник, свежие, охлажденные, мороженые или сушеные, дробленые или недробленые; косточки плодов и их ядра, прочие продукты растительного происхождения (включая необжаренные корни цикория вида Cichorium intybus sativum), используемые главным образом для пищевых целей, в другом месте не поименованные или не включенные:->прочие:->семена рожкового дерева:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 89639.5,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-18 09:16:19.386880"
  },
  {
    "id": 578,
    "status": true,
    "code": 1212994900,
    "name": 577,
    "description": "Плоды рожкового дерева, морские и прочие водоросли, свекла сахарная и сахарный тростник, свежие, охлажденные, мороженые или сушеные, дробленые или недробленые; косточки плодов и их ядра, прочие продукты растительного происхождения (включая необжаренные корни цикория вида Cichorium intybus sativum), используемые главным образом для пищевых целей, в другом месте не поименованные или не включенные:->прочие:->семена рожкового дерева:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 89639.5,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-05-18 09:16:48.331949"
  },
  {
    "id": 579,
    "status": true,
    "code": 1212999500,
    "name": 578,
    "description": "Плоды рожкового дерева, морские и прочие водоросли, свекла сахарная и сахарный тростник, свежие, охлажденные, мороженые или сушеные, дробленые или недробленые; косточки плодов и их ядра, прочие продукты растительного происхождения (включая необжаренные корни цикория вида Cichorium intybus sativum), используемые главным образом для пищевых целей, в другом месте не поименованные или не включенные:->прочие:->семена рожкового дерева:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2230,
    "status": true,
    "code": 1212999509,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-06 11:54:30.766261",
    "updated_at": "2022-10-06 11:54:30.766270"
  },
  {
    "id": 580,
    "status": true,
    "code": 1213000000,
    "name": 579,
    "description": "Плоды рожкового дерева, морские и прочие водоросли, свекла сахарная и сахарный тростник, свежие, охлажденные, мороженые или сушеные, дробленые или недробленые; косточки плодов и их ядра, прочие продукты растительного происхождения (включая необжаренные корни цикория вида Cichorium intybus sativum), используемые главным образом для пищевых целей, в другом месте не поименованные или не включенные:->прочие:->семена рожкового дерева:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 581,
    "status": true,
    "code": 1214100000,
    "name": 580,
    "description": "Брюква, свекла листовая (мангольд), корнеплоды кормовые, сено, люцерна, клевер, эспарцет, капуста кормовая, люпин, вика и аналогичные кормовые продукты, гранулированные или негранулированные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 582,
    "status": true,
    "code": 1214901000,
    "name": 581,
    "description": "Брюква, свекла листовая (мангольд), корнеплоды кормовые, сено, люцерна, клевер, эспарцет, капуста кормовая, люпин, вика и аналогичные кормовые продукты, гранулированные или негранулированные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 583,
    "status": true,
    "code": 1214909000,
    "name": 582,
    "description": "Брюква, свекла листовая (мангольд), корнеплоды кормовые, сено, люцерна, клевер, эспарцет, капуста кормовая, люпин, вика и аналогичные кормовые продукты, гранулированные или негранулированные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1370,
    "status": true,
    "code": 1301900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:52:48.867392",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1285,
    "status": true,
    "code": 1302120000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:45.545434",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1340,
    "status": true,
    "code": 1302199000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:47:42.432682",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1612,
    "status": true,
    "code": 1302201000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-24 10:18:42.689582",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1611,
    "status": true,
    "code": 1302310000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-24 10:18:42.686434",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 584,
    "status": true,
    "code": 1401100000,
    "name": 583,
    "description": "Материалы растительного происхождения, используемые главным образом для плетения (например, бамбук, ротанг, тростник, ситник, ива, рафия, очищенная, отбеленная или окрашенная солома зерновых и липовая кора):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 585,
    "status": true,
    "code": 1401200000,
    "name": 584,
    "description": "Материалы растительного происхождения, используемые главным образом для плетения (например, бамбук, ротанг, тростник, ситник, ива, рафия, очищенная, отбеленная или окрашенная солома зерновых и липовая кора):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 586,
    "status": true,
    "code": 1401900000,
    "name": 585,
    "description": "Материалы растительного происхождения, используемые главным образом для плетения (например, бамбук, ротанг, тростник, ситник, ива, рафия, очищенная, отбеленная или окрашенная солома зерновых и липовая кора):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 587,
    "status": true,
    "code": 1404200000,
    "name": 586,
    "description": "Материалы растительного происхождения, в другом месте не поименованные или не включенные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.335276"
  },
  {
    "id": 588,
    "status": true,
    "code": 1404900000,
    "name": 587,
    "description": "Материалы растительного происхождения, в другом месте не поименованные или не включенные:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-04-11 12:34:34.351091"
  },
  {
    "id": 2308,
    "status": true,
    "code": 1404900001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-07 21:04:15.410938",
    "updated_at": "2023-01-07 21:04:15.410966"
  },
  {
    "id": 2320,
    "status": true,
    "code": 1404900008,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-12 12:28:30.888312",
    "updated_at": "2023-01-12 12:28:30.888330"
  },
  {
    "id": 589,
    "status": true,
    "code": 1507101000,
    "name": 588,
    "description": "Масло соевое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое, нерафинированное или рафинированное гидратацией:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 590,
    "status": true,
    "code": 1507109001,
    "name": 589,
    "description": "Масло соевое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое, нерафинированное или рафинированное гидратацией:->прочее",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 591,
    "status": true,
    "code": 1507109009,
    "name": 590,
    "description": "Масло соевое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое, нерафинированное или рафинированное гидратацией:->прочее",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 592,
    "status": true,
    "code": 1507901000,
    "name": 591,
    "description": "Масло соевое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 593,
    "status": true,
    "code": 1507909001,
    "name": 592,
    "description": "Масло соевое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 594,
    "status": true,
    "code": 1507909009,
    "name": 593,
    "description": "Масло соевое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 595,
    "status": true,
    "code": 1508101000,
    "name": 594,
    "description": "Масло арахисовое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 596,
    "status": true,
    "code": 1508109000,
    "name": 595,
    "description": "Масло арахисовое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 597,
    "status": true,
    "code": 1508901000,
    "name": 596,
    "description": "Масло арахисовое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 598,
    "status": true,
    "code": 1508909000,
    "name": 597,
    "description": "Масло арахисовое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 599,
    "status": true,
    "code": 1509101000,
    "name": 598,
    "description": "Масло оливковое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло оливковое первого (холодного) прессования:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 600,
    "status": true,
    "code": 1509109000,
    "name": 599,
    "description": "Масло оливковое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло оливковое первого (холодного) прессования:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 601,
    "status": true,
    "code": 1509900000,
    "name": 600,
    "description": "Масло оливковое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло оливковое первого (холодного) прессования:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 602,
    "status": true,
    "code": 1510001000,
    "name": 601,
    "description": "Масло оливковое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->Прочие масла и их фракции, получаемые только из маслин, или оливок, нерафинированные или рафинированные, но без изменения химического состава, включая смеси этих масел или фракций с маслами или фракциями товарной позиции 1509:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 603,
    "status": true,
    "code": 1510009000,
    "name": 602,
    "description": "Масло оливковое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->Прочие масла и их фракции, получаемые только из маслин, или оливок, нерафинированные или рафинированные, но без изменения химического состава, включая смеси этих масел или фракций с маслами или фракциями товарной позиции 1509:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 604,
    "status": true,
    "code": 1511101000,
    "name": 603,
    "description": "Масло пальмовое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 605,
    "status": true,
    "code": 1511109002,
    "name": 604,
    "description": "Масло пальмовое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 606,
    "status": true,
    "code": 1511109008,
    "name": 605,
    "description": "Масло пальмовое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 607,
    "status": true,
    "code": 1511901100,
    "name": 606,
    "description": "Масло пальмовое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->твердые фракции:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 608,
    "status": true,
    "code": 1511901902,
    "name": 607,
    "description": "Масло пальмовое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->твердые фракции:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 609,
    "status": true,
    "code": 1511901908,
    "name": 608,
    "description": "Масло пальмовое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->твердые фракции:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 610,
    "status": true,
    "code": 1511909100,
    "name": 609,
    "description": "Масло пальмовое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 611,
    "status": true,
    "code": 1511909902,
    "name": 610,
    "description": "Масло пальмовое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 612,
    "status": true,
    "code": 1511909908,
    "name": 611,
    "description": "Масло пальмовое и его фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 613,
    "status": true,
    "code": 1512111000,
    "name": 612,
    "description": "Масло подсолнечное, сафлоровое или хлопковое и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 614,
    "status": true,
    "code": 1512119101,
    "name": 613,
    "description": "Масло подсолнечное, сафлоровое или хлопковое и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее:->масло подсолнечное",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 615,
    "status": true,
    "code": 1512119109,
    "name": 614,
    "description": "Масло подсолнечное, сафлоровое или хлопковое и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее:->масло подсолнечное",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 616,
    "status": true,
    "code": 1512119901,
    "name": 615,
    "description": "Масло подсолнечное, сафлоровое или хлопковое и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее:->масло сафлоровое",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 617,
    "status": true,
    "code": 1512119909,
    "name": 616,
    "description": "Масло подсолнечное, сафлоровое или хлопковое и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее:->масло сафлоровое",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 618,
    "status": true,
    "code": 1512191000,
    "name": 617,
    "description": "Масло подсолнечное, сафлоровое или хлопковое и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 619,
    "status": true,
    "code": 1512199002,
    "name": 618,
    "description": "Масло подсолнечное, сафлоровое или хлопковое и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 620,
    "status": true,
    "code": 1512199003,
    "name": 619,
    "description": "Масло подсолнечное, сафлоровое или хлопковое и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 621,
    "status": true,
    "code": 1512199009,
    "name": 620,
    "description": "Масло подсолнечное, сафлоровое или хлопковое и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 622,
    "status": true,
    "code": 1512211000,
    "name": 621,
    "description": "Масло подсолнечное, сафлоровое или хлопковое и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое, очищенное от госсипола или не очищенное:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 623,
    "status": true,
    "code": 1512219000,
    "name": 622,
    "description": "Масло подсолнечное, сафлоровое или хлопковое и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое, очищенное от госсипола или не очищенное:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 624,
    "status": true,
    "code": 1512291000,
    "name": 623,
    "description": "Масло подсолнечное, сафлоровое или хлопковое и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 625,
    "status": true,
    "code": 1512299000,
    "name": 624,
    "description": "Масло подсолнечное, сафлоровое или хлопковое и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 626,
    "status": true,
    "code": 1513111000,
    "name": 625,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 627,
    "status": true,
    "code": 1513119100,
    "name": 626,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 628,
    "status": true,
    "code": 1513119901,
    "name": 627,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее:->прочее",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 629,
    "status": true,
    "code": 1513119909,
    "name": 628,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее:->прочее",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 630,
    "status": true,
    "code": 1513191100,
    "name": 629,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->твердые фракции:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 631,
    "status": true,
    "code": 1513191901,
    "name": 630,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->твердые фракции:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 632,
    "status": true,
    "code": 1513191909,
    "name": 631,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->твердые фракции:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 633,
    "status": true,
    "code": 1513193000,
    "name": 632,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 634,
    "status": true,
    "code": 1513199100,
    "name": 633,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 635,
    "status": true,
    "code": 1513199901,
    "name": 634,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 636,
    "status": true,
    "code": 1513199909,
    "name": 635,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 637,
    "status": true,
    "code": 1513211000,
    "name": 636,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 638,
    "status": true,
    "code": 1513213000,
    "name": 637,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 639,
    "status": true,
    "code": 1513219001,
    "name": 638,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее:->прочее",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 640,
    "status": true,
    "code": 1513219009,
    "name": 639,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее:->прочее",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 641,
    "status": true,
    "code": 1513291100,
    "name": 640,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->твердые фракции:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 642,
    "status": true,
    "code": 1513291901,
    "name": 641,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->твердые фракции:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 643,
    "status": true,
    "code": 1513291909,
    "name": 642,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->твердые фракции:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 644,
    "status": true,
    "code": 1513293000,
    "name": 643,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 645,
    "status": true,
    "code": 1513295000,
    "name": 644,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 646,
    "status": true,
    "code": 1513299001,
    "name": 645,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 647,
    "status": true,
    "code": 1513299009,
    "name": 646,
    "description": "Масло кокосовое (копровое), пальмоядровое или масло бабассу и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 648,
    "status": true,
    "code": 1514111000,
    "name": 647,
    "description": "Масло рапсовое (из рапса, или кользы) или горчичное и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 649,
    "status": true,
    "code": 1514119001,
    "name": 648,
    "description": "Масло рапсовое (из рапса, или кользы) или горчичное и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 650,
    "status": true,
    "code": 1514119009,
    "name": 649,
    "description": "Масло рапсовое (из рапса, или кользы) или горчичное и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 651,
    "status": true,
    "code": 1514191000,
    "name": 650,
    "description": "Масло рапсовое (из рапса, или кользы) или горчичное и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 652,
    "status": true,
    "code": 1514199001,
    "name": 651,
    "description": "Масло рапсовое (из рапса, или кользы) или горчичное и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 653,
    "status": true,
    "code": 1514199009,
    "name": 652,
    "description": "Масло рапсовое (из рапса, или кользы) или горчичное и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 654,
    "status": true,
    "code": 1514911000,
    "name": 653,
    "description": "Масло рапсовое (из рапса, или кользы) или горчичное и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 655,
    "status": true,
    "code": 1514919001,
    "name": 654,
    "description": "Масло рапсовое (из рапса, или кользы) или горчичное и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 656,
    "status": true,
    "code": 1514919009,
    "name": 655,
    "description": "Масло рапсовое (из рапса, или кользы) или горчичное и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:->прочее",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 657,
    "status": true,
    "code": 1514991000,
    "name": 656,
    "description": "Масло рапсовое (из рапса, или кользы) или горчичное и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 658,
    "status": true,
    "code": 1514999001,
    "name": 657,
    "description": "Масло рапсовое (из рапса, или кользы) или горчичное и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 659,
    "status": true,
    "code": 1514999009,
    "name": 658,
    "description": "Масло рапсовое (из рапса, или кользы) или горчичное и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 660,
    "status": true,
    "code": 1515110000,
    "name": 659,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло льняное и его фракции:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 661,
    "status": true,
    "code": 1515191000,
    "name": 660,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 662,
    "status": true,
    "code": 1515199000,
    "name": 661,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 663,
    "status": true,
    "code": 1515211000,
    "name": 662,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 664,
    "status": true,
    "code": 1515219000,
    "name": 663,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло сырое:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 665,
    "status": true,
    "code": 1515291000,
    "name": 664,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 666,
    "status": true,
    "code": 1515299000,
    "name": 665,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 667,
    "status": true,
    "code": 1515301000,
    "name": 666,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло касторовое и его фракции:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 668,
    "status": true,
    "code": 1515309000,
    "name": 667,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло касторовое и его фракции:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 669,
    "status": true,
    "code": 1515501100,
    "name": 668,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло кунжутное и его фракции:->масло сырое:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 670,
    "status": true,
    "code": 1515501900,
    "name": 669,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло кунжутное и его фракции:->масло сырое:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 671,
    "status": true,
    "code": 1515509100,
    "name": 670,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло кунжутное и его фракции:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 672,
    "status": true,
    "code": 1515509900,
    "name": 671,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->масло кунжутное и его фракции:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 673,
    "status": true,
    "code": 1515901100,
    "name": 672,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 674,
    "status": true,
    "code": 1515902100,
    "name": 673,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->масло сырое:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 675,
    "status": true,
    "code": 1515902900,
    "name": 674,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->масло сырое:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 676,
    "status": true,
    "code": 1515903100,
    "name": 675,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 677,
    "status": true,
    "code": 1515903900,
    "name": 676,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 678,
    "status": true,
    "code": 1515904000,
    "name": 677,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->масла сырые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 679,
    "status": true,
    "code": 1515905100,
    "name": 678,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 680,
    "status": true,
    "code": 1515905900,
    "name": 679,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 681,
    "status": true,
    "code": 1515906000,
    "name": 680,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 682,
    "status": true,
    "code": 1515909100,
    "name": 681,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1122,
    "status": true,
    "code": 1515909900,
    "name": 1121,
    "description": "Прочие нелетучие растительные жиры, масла (включая масло жожоба) и их фракции, нерафинированные или рафинированные, но без изменения химического состава",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1908,
    "status": true,
    "code": 1516209602,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-20 19:32:08.034564",
    "updated_at": "2021-01-20 19:32:08.034572"
  },
  {
    "id": 1193,
    "status": true,
    "code": 1516209801,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1725,
    "status": true,
    "code": 1516209809,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-21 11:12:25.277357",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1909,
    "status": true,
    "code": 1517109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-21 15:53:19.431163",
    "updated_at": "2021-01-21 15:53:19.431171"
  },
  {
    "id": 1164,
    "status": true,
    "code": 1517909100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1378,
    "status": true,
    "code": 1517909900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:53:56.757064",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1705,
    "status": true,
    "code": 1518009100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-11 15:30:03.790129",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2372,
    "status": true,
    "code": 1522009100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-16 21:09:22.675145",
    "updated_at": "2023-02-16 21:09:22.675158"
  },
  {
    "id": 1741,
    "status": true,
    "code": 1600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-04 23:29:05.819496",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1477,
    "status": true,
    "code": 1601009900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-30 20:00:12.319314",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2036,
    "status": true,
    "code": 1602321100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-16 16:50:25.436334",
    "updated_at": "2021-08-16 16:50:25.436362"
  },
  {
    "id": 683,
    "status": true,
    "code": 1701121001,
    "name": 682,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->свекловичный сахар:->для рафинирования:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 684,
    "status": true,
    "code": 1701121009,
    "name": 683,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->свекловичный сахар:->для рафинирования:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 685,
    "status": true,
    "code": 1701129001,
    "name": 684,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->свекловичный сахар:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 686,
    "status": true,
    "code": 1701129009,
    "name": 685,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->свекловичный сахар:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 687,
    "status": true,
    "code": 1701131011,
    "name": 686,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 688,
    "status": true,
    "code": 1701131012,
    "name": 687,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 689,
    "status": true,
    "code": 1701131013,
    "name": 688,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 690,
    "status": true,
    "code": 1701131014,
    "name": 689,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 691,
    "status": true,
    "code": 1701131015,
    "name": 690,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 692,
    "status": true,
    "code": 1701131021,
    "name": 691,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 693,
    "status": true,
    "code": 1701131022,
    "name": 692,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 694,
    "status": true,
    "code": 1701131023,
    "name": 693,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 695,
    "status": true,
    "code": 1701131024,
    "name": 694,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 696,
    "status": true,
    "code": 1701131025,
    "name": 695,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 697,
    "status": true,
    "code": 1701131026,
    "name": 696,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 698,
    "status": true,
    "code": 1701131027,
    "name": 697,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 699,
    "status": true,
    "code": 1701131031,
    "name": 698,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 700,
    "status": true,
    "code": 1701131032,
    "name": 699,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 701,
    "status": true,
    "code": 1701131033,
    "name": 700,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 702,
    "status": true,
    "code": 1701131034,
    "name": 701,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 703,
    "status": true,
    "code": 1701131035,
    "name": 702,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 704,
    "status": true,
    "code": 1701139011,
    "name": 703,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 705,
    "status": true,
    "code": 1701139012,
    "name": 704,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 706,
    "status": true,
    "code": 1701139013,
    "name": 705,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 707,
    "status": true,
    "code": 1701139014,
    "name": 706,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 708,
    "status": true,
    "code": 1701139015,
    "name": 707,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 709,
    "status": true,
    "code": 1701139021,
    "name": 708,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 710,
    "status": true,
    "code": 1701139022,
    "name": 709,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 711,
    "status": true,
    "code": 1701139023,
    "name": 710,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 712,
    "status": true,
    "code": 1701139024,
    "name": 711,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 713,
    "status": true,
    "code": 1701139025,
    "name": 712,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 714,
    "status": true,
    "code": 1701139026,
    "name": 713,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 715,
    "status": true,
    "code": 1701139027,
    "name": 714,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 716,
    "status": true,
    "code": 1701139031,
    "name": 715,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 717,
    "status": true,
    "code": 1701139032,
    "name": 716,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 718,
    "status": true,
    "code": 1701139033,
    "name": 717,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 719,
    "status": true,
    "code": 1701139034,
    "name": 718,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 720,
    "status": true,
    "code": 1701139035,
    "name": 719,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар, указанный в примечании к субпозициям 2 к данной группе:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 721,
    "status": true,
    "code": 1701141011,
    "name": 720,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 722,
    "status": true,
    "code": 1701141012,
    "name": 721,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 723,
    "status": true,
    "code": 1701141013,
    "name": 722,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 724,
    "status": true,
    "code": 1701141014,
    "name": 723,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 725,
    "status": true,
    "code": 1701141015,
    "name": 724,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 726,
    "status": true,
    "code": 1701141021,
    "name": 725,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 727,
    "status": true,
    "code": 1701141022,
    "name": 726,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 728,
    "status": true,
    "code": 1701141023,
    "name": 727,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 729,
    "status": true,
    "code": 1701141024,
    "name": 728,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 730,
    "status": true,
    "code": 1701141025,
    "name": 729,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 731,
    "status": true,
    "code": 1701141026,
    "name": 730,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 732,
    "status": true,
    "code": 1701141027,
    "name": 731,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 733,
    "status": true,
    "code": 1701141031,
    "name": 732,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 734,
    "status": true,
    "code": 1701141032,
    "name": 733,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 735,
    "status": true,
    "code": 1701141033,
    "name": 734,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 736,
    "status": true,
    "code": 1701141034,
    "name": 735,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 737,
    "status": true,
    "code": 1701141035,
    "name": 736,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 738,
    "status": true,
    "code": 1701149011,
    "name": 737,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 739,
    "status": true,
    "code": 1701149012,
    "name": 738,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 740,
    "status": true,
    "code": 1701149013,
    "name": 739,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 741,
    "status": true,
    "code": 1701149014,
    "name": 740,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 742,
    "status": true,
    "code": 1701149015,
    "name": 741,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 743,
    "status": true,
    "code": 1701149021,
    "name": 742,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 744,
    "status": true,
    "code": 1701149022,
    "name": 743,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 745,
    "status": true,
    "code": 1701149023,
    "name": 744,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 746,
    "status": true,
    "code": 1701149024,
    "name": 745,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 747,
    "status": true,
    "code": 1701149025,
    "name": 746,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 748,
    "status": true,
    "code": 1701149026,
    "name": 747,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 749,
    "status": true,
    "code": 1701149027,
    "name": 748,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 750,
    "status": true,
    "code": 1701149031,
    "name": 749,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 751,
    "status": true,
    "code": 1701149032,
    "name": 750,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 752,
    "status": true,
    "code": 1701149033,
    "name": 751,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 753,
    "status": true,
    "code": 1701149034,
    "name": 752,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 754,
    "status": true,
    "code": 1701149035,
    "name": 753,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 755,
    "status": true,
    "code": 1701910011,
    "name": 754,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 756,
    "status": true,
    "code": 1701910012,
    "name": 755,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 757,
    "status": true,
    "code": 1701910013,
    "name": 756,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 758,
    "status": true,
    "code": 1701910014,
    "name": 757,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 759,
    "status": true,
    "code": 1701910015,
    "name": 758,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 января по 30 апреля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 760,
    "status": true,
    "code": 1701910021,
    "name": 759,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 761,
    "status": true,
    "code": 1701910022,
    "name": 760,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 762,
    "status": true,
    "code": 1701910023,
    "name": 761,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 763,
    "status": true,
    "code": 1701910024,
    "name": 762,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 764,
    "status": true,
    "code": 1701910025,
    "name": 763,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 765,
    "status": true,
    "code": 1701910026,
    "name": 764,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 766,
    "status": true,
    "code": 1701910027,
    "name": 765,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 мая по 31 июля:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 767,
    "status": true,
    "code": 1701910031,
    "name": 766,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 768,
    "status": true,
    "code": 1701910032,
    "name": 767,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 769,
    "status": true,
    "code": 1701910033,
    "name": 768,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 770,
    "status": true,
    "code": 1701910034,
    "name": 769,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 771,
    "status": true,
    "code": 1701910035,
    "name": 770,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->тростниковый сахар прочий:->прочий:->с 1 августа по 31 декабря:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 772,
    "status": true,
    "code": 1701991001,
    "name": 771,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->прочий:->сахар белый:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 773,
    "status": true,
    "code": 1701991009,
    "name": 772,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->прочий:->сахар белый:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 774,
    "status": true,
    "code": 1701999001,
    "name": 773,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->прочий:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 775,
    "status": true,
    "code": 1701999009,
    "name": 774,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->прочий:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1838,
    "status": true,
    "code": 1702209000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-21 17:30:03.551755",
    "updated_at": "2020-11-21 17:30:03.551763"
  },
  {
    "id": 2094,
    "status": true,
    "code": 1702309001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-03 15:28:44.523118",
    "updated_at": "2021-12-03 15:28:44.523129"
  },
  {
    "id": 1722,
    "status": true,
    "code": 1702309009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-19 20:57:21.135410",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1350,
    "status": true,
    "code": 1702909500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:49:36.129008",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1407,
    "status": true,
    "code": 1703100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:00:44.158695",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1577,
    "status": true,
    "code": 1704101000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-12 22:08:44.222784",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1327,
    "status": true,
    "code": 1704109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:45:12.549830",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2031,
    "status": true,
    "code": 1704901000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-07-27 15:02:47.645521",
    "updated_at": "2021-07-27 15:02:47.645537"
  },
  {
    "id": 1831,
    "status": true,
    "code": 1704903000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-14 15:47:58.949985",
    "updated_at": "2020-11-14 15:47:58.949994"
  },
  {
    "id": 1170,
    "status": true,
    "code": 1704906100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2317,
    "status": true,
    "code": 1704906200,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-10 18:41:03.557902",
    "updated_at": "2023-01-10 18:41:03.557910"
  },
  {
    "id": 1749,
    "status": true,
    "code": 1704906500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-10 15:08:28.602651",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1322,
    "status": true,
    "code": 1704907100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:44:36.727658",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1189,
    "status": true,
    "code": 1704907500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2288,
    "status": true,
    "code": 1704909800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 19:33:22.210483",
    "updated_at": "2023-01-05 19:33:22.210493"
  },
  {
    "id": 1169,
    "status": true,
    "code": 1704909900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 776,
    "status": true,
    "code": 1801000000,
    "name": 775,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->прочий:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 777,
    "status": true,
    "code": 1802000000,
    "name": 776,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->прочий:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1187,
    "status": true,
    "code": 1803100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1188,
    "status": true,
    "code": 1804000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 778,
    "status": true,
    "code": 1805000000,
    "name": 777,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->прочий:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1944,
    "status": true,
    "code": 1806101500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-24 09:21:04.500122",
    "updated_at": "2021-03-24 09:21:04.500131"
  },
  {
    "id": 2085,
    "status": true,
    "code": 1806103000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-11-23 12:18:07.175258",
    "updated_at": "2021-11-23 12:18:07.175268"
  },
  {
    "id": 1202,
    "status": true,
    "code": 1806109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-24 17:19:39.781820",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1398,
    "status": true,
    "code": 1806310000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:58:38.363001",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1467,
    "status": true,
    "code": 1806321000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:15:06.815928",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1206,
    "status": true,
    "code": 1806329000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 14:08:43.570477",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1260,
    "status": true,
    "code": 1806901900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:39:53.139445",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1273,
    "status": true,
    "code": 1806903100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:09.940085",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2057,
    "status": true,
    "code": 1806903101,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-17 00:17:06.896647",
    "updated_at": "2021-09-17 00:17:06.896661"
  },
  {
    "id": 1332,
    "status": true,
    "code": 1806903900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:45:54.561220",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1383,
    "status": true,
    "code": 1806905001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:54:57.926949",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2058,
    "status": true,
    "code": 1806905002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-17 00:17:06.929895",
    "updated_at": "2021-09-17 00:17:06.929906"
  },
  {
    "id": 1318,
    "status": true,
    "code": 1806905009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:44:34.400873",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1302,
    "status": true,
    "code": 1806906000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:42:43.990395",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1733,
    "status": true,
    "code": 1806907000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-27 20:17:30.248103",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1801,
    "status": true,
    "code": 1806909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-19 16:59:08.648556",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1498,
    "status": true,
    "code": 1901100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-01 12:39:36.185513",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1601,
    "status": true,
    "code": 1901200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-23 19:18:41.909599",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2370,
    "status": true,
    "code": 1901909800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-15 16:05:10.331036",
    "updated_at": "2023-02-15 16:05:10.331047"
  },
  {
    "id": 2130,
    "status": true,
    "code": 1901909900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-02-09 10:57:03.043385",
    "updated_at": "2022-02-09 10:57:03.043394"
  },
  {
    "id": 1549,
    "status": true,
    "code": 1902110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-02 22:28:58.909949",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1772,
    "status": true,
    "code": 1902191000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-25 12:14:14.036028",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1588,
    "status": true,
    "code": 1902199000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-18 22:28:52.897477",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1465,
    "status": true,
    "code": 1902209100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:14:12.643925",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2219,
    "status": true,
    "code": 1902209900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-18 16:04:29.448732",
    "updated_at": "2022-08-18 16:04:29.448749"
  },
  {
    "id": 1402,
    "status": true,
    "code": 1902301000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:00:28.492524",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1930,
    "status": true,
    "code": 1902309000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-19 19:26:48.947117",
    "updated_at": "2021-02-19 19:26:48.947125"
  },
  {
    "id": 1931,
    "status": true,
    "code": 1902401000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-19 19:26:49.017273",
    "updated_at": "2021-02-19 19:26:49.017281"
  },
  {
    "id": 779,
    "status": true,
    "code": 1903000000,
    "name": 778,
    "description": "Сахар тростниковый или свекловичный и химически чистая сахароза, в твердом состоянии:->прочий:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1423,
    "status": true,
    "code": 1904101000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:04:34.688069",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1948,
    "status": true,
    "code": 1904103000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-24 18:34:46.602900",
    "updated_at": "2021-03-24 18:34:46.602909"
  },
  {
    "id": 1399,
    "status": true,
    "code": 1904109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:58:51.911236",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1703,
    "status": true,
    "code": 1904201000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-04 20:07:34.971152",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1155,
    "status": true,
    "code": 1904209100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1156,
    "status": true,
    "code": 1904209500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1157,
    "status": true,
    "code": 1904209900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1186,
    "status": true,
    "code": 1904300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1269,
    "status": true,
    "code": 1904901000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:40:36.074811",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1165,
    "status": true,
    "code": 1904908000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1979,
    "status": true,
    "code": 1905203000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-21 17:16:21.627573",
    "updated_at": "2021-04-21 17:16:21.627581"
  },
  {
    "id": 1319,
    "status": true,
    "code": 1905311100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:44:34.412934",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1243,
    "status": true,
    "code": 1905311900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:38.534837",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1650,
    "status": true,
    "code": 1905313000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-10 10:24:36.850976",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1320,
    "status": true,
    "code": 1905319100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:44:34.423273",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1251,
    "status": true,
    "code": 1905319900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:55.819342",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1447,
    "status": true,
    "code": 1905321100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:08:59.939250",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1321,
    "status": true,
    "code": 1905321900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:44:34.433561",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1718,
    "status": true,
    "code": 1905329100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-18 09:52:35.825600",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1303,
    "status": true,
    "code": 1905329900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:42:46.617834",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2060,
    "status": true,
    "code": 1905401000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-20 16:07:45.069390",
    "updated_at": "2021-09-20 16:07:45.069401"
  },
  {
    "id": 1655,
    "status": true,
    "code": 1905409000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-10 12:33:50.014453",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1331,
    "status": true,
    "code": 1905904500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:45:54.542413",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1771,
    "status": true,
    "code": 1905905500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-25 12:14:14.021966",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1268,
    "status": true,
    "code": 1905906000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:40:30.907691",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2093,
    "status": true,
    "code": 1905909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-03 12:15:52.228112",
    "updated_at": "2021-12-03 12:15:52.228127"
  },
  {
    "id": 1742,
    "status": true,
    "code": 200,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-04 23:29:05.843298",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1329,
    "status": true,
    "code": 2001100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:45:38.755903",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2000,
    "status": true,
    "code": 2001902000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-24 14:42:39.891888",
    "updated_at": "2021-05-24 14:42:39.891899"
  },
  {
    "id": 1525,
    "status": true,
    "code": 2001905000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-19 17:38:38.990910",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1803,
    "status": true,
    "code": 2001906500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-20 16:39:28.286802",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1911,
    "status": true,
    "code": 2001907000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-25 16:43:36.344184",
    "updated_at": "2021-01-25 16:43:36.344193"
  },
  {
    "id": 1357,
    "status": true,
    "code": 2001909700,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:50:38.082456",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2205,
    "status": true,
    "code": 2001909709,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-07-16 02:31:30.412825",
    "updated_at": "2022-07-16 02:31:30.412834"
  },
  {
    "id": 1358,
    "status": true,
    "code": 2002109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:50:38.088301",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1942,
    "status": true,
    "code": 2002903100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-23 17:32:22.899448",
    "updated_at": "2021-03-23 17:32:22.899457"
  },
  {
    "id": 1526,
    "status": true,
    "code": 2002903900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-19 17:38:39.035807",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1195,
    "status": true,
    "code": 2002909100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1333,
    "status": true,
    "code": 2004101000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:45:54.675316",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1550,
    "status": true,
    "code": 2004109900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-03 11:19:00.939760",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1847,
    "status": true,
    "code": 2004903000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-26 23:33:16.457079",
    "updated_at": "2020-11-26 23:33:16.457088"
  },
  {
    "id": 1185,
    "status": true,
    "code": 2004909800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1394,
    "status": true,
    "code": 2005100090,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:57:31.882347",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1674,
    "status": true,
    "code": 2005202000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-20 16:05:55.864388",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1194,
    "status": true,
    "code": 2005400000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1686,
    "status": true,
    "code": 2005590000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-27 19:30:10.008192",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1918,
    "status": true,
    "code": 2005700000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-08 10:34:54.609107",
    "updated_at": "2021-02-08 10:34:54.609115"
  },
  {
    "id": 1238,
    "status": true,
    "code": 2005800000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:14.774895",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1680,
    "status": true,
    "code": 2005991000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-24 16:55:16.101384",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2140,
    "status": true,
    "code": 2005993000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-03-14 11:00:42.481058",
    "updated_at": "2022-03-14 11:00:42.481068"
  },
  {
    "id": 1489,
    "status": true,
    "code": 2005995000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-24 12:59:36.583891",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1359,
    "status": true,
    "code": 2005996000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:50:38.093720",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1287,
    "status": true,
    "code": 2005998000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:49.621498",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1535,
    "status": true,
    "code": 2006003800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-25 02:49:07.558120",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2152,
    "status": true,
    "code": 2006009100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-02 21:12:14.934156",
    "updated_at": "2022-04-02 21:12:14.934170"
  },
  {
    "id": 1452,
    "status": true,
    "code": 2006009900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:11:34.026880",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2170,
    "status": true,
    "code": 2007109190,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-21 16:52:21.893068",
    "updated_at": "2022-04-21 16:52:21.893078"
  },
  {
    "id": 1661,
    "status": true,
    "code": 2007911000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-14 16:06:38.090442",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1512,
    "status": true,
    "code": 2007919000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-08 17:19:02.283689",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2238,
    "status": true,
    "code": 2007991000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-24 12:02:03.035079",
    "updated_at": "2022-10-24 12:02:03.035089"
  },
  {
    "id": 1508,
    "status": true,
    "code": 2007993100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-08 17:19:02.232287",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1509,
    "status": true,
    "code": 2007993300,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-08 17:19:02.240016",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1510,
    "status": true,
    "code": 2007993500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-08 17:19:02.245928",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1511,
    "status": true,
    "code": 2007993900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-08 17:19:02.258560",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1660,
    "status": true,
    "code": 2007995000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-14 16:06:38.080873",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2069,
    "status": true,
    "code": 2007995001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-10-06 13:42:40.898652",
    "updated_at": "2021-10-06 13:42:40.898669"
  },
  {
    "id": 1663,
    "status": true,
    "code": 2007995009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-15 11:08:01.058063",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2014,
    "status": true,
    "code": 2007999701,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-15 15:32:03.248686",
    "updated_at": "2021-06-15 15:32:03.248694"
  },
  {
    "id": 1338,
    "status": true,
    "code": 2007999709,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:46:53.546039",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2033,
    "status": true,
    "code": 2008111000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-10 16:47:47.854659",
    "updated_at": "2021-08-10 16:47:47.854668"
  },
  {
    "id": 1231,
    "status": true,
    "code": 2008119100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:46.616925",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1385,
    "status": true,
    "code": 2008119600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:56:05.809477",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1756,
    "status": true,
    "code": 2008119800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-16 16:24:23.908699",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1795,
    "status": true,
    "code": 2008191200,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-11 09:01:14.517891",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1415,
    "status": true,
    "code": 2008191300,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:02:15.994909",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1211,
    "status": true,
    "code": 2008191900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 15:14:56.996740",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2183,
    "status": true,
    "code": 2008199200,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-05-27 10:04:57.403760",
    "updated_at": "2022-05-27 10:04:57.403770"
  },
  {
    "id": 2338,
    "status": true,
    "code": 2008199201,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-21 13:55:35.910048",
    "updated_at": "2023-01-21 13:55:35.910058"
  },
  {
    "id": 2337,
    "status": true,
    "code": 2008199209,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-20 17:52:57.532026",
    "updated_at": "2023-01-20 17:52:57.532035"
  },
  {
    "id": 1476,
    "status": true,
    "code": 2008199300,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-30 20:00:05.317960",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1495,
    "status": true,
    "code": 2008199500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-30 22:19:19.055143",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1437,
    "status": true,
    "code": 2008199900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:07:09.451341",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1740,
    "status": true,
    "code": 2008210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-03 16:25:05.112074",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2169,
    "status": true,
    "code": 2008305900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-21 16:52:21.781334",
    "updated_at": "2022-04-21 16:52:21.781344"
  },
  {
    "id": 1664,
    "status": true,
    "code": 2008307500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-15 11:08:01.066314",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1792,
    "status": true,
    "code": 2008506900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-07 17:51:58.554684",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2070,
    "status": true,
    "code": 2008507100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-10-06 13:42:40.934260",
    "updated_at": "2021-10-06 13:42:40.934272"
  },
  {
    "id": 1964,
    "status": true,
    "code": 2008507900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-03 20:09:06.218554",
    "updated_at": "2021-04-03 20:09:06.218563"
  },
  {
    "id": 2040,
    "status": true,
    "code": 2008509800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-23 10:20:16.358326",
    "updated_at": "2021-08-23 10:20:16.358338"
  },
  {
    "id": 1754,
    "status": true,
    "code": 2008605000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-15 15:22:34.897246",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1364,
    "status": true,
    "code": 2008606000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:51:33.208315",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1966,
    "status": true,
    "code": 2008706900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-03 20:09:06.282888",
    "updated_at": "2021-04-03 20:09:06.282897"
  },
  {
    "id": 1989,
    "status": true,
    "code": 2008803900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-12 10:01:46.725273",
    "updated_at": "2021-05-12 10:01:46.725284"
  },
  {
    "id": 2165,
    "status": true,
    "code": 2008807000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-15 09:33:06.504705",
    "updated_at": "2022-04-15 09:33:06.504716"
  },
  {
    "id": 2041,
    "status": true,
    "code": 2008809000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-23 10:20:16.379882",
    "updated_at": "2021-08-23 10:20:16.379891"
  },
  {
    "id": 1962,
    "status": true,
    "code": 2008939100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-03 20:09:06.179938",
    "updated_at": "2021-04-03 20:09:06.179947"
  },
  {
    "id": 2039,
    "status": true,
    "code": 2008939900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-23 10:20:16.288342",
    "updated_at": "2021-08-23 10:20:16.288357"
  },
  {
    "id": 2122,
    "status": true,
    "code": 2008970500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-01-28 11:49:13.512901",
    "updated_at": "2022-01-28 11:49:13.512912"
  },
  {
    "id": 1764,
    "status": true,
    "code": 2008975900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-21 11:58:00.179563",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2114,
    "status": true,
    "code": 2008977800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-29 08:46:46.651772",
    "updated_at": "2021-12-29 08:46:46.651780"
  },
  {
    "id": 1475,
    "status": true,
    "code": 2008979800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-30 20:00:05.121067",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1166,
    "status": true,
    "code": 2008992100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1693,
    "status": true,
    "code": 2008992300,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-29 13:33:16.237658",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2059,
    "status": true,
    "code": 2008994100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-17 00:17:07.782124",
    "updated_at": "2021-09-17 00:17:07.782133"
  },
  {
    "id": 1961,
    "status": true,
    "code": 2008994500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-03 20:09:06.112754",
    "updated_at": "2021-04-03 20:09:06.112763"
  },
  {
    "id": 1727,
    "status": true,
    "code": 2008994800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-24 09:07:33.899501",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1963,
    "status": true,
    "code": 2008994900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-03 20:09:06.192896",
    "updated_at": "2021-04-03 20:09:06.192905"
  },
  {
    "id": 1842,
    "status": true,
    "code": 2008996701,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-25 11:39:54.418236",
    "updated_at": "2020-11-25 11:39:54.418245"
  },
  {
    "id": 1967,
    "status": true,
    "code": 2008996702,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-03 20:09:06.292732",
    "updated_at": "2021-04-03 20:09:06.292740"
  },
  {
    "id": 1965,
    "status": true,
    "code": 2008996709,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-03 20:09:06.231226",
    "updated_at": "2021-04-03 20:09:06.231239"
  },
  {
    "id": 2110,
    "status": true,
    "code": 2008997800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-18 11:00:32.103739",
    "updated_at": "2021-12-18 11:00:32.103747"
  },
  {
    "id": 1368,
    "status": true,
    "code": 2008999800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:52:16.664849",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2077,
    "status": true,
    "code": 2009191909,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-11-01 17:46:54.845411",
    "updated_at": "2021-11-01 17:46:54.845420"
  },
  {
    "id": 1899,
    "status": true,
    "code": 2009199809,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-14 17:11:28.960079",
    "updated_at": "2021-01-14 17:11:28.960088"
  },
  {
    "id": 2075,
    "status": true,
    "code": 2009501000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-10-22 15:52:49.516696",
    "updated_at": "2021-10-22 15:52:49.516706"
  },
  {
    "id": 1679,
    "status": true,
    "code": 2009509009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-23 18:17:59.782815",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2296,
    "status": true,
    "code": 2009611007,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-06 16:05:59.984592",
    "updated_at": "2023-01-06 16:05:59.984601"
  },
  {
    "id": 1579,
    "status": true,
    "code": 2009611009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-15 17:38:50.917309",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1470,
    "status": true,
    "code": 2009691900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:17:46.955900",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1248,
    "status": true,
    "code": 2009695101,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:49.725482",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1902,
    "status": true,
    "code": 2009712009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-14 17:11:29.010541",
    "updated_at": "2021-01-14 17:11:29.010550"
  },
  {
    "id": 1283,
    "status": true,
    "code": 2009719900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:42.846764",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1288,
    "status": true,
    "code": 2009791901,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:50.112317",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2290,
    "status": true,
    "code": 2009791902,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-06 11:03:47.504861",
    "updated_at": "2023-01-06 11:03:47.504877"
  },
  {
    "id": 1903,
    "status": true,
    "code": 2009819900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-14 17:11:29.020586",
    "updated_at": "2021-01-14 17:11:29.020595"
  },
  {
    "id": 2142,
    "status": true,
    "code": 2009893601,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-03-16 14:42:02.748561",
    "updated_at": "2022-03-16 14:42:02.748571"
  },
  {
    "id": 1900,
    "status": true,
    "code": 2009897109,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-14 17:11:28.978438",
    "updated_at": "2021-01-14 17:11:28.978447"
  },
  {
    "id": 1791,
    "status": true,
    "code": 2009897909,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-07 17:51:58.526306",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1789,
    "status": true,
    "code": 2009898800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-05 12:59:30.579118",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2068,
    "status": true,
    "code": 2009898900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-10-06 13:42:40.798763",
    "updated_at": "2021-10-06 13:42:40.798820"
  },
  {
    "id": 1765,
    "status": true,
    "code": 2009899600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-21 13:39:40.511163",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1901,
    "status": true,
    "code": 2009899700,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-14 17:11:29.000490",
    "updated_at": "2021-01-14 17:11:29.000499"
  },
  {
    "id": 1297,
    "status": true,
    "code": 2009899900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:42:27.034086",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2340,
    "status": true,
    "code": 2009905908,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-23 11:06:13.666982",
    "updated_at": "2023-01-23 11:06:13.666991"
  },
  {
    "id": 1355,
    "status": true,
    "code": 2009905909,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:50:19.831815",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1715,
    "status": true,
    "code": 2009909600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-17 17:24:59.978626",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1354,
    "status": true,
    "code": 2009909800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:50:19.807780",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1168,
    "status": true,
    "code": 2101110011,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1161,
    "status": true,
    "code": 2101110013,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2084,
    "status": true,
    "code": 2101110014,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-11-23 11:00:28.399600",
    "updated_at": "2021-11-23 11:00:28.399612"
  },
  {
    "id": 1159,
    "status": true,
    "code": 2101110015,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1162,
    "status": true,
    "code": 2101129201,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1176,
    "status": true,
    "code": 2101129801,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1933,
    "status": true,
    "code": 2101209200,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-23 21:57:07.262442",
    "updated_at": "2021-02-23 21:57:07.262457"
  },
  {
    "id": 2086,
    "status": true,
    "code": 2101309100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-11-23 12:18:07.217807",
    "updated_at": "2021-11-23 12:18:07.217816"
  },
  {
    "id": 780,
    "status": true,
    "code": 2102101000,
    "name": 779,
    "description": "Дрожжи (активные или неактивные); прочие мертвые одноклеточные микроорганизмы (кроме вакцин товарной позиции 3002); готовые пекарные порошки:->дрожжи активные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 781,
    "status": true,
    "code": 2102103100,
    "name": 780,
    "description": "Дрожжи (активные или неактивные); прочие мертвые одноклеточные микроорганизмы (кроме вакцин товарной позиции 3002); готовые пекарные порошки:->дрожжи активные:->дрожжи пекарные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 782,
    "status": true,
    "code": 2102103900,
    "name": 781,
    "description": "Дрожжи (активные или неактивные); прочие мертвые одноклеточные микроорганизмы (кроме вакцин товарной позиции 3002); готовые пекарные порошки:->дрожжи активные:->дрожжи пекарные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 783,
    "status": true,
    "code": 2102109000,
    "name": 782,
    "description": "Дрожжи (активные или неактивные); прочие мертвые одноклеточные микроорганизмы (кроме вакцин товарной позиции 3002); готовые пекарные порошки:->дрожжи активные:->дрожжи пекарные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 784,
    "status": true,
    "code": 2102201100,
    "name": 783,
    "description": "Дрожжи (активные или неактивные); прочие мертвые одноклеточные микроорганизмы (кроме вакцин товарной позиции 3002); готовые пекарные порошки:->дрожжи неактивные; прочие мертвые одноклеточные микроорганизмы:->дрожжи неактивные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 785,
    "status": true,
    "code": 2102201900,
    "name": 784,
    "description": "Дрожжи (активные или неактивные); прочие мертвые одноклеточные микроорганизмы (кроме вакцин товарной позиции 3002); готовые пекарные порошки:->дрожжи неактивные; прочие мертвые одноклеточные микроорганизмы:->дрожжи неактивные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1783,
    "status": true,
    "code": 2102300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-29 18:04:35.814891",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1171,
    "status": true,
    "code": 2103100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1662,
    "status": true,
    "code": 2103200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-14 16:09:23.066817",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1784,
    "status": true,
    "code": 2103301000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-29 18:04:35.825251",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1951,
    "status": true,
    "code": 2103309000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-27 15:32:02.245539",
    "updated_at": "2021-03-27 15:32:02.245552"
  },
  {
    "id": 2228,
    "status": true,
    "code": 2103901000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-05 16:23:32.239826",
    "updated_at": "2022-10-05 16:23:32.239840"
  },
  {
    "id": 1949,
    "status": true,
    "code": 2103909001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-26 16:57:28.866942",
    "updated_at": "2021-03-26 16:57:28.866950"
  },
  {
    "id": 1174,
    "status": true,
    "code": 2103909009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1336,
    "status": true,
    "code": 2104100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:46:21.431982",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1615,
    "status": true,
    "code": 2106102000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-24 10:18:42.733742",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1614,
    "status": true,
    "code": 2106108000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-24 10:18:42.706486",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1610,
    "status": true,
    "code": 2106905900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-24 10:18:42.671609",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1163,
    "status": true,
    "code": 2106909200,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1709,
    "status": true,
    "code": 2106909801,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-13 14:27:24.993723",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2175,
    "status": true,
    "code": 2106909803,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-05-17 10:16:51.922906",
    "updated_at": "2022-05-17 10:16:51.922919"
  },
  {
    "id": 2229,
    "status": true,
    "code": 2106909805,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-05 16:23:32.300091",
    "updated_at": "2022-10-05 16:23:32.300101"
  },
  {
    "id": 2318,
    "status": true,
    "code": 2106909808,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-11 12:06:05.482914",
    "updated_at": "2023-01-11 12:06:05.482927"
  },
  {
    "id": 1158,
    "status": true,
    "code": 2106909809,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2186,
    "status": true,
    "code": 2201101900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-01 15:07:25.946032",
    "updated_at": "2022-06-01 15:07:25.946041"
  },
  {
    "id": 1904,
    "status": true,
    "code": 2202100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-14 17:11:29.062471",
    "updated_at": "2021-01-14 17:11:29.062480"
  },
  {
    "id": 1478,
    "status": true,
    "code": 2202991900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-31 11:39:55.595745",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2154,
    "status": true,
    "code": 2202991901,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-07 12:22:35.420300",
    "updated_at": "2022-04-07 12:22:35.420309"
  },
  {
    "id": 2155,
    "status": true,
    "code": 2202991902,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-07 12:22:35.429575",
    "updated_at": "2022-04-07 12:22:35.429584"
  },
  {
    "id": 2156,
    "status": true,
    "code": 2202991903,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-07 12:22:35.438789",
    "updated_at": "2022-04-07 12:22:35.438798"
  },
  {
    "id": 2157,
    "status": true,
    "code": 2202991904,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-07 12:22:35.448196",
    "updated_at": "2022-04-07 12:22:35.448205"
  },
  {
    "id": 2158,
    "status": true,
    "code": 2202991905,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-07 12:22:35.457487",
    "updated_at": "2022-04-07 12:22:35.457496"
  },
  {
    "id": 2159,
    "status": true,
    "code": 2202991906,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-07 12:22:35.466531",
    "updated_at": "2022-04-07 12:22:35.466539"
  },
  {
    "id": 2160,
    "status": true,
    "code": 2202991907,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-07 12:22:35.475513",
    "updated_at": "2022-04-07 12:22:35.475522"
  },
  {
    "id": 2153,
    "status": true,
    "code": 2202991908,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-07 12:22:35.375018",
    "updated_at": "2022-04-07 12:22:35.375031"
  },
  {
    "id": 2161,
    "status": true,
    "code": 2202991911,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-07 12:22:35.498477",
    "updated_at": "2022-04-07 12:22:35.498486"
  },
  {
    "id": 1201,
    "status": true,
    "code": 2202999500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-24 17:19:39.710772",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1500,
    "status": true,
    "code": 2203001000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-04 13:18:38.414039",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1939,
    "status": true,
    "code": 2204219700,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-15 14:52:17.263755",
    "updated_at": "2021-03-15 14:52:17.263765"
  },
  {
    "id": 1941,
    "status": true,
    "code": 2204219800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-16 13:31:18.236778",
    "updated_at": "2021-03-16 13:31:18.236788"
  },
  {
    "id": 1348,
    "status": true,
    "code": 2204299501,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:49:20.144199",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1245,
    "status": true,
    "code": 2204299601,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:42.405399",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1796,
    "status": true,
    "code": 2204301000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-14 14:50:04.560916",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1578,
    "status": true,
    "code": 2204309800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-13 10:08:49.059289",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2171,
    "status": true,
    "code": 2206005909,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-26 13:43:22.007669",
    "updated_at": "2022-04-26 13:43:22.007682"
  },
  {
    "id": 1546,
    "status": true,
    "code": 2207100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-30 17:19:03.122673",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1804,
    "status": true,
    "code": 2208208900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-20 17:41:12.462691",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1480,
    "status": true,
    "code": 2209001100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-03 14:39:31.743885",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2115,
    "status": true,
    "code": 2209009100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-01-07 17:08:26.720148",
    "updated_at": "2022-01-07 17:08:26.720158"
  },
  {
    "id": 786,
    "status": true,
    "code": 2302101000,
    "name": 785,
    "description": "Отруби, высевки, месятки и прочие остатки от просеивания, помола или других способов переработки зерна злаков или бобовых культур, негранулированные или гранулированные:->кукурузные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 787,
    "status": true,
    "code": 2302109000,
    "name": 786,
    "description": "Отруби, высевки, месятки и прочие остатки от просеивания, помола или других способов переработки зерна злаков или бобовых культур, негранулированные или гранулированные:->кукурузные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 788,
    "status": true,
    "code": 2302301000,
    "name": 787,
    "description": "Отруби, высевки, месятки и прочие остатки от просеивания, помола или других способов переработки зерна злаков или бобовых культур, негранулированные или гранулированные:->пшеничные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 789,
    "status": true,
    "code": 2302309000,
    "name": 788,
    "description": "Отруби, высевки, месятки и прочие остатки от просеивания, помола или других способов переработки зерна злаков или бобовых культур, негранулированные или гранулированные:->пшеничные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 790,
    "status": true,
    "code": 2302400200,
    "name": 789,
    "description": "Отруби, высевки, месятки и прочие остатки от просеивания, помола или других способов переработки зерна злаков или бобовых культур, негранулированные или гранулированные:->прочих злаков:->рисовые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 791,
    "status": true,
    "code": 2302400800,
    "name": 790,
    "description": "Отруби, высевки, месятки и прочие остатки от просеивания, помола или других способов переработки зерна злаков или бобовых культур, негранулированные или гранулированные:->прочих злаков:->рисовые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 792,
    "status": true,
    "code": 2302401000,
    "name": 791,
    "description": "Отруби, высевки, месятки и прочие остатки от просеивания, помола или других способов переработки зерна злаков или бобовых культур, негранулированные или гранулированные:->прочих злаков:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 793,
    "status": true,
    "code": 2302409000,
    "name": 792,
    "description": "Отруби, высевки, месятки и прочие остатки от просеивания, помола или других способов переработки зерна злаков или бобовых культур, негранулированные или гранулированные:->прочих злаков:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 794,
    "status": true,
    "code": 2302500000,
    "name": 793,
    "description": "Отруби, высевки, месятки и прочие остатки от просеивания, помола или других способов переработки зерна злаков или бобовых культур, негранулированные или гранулированные:->прочих злаков:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 795,
    "status": true,
    "code": 2303101100,
    "name": 794,
    "description": "Остатки от производства крахмала и аналогичные остатки, свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара, барда и прочие отходы пивоварения или винокурения, негранулированные или гранулированные:->остатки от производства крахмала и аналогичные остатки:->остатки от производства крахмала из кукурузы (за исключением концентрированной замочной жидкости), с содержанием белка в пересчете на сухое вещество:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 796,
    "status": true,
    "code": 2303101900,
    "name": 795,
    "description": "Остатки от производства крахмала и аналогичные остатки, свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара, барда и прочие отходы пивоварения или винокурения, негранулированные или гранулированные:->остатки от производства крахмала и аналогичные остатки:->остатки от производства крахмала из кукурузы (за исключением концентрированной замочной жидкости), с содержанием белка в пересчете на сухое вещество:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 797,
    "status": true,
    "code": 2303109000,
    "name": 796,
    "description": "Остатки от производства крахмала и аналогичные остатки, свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара, барда и прочие отходы пивоварения или винокурения, негранулированные или гранулированные:->остатки от производства крахмала и аналогичные остатки:->остатки от производства крахмала из кукурузы (за исключением концентрированной замочной жидкости), с содержанием белка в пересчете на сухое вещество:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 798,
    "status": true,
    "code": 2303201000,
    "name": 797,
    "description": "Остатки от производства крахмала и аналогичные остатки, свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара, барда и прочие отходы пивоварения или винокурения, негранулированные или гранулированные:->свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 799,
    "status": true,
    "code": 2303209000,
    "name": 798,
    "description": "Остатки от производства крахмала и аналогичные остатки, свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара, барда и прочие отходы пивоварения или винокурения, негранулированные или гранулированные:->свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 800,
    "status": true,
    "code": 2303300000,
    "name": 799,
    "description": "Остатки от производства крахмала и аналогичные остатки, свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара, барда и прочие отходы пивоварения или винокурения, негранулированные или гранулированные:->свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 801,
    "status": true,
    "code": 2304000001,
    "name": 800,
    "description": "Остатки от производства крахмала и аналогичные остатки, свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара, барда и прочие отходы пивоварения или винокурения, негранулированные или гранулированные:->свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара:->Жмыхи и другие твердые остатки, получаемые при извлечении соевого масла, немолотые или молотые, негранулированные или гранулированные",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 16:47:28.497836"
  },
  {
    "id": 802,
    "status": true,
    "code": 2304000009,
    "name": 801,
    "description": "Остатки от производства крахмала и аналогичные остатки, свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара, барда и прочие отходы пивоварения или винокурения, негранулированные или гранулированные:->свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара:->Жмыхи и другие твердые остатки, получаемые при извлечении соевого масла, немолотые или молотые, негранулированные или гранулированные",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.252375"
  },
  {
    "id": 803,
    "status": true,
    "code": 2305000000,
    "name": 802,
    "description": "Остатки от производства крахмала и аналогичные остатки, свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара, барда и прочие отходы пивоварения или винокурения, негранулированные или гранулированные:->свекловичный жом, багасса, или жом сахарного тростника, и прочие отходы производства сахара:->Жмыхи и другие твердые остатки, получаемые при извлечении соевого масла, немолотые или молотые, негранулированные или гранулированные",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 804,
    "status": true,
    "code": 2306100000,
    "name": 803,
    "description": "Жмыхи и другие твердые остатки, получаемые при извлечении растительных жиров или масел, немолотые или молотые, негранулированные или гранулированные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.255817"
  },
  {
    "id": 805,
    "status": true,
    "code": 2306200000,
    "name": 804,
    "description": "Жмыхи и другие твердые остатки, получаемые при извлечении растительных жиров или масел, немолотые или молотые, негранулированные или гранулированные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 806,
    "status": true,
    "code": 2306300000,
    "name": 805,
    "description": "Жмыхи и другие твердые остатки, получаемые при извлечении растительных жиров или масел, немолотые или молотые, негранулированные или гранулированные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 807,
    "status": true,
    "code": 2306410000,
    "name": 806,
    "description": "Жмыхи и другие твердые остатки, получаемые при извлечении растительных жиров или масел, немолотые или молотые, негранулированные или гранулированные:->из семян рапса, или кользы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 808,
    "status": true,
    "code": 2306490000,
    "name": 807,
    "description": "Жмыхи и другие твердые остатки, получаемые при извлечении растительных жиров или масел, немолотые или молотые, негранулированные или гранулированные:->из семян рапса, или кользы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 809,
    "status": true,
    "code": 2306500000,
    "name": 808,
    "description": "Жмыхи и другие твердые остатки, получаемые при извлечении растительных жиров или масел, немолотые или молотые, негранулированные или гранулированные:->из семян рапса, или кользы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 810,
    "status": true,
    "code": 2306600000,
    "name": 809,
    "description": "Жмыхи и другие твердые остатки, получаемые при извлечении растительных жиров или масел, немолотые или молотые, негранулированные или гранулированные:->из семян рапса, или кользы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 811,
    "status": true,
    "code": 2306900500,
    "name": 810,
    "description": "Жмыхи и другие твердые остатки, получаемые при извлечении растительных жиров или масел, немолотые или молотые, негранулированные или гранулированные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 812,
    "status": true,
    "code": 2306901100,
    "name": 811,
    "description": "Жмыхи и другие твердые остатки, получаемые при извлечении растительных жиров или масел, немолотые или молотые, негранулированные или гранулированные:->прочие:->жмыхи и другие остатки, получаемые при извлечении оливкового масла:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 813,
    "status": true,
    "code": 2306901900,
    "name": 812,
    "description": "Жмыхи и другие твердые остатки, получаемые при извлечении растительных жиров или масел, немолотые или молотые, негранулированные или гранулированные:->прочие:->жмыхи и другие остатки, получаемые при извлечении оливкового масла:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 814,
    "status": true,
    "code": 2306909000,
    "name": 813,
    "description": "Жмыхи и другие твердые остатки, получаемые при извлечении растительных жиров или масел, немолотые или молотые, негранулированные или гранулированные:->прочие:->жмыхи и другие остатки, получаемые при извлечении оливкового масла:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2015,
    "status": true,
    "code": 2307009000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-17 14:51:36.645181",
    "updated_at": "2021-06-17 14:51:36.645192"
  },
  {
    "id": 1896,
    "status": true,
    "code": 2308004000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-08 16:52:11.167277",
    "updated_at": "2021-01-08 16:52:11.167289"
  },
  {
    "id": 1543,
    "status": true,
    "code": 2308009000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-29 14:58:52.820099",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2173,
    "status": true,
    "code": 2309109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-05-05 10:17:29.006388",
    "updated_at": "2022-05-05 10:17:29.006402"
  },
  {
    "id": 1150,
    "status": true,
    "code": 2309901000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2151,
    "status": true,
    "code": 2309902000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-01 10:53:50.022569",
    "updated_at": "2022-04-01 10:53:50.022583"
  },
  {
    "id": 2139,
    "status": true,
    "code": 2309903100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-03-10 18:04:09.553525",
    "updated_at": "2022-03-10 18:04:09.553534"
  },
  {
    "id": 2234,
    "status": true,
    "code": 2309903300,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-12 17:57:49.705435",
    "updated_at": "2022-10-12 17:57:49.705459"
  },
  {
    "id": 2078,
    "status": true,
    "code": 2309903500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-11-02 18:26:56.717421",
    "updated_at": "2021-11-02 18:26:56.717436"
  },
  {
    "id": 1995,
    "status": true,
    "code": 2309904100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-19 15:18:31.795053",
    "updated_at": "2021-05-19 15:18:31.795062"
  },
  {
    "id": 1994,
    "status": true,
    "code": 2309905100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-19 15:18:31.748873",
    "updated_at": "2021-05-19 15:18:31.748885"
  },
  {
    "id": 1721,
    "status": true,
    "code": 2309909601,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-18 16:02:55.534866",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1181,
    "status": true,
    "code": 2309909609,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 815,
    "status": true,
    "code": 2401103500,
    "name": 814,
    "description": "Табачное сырье; табачные отходы:->табак с неотделенной средней жилкой:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-01-11 13:04:05.636288"
  },
  {
    "id": 816,
    "status": true,
    "code": 2401106000,
    "name": 815,
    "description": "Табачное сырье; табачные отходы:->табак с неотделенной средней жилкой:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-01-11 13:04:14.278456"
  },
  {
    "id": 817,
    "status": true,
    "code": 2401107000,
    "name": 816,
    "description": "Табачное сырье; табачные отходы:->табак с неотделенной средней жилкой:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-01-11 13:04:21.473736"
  },
  {
    "id": 818,
    "status": true,
    "code": 2401108500,
    "name": 817,
    "description": "Табачное сырье; табачные отходы:->табак с неотделенной средней жилкой:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-01-11 13:04:28.986349"
  },
  {
    "id": 819,
    "status": true,
    "code": 2401109500,
    "name": 818,
    "description": "Табачное сырье; табачные отходы:->табак с неотделенной средней жилкой:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-01-11 13:04:37.583349"
  },
  {
    "id": 820,
    "status": true,
    "code": 2401203500,
    "name": 819,
    "description": "Табачное сырье; табачные отходы:->табак с частично или полностью отделенной средней жилкой:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-01-11 13:02:25.866318"
  },
  {
    "id": 821,
    "status": true,
    "code": 2401206000,
    "name": 820,
    "description": "Табачное сырье; табачные отходы:->табак с частично или полностью отделенной средней жилкой:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-01-11 13:02:39.810395"
  },
  {
    "id": 822,
    "status": true,
    "code": 2401207000,
    "name": 821,
    "description": "Табачное сырье; табачные отходы:->табак с частично или полностью отделенной средней жилкой:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-01-11 13:02:50.677401"
  },
  {
    "id": 823,
    "status": true,
    "code": 2401208500,
    "name": 822,
    "description": "Табачное сырье; табачные отходы:->табак с частично или полностью отделенной средней жилкой:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-01-11 13:03:01.639362"
  },
  {
    "id": 2300,
    "status": true,
    "code": 2401208501,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-07 14:45:23.195746",
    "updated_at": "2023-01-07 14:45:23.195763"
  },
  {
    "id": 824,
    "status": true,
    "code": 2401209500,
    "name": 823,
    "description": "Табачное сырье; табачные отходы:->табак с частично или полностью отделенной средней жилкой:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-01-11 13:03:11.680278"
  },
  {
    "id": 825,
    "status": true,
    "code": 2401300000,
    "name": 824,
    "description": "Табачное сырье; табачные отходы:->табак с частично или полностью отделенной средней жилкой:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-01-11 13:03:42.528841"
  },
  {
    "id": 2029,
    "status": true,
    "code": 2402100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-07-09 14:36:53.611299",
    "updated_at": "2021-07-09 14:36:53.611310"
  },
  {
    "id": 1325,
    "status": true,
    "code": 2402209000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:44:43.920155",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2197,
    "status": true,
    "code": 2403110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-21 15:53:55.427083",
    "updated_at": "2022-06-21 15:53:55.427095"
  },
  {
    "id": 1503,
    "status": true,
    "code": 2403199000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-05 17:18:58.805420",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1184,
    "status": true,
    "code": 2403910000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2363,
    "status": true,
    "code": 2403999008,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-11 08:52:23.857285",
    "updated_at": "2023-02-11 08:52:23.857298"
  },
  {
    "id": 1183,
    "status": true,
    "code": 2403999009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2035,
    "status": true,
    "code": 2501009110,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-16 10:13:14.135419",
    "updated_at": "2021-08-16 10:13:14.135429"
  },
  {
    "id": 1976,
    "status": true,
    "code": 2501009190,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-16 13:36:40.663814",
    "updated_at": "2021-04-16 13:36:40.663823"
  },
  {
    "id": 1589,
    "status": true,
    "code": 2507008000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-18 22:28:52.923275",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1694,
    "status": true,
    "code": 2508300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-29 21:18:21.088297",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1563,
    "status": true,
    "code": 2509000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-08 21:18:53.408166",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1702,
    "status": true,
    "code": 2512000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-04 10:15:17.739787",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1445,
    "status": true,
    "code": 2515110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:08:32.976589",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1767,
    "status": true,
    "code": 2517410000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-21 17:20:51.432445",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1986,
    "status": true,
    "code": 2522100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-04 12:24:41.608157",
    "updated_at": "2021-05-04 12:24:41.608167"
  },
  {
    "id": 2100,
    "status": true,
    "code": 2523290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-13 14:32:55.097094",
    "updated_at": "2021-12-13 14:32:55.097106"
  },
  {
    "id": 1953,
    "status": true,
    "code": 2530900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-27 18:19:28.872980",
    "updated_at": "2021-03-27 18:19:28.872995"
  },
  {
    "id": 2328,
    "status": true,
    "code": 2530900009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-16 15:19:10.260676",
    "updated_at": "2023-01-16 15:19:10.260689"
  },
  {
    "id": 1524,
    "status": true,
    "code": 2621900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-19 16:38:55.405343",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2252,
    "status": true,
    "code": 2702100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-12-06 18:10:16.107856",
    "updated_at": "2022-12-06 18:10:16.107868"
  },
  {
    "id": 1947,
    "status": true,
    "code": 2703000000,
    "name": "Торф (включая торфяную крошку), агломерированный или неагломерированный",
    "description": "Торф (включая торфяную крошку), агломерированный или неагломерированный",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-24 15:43:51.765950",
    "updated_at": "2022-04-11 12:32:45.567731"
  },
  {
    "id": 1983,
    "status": true,
    "code": 2710198400,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-29 16:59:06.325979",
    "updated_at": "2021-04-29 16:59:06.325990"
  },
  {
    "id": 1973,
    "status": true,
    "code": 2804509000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-14 15:36:35.376860",
    "updated_at": "2021-04-14 15:36:35.376871"
  },
  {
    "id": 1972,
    "status": true,
    "code": 2804900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-14 15:24:26.193254",
    "updated_at": "2021-04-14 15:24:26.193265"
  },
  {
    "id": 2101,
    "status": true,
    "code": 2832100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-14 11:09:33.517080",
    "updated_at": "2021-12-14 11:09:33.517092"
  },
  {
    "id": 2330,
    "status": true,
    "code": 2833298000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-17 17:28:03.495073",
    "updated_at": "2023-01-17 17:28:03.495103"
  },
  {
    "id": 1763,
    "status": true,
    "code": 2835260000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-21 11:24:22.652788",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1647,
    "status": true,
    "code": 2836200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-06 17:31:47.381486",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1646,
    "status": true,
    "code": 2836500000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-06 17:31:47.291417",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1401,
    "status": true,
    "code": 2841908500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:00:01.682895",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1609,
    "status": true,
    "code": 2905490000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-24 10:18:42.663698",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1852,
    "status": true,
    "code": 2912410000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-05 10:39:49.162580",
    "updated_at": "2020-12-05 10:39:49.162588"
  },
  {
    "id": 2255,
    "status": true,
    "code": 2916310000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-12-20 11:55:20.714608",
    "updated_at": "2022-12-20 11:55:20.714617"
  },
  {
    "id": 2008,
    "status": true,
    "code": 2918140000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-02 17:21:21.800716",
    "updated_at": "2021-06-02 17:21:21.800729"
  },
  {
    "id": 2016,
    "status": true,
    "code": 2922410000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-17 17:21:26.877051",
    "updated_at": "2021-06-17 17:21:26.877060"
  },
  {
    "id": 2362,
    "status": true,
    "code": 2922420000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-10 15:26:32.159324",
    "updated_at": "2023-02-10 15:26:32.159337"
  },
  {
    "id": 2063,
    "status": true,
    "code": 2922500000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-24 09:47:52.976938",
    "updated_at": "2021-09-24 09:47:52.976947"
  },
  {
    "id": 2017,
    "status": true,
    "code": 2930409000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-17 17:21:26.893763",
    "updated_at": "2021-06-17 17:21:26.893771"
  },
  {
    "id": 1981,
    "status": true,
    "code": 2936900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-23 17:36:55.553799",
    "updated_at": "2021-04-23 17:36:55.553809"
  },
  {
    "id": 2046,
    "status": true,
    "code": 2938909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-27 15:25:56.881895",
    "updated_at": "2021-08-27 15:25:56.881906"
  },
  {
    "id": 2299,
    "status": true,
    "code": 3002490001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-06 17:47:05.959491",
    "updated_at": "2023-02-14 15:43:26.473330"
  },
  {
    "id": 2355,
    "status": true,
    "code": 3002490009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-01 17:42:47.529583",
    "updated_at": "2023-02-10 15:06:13.321802"
  },
  {
    "id": 1484,
    "status": true,
    "code": 3002903000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-13 12:49:23.326535",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 826,
    "status": true,
    "code": 3002905000,
    "name": 825,
    "description": "Табачное сырье; табачные отходы:->табак с частично или полностью отделенной средней жилкой:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.264017"
  },
  {
    "id": 2301,
    "status": true,
    "code": 3002908000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-07 15:34:58.617489",
    "updated_at": "2023-01-07 15:34:58.617502"
  },
  {
    "id": 1197,
    "status": true,
    "code": 3002909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-09 09:49:50.711158",
    "updated_at": "2021-04-21 13:40:33.267626"
  },
  {
    "id": 2019,
    "status": true,
    "code": 3003200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-23 16:37:42.574526",
    "updated_at": "2021-06-23 16:37:42.574535"
  },
  {
    "id": 2006,
    "status": true,
    "code": 3004900002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-01 15:14:32.568191",
    "updated_at": "2021-06-01 15:14:32.568202"
  },
  {
    "id": 1542,
    "status": true,
    "code": 3005901000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-29 14:29:06.536940",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1337,
    "status": true,
    "code": 3005903100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:46:27.883565",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 827,
    "status": true,
    "code": 3101000000,
    "name": 826,
    "description": "Табачное сырье; табачные отходы:->табак с частично или полностью отделенной средней жилкой:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.271041"
  },
  {
    "id": 1844,
    "status": true,
    "code": 3102900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-25 16:21:17.677559",
    "updated_at": "2020-11-25 16:21:17.677569"
  },
  {
    "id": 2249,
    "status": true,
    "code": 3104300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-12-02 09:58:08.643368",
    "updated_at": "2022-12-02 09:58:08.643383"
  },
  {
    "id": 2213,
    "status": true,
    "code": 3104900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-08 16:46:13.835038",
    "updated_at": "2022-08-08 16:46:13.835051"
  },
  {
    "id": 1154,
    "status": true,
    "code": 3105100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1993,
    "status": true,
    "code": 3105201000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-18 17:42:34.757210",
    "updated_at": "2021-05-18 17:42:34.757218"
  },
  {
    "id": 1992,
    "status": true,
    "code": 3105209000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-18 17:42:34.748013",
    "updated_at": "2021-05-18 17:42:34.748023"
  },
  {
    "id": 2071,
    "status": true,
    "code": 3105510000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-10-09 12:36:47.217057",
    "updated_at": "2021-10-09 12:36:47.217069"
  },
  {
    "id": 1685,
    "status": true,
    "code": 3105590000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-27 11:34:17.973375",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1152,
    "status": true,
    "code": 3105902000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1845,
    "status": true,
    "code": 3105908000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-25 16:21:17.691993",
    "updated_at": "2020-11-25 16:21:17.692002"
  },
  {
    "id": 1172,
    "status": true,
    "code": 3203001000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2313,
    "status": true,
    "code": 3203001009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-09 17:57:44.043666",
    "updated_at": "2023-01-09 17:57:44.043681"
  },
  {
    "id": 2233,
    "status": true,
    "code": 3203009000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-11 16:49:35.895415",
    "updated_at": "2022-10-11 16:49:35.895430"
  },
  {
    "id": 1446,
    "status": true,
    "code": 3204160000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:08:39.258104",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2118,
    "status": true,
    "code": 3205000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-01-18 16:37:18.004812",
    "updated_at": "2022-01-18 16:37:18.004822"
  },
  {
    "id": 1638,
    "status": true,
    "code": 3208109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-03 08:38:40.422319",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1934,
    "status": true,
    "code": 3209100009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-26 17:37:30.144719",
    "updated_at": "2021-02-26 17:37:30.144729"
  },
  {
    "id": 2012,
    "status": true,
    "code": 3209900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-14 18:25:16.045710",
    "updated_at": "2021-06-14 18:25:16.045719"
  },
  {
    "id": 1581,
    "status": true,
    "code": 3210001000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-15 19:48:41.333568",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1422,
    "status": true,
    "code": 3214109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:04:10.469536",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1574,
    "status": true,
    "code": 3214900009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-11 19:58:49.314601",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1485,
    "status": true,
    "code": 3302109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-14 15:19:22.696151",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2351,
    "status": true,
    "code": 3303009000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-29 19:41:32.049779",
    "updated_at": "2023-01-29 19:41:32.049789"
  },
  {
    "id": 1377,
    "status": true,
    "code": 3304990000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:53:49.213330",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1604,
    "status": true,
    "code": 3305100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-23 22:08:48.465940",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1708,
    "status": true,
    "code": 3305900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-13 10:17:26.229149",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1707,
    "status": true,
    "code": 3307200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-13 10:17:26.128399",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1442,
    "status": true,
    "code": 3307410000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:08:12.116089",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1356,
    "status": true,
    "code": 3401110001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:50:19.858479",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1479,
    "status": true,
    "code": 3401190000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-31 18:20:07.146276",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1809,
    "status": true,
    "code": 3402190000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-22 18:27:25.031480",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1637,
    "status": true,
    "code": 3402209000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-03 08:38:40.416182",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1641,
    "status": true,
    "code": 3404900009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-03 11:48:32.687699",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1635,
    "status": true,
    "code": 3405100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-03 08:38:40.354533",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2074,
    "status": true,
    "code": 3503001001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-10-22 12:20:47.203820",
    "updated_at": "2021-10-22 12:20:47.203833"
  },
  {
    "id": 2002,
    "status": true,
    "code": 3507909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-27 15:18:13.647072",
    "updated_at": "2021-05-27 15:18:13.647082"
  },
  {
    "id": 2244,
    "status": true,
    "code": 3605000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-11-16 13:14:26.806554",
    "updated_at": "2022-11-16 13:14:26.806569"
  },
  {
    "id": 1491,
    "status": true,
    "code": 3808919000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-29 08:29:21.162018",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1851,
    "status": true,
    "code": 3808921000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-02 11:15:35.801815",
    "updated_at": "2020-12-02 11:15:35.801824"
  },
  {
    "id": 2148,
    "status": true,
    "code": 3808922000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-03-30 11:45:05.454253",
    "updated_at": "2022-03-30 11:45:05.454262"
  },
  {
    "id": 2111,
    "status": true,
    "code": 3808949000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-21 18:01:26.458036",
    "updated_at": "2021-12-21 18:01:26.458047"
  },
  {
    "id": 1640,
    "status": true,
    "code": 3808999000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-03 11:48:32.681664",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1975,
    "status": true,
    "code": 3812100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-16 10:52:53.380632",
    "updated_at": "2021-04-16 10:52:53.380641"
  },
  {
    "id": 1704,
    "status": true,
    "code": 3814009000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-07 14:56:34.553876",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2141,
    "status": true,
    "code": 3821000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-03-14 17:22:25.763527",
    "updated_at": "2022-03-14 17:22:25.763539"
  },
  {
    "id": 2082,
    "status": true,
    "code": 3822000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-11-21 22:06:43.347449",
    "updated_at": "2021-11-21 22:06:43.347465"
  },
  {
    "id": 2323,
    "status": true,
    "code": 3822900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-12 16:45:24.087378",
    "updated_at": "2023-01-12 16:45:24.087387"
  },
  {
    "id": 1839,
    "status": true,
    "code": 3823199000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-23 14:06:35.442423",
    "updated_at": "2020-11-23 14:06:35.442434"
  },
  {
    "id": 1898,
    "status": true,
    "code": 3824400000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-14 12:18:34.860114",
    "updated_at": "2021-01-14 12:18:34.860123"
  },
  {
    "id": 1681,
    "status": true,
    "code": 3824994500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-24 16:55:16.113587",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1153,
    "status": true,
    "code": 3824999608,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1818,
    "status": true,
    "code": 3901109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-01 16:31:31.475939",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1586,
    "status": true,
    "code": 3907400009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-15 19:48:41.374507",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2253,
    "status": true,
    "code": 3912398500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-12-08 15:19:17.936263",
    "updated_at": "2022-12-08 15:19:17.936275"
  },
  {
    "id": 1639,
    "status": true,
    "code": 3912909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-03 11:08:32.601289",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1561,
    "status": true,
    "code": 3915908000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-08 11:49:00.290199",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1606,
    "status": true,
    "code": 3917229000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-23 22:08:48.494651",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1492,
    "status": true,
    "code": 3917310000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-29 08:29:21.168532",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1959,
    "status": true,
    "code": 3917320001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-31 09:41:03.058192",
    "updated_at": "2021-03-31 09:41:03.058201"
  },
  {
    "id": 1540,
    "status": true,
    "code": 3917320009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-28 22:19:04.244310",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1529,
    "status": true,
    "code": 3917390009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-20 19:49:11.095768",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1958,
    "status": true,
    "code": 3917400000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-31 09:41:03.031643",
    "updated_at": "2021-03-31 09:41:03.031654"
  },
  {
    "id": 1636,
    "status": true,
    "code": 3919108000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-03 08:38:40.364416",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1802,
    "status": true,
    "code": 3920102400,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-20 11:23:05.996663",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1835,
    "status": true,
    "code": 3920102500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-16 17:39:26.035500",
    "updated_at": "2020-11-16 17:39:26.035509"
  },
  {
    "id": 1580,
    "status": true,
    "code": 3920108900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-15 19:48:41.306163",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1879,
    "status": true,
    "code": 3920431000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-16 10:13:15.723996",
    "updated_at": "2020-12-16 10:13:15.724005"
  },
  {
    "id": 1620,
    "status": true,
    "code": 3921131000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-27 12:18:48.066118",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2120,
    "status": true,
    "code": 3921906000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-01-19 14:33:44.997045",
    "updated_at": "2022-01-19 14:33:44.997077"
  },
  {
    "id": 1425,
    "status": true,
    "code": 3922200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:04:53.889700",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1280,
    "status": true,
    "code": 3922900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:38.037725",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1883,
    "status": true,
    "code": 3923100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-16 17:53:19.452396",
    "updated_at": "2020-12-16 17:53:19.452405"
  },
  {
    "id": 1493,
    "status": true,
    "code": 3923210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-29 08:29:21.179112",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1748,
    "status": true,
    "code": 3923299000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-09 16:55:21.680633",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1728,
    "status": true,
    "code": 3923301010,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-25 16:17:37.672485",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1440,
    "status": true,
    "code": 3923301090,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:07:45.366983",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2023,
    "status": true,
    "code": 3923309090,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-29 15:01:01.576898",
    "updated_at": "2021-06-29 15:01:01.576914"
  },
  {
    "id": 1692,
    "status": true,
    "code": 3923509000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-28 13:35:22.420524",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1687,
    "status": true,
    "code": 3923900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-27 21:11:15.506948",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1688,
    "status": true,
    "code": 3924100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-27 21:11:15.563499",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1421,
    "status": true,
    "code": 3924900009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:03:50.912730",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2235,
    "status": true,
    "code": 3925200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-18 15:49:47.272816",
    "updated_at": "2022-10-18 15:49:47.272831"
  },
  {
    "id": 2236,
    "status": true,
    "code": 3925300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-18 15:49:47.303685",
    "updated_at": "2022-10-18 15:49:47.303697"
  },
  {
    "id": 1618,
    "status": true,
    "code": 3925908000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-26 07:48:46.090395",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1652,
    "status": true,
    "code": 3926200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-10 10:25:03.174161",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1433,
    "status": true,
    "code": 3926300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:06:45.864457",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1887,
    "status": true,
    "code": 3926400000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-16 21:04:01.146437",
    "updated_at": "2020-12-16 21:04:01.146446"
  },
  {
    "id": 1292,
    "status": true,
    "code": 3926909709,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:54.063185",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1530,
    "status": true,
    "code": 4008219000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-20 19:49:11.121484",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1735,
    "status": true,
    "code": 4011500000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-29 12:05:44.219108",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1576,
    "status": true,
    "code": 4015190000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-12 16:28:53.998668",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1444,
    "status": true,
    "code": 4016100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:08:24.220244",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1441,
    "status": true,
    "code": 4016930008,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:07:45.372610",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 828,
    "status": true,
    "code": 4101201000,
    "name": 827,
    "description": "Необработанные шкуры крупного рогатого скота (включая буйволов) или животных семейства лошадиных (парные или соленые, сушеные, золеные, пикелеванные или консервированные другим способом, но не дубленые, не выделанные под пергамент или не подвергнутые дальнейшей обработке), с волосяным покровом или без волосяного покрова, двоеные или недвоеные:->целые шкуры, недвоеные, каждая массой не более 8 кг в сухом состоянии, 10 кг в сухосоленом или 16 кг в парном, мокросоленом или ином консервированном виде:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 829,
    "status": true,
    "code": 4101203000,
    "name": 828,
    "description": "Необработанные шкуры крупного рогатого скота (включая буйволов) или животных семейства лошадиных (парные или соленые, сушеные, золеные, пикелеванные или консервированные другим способом, но не дубленые, не выделанные под пергамент или не подвергнутые дальнейшей обработке), с волосяным покровом или без волосяного покрова, двоеные или недвоеные:->целые шкуры, недвоеные, каждая массой не более 8 кг в сухом состоянии, 10 кг в сухосоленом или 16 кг в парном, мокросоленом или ином консервированном виде:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 830,
    "status": true,
    "code": 4101205000,
    "name": 829,
    "description": "Необработанные шкуры крупного рогатого скота (включая буйволов) или животных семейства лошадиных (парные или соленые, сушеные, золеные, пикелеванные или консервированные другим способом, но не дубленые, не выделанные под пергамент или не подвергнутые дальнейшей обработке), с волосяным покровом или без волосяного покрова, двоеные или недвоеные:->целые шкуры, недвоеные, каждая массой не более 8 кг в сухом состоянии, 10 кг в сухосоленом или 16 кг в парном, мокросоленом или ином консервированном виде:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 831,
    "status": true,
    "code": 4101208000,
    "name": 830,
    "description": "Необработанные шкуры крупного рогатого скота (включая буйволов) или животных семейства лошадиных (парные или соленые, сушеные, золеные, пикелеванные или консервированные другим способом, но не дубленые, не выделанные под пергамент или не подвергнутые дальнейшей обработке), с волосяным покровом или без волосяного покрова, двоеные или недвоеные:->целые шкуры, недвоеные, каждая массой не более 8 кг в сухом состоянии, 10 кг в сухосоленом или 16 кг в парном, мокросоленом или ином консервированном виде:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 832,
    "status": true,
    "code": 4101501000,
    "name": 831,
    "description": "Необработанные шкуры крупного рогатого скота (включая буйволов) или животных семейства лошадиных (парные или соленые, сушеные, золеные, пикелеванные или консервированные другим способом, но не дубленые, не выделанные под пергамент или не подвергнутые дальнейшей обработке), с волосяным покровом или без волосяного покрова, двоеные или недвоеные:->целые шкуры массой более 16 кг:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 833,
    "status": true,
    "code": 4101503000,
    "name": 832,
    "description": "Необработанные шкуры крупного рогатого скота (включая буйволов) или животных семейства лошадиных (парные или соленые, сушеные, золеные, пикелеванные или консервированные другим способом, но не дубленые, не выделанные под пергамент или не подвергнутые дальнейшей обработке), с волосяным покровом или без волосяного покрова, двоеные или недвоеные:->целые шкуры массой более 16 кг:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 834,
    "status": true,
    "code": 4101505000,
    "name": 833,
    "description": "Необработанные шкуры крупного рогатого скота (включая буйволов) или животных семейства лошадиных (парные или соленые, сушеные, золеные, пикелеванные или консервированные другим способом, но не дубленые, не выделанные под пергамент или не подвергнутые дальнейшей обработке), с волосяным покровом или без волосяного покрова, двоеные или недвоеные:->целые шкуры массой более 16 кг:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 835,
    "status": true,
    "code": 4101509000,
    "name": 834,
    "description": "Необработанные шкуры крупного рогатого скота (включая буйволов) или животных семейства лошадиных (парные или соленые, сушеные, золеные, пикелеванные или консервированные другим способом, но не дубленые, не выделанные под пергамент или не подвергнутые дальнейшей обработке), с волосяным покровом или без волосяного покрова, двоеные или недвоеные:->целые шкуры массой более 16 кг:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 836,
    "status": true,
    "code": 4101900000,
    "name": 835,
    "description": "Необработанные шкуры крупного рогатого скота (включая буйволов) или животных семейства лошадиных (парные или соленые, сушеные, золеные, пикелеванные или консервированные другим способом, но не дубленые, не выделанные под пергамент или не подвергнутые дальнейшей обработке), с волосяным покровом или без волосяного покрова, двоеные или недвоеные:->целые шкуры массой более 16 кг:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2005,
    "status": true,
    "code": 4102101000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-01 15:12:12.210811",
    "updated_at": "2021-06-01 15:12:12.210824"
  },
  {
    "id": 1980,
    "status": true,
    "code": 4102109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-22 17:49:04.776813",
    "updated_at": "2021-04-22 17:49:04.776825"
  },
  {
    "id": 837,
    "status": true,
    "code": 4103200000,
    "name": 836,
    "description": "Прочие необработанные шкуры (парные или соленые, сушеные, золеные, пикелеванные или консервированные другим способом, но не дубленые, не выделанные под пергамент или не подвергнутые дальнейшей обработке), с волосяным покровом или без волосяного покрова, двоеные или недвоеные, кроме исключенных примечанием 1 (б) или 1 (в) к данной группе:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 838,
    "status": true,
    "code": 4103300000,
    "name": 837,
    "description": "Прочие необработанные шкуры (парные или соленые, сушеные, золеные, пикелеванные или консервированные другим способом, но не дубленые, не выделанные под пергамент или не подвергнутые дальнейшей обработке), с волосяным покровом или без волосяного покрова, двоеные или недвоеные, кроме исключенных примечанием 1 (б) или 1 (в) к данной группе:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 839,
    "status": true,
    "code": 4103900000,
    "name": 838,
    "description": "Прочие необработанные шкуры (парные или соленые, сушеные, золеные, пикелеванные или консервированные другим способом, но не дубленые, не выделанные под пергамент или не подвергнутые дальнейшей обработке), с волосяным покровом или без волосяного покрова, двоеные или недвоеные, кроме исключенных примечанием 1 (б) или 1 (в) к данной группе:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1232,
    "status": true,
    "code": 4104111000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:49.661084",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1241,
    "status": true,
    "code": 4104115100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:22.263087",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2176,
    "status": true,
    "code": 4104115101,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-05-19 17:11:57.092825",
    "updated_at": "2022-05-19 17:11:57.092839"
  },
  {
    "id": 2177,
    "status": true,
    "code": 4104115102,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-05-19 17:13:12.526563",
    "updated_at": "2022-05-19 17:13:12.526576"
  },
  {
    "id": 2178,
    "status": true,
    "code": 4104115103,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-05-19 17:14:29.149156",
    "updated_at": "2022-05-19 17:14:29.149166"
  },
  {
    "id": 1821,
    "status": true,
    "code": 4104191000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-05 15:48:07.735310",
    "updated_at": "2020-11-05 15:48:07.735321"
  },
  {
    "id": 2199,
    "status": true,
    "code": 4104195100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-28 13:05:04.613245",
    "updated_at": "2022-06-28 13:05:04.613264"
  },
  {
    "id": 1252,
    "status": true,
    "code": 4105100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:57.909007",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1341,
    "status": true,
    "code": 4106210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:47:57.855849",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1468,
    "status": true,
    "code": 4106310000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:17:01.991397",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2179,
    "status": true,
    "code": 4107111100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-05-23 11:36:27.511550",
    "updated_at": "2022-05-23 11:36:27.511565"
  },
  {
    "id": 2163,
    "status": true,
    "code": 4107121900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-11 11:24:33.328320",
    "updated_at": "2022-04-11 11:24:33.328330"
  },
  {
    "id": 2145,
    "status": true,
    "code": 4107129100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-03-24 15:13:52.420730",
    "updated_at": "2022-03-24 15:13:52.420760"
  },
  {
    "id": 1326,
    "status": true,
    "code": 4107921000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:45:02.544339",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1940,
    "status": true,
    "code": 4112000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-16 12:11:00.913988",
    "updated_at": "2021-03-16 12:11:00.913998"
  },
  {
    "id": 1436,
    "status": true,
    "code": 4201000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:06:45.913923",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1167,
    "status": true,
    "code": 4202121100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1843,
    "status": true,
    "code": 4202229000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-25 13:52:47.120664",
    "updated_at": "2020-11-25 13:52:47.120673"
  },
  {
    "id": 1700,
    "status": true,
    "code": 4202911000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-30 15:34:07.843782",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1717,
    "status": true,
    "code": 4202929100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-17 20:23:13.100769",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1239,
    "status": true,
    "code": 4202929800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:15.478461",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1175,
    "status": true,
    "code": 4202990000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1699,
    "status": true,
    "code": 4203291000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-30 14:30:44.198590",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1420,
    "status": true,
    "code": 4203300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:03:50.906523",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1603,
    "status": true,
    "code": 4205001900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-23 22:08:48.460399",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2203,
    "status": true,
    "code": 4304000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-07-08 19:19:04.305025",
    "updated_at": "2022-07-08 19:19:04.305042"
  },
  {
    "id": 840,
    "status": true,
    "code": 4401110001,
    "name": 839,
    "description": "Древесина топливная в виде бревен, поленьев, ветвей, вязанок хвороста или в аналогичных видах; древесина в виде щепок или стружки; опилки и древесные отходы и скрап, неагломерированные или агломерированные в виде бревен, брикетов, гранул или в аналогичных видах:->древесина топливная в виде бревен, поленьев, ветвей, вязанок хвороста или в аналогичных видах:->хвойных пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 841,
    "status": true,
    "code": 4401110009,
    "name": 840,
    "description": "Древесина топливная в виде бревен, поленьев, ветвей, вязанок хвороста или в аналогичных видах; древесина в виде щепок или стружки; опилки и древесные отходы и скрап, неагломерированные или агломерированные в виде бревен, брикетов, гранул или в аналогичных видах:->древесина топливная в виде бревен, поленьев, ветвей, вязанок хвороста или в аналогичных видах:->хвойных пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 842,
    "status": true,
    "code": 4401120001,
    "name": 841,
    "description": "Древесина топливная в виде бревен, поленьев, ветвей, вязанок хвороста или в аналогичных видах; древесина в виде щепок или стружки; опилки и древесные отходы и скрап, неагломерированные или агломерированные в виде бревен, брикетов, гранул или в аналогичных видах:->древесина топливная в виде бревен, поленьев, ветвей, вязанок хвороста или в аналогичных видах:->лиственных пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 843,
    "status": true,
    "code": 4401120009,
    "name": 842,
    "description": "Древесина топливная в виде бревен, поленьев, ветвей, вязанок хвороста или в аналогичных видах; древесина в виде щепок или стружки; опилки и древесные отходы и скрап, неагломерированные или агломерированные в виде бревен, брикетов, гранул или в аналогичных видах:->древесина топливная в виде бревен, поленьев, ветвей, вязанок хвороста или в аналогичных видах:->лиственных пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 844,
    "status": true,
    "code": 4401210000,
    "name": 843,
    "description": "Древесина топливная в виде бревен, поленьев, ветвей, вязанок хвороста или в аналогичных видах; древесина в виде щепок или стружки; опилки и древесные отходы и скрап, неагломерированные или агломерированные в виде бревен, брикетов, гранул или в аналогичных видах:->древесина в виде щепок или стружки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 845,
    "status": true,
    "code": 4401220000,
    "name": 844,
    "description": "Древесина топливная в виде бревен, поленьев, ветвей, вязанок хвороста или в аналогичных видах; древесина в виде щепок или стружки; опилки и древесные отходы и скрап, неагломерированные или агломерированные в виде бревен, брикетов, гранул или в аналогичных видах:->древесина в виде щепок или стружки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 846,
    "status": true,
    "code": 4401310000,
    "name": 845,
    "description": "Древесина топливная в виде бревен, поленьев, ветвей, вязанок хвороста или в аналогичных видах; древесина в виде щепок или стружки; опилки и древесные отходы и скрап, неагломерированные или агломерированные в виде бревен, брикетов, гранул или в аналогичных видах:->опилки, древесные отходы и скрап, агломерированные в виде бревен, брикетов, гранул или в аналогичных видах:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 847,
    "status": true,
    "code": 4401390000,
    "name": 846,
    "description": "Древесина топливная в виде бревен, поленьев, ветвей, вязанок хвороста или в аналогичных видах; древесина в виде щепок или стружки; опилки и древесные отходы и скрап, неагломерированные или агломерированные в виде бревен, брикетов, гранул или в аналогичных видах:->опилки, древесные отходы и скрап, агломерированные в виде бревен, брикетов, гранул или в аналогичных видах:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1628,
    "status": true,
    "code": 4401401000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-30 17:48:34.578366",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2055,
    "status": true,
    "code": 4401409000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-14 16:46:40.776456",
    "updated_at": "2021-09-14 16:46:40.776465"
  },
  {
    "id": 2124,
    "status": true,
    "code": 4402900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-01-31 17:06:26.502498",
    "updated_at": "2022-01-31 17:06:26.502511"
  },
  {
    "id": 1123,
    "status": true,
    "code": 4403110001,
    "name": 1122,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1124,
    "status": true,
    "code": 4403110009,
    "name": 1123,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 848,
    "status": true,
    "code": 4403120001,
    "name": 847,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->обработанные краской, травителями, креозотом или другими консервантами:->лиственных пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 849,
    "status": true,
    "code": 4403120002,
    "name": 848,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->обработанные краской, травителями, креозотом или другими консервантами:->лиственных пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 850,
    "status": true,
    "code": 4403120003,
    "name": 849,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->обработанные краской, травителями, креозотом или другими консервантами:->лиственных пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 851,
    "status": true,
    "code": 4403120009,
    "name": 850,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->обработанные краской, травителями, креозотом или другими консервантами:->лиственных пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 852,
    "status": true,
    "code": 4403211100,
    "name": 851,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из сосны (Pinus spp.), с размером наибольшего поперечного сечения 15 см или более:->сосна обыкновенная вида \"Pinus sylvestris L.\":",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2287,
    "status": true,
    "code": 4403211200,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 17:02:55.114392",
    "updated_at": "2023-01-05 17:02:55.114402"
  },
  {
    "id": 2305,
    "status": true,
    "code": 4403211800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-07 15:47:29.773076",
    "updated_at": "2023-01-07 15:47:29.773085"
  },
  {
    "id": 853,
    "status": true,
    "code": 4403211900,
    "name": 852,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из сосны (Pinus spp.), с размером наибольшего поперечного сечения 15 см или более:->сосна обыкновенная вида \"Pinus sylvestris L.\":",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 854,
    "status": true,
    "code": 4403219100,
    "name": 853,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из сосны (Pinus spp.), с размером наибольшего поперечного сечения 15 см или более:->сосна прочая:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2373,
    "status": true,
    "code": 4403219200,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-17 12:39:41.805644",
    "updated_at": "2023-02-17 12:39:41.805660"
  },
  {
    "id": 855,
    "status": true,
    "code": 4403219900,
    "name": 854,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из сосны (Pinus spp.), с размером наибольшего поперечного сечения 15 см или более:->сосна прочая:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 856,
    "status": true,
    "code": 4403221000,
    "name": 855,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из сосны (Pinus spp.), прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2304,
    "status": true,
    "code": 4403222000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-07 15:47:29.766799",
    "updated_at": "2023-01-07 15:47:29.766807"
  },
  {
    "id": 2358,
    "status": true,
    "code": 4403228000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-02 17:13:06.259651",
    "updated_at": "2023-02-02 17:13:06.259660"
  },
  {
    "id": 857,
    "status": true,
    "code": 4403229000,
    "name": 856,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из сосны (Pinus spp.), прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 858,
    "status": true,
    "code": 4403231100,
    "name": 857,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из пихты (Abiesspp.) и ели (Piceaspp.), с размером наибольшего поперечного сечения 15 см или более:->ель обыкновенная вида \"PiceaabiesKarst.\" или пихта белая европейская вида \"AbiesalbaMill.\":",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2374,
    "status": true,
    "code": 4403231200,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-17 12:39:41.883873",
    "updated_at": "2023-02-17 12:39:41.883884"
  },
  {
    "id": 2356,
    "status": true,
    "code": 4403231800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-02 17:13:06.226983",
    "updated_at": "2023-02-02 17:13:06.226993"
  },
  {
    "id": 859,
    "status": true,
    "code": 4403231900,
    "name": 858,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из пихты (Abiesspp.) и ели (Piceaspp.), с размером наибольшего поперечного сечения 15 см или более:->ель обыкновенная вида \"PiceaabiesKarst.\" или пихта белая европейская вида \"AbiesalbaMill.\":",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 860,
    "status": true,
    "code": 4403239100,
    "name": 859,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из пихты (Abiesspp.) и ели (Piceaspp.), с размером наибольшего поперечного сечения 15 см или более:->ель прочая или пихта прочая:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2357,
    "status": true,
    "code": 4403239800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-02 17:13:06.253098",
    "updated_at": "2023-02-02 17:13:06.253107"
  },
  {
    "id": 861,
    "status": true,
    "code": 4403239900,
    "name": 860,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из пихты (Abiesspp.) и ели (Piceaspp.), с размером наибольшего поперечного сечения 15 см или более:->ель прочая или пихта прочая:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 862,
    "status": true,
    "code": 4403241000,
    "name": 861,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из пихты (Abies spp.) и ели (Picea spp.), прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2307,
    "status": true,
    "code": 4403248000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-07 15:47:29.799923",
    "updated_at": "2023-01-07 15:47:29.799932"
  },
  {
    "id": 863,
    "status": true,
    "code": 4403249000,
    "name": 862,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из пихты (Abies spp.) и ели (Picea spp.), прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2306,
    "status": true,
    "code": 4403251000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-07 15:47:29.783461",
    "updated_at": "2023-01-07 15:47:29.783471"
  },
  {
    "id": 2333,
    "status": true,
    "code": 4403259000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-18 17:50:47.255250",
    "updated_at": "2023-01-18 17:50:47.255261"
  },
  {
    "id": 864,
    "status": true,
    "code": 4403259100,
    "name": 863,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->прочие, с размером наибольшего поперечного сечения 15 см или более:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 865,
    "status": true,
    "code": 4403259900,
    "name": 864,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->прочие, с размером наибольшего поперечного сечения 15 см или более:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1564,
    "status": true,
    "code": 4403260000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-09 10:18:41.792303",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 866,
    "status": true,
    "code": 4403410000,
    "name": 865,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->прочие, с размером наибольшего поперечного сечения 15 см или более:->прочие из древесины тропических пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 867,
    "status": true,
    "code": 4403491000,
    "name": 866,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 868,
    "status": true,
    "code": 4403493500,
    "name": 867,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 869,
    "status": true,
    "code": 4403499500,
    "name": 868,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 870,
    "status": true,
    "code": 4403911000,
    "name": 869,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из дуба (Quercus spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 871,
    "status": true,
    "code": 4403919000,
    "name": 870,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из дуба (Quercus spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 872,
    "status": true,
    "code": 4403931000,
    "name": 871,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из бука (Fagus spp.), с размером наибольшего поперечного сечения 15 см или более:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 873,
    "status": true,
    "code": 4403939000,
    "name": 872,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из бука (Fagus spp.), с размером наибольшего поперечного сечения 15 см или более:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 874,
    "status": true,
    "code": 4403940000,
    "name": 873,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из бука (Fagus spp.), с размером наибольшего поперечного сечения 15 см или более:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2303,
    "status": true,
    "code": 4403950000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-07 15:47:29.760569",
    "updated_at": "2023-01-07 15:47:29.760578"
  },
  {
    "id": 875,
    "status": true,
    "code": 4403950001,
    "name": 874,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из бука (Fagus spp.), с размером наибольшего поперечного сечения 15 см или более:->из березы (Betula spp.), с размером наибольшего поперечного сечения 15 см или более:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 876,
    "status": true,
    "code": 4403950002,
    "name": 875,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из бука (Fagus spp.), с размером наибольшего поперечного сечения 15 см или более:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 877,
    "status": true,
    "code": 4403950009,
    "name": 876,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из бука (Fagus spp.), с размером наибольшего поперечного сечения 15 см или более:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 878,
    "status": true,
    "code": 4403960001,
    "name": 877,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из бука (Fagus spp.), с размером наибольшего поперечного сечения 15 см или более:->прочие:->из березы (Betula spp.), прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2302,
    "status": true,
    "code": 4403960002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-07 15:47:29.749508",
    "updated_at": "2023-01-07 15:47:29.749517"
  },
  {
    "id": 2331,
    "status": true,
    "code": 4403960008,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-17 17:41:05.350492",
    "updated_at": "2023-01-17 17:41:05.350502"
  },
  {
    "id": 879,
    "status": true,
    "code": 4403960009,
    "name": 878,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из бука (Fagus spp.), с размером наибольшего поперечного сечения 15 см или более:->прочие:->из березы (Betula spp.), прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 880,
    "status": true,
    "code": 4403970001,
    "name": 879,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из бука (Fagus spp.), с размером наибольшего поперечного сечения 15 см или более:->прочие:->из тополя и осины (Populus spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 881,
    "status": true,
    "code": 4403970002,
    "name": 880,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из бука (Fagus spp.), с размером наибольшего поперечного сечения 15 см или более:->прочие:->из тополя и осины (Populus spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 882,
    "status": true,
    "code": 4403980000,
    "name": 881,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из бука (Fagus spp.), с размером наибольшего поперечного сечения 15 см или более:->прочие:->из тополя и осины (Populus spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 883,
    "status": true,
    "code": 4403990001,
    "name": 882,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из бука (Fagus spp.), с размером наибольшего поперечного сечения 15 см или более:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 884,
    "status": true,
    "code": 4403990009,
    "name": 883,
    "description": "Лесоматериалы необработанные, с удаленной или неудаленной корой или заболонью или грубо окантованные или неокантованные:->из бука (Fagus spp.), с размером наибольшего поперечного сечения 15 см или более:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 885,
    "status": true,
    "code": 4404100000,
    "name": 884,
    "description": "Древесина бондарная; бревна расколотые; сваи, колья и столбы из дерева, заостренные, но не распиленные вдоль; лесоматериалы, грубо обтесанные, но не обточенные, не изогнутые или не обработанные другим способом, используемые для производства тростей, зонтов, ручек для инструментов или аналогичных изделий; щепа и аналогичная древесина:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2359,
    "status": true,
    "code": 4404100001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-02 22:49:46.199913",
    "updated_at": "2023-02-02 22:49:46.199926"
  },
  {
    "id": 2371,
    "status": true,
    "code": 4404100009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-16 17:03:18.346051",
    "updated_at": "2023-02-16 17:03:18.346065"
  },
  {
    "id": 886,
    "status": true,
    "code": 4404200000,
    "name": 885,
    "description": "Древесина бондарная; бревна расколотые; сваи, колья и столбы из дерева, заостренные, но не распиленные вдоль; лесоматериалы, грубо обтесанные, но не обточенные, не изогнутые или не обработанные другим способом, используемые для производства тростей, зонтов, ручек для инструментов или аналогичных изделий; щепа и аналогичная древесина:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 887,
    "status": true,
    "code": 4405000000,
    "name": 886,
    "description": "Древесина бондарная; бревна расколотые; сваи, колья и столбы из дерева, заостренные, но не распиленные вдоль; лесоматериалы, грубо обтесанные, но не обточенные, не изогнутые или не обработанные другим способом, используемые для производства тростей, зонтов, ручек для инструментов или аналогичных изделий; щепа и аналогичная древесина:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 888,
    "status": true,
    "code": 4406110000,
    "name": 887,
    "description": "Шпалы деревянные для железнодорожных или трамвайных путей:->непропитанные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 889,
    "status": true,
    "code": 4406120000,
    "name": 888,
    "description": "Шпалы деревянные для железнодорожных или трамвайных путей:->непропитанные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 890,
    "status": true,
    "code": 4406910000,
    "name": 889,
    "description": "Шпалы деревянные для железнодорожных или трамвайных путей:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 891,
    "status": true,
    "code": 4406920000,
    "name": 890,
    "description": "Шпалы деревянные для железнодорожных или трамвайных путей:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 892,
    "status": true,
    "code": 4407111500,
    "name": 891,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из сосны (Pinus spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2311,
    "status": true,
    "code": 4407111600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-09 11:34:18.538554",
    "updated_at": "2023-01-09 11:34:18.538564"
  },
  {
    "id": 893,
    "status": true,
    "code": 4407113300,
    "name": 892,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из сосны (Pinus spp.):->обработанные строганием:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2270,
    "status": true,
    "code": 4407113400,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 11:31:11.579903",
    "updated_at": "2023-01-05 11:31:11.579916"
  },
  {
    "id": 894,
    "status": true,
    "code": 4407113800,
    "name": 893,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из сосны (Pinus spp.):->обработанные строганием:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2294,
    "status": true,
    "code": 4407113900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-06 15:50:41.438377",
    "updated_at": "2023-01-06 15:50:41.438388"
  },
  {
    "id": 895,
    "status": true,
    "code": 4407119300,
    "name": 894,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из сосны (Pinus spp.):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2265,
    "status": true,
    "code": 4407119400,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 11:31:11.493998",
    "updated_at": "2023-01-05 11:31:11.494014"
  },
  {
    "id": 896,
    "status": true,
    "code": 4407119800,
    "name": 895,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из сосны (Pinus spp.):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2268,
    "status": true,
    "code": 4407119900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 11:31:11.530413",
    "updated_at": "2023-01-05 11:31:11.530424"
  },
  {
    "id": 897,
    "status": true,
    "code": 4407121500,
    "name": 896,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из пихты (Abiesspp.) и ели (Piceaspp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 898,
    "status": true,
    "code": 4407123100,
    "name": 897,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из пихты (Abiesspp.) и ели (Piceaspp.):->обработанные строганием:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2310,
    "status": true,
    "code": 4407123200,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-09 11:34:18.499682",
    "updated_at": "2023-01-09 11:34:18.499693"
  },
  {
    "id": 899,
    "status": true,
    "code": 4407123800,
    "name": 898,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из пихты (Abiesspp.) и ели (Piceaspp.):->обработанные строганием:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2293,
    "status": true,
    "code": 4407123900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-06 15:50:41.431877",
    "updated_at": "2023-01-06 15:50:41.431886"
  },
  {
    "id": 900,
    "status": true,
    "code": 4407129100,
    "name": 899,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из пихты (Abiesspp.) и ели (Piceaspp.):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2267,
    "status": true,
    "code": 4407129200,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 11:31:11.523065",
    "updated_at": "2023-01-05 11:31:11.523078"
  },
  {
    "id": 901,
    "status": true,
    "code": 4407129800,
    "name": 900,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из пихты (Abiesspp.) и ели (Piceaspp.):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2266,
    "status": true,
    "code": 4407129900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 11:31:11.515789",
    "updated_at": "2023-01-05 11:31:11.515806"
  },
  {
    "id": 2314,
    "status": true,
    "code": 4407130000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-10 12:51:13.120837",
    "updated_at": "2023-01-10 12:51:13.120866"
  },
  {
    "id": 2309,
    "status": true,
    "code": 4407140000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-08 12:13:23.476486",
    "updated_at": "2023-01-08 12:13:23.476495"
  },
  {
    "id": 902,
    "status": true,
    "code": 4407191500,
    "name": 901,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2315,
    "status": true,
    "code": 4407191600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-10 17:32:31.967414",
    "updated_at": "2023-01-10 17:32:31.967423"
  },
  {
    "id": 903,
    "status": true,
    "code": 4407199100,
    "name": 902,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2295,
    "status": true,
    "code": 4407199200,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-06 15:50:41.471441",
    "updated_at": "2023-01-06 15:50:41.471449"
  },
  {
    "id": 2269,
    "status": true,
    "code": 4407199700,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 11:31:11.567959",
    "updated_at": "2023-01-05 11:31:11.567977"
  },
  {
    "id": 904,
    "status": true,
    "code": 4407199800,
    "name": 903,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 905,
    "status": true,
    "code": 4407211000,
    "name": 904,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->махогониевое дерево (Swieteniaspp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 906,
    "status": true,
    "code": 4407219100,
    "name": 905,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->махогониевое дерево (Swieteniaspp.):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 907,
    "status": true,
    "code": 4407219900,
    "name": 906,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->махогониевое дерево (Swieteniaspp.):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 908,
    "status": true,
    "code": 4407221000,
    "name": 907,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->вирола суринамская, феба пористая и бальза:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 909,
    "status": true,
    "code": 4407229100,
    "name": 908,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->вирола суринамская, феба пористая и бальза:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 910,
    "status": true,
    "code": 4407229900,
    "name": 909,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->вирола суринамская, феба пористая и бальза:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 911,
    "status": true,
    "code": 4407251000,
    "name": 910,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->шорея с темно-красной древесиной, шорея с бледно-красной древесиной и шореябакау:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 912,
    "status": true,
    "code": 4407253000,
    "name": 911,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->шорея с темно-красной древесиной, шорея с бледно-красной древесиной и шореябакау:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 913,
    "status": true,
    "code": 4407255000,
    "name": 912,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->шорея с темно-красной древесиной, шорея с бледно-красной древесиной и шореябакау:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 914,
    "status": true,
    "code": 4407259000,
    "name": 913,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->шорея с темно-красной древесиной, шорея с бледно-красной древесиной и шореябакау:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 915,
    "status": true,
    "code": 4407261000,
    "name": 914,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->древесина различных видов шореи, парашореи, пентакме, заболонная древесина шореи всех видов, парашорея, шореяфагуцина и другие виды шореи и фрагрэа душистая:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 916,
    "status": true,
    "code": 4407263000,
    "name": 915,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->древесина различных видов шореи, парашореи, пентакме, заболонная древесина шореи всех видов, парашорея, шореяфагуцина и другие виды шореи и фрагрэа душистая:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 917,
    "status": true,
    "code": 4407265000,
    "name": 916,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->древесина различных видов шореи, парашореи, пентакме, заболонная древесина шореи всех видов, парашорея, шореяфагуцина и другие виды шореи и фрагрэа душистая:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 918,
    "status": true,
    "code": 4407269000,
    "name": 917,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->древесина различных видов шореи, парашореи, пентакме, заболонная древесина шореи всех видов, парашорея, шореяфагуцина и другие виды шореи и фрагрэа душистая:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 919,
    "status": true,
    "code": 4407271000,
    "name": 918,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->энтандрофрагма цилиндрическая:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 920,
    "status": true,
    "code": 4407279100,
    "name": 919,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->энтандрофрагма цилиндрическая:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 921,
    "status": true,
    "code": 4407279900,
    "name": 920,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->энтандрофрагма цилиндрическая:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 922,
    "status": true,
    "code": 4407281000,
    "name": 921,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->хлорофора высокая, или африканское тиковое дерево:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 923,
    "status": true,
    "code": 4407289100,
    "name": 922,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->хлорофора высокая, или африканское тиковое дерево:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 924,
    "status": true,
    "code": 4407289900,
    "name": 923,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->хлорофора высокая, или африканское тиковое дерево:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 925,
    "status": true,
    "code": 4407291500,
    "name": 924,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 926,
    "status": true,
    "code": 4407292000,
    "name": 925,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:->обработанные строганием:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 927,
    "status": true,
    "code": 4407292500,
    "name": 926,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:->обработанные строганием:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 928,
    "status": true,
    "code": 4407294500,
    "name": 927,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:->обработанные строганием:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 929,
    "status": true,
    "code": 4407296000,
    "name": 928,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:->обработанные строганием:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 930,
    "status": true,
    "code": 4407298300,
    "name": 929,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 931,
    "status": true,
    "code": 4407298500,
    "name": 930,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 932,
    "status": true,
    "code": 4407299500,
    "name": 931,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 933,
    "status": true,
    "code": 4407911500,
    "name": 932,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из дуба (Quercus spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 934,
    "status": true,
    "code": 4407913100,
    "name": 933,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из дуба (Quercus spp.):->обработанные строганием:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 935,
    "status": true,
    "code": 4407913900,
    "name": 934,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из дуба (Quercus spp.):->обработанные строганием:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 936,
    "status": true,
    "code": 4407919000,
    "name": 935,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из дуба (Quercus spp.):->обработанные строганием:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 937,
    "status": true,
    "code": 4407920000,
    "name": 936,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из дуба (Quercus spp.):->обработанные строганием:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 938,
    "status": true,
    "code": 4407931000,
    "name": 937,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из клена (Acer spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 939,
    "status": true,
    "code": 4407939100,
    "name": 938,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из клена (Acer spp.):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 940,
    "status": true,
    "code": 4407939900,
    "name": 939,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из клена (Acer spp.):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 941,
    "status": true,
    "code": 4407941000,
    "name": 940,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из вишни (Prunus spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 942,
    "status": true,
    "code": 4407949100,
    "name": 941,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из вишни (Prunus spp.):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 943,
    "status": true,
    "code": 4407949900,
    "name": 942,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из вишни (Prunus spp.):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 944,
    "status": true,
    "code": 4407951000,
    "name": 943,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из ясеня (Fraxinus spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 945,
    "status": true,
    "code": 4407959100,
    "name": 944,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из ясеня (Fraxinus spp.):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 946,
    "status": true,
    "code": 4407959900,
    "name": 945,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из ясеня (Fraxinus spp.):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 947,
    "status": true,
    "code": 4407961000,
    "name": 946,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из березы (Betula spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 948,
    "status": true,
    "code": 4407964000,
    "name": 947,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из березы (Betula spp.):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 949,
    "status": true,
    "code": 4407969001,
    "name": 948,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из березы (Betula spp.):->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 950,
    "status": true,
    "code": 4407969009,
    "name": 949,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из березы (Betula spp.):->прочие:->прочие",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 951,
    "status": true,
    "code": 4407971000,
    "name": 950,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из тополя и осины (Populus spp.):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 952,
    "status": true,
    "code": 4407974000,
    "name": 951,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из тополя и осины (Populus spp.):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 953,
    "status": true,
    "code": 4407979001,
    "name": 952,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из тополя и осины (Populus spp.):->из осины (Populus adenopoda, Populus davidiana, Populus grandidentata, Populus sieboldii, Populus tremula, Populus tremuloides):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 954,
    "status": true,
    "code": 4407979002,
    "name": 953,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из тополя и осины (Populus spp.):->из осины (Populus adenopoda, Populus davidiana, Populus grandidentata, Populus sieboldii, Populus tremula, Populus tremuloides):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 955,
    "status": true,
    "code": 4407979009,
    "name": 954,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->из тополя и осины (Populus spp.):->из осины (Populus adenopoda, Populus davidiana, Populus grandidentata, Populus sieboldii, Populus tremula, Populus tremuloides):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 956,
    "status": true,
    "code": 4407991000,
    "name": 955,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 957,
    "status": true,
    "code": 4407994000,
    "name": 956,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 958,
    "status": true,
    "code": 4407999001,
    "name": 957,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 959,
    "status": true,
    "code": 4407999009,
    "name": 958,
    "description": "Лесоматериалы, полученные распиловкой или расщеплением вдоль, строганием или лущением, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения, толщиной более 6 мм:->прочие:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1192,
    "status": true,
    "code": 4408101501,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2136,
    "status": true,
    "code": 4408101509,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-02-18 17:41:01.846110",
    "updated_at": "2022-02-18 17:41:01.846125"
  },
  {
    "id": 2032,
    "status": true,
    "code": 4408109803,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-07-30 17:37:55.917137",
    "updated_at": "2021-07-30 17:37:55.917151"
  },
  {
    "id": 1483,
    "status": true,
    "code": 4408109809,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-08 09:29:26.571958",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2007,
    "status": true,
    "code": 4408393002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-02 10:16:30.064180",
    "updated_at": "2021-06-02 10:16:30.064189"
  },
  {
    "id": 2243,
    "status": true,
    "code": 4408393009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-11-14 10:47:33.234874",
    "updated_at": "2022-11-14 10:47:33.234890"
  },
  {
    "id": 2257,
    "status": true,
    "code": 4408395509,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-12-23 17:40:28.494694",
    "updated_at": "2022-12-23 17:40:28.494709"
  },
  {
    "id": 1957,
    "status": true,
    "code": 4408399509,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-30 20:26:42.439155",
    "updated_at": "2021-03-30 20:26:42.439165"
  },
  {
    "id": 2214,
    "status": true,
    "code": 4408901509,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-09 11:49:36.071259",
    "updated_at": "2022-08-09 11:49:36.071269"
  },
  {
    "id": 2087,
    "status": true,
    "code": 4408908501,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-11-24 14:56:58.567010",
    "updated_at": "2021-11-24 14:56:58.567025"
  },
  {
    "id": 1996,
    "status": true,
    "code": 4408908502,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-20 14:53:55.110348",
    "updated_at": "2021-05-20 14:53:55.110358"
  },
  {
    "id": 1950,
    "status": true,
    "code": 4408908509,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-27 10:37:33.284018",
    "updated_at": "2021-03-27 10:37:33.284033"
  },
  {
    "id": 2018,
    "status": true,
    "code": 4408909501,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-22 17:39:07.821968",
    "updated_at": "2021-06-22 17:39:07.821979"
  },
  {
    "id": 2226,
    "status": true,
    "code": 4408909507,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-09-14 15:40:36.740322",
    "updated_at": "2022-09-14 15:40:36.740335"
  },
  {
    "id": 1956,
    "status": true,
    "code": 4408909509,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-30 14:59:38.340459",
    "updated_at": "2021-03-30 14:59:38.340472"
  },
  {
    "id": 960,
    "status": true,
    "code": 4409101100,
    "name": 959,
    "description": "Пиломатериалы (включая планки и фриз для паркетного покрытия пола, несобранные) в виде профилированного погонажа (с гребнями, пазами, шпунтованные, со стесанными краями, с соединением в виде полукруглой калевки, фасонные, закругленные или аналогичные) по любой из кромок, торцов или плоскостей, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения:->хвойные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 961,
    "status": true,
    "code": 4409101800,
    "name": 960,
    "description": "Пиломатериалы (включая планки и фриз для паркетного покрытия пола, несобранные) в виде профилированного погонажа (с гребнями, пазами, шпунтованные, со стесанными краями, с соединением в виде полукруглой калевки, фасонные, закругленные или аналогичные) по любой из кромок, торцов или плоскостей, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения:->хвойные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 962,
    "status": true,
    "code": 4409210000,
    "name": 961,
    "description": "Пиломатериалы (включая планки и фриз для паркетного покрытия пола, несобранные) в виде профилированного погонажа (с гребнями, пазами, шпунтованные, со стесанными краями, с соединением в виде полукруглой калевки, фасонные, закругленные или аналогичные) по любой из кромок, торцов или плоскостей, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения:->хвойные:->лиственные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2053,
    "status": true,
    "code": 4409220000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-06 17:46:48.333129",
    "updated_at": "2021-09-06 17:46:48.333144"
  },
  {
    "id": 963,
    "status": true,
    "code": 4409291500,
    "name": 962,
    "description": "Пиломатериалы (включая планки и фриз для паркетного покрытия пола, несобранные) в виде профилированного погонажа (с гребнями, пазами, шпунтованные, со стесанными краями, с соединением в виде полукруглой калевки, фасонные, закругленные или аналогичные) по любой из кромок, торцов или плоскостей, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 964,
    "status": true,
    "code": 4409299200,
    "name": 963,
    "description": "Пиломатериалы (включая планки и фриз для паркетного покрытия пола, несобранные) в виде профилированного погонажа (с гребнями, пазами, шпунтованные, со стесанными краями, с соединением в виде полукруглой калевки, фасонные, закругленные или аналогичные) по любой из кромок, торцов или плоскостей, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 965,
    "status": true,
    "code": 4409299800,
    "name": 964,
    "description": "Пиломатериалы (включая планки и фриз для паркетного покрытия пола, несобранные) в виде профилированного погонажа (с гребнями, пазами, шпунтованные, со стесанными краями, с соединением в виде полукруглой калевки, фасонные, закругленные или аналогичные) по любой из кромок, торцов или плоскостей, не обработанные или обработанные строганием, шлифованием, имеющие или не имеющие торцевые соединения:->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 966,
    "status": true,
    "code": 4410111000,
    "name": 965,
    "description": "Плиты древесно-стружечные, плиты с ориентированной стружкой (OSB) и аналогичные плиты (например, вафельные плиты) из древесины или других одревесневших материалов, не пропитанные или пропитанные смолами или другими органическими связующими веществами:->плиты древесно-стружечные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 967,
    "status": true,
    "code": 4410113000,
    "name": 966,
    "description": "Плиты древесно-стружечные, плиты с ориентированной стружкой (OSB) и аналогичные плиты (например, вафельные плиты) из древесины или других одревесневших материалов, не пропитанные или пропитанные смолами или другими органическими связующими веществами:->плиты древесно-стружечные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 968,
    "status": true,
    "code": 4410115000,
    "name": 967,
    "description": "Плиты древесно-стружечные, плиты с ориентированной стружкой (OSB) и аналогичные плиты (например, вафельные плиты) из древесины или других одревесневших материалов, не пропитанные или пропитанные смолами или другими органическими связующими веществами:->плиты древесно-стружечные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 969,
    "status": true,
    "code": 4410119000,
    "name": 968,
    "description": "Плиты древесно-стружечные, плиты с ориентированной стружкой (OSB) и аналогичные плиты (например, вафельные плиты) из древесины или других одревесневших материалов, не пропитанные или пропитанные смолами или другими органическими связующими веществами:->плиты древесно-стружечные:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 970,
    "status": true,
    "code": 4410121000,
    "name": 969,
    "description": "Плиты древесно-стружечные, плиты с ориентированной стружкой (OSB) и аналогичные плиты (например, вафельные плиты) из древесины или других одревесневших материалов, не пропитанные или пропитанные смолами или другими органическими связующими веществами:->плиты с ориентированной стружкой (OSB):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 971,
    "status": true,
    "code": 4410129000,
    "name": 970,
    "description": "Плиты древесно-стружечные, плиты с ориентированной стружкой (OSB) и аналогичные плиты (например, вафельные плиты) из древесины или других одревесневших материалов, не пропитанные или пропитанные смолами или другими органическими связующими веществами:->плиты с ориентированной стружкой (OSB):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 972,
    "status": true,
    "code": 4410190001,
    "name": 971,
    "description": "Плиты древесно-стружечные, плиты с ориентированной стружкой (OSB) и аналогичные плиты (например, вафельные плиты) из древесины или других одревесневших материалов, не пропитанные или пропитанные смолами или другими органическими связующими веществами:->плиты с ориентированной стружкой (OSB):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 973,
    "status": true,
    "code": 4410190002,
    "name": 972,
    "description": "Плиты древесно-стружечные, плиты с ориентированной стружкой (OSB) и аналогичные плиты (например, вафельные плиты) из древесины или других одревесневших материалов, не пропитанные или пропитанные смолами или другими органическими связующими веществами:->плиты с ориентированной стружкой (OSB):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 974,
    "status": true,
    "code": 4410190003,
    "name": 973,
    "description": "Плиты древесно-стружечные, плиты с ориентированной стружкой (OSB) и аналогичные плиты (например, вафельные плиты) из древесины или других одревесневших материалов, не пропитанные или пропитанные смолами или другими органическими связующими веществами:->плиты с ориентированной стружкой (OSB):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 975,
    "status": true,
    "code": 4410190009,
    "name": 974,
    "description": "Плиты древесно-стружечные, плиты с ориентированной стружкой (OSB) и аналогичные плиты (например, вафельные плиты) из древесины или других одревесневших материалов, не пропитанные или пропитанные смолами или другими органическими связующими веществами:->плиты с ориентированной стружкой (OSB):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 976,
    "status": true,
    "code": 4410900000,
    "name": 975,
    "description": "Плиты древесно-стружечные, плиты с ориентированной стружкой (OSB) и аналогичные плиты (например, вафельные плиты) из древесины или других одревесневших материалов, не пропитанные или пропитанные смолами или другими органическими связующими веществами:->плиты с ориентированной стружкой (OSB):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 977,
    "status": true,
    "code": 4411121000,
    "name": 976,
    "description": "Плиты древесно-волокнистые из древесины или других одревесневших материалов с добавлением или без добавления смол или других органических веществ:->толщиной не более 5 мм:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 978,
    "status": true,
    "code": 4411129000,
    "name": 977,
    "description": "Плиты древесно-волокнистые из древесины или других одревесневших материалов с добавлением или без добавления смол или других органических веществ:->толщиной не более 5 мм:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 979,
    "status": true,
    "code": 4411131000,
    "name": 978,
    "description": "Плиты древесно-волокнистые из древесины или других одревесневших материалов с добавлением или без добавления смол или других органических веществ:->толщиной более 5 мм, но не более 9 мм:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 980,
    "status": true,
    "code": 4411139000,
    "name": 979,
    "description": "Плиты древесно-волокнистые из древесины или других одревесневших материалов с добавлением или без добавления смол или других органических веществ:->толщиной более 5 мм, но не более 9 мм:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 981,
    "status": true,
    "code": 4411141000,
    "name": 980,
    "description": "Плиты древесно-волокнистые из древесины или других одревесневших материалов с добавлением или без добавления смол или других органических веществ:->толщиной более 9 мм:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 982,
    "status": true,
    "code": 4411149000,
    "name": 981,
    "description": "Плиты древесно-волокнистые из древесины или других одревесневших материалов с добавлением или без добавления смол или других органических веществ:->толщиной более 9 мм:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 983,
    "status": true,
    "code": 4411921000,
    "name": 982,
    "description": "Плиты древесно-волокнистые из древесины или других одревесневших материалов с добавлением или без добавления смол или других органических веществ:->плотностью более 0,8 г/куб. см:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 984,
    "status": true,
    "code": 4411929000,
    "name": 983,
    "description": "Плиты древесно-волокнистые из древесины или других одревесневших материалов с добавлением или без добавления смол или других органических веществ:->плотностью более 0,8 г/куб. см:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 985,
    "status": true,
    "code": 4411931000,
    "name": 984,
    "description": "Плиты древесно-волокнистые из древесины или других одревесневших материалов с добавлением или без добавления смол или других органических веществ:->плотностью более 0,5 г/куб. см, но не более 0,8 г/куб. см:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 986,
    "status": true,
    "code": 4411939000,
    "name": 985,
    "description": "Плиты древесно-волокнистые из древесины или других одревесневших материалов с добавлением или без добавления смол или других органических веществ:->плотностью более 0,5 г/куб. см, но не более 0,8 г/куб. см:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 987,
    "status": true,
    "code": 4411941000,
    "name": 986,
    "description": "Плиты древесно-волокнистые из древесины или других одревесневших материалов с добавлением или без добавления смол или других органических веществ:->плотностью не более 0,5 г/куб. см:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 988,
    "status": true,
    "code": 4411949000,
    "name": 987,
    "description": "Плиты древесно-волокнистые из древесины или других одревесневших материалов с добавлением или без добавления смол или других органических веществ:->плотностью не более 0,5 г/куб. см:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 989,
    "status": true,
    "code": 4412100002,
    "name": 988,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->фанера клееная, состоящая из листов, толщина каждого из которых не более 6 мм:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 990,
    "status": true,
    "code": 4412100003,
    "name": 989,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->фанера клееная, состоящая из листов, толщина каждого из которых не более 6 мм:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 991,
    "status": true,
    "code": 4412100005,
    "name": 990,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 992,
    "status": true,
    "code": 4412100006,
    "name": 991,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 993,
    "status": true,
    "code": 4412100009,
    "name": 992,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 994,
    "status": true,
    "code": 4412311000,
    "name": 993,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->имеющая, по крайней мере, один наружный слой из древесины тропических пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 995,
    "status": true,
    "code": 4412319000,
    "name": 994,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->имеющая, по крайней мере, один наружный слой из древесины тропических пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 996,
    "status": true,
    "code": 4412330000,
    "name": 995,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->имеющая, по крайней мере, один наружный слой из древесины тропических пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 997,
    "status": true,
    "code": 4412340000,
    "name": 996,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->имеющая, по крайней мере, один наружный слой из древесины тропических пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 998,
    "status": true,
    "code": 4412390000,
    "name": 997,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->имеющая, по крайней мере, один наружный слой из древесины тропических пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 999,
    "status": true,
    "code": 4412941000,
    "name": 998,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->брусковые, многослойные и реечные столярные плиты:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1000,
    "status": true,
    "code": 4412949000,
    "name": 999,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->брусковые, многослойные и реечные столярные плиты:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1001,
    "status": true,
    "code": 4412993002,
    "name": 1000,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->прочие:->содержащие, по крайней мере, один слой из древесностружечной плиты:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1002,
    "status": true,
    "code": 4412993009,
    "name": 1001,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->прочие:->содержащие, по крайней мере, один слой из древесностружечной плиты:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1003,
    "status": true,
    "code": 4412994000,
    "name": 1002,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->прочие:->имеющие, по крайней мере, один наружный слой из древесины лиственных пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1004,
    "status": true,
    "code": 4412995000,
    "name": 1003,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->прочие:->имеющие, по крайней мере, один наружный слой из древесины лиственных пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1005,
    "status": true,
    "code": 4412998500,
    "name": 1004,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->прочие:->имеющие, по крайней мере, один наружный слой из древесины лиственных пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1006,
    "status": true,
    "code": 4413000000,
    "name": 1005,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->прочие:->имеющие, по крайней мере, один наружный слой из древесины лиственных пород:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1007,
    "status": true,
    "code": 4414001000,
    "name": 1006,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->Рамы деревянные для картин, фотографий, зеркал или аналогичных предметов:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1008,
    "status": true,
    "code": 4414009000,
    "name": 1007,
    "description": "Фанера клееная, панели фанерованные и аналогичные материалы из слоистой древесины:->Рамы деревянные для картин, фотографий, зеркал или аналогичных предметов:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1009,
    "status": true,
    "code": 4415101000,
    "name": 1008,
    "description": "Ящики, коробки, упаковочные клети или корзины, барабаны и аналогичная тара, из древесины; кабельные барабаны деревянные; паллеты, поддоны и прочие погрузочные щиты, деревянные; обечайки деревянные:->ящики, коробки, упаковочные клети или корзины, барабаны и аналогичная тара; кабельные барабаны:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1010,
    "status": true,
    "code": 4415109000,
    "name": 1009,
    "description": "Ящики, коробки, упаковочные клети или корзины, барабаны и аналогичная тара, из древесины; кабельные барабаны деревянные; паллеты, поддоны и прочие погрузочные щиты, деревянные; обечайки деревянные:->ящики, коробки, упаковочные клети или корзины, барабаны и аналогичная тара; кабельные барабаны:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1011,
    "status": true,
    "code": 4415202000,
    "name": 1010,
    "description": "Ящики, коробки, упаковочные клети или корзины, барабаны и аналогичная тара, из древесины; кабельные барабаны деревянные; паллеты, поддоны и прочие погрузочные щиты, деревянные; обечайки деревянные:->паллеты, поддоны и прочие погрузочные щиты; обечайки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1012,
    "status": true,
    "code": 4415209000,
    "name": 1011,
    "description": "Ящики, коробки, упаковочные клети или корзины, барабаны и аналогичная тара, из древесины; кабельные барабаны деревянные; паллеты, поддоны и прочие погрузочные щиты, деревянные; обечайки деревянные:->паллеты, поддоны и прочие погрузочные щиты; обечайки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1013,
    "status": true,
    "code": 4416000000,
    "name": 1012,
    "description": "Ящики, коробки, упаковочные клети или корзины, барабаны и аналогичная тара, из древесины; кабельные барабаны деревянные; паллеты, поддоны и прочие погрузочные щиты, деревянные; обечайки деревянные:->паллеты, поддоны и прочие погрузочные щиты; обечайки:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1429,
    "status": true,
    "code": 4417000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:06:45.831112",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1014,
    "status": true,
    "code": 4418101000,
    "name": 1013,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->окна, балконные двери и их рамы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1015,
    "status": true,
    "code": 4418105000,
    "name": 1014,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->окна, балконные двери и их рамы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1016,
    "status": true,
    "code": 4418109000,
    "name": 1015,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->окна, балконные двери и их рамы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2353,
    "status": true,
    "code": 4418190000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-01 14:39:07.757512",
    "updated_at": "2023-02-01 14:39:07.757522"
  },
  {
    "id": 1017,
    "status": true,
    "code": 4418201000,
    "name": 1016,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->двери и их рамы и пороги:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1018,
    "status": true,
    "code": 4418205000,
    "name": 1017,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->двери и их рамы и пороги:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1019,
    "status": true,
    "code": 4418208000,
    "name": 1018,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->двери и их рамы и пороги:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2354,
    "status": true,
    "code": 4418211000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-01 16:47:29.368359",
    "updated_at": "2023-02-01 16:47:29.368376"
  },
  {
    "id": 2329,
    "status": true,
    "code": 4418219000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-16 20:08:27.482130",
    "updated_at": "2023-01-16 20:08:27.482143"
  },
  {
    "id": 2276,
    "status": true,
    "code": 4418290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 15:17:18.718388",
    "updated_at": "2023-01-05 15:17:18.718399"
  },
  {
    "id": 1020,
    "status": true,
    "code": 4418400000,
    "name": 1019,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->двери и их рамы и пороги:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1021,
    "status": true,
    "code": 4418500000,
    "name": 1020,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->двери и их рамы и пороги:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1022,
    "status": true,
    "code": 4418600000,
    "name": 1021,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->двери и их рамы и пороги:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1023,
    "status": true,
    "code": 4418730001,
    "name": 1022,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->двери и их рамы и пороги:->панели напольные собранные:->из бамбука или имеющие, по крайней мере, лицевой слой (слой износа) из бамбука:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1024,
    "status": true,
    "code": 4418730002,
    "name": 1023,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->двери и их рамы и пороги:->панели напольные собранные:->из бамбука или имеющие, по крайней мере, лицевой слой (слой износа) из бамбука:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1025,
    "status": true,
    "code": 4418730009,
    "name": 1024,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->двери и их рамы и пороги:->панели напольные собранные:->из бамбука или имеющие, по крайней мере, лицевой слой (слой износа) из бамбука:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1026,
    "status": true,
    "code": 4418740000,
    "name": 1025,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->двери и их рамы и пороги:->панели напольные собранные:->из бамбука или имеющие, по крайней мере, лицевой слой (слой износа) из бамбука:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1027,
    "status": true,
    "code": 4418750000,
    "name": 1026,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->двери и их рамы и пороги:->панели напольные собранные:->из бамбука или имеющие, по крайней мере, лицевой слой (слой износа) из бамбука:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1028,
    "status": true,
    "code": 4418790000,
    "name": 1027,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->двери и их рамы и пороги:->панели напольные собранные:->из бамбука или имеющие, по крайней мере, лицевой слой (слой износа) из бамбука:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1029,
    "status": true,
    "code": 4418911000,
    "name": 1028,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->из бамбука:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1030,
    "status": true,
    "code": 4418918000,
    "name": 1029,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->из бамбука:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1031,
    "status": true,
    "code": 4418991000,
    "name": 1030,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2319,
    "status": true,
    "code": 4418997000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-11 16:11:28.175706",
    "updated_at": "2023-01-11 16:11:28.175716"
  },
  {
    "id": 1032,
    "status": true,
    "code": 4418998000,
    "name": 1031,
    "description": "Изделия столярные и плотницкие, деревянные, строительные, включая ячеистые деревянные панели, панели напольные собранные, гонт и дранку кровельные:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1517,
    "status": true,
    "code": 4419190000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-12 15:18:31.881003",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2345,
    "status": true,
    "code": 4419900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-29 17:59:20.792416",
    "updated_at": "2023-01-29 17:59:20.792428"
  },
  {
    "id": 1151,
    "status": true,
    "code": 4419909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1943,
    "status": true,
    "code": 4420101900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-24 09:21:04.448818",
    "updated_at": "2021-03-24 09:21:04.448835"
  },
  {
    "id": 1182,
    "status": true,
    "code": 4420909900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1460,
    "status": true,
    "code": 4421100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:12:18.244697",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1191,
    "status": true,
    "code": 4421991000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2284,
    "status": true,
    "code": 4421999000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 16:50:45.422614",
    "updated_at": "2023-01-05 16:50:45.422623"
  },
  {
    "id": 2202,
    "status": true,
    "code": 4421999500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-07-05 10:58:58.450611",
    "updated_at": "2022-07-05 10:58:58.450624"
  },
  {
    "id": 1343,
    "status": true,
    "code": 4421999900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:48:19.664299",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1033,
    "status": true,
    "code": 4501100000,
    "name": 1032,
    "description": "Пробка натуральная, необработанная или прошедшая первичную обработку; отходы пробки; измельченная, гранулированная или молотая пробка:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1034,
    "status": true,
    "code": 4501900000,
    "name": 1033,
    "description": "Пробка натуральная, необработанная или прошедшая первичную обработку; отходы пробки; измельченная, гранулированная или молотая пробка:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1035,
    "status": true,
    "code": 4601211000,
    "name": 1034,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->из бамбука:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1036,
    "status": true,
    "code": 4601219000,
    "name": 1035,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->из бамбука:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1037,
    "status": true,
    "code": 4601221000,
    "name": 1036,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->из ротанга:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1038,
    "status": true,
    "code": 4601229000,
    "name": 1037,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->из ротанга:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1039,
    "status": true,
    "code": 4601291000,
    "name": 1038,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1040,
    "status": true,
    "code": 4601299000,
    "name": 1039,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1041,
    "status": true,
    "code": 4601920500,
    "name": 1040,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->из бамбука:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1042,
    "status": true,
    "code": 4601921000,
    "name": 1041,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->из бамбука:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1043,
    "status": true,
    "code": 4601929000,
    "name": 1042,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->из бамбука:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1044,
    "status": true,
    "code": 4601930500,
    "name": 1043,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->из ротанга:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1045,
    "status": true,
    "code": 4601931000,
    "name": 1044,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->из ротанга:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1046,
    "status": true,
    "code": 4601939000,
    "name": 1045,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->из ротанга:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1047,
    "status": true,
    "code": 4601940500,
    "name": 1046,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->из прочих растительных материалов:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1048,
    "status": true,
    "code": 4601941000,
    "name": 1047,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->из прочих растительных материалов:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1049,
    "status": true,
    "code": 4601949000,
    "name": 1048,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->из прочих растительных материалов:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1050,
    "status": true,
    "code": 4601990500,
    "name": 1049,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1051,
    "status": true,
    "code": 4601991000,
    "name": 1050,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1052,
    "status": true,
    "code": 4601999000,
    "name": 1051,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->прочие:->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1974,
    "status": true,
    "code": 4602110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-15 15:03:35.675351",
    "updated_at": "2021-04-15 15:03:35.675367"
  },
  {
    "id": 1969,
    "status": true,
    "code": 4602120000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-06 09:38:34.039019",
    "updated_at": "2021-04-06 09:38:34.039029"
  },
  {
    "id": 1160,
    "status": true,
    "code": 4602199000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1968,
    "status": true,
    "code": 4602900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-05 20:44:41.671122",
    "updated_at": "2021-04-05 20:44:41.671131"
  },
  {
    "id": 1053,
    "status": true,
    "code": 4701001000,
    "name": 1052,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->Древесная масса:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1054,
    "status": true,
    "code": 4701009000,
    "name": 1053,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->Древесная масса:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1055,
    "status": true,
    "code": 4702000000,
    "name": 1054,
    "description": "Плетеные и аналогичные изделия из материалов для плетения, соединенные или не соединенные в полосы или ленты; материалы для плетения, плетеные и аналогичные изделия из материалов для плетения, связанные в параллельные пряди или сотканные, в виде листов, законченные или незаконченные (например, коврики, циновки, ширмы):->Древесная масса:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2240,
    "status": true,
    "code": 4703290009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-27 16:23:14.520904",
    "updated_at": "2022-10-27 16:23:14.520936"
  },
  {
    "id": 1056,
    "status": true,
    "code": 4706100000,
    "name": 1055,
    "description": "Масса волокнистая, полученная из регенерируемых бумаги или картона (макулатуры и отходов) или из других волокнистых целлюлозных материалов:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1057,
    "status": true,
    "code": 4706200000,
    "name": 1056,
    "description": "Масса волокнистая, полученная из регенерируемых бумаги или картона (макулатуры и отходов) или из других волокнистых целлюлозных материалов:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1058,
    "status": true,
    "code": 4706300000,
    "name": 1057,
    "description": "Масса волокнистая, полученная из регенерируемых бумаги или картона (макулатуры и отходов) или из других волокнистых целлюлозных материалов:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1059,
    "status": true,
    "code": 4706910000,
    "name": 1058,
    "description": "Масса волокнистая, полученная из регенерируемых бумаги или картона (макулатуры и отходов) или из других волокнистых целлюлозных материалов:->прочая:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1060,
    "status": true,
    "code": 4706920000,
    "name": 1059,
    "description": "Масса волокнистая, полученная из регенерируемых бумаги или картона (макулатуры и отходов) или из других волокнистых целлюлозных материалов:->прочая:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1061,
    "status": true,
    "code": 4706930000,
    "name": 1060,
    "description": "Масса волокнистая, полученная из регенерируемых бумаги или картона (макулатуры и отходов) или из других волокнистых целлюлозных материалов:->прочая:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1062,
    "status": true,
    "code": 4707100000,
    "name": 1061,
    "description": "Регенерируемые бумага или картон (макулатура и отходы):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1063,
    "status": true,
    "code": 4707200000,
    "name": 1062,
    "description": "Регенерируемые бумага или картон (макулатура и отходы):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1064,
    "status": true,
    "code": 4707301000,
    "name": 1063,
    "description": "Регенерируемые бумага или картон (макулатура и отходы):->бумага или картон, полученные в основном из древесной массы (например, газеты, журналы и аналогичная печатная продукция):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1065,
    "status": true,
    "code": 4707309000,
    "name": 1064,
    "description": "Регенерируемые бумага или картон (макулатура и отходы):->бумага или картон, полученные в основном из древесной массы (например, газеты, журналы и аналогичная печатная продукция):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1066,
    "status": true,
    "code": 4707901000,
    "name": 1065,
    "description": "Регенерируемые бумага или картон (макулатура и отходы):->прочие, включая неотсортированные макулатуру и отходы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1067,
    "status": true,
    "code": 4707909000,
    "name": 1066,
    "description": "Регенерируемые бумага или картон (макулатура и отходы):->прочие, включая неотсортированные макулатуру и отходы:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2239,
    "status": true,
    "code": 4802620000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-27 10:38:01.580218",
    "updated_at": "2022-10-27 10:38:01.580230"
  },
  {
    "id": 1977,
    "status": true,
    "code": 4803009000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-19 10:59:43.264401",
    "updated_at": "2021-04-19 10:59:43.264412"
  },
  {
    "id": 1827,
    "status": true,
    "code": 4805199000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-12 03:55:19.420849",
    "updated_at": "2020-11-12 03:55:19.420859"
  },
  {
    "id": 2143,
    "status": true,
    "code": 4805300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-03-18 11:14:14.658737",
    "updated_at": "2022-03-18 11:14:14.658752"
  },
  {
    "id": 1952,
    "status": true,
    "code": 4805400000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-27 16:54:03.619855",
    "updated_at": "2021-03-27 16:54:03.619865"
  },
  {
    "id": 2184,
    "status": true,
    "code": 4805920000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-05-31 16:00:13.073464",
    "updated_at": "2022-05-31 16:00:13.073477"
  },
  {
    "id": 1781,
    "status": true,
    "code": 4807003000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-29 11:10:56.190901",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2092,
    "status": true,
    "code": 4807008000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-03 11:34:57.892094",
    "updated_at": "2021-12-03 11:34:57.892105"
  },
  {
    "id": 1068,
    "status": true,
    "code": 4808100000,
    "name": 1067,
    "description": "Бумага и картон гофрированные (оклеенные или не оклеенные гладкими наружными листами), крепированные, тисненые или перфорированные, в рулонах или листах, кроме указанных в товарной позиции 4803:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1069,
    "status": true,
    "code": 4808400001,
    "name": 1068,
    "description": "Бумага и картон гофрированные (оклеенные или не оклеенные гладкими наружными листами), крепированные, тисненые или перфорированные, в рулонах или листах, кроме указанных в товарной позиции 4803:->нетисненая, перфорированная или неперфорированная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1070,
    "status": true,
    "code": 4808400009,
    "name": 1069,
    "description": "Бумага и картон гофрированные (оклеенные или не оклеенные гладкими наружными листами), крепированные, тисненые или перфорированные, в рулонах или листах, кроме указанных в товарной позиции 4803:->нетисненая, перфорированная или неперфорированная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1071,
    "status": true,
    "code": 4808900000,
    "name": 1070,
    "description": "Бумага и картон гофрированные (оклеенные или не оклеенные гладкими наружными листами), крепированные, тисненые или перфорированные, в рулонах или листах, кроме указанных в товарной позиции 4803:->нетисненая, перфорированная или неперфорированная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1876,
    "status": true,
    "code": 4809200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-14 11:49:15.812596",
    "updated_at": "2020-12-14 11:49:15.812608"
  },
  {
    "id": 1877,
    "status": true,
    "code": 4809900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-14 11:49:15.878234",
    "updated_at": "2020-12-14 11:49:15.878244"
  },
  {
    "id": 1751,
    "status": true,
    "code": 4810190009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-10 23:46:33.207431",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2095,
    "status": true,
    "code": 4810929009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-03 16:12:34.062850",
    "updated_at": "2021-12-03 16:12:34.062860"
  },
  {
    "id": 1828,
    "status": true,
    "code": 4811412000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-12 03:55:19.456379",
    "updated_at": "2020-11-12 03:55:19.456387"
  },
  {
    "id": 1582,
    "status": true,
    "code": 4811419000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-15 19:48:41.347700",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1198,
    "status": true,
    "code": 4811490000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-09 10:39:50.363498",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1180,
    "status": true,
    "code": 4811900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2022,
    "status": true,
    "code": 4814200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-28 11:22:36.739407",
    "updated_at": "2021-06-28 11:22:36.739423"
  },
  {
    "id": 1482,
    "status": true,
    "code": 4816200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-06 16:49:43.557786",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1829,
    "status": true,
    "code": 4816900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-12 19:10:25.719094",
    "updated_at": "2020-11-12 19:10:25.719105"
  },
  {
    "id": 1945,
    "status": true,
    "code": 4817300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-24 09:21:04.514566",
    "updated_at": "2021-03-24 09:21:04.514575"
  },
  {
    "id": 1817,
    "status": true,
    "code": 4818109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-30 15:12:32.864847",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1837,
    "status": true,
    "code": 4818201000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-20 13:32:16.409230",
    "updated_at": "2020-11-20 13:32:16.409239"
  },
  {
    "id": 1649,
    "status": true,
    "code": 4818300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-07 18:08:28.507396",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1072,
    "status": true,
    "code": 4819100000,
    "name": 1071,
    "description": "Картонки, ящики, коробки, мешки, пакеты и другая упаковочная тара, из бумаги, картона, целлюлозной ваты или полотна из целлюлозных волокон; коробки для картотек, лотки для писем и аналогичные изделия, из бумаги или картона, используемые в учреждениях, магазинах или в аналогичных целях:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1073,
    "status": true,
    "code": 4819200000,
    "name": 1072,
    "description": "Картонки, ящики, коробки, мешки, пакеты и другая упаковочная тара, из бумаги, картона, целлюлозной ваты или полотна из целлюлозных волокон; коробки для картотек, лотки для писем и аналогичные изделия, из бумаги или картона, используемые в учреждениях, магазинах или в аналогичных целях:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1074,
    "status": true,
    "code": 4819300001,
    "name": 1073,
    "description": "Картонки, ящики, коробки, мешки, пакеты и другая упаковочная тара, из бумаги, картона, целлюлозной ваты или полотна из целлюлозных волокон; коробки для картотек, лотки для писем и аналогичные изделия, из бумаги или картона, используемые в учреждениях, магазинах или в аналогичных целях:->мешки и пакеты с шириной у основания 40 см или более:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1075,
    "status": true,
    "code": 4819300002,
    "name": 1074,
    "description": "Картонки, ящики, коробки, мешки, пакеты и другая упаковочная тара, из бумаги, картона, целлюлозной ваты или полотна из целлюлозных волокон; коробки для картотек, лотки для писем и аналогичные изделия, из бумаги или картона, используемые в учреждениях, магазинах или в аналогичных целях:->мешки и пакеты с шириной у основания 40 см или более:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1076,
    "status": true,
    "code": 4819400000,
    "name": 1075,
    "description": "Картонки, ящики, коробки, мешки, пакеты и другая упаковочная тара, из бумаги, картона, целлюлозной ваты или полотна из целлюлозных волокон; коробки для картотек, лотки для писем и аналогичные изделия, из бумаги или картона, используемые в учреждениях, магазинах или в аналогичных целях:->мешки и пакеты с шириной у основания 40 см или более:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1077,
    "status": true,
    "code": 4819500000,
    "name": 1076,
    "description": "Картонки, ящики, коробки, мешки, пакеты и другая упаковочная тара, из бумаги, картона, целлюлозной ваты или полотна из целлюлозных волокон; коробки для картотек, лотки для писем и аналогичные изделия, из бумаги или картона, используемые в учреждениях, магазинах или в аналогичных целях:->мешки и пакеты с шириной у основания 40 см или более:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1078,
    "status": true,
    "code": 4819600000,
    "name": 1077,
    "description": "Картонки, ящики, коробки, мешки, пакеты и другая упаковочная тара, из бумаги, картона, целлюлозной ваты или полотна из целлюлозных волокон; коробки для картотек, лотки для писем и аналогичные изделия, из бумаги или картона, используемые в учреждениях, магазинах или в аналогичных целях:->мешки и пакеты с шириной у основания 40 см или более:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2126,
    "status": true,
    "code": 4820103000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-02-01 12:22:00.576024",
    "updated_at": "2022-02-01 12:22:00.576034"
  },
  {
    "id": 2346,
    "status": true,
    "code": 4820200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-29 17:59:20.828221",
    "updated_at": "2023-01-29 17:59:20.828231"
  },
  {
    "id": 2083,
    "status": true,
    "code": 4821101000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-11-22 15:49:15.633913",
    "updated_at": "2021-11-22 15:49:15.633927"
  },
  {
    "id": 2172,
    "status": true,
    "code": 4821109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-29 12:57:36.039912",
    "updated_at": "2022-04-29 12:57:36.039922"
  },
  {
    "id": 1443,
    "status": true,
    "code": 4822100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:08:14.374372",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1960,
    "status": true,
    "code": 4822900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-02 14:54:55.177166",
    "updated_at": "2021-04-02 14:54:55.177179"
  },
  {
    "id": 2221,
    "status": true,
    "code": 4823691000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-27 16:21:48.704682",
    "updated_at": "2022-08-27 16:21:48.704691"
  },
  {
    "id": 1179,
    "status": true,
    "code": 4823699000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1560,
    "status": true,
    "code": 4823709000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-06 23:58:54.832384",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1594,
    "status": true,
    "code": 4823908598,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-20 18:48:50.816313",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2011,
    "status": true,
    "code": 4849100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-12 10:25:29.874715",
    "updated_at": "2021-06-12 10:25:29.874729"
  },
  {
    "id": 2127,
    "status": true,
    "code": 4901100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-02-01 12:22:00.612034",
    "updated_at": "2022-02-01 12:22:00.612043"
  },
  {
    "id": 1946,
    "status": true,
    "code": 4901990000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-24 09:21:04.525491",
    "updated_at": "2021-03-24 09:21:04.525500"
  },
  {
    "id": 1919,
    "status": true,
    "code": 4911101000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-11 10:43:30.595112",
    "updated_at": "2021-02-11 10:43:30.595120"
  },
  {
    "id": 2049,
    "status": true,
    "code": 4911990000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-31 11:16:37.850819",
    "updated_at": "2021-08-31 11:16:37.850834"
  },
  {
    "id": 1079,
    "status": true,
    "code": 5001000000,
    "name": 1078,
    "description": "Картонки, ящики, коробки, мешки, пакеты и другая упаковочная тара, из бумаги, картона, целлюлозной ваты или полотна из целлюлозных волокон; коробки для картотек, лотки для писем и аналогичные изделия, из бумаги или картона, используемые в учреждениях, магазинах или в аналогичных целях:->мешки и пакеты с шириной у основания 40 см или более:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1080,
    "status": true,
    "code": 5002000000,
    "name": 1079,
    "description": "Картонки, ящики, коробки, мешки, пакеты и другая упаковочная тара, из бумаги, картона, целлюлозной ваты или полотна из целлюлозных волокон; коробки для картотек, лотки для писем и аналогичные изделия, из бумаги или картона, используемые в учреждениях, магазинах или в аналогичных целях:->мешки и пакеты с шириной у основания 40 см или более:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1081,
    "status": true,
    "code": 5003000000,
    "name": 1080,
    "description": "Картонки, ящики, коробки, мешки, пакеты и другая упаковочная тара, из бумаги, картона, целлюлозной ваты или полотна из целлюлозных волокон; коробки для картотек, лотки для писем и аналогичные изделия, из бумаги или картона, используемые в учреждениях, магазинах или в аналогичных целях:->мешки и пакеты с шириной у основания 40 см или более:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1474,
    "status": true,
    "code": 5004009000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-30 20:00:03.203457",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1082,
    "status": true,
    "code": 5101110000,
    "name": 1081,
    "description": "Шерсть, не подвергнутая кардо- или гребнечесанию:->немытая, включая шерсть, мытую в руне:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1083,
    "status": true,
    "code": 5101190000,
    "name": 1082,
    "description": "Шерсть, не подвергнутая кардо- или гребнечесанию:->немытая, включая шерсть, мытую в руне:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1084,
    "status": true,
    "code": 5101210000,
    "name": 1083,
    "description": "Шерсть, не подвергнутая кардо- или гребнечесанию:->мытая, некарбонизованная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1085,
    "status": true,
    "code": 5101290000,
    "name": 1084,
    "description": "Шерсть, не подвергнутая кардо- или гребнечесанию:->мытая, некарбонизованная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1086,
    "status": true,
    "code": 5101300000,
    "name": 1085,
    "description": "Шерсть, не подвергнутая кардо- или гребнечесанию:->мытая, некарбонизованная:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1087,
    "status": true,
    "code": 5102110000,
    "name": 1086,
    "description": "Волос животных, тонкий или грубый, не подвергнутый кардо- или гребнечесанию:->тонкий волос животных:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1088,
    "status": true,
    "code": 5102191000,
    "name": 1087,
    "description": "Волос животных, тонкий или грубый, не подвергнутый кардо- или гребнечесанию:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1089,
    "status": true,
    "code": 5102193000,
    "name": 1088,
    "description": "Волос животных, тонкий или грубый, не подвергнутый кардо- или гребнечесанию:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1090,
    "status": true,
    "code": 5102194000,
    "name": 1089,
    "description": "Волос животных, тонкий или грубый, не подвергнутый кардо- или гребнечесанию:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1091,
    "status": true,
    "code": 5102199000,
    "name": 1090,
    "description": "Волос животных, тонкий или грубый, не подвергнутый кардо- или гребнечесанию:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1092,
    "status": true,
    "code": 5102200000,
    "name": 1091,
    "description": "Волос животных, тонкий или грубый, не подвергнутый кардо- или гребнечесанию:->прочий:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1093,
    "status": true,
    "code": 5103101000,
    "name": 1092,
    "description": "Отходы шерсти или тонкого или грубого волоса животных, включая прядильные отходы, но исключая расщипанное сырье:->гребенные очесы шерсти или тонкого волоса животных:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1094,
    "status": true,
    "code": 5103109000,
    "name": 1093,
    "description": "Отходы шерсти или тонкого или грубого волоса животных, включая прядильные отходы, но исключая расщипанное сырье:->гребенные очесы шерсти или тонкого волоса животных:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1095,
    "status": true,
    "code": 5103200000,
    "name": 1094,
    "description": "Отходы шерсти или тонкого или грубого волоса животных, включая прядильные отходы, но исключая расщипанное сырье:->гребенные очесы шерсти или тонкого волоса животных:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1096,
    "status": true,
    "code": 5103300000,
    "name": 1095,
    "description": "Отходы шерсти или тонкого или грубого волоса животных, включая прядильные отходы, но исключая расщипанное сырье:->гребенные очесы шерсти или тонкого волоса животных:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1097,
    "status": true,
    "code": 5104000000,
    "name": 1096,
    "description": "Отходы шерсти или тонкого или грубого волоса животных, включая прядильные отходы, но исключая расщипанное сырье:->гребенные очесы шерсти или тонкого волоса животных:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1098,
    "status": true,
    "code": 5105100000,
    "name": 1097,
    "description": "Шерсть и тонкий или грубый волос животных, подвергнутые кардо- или гребнечесанию (включая шерсть, подвергнутую гребнечесанию, в отрезках):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1099,
    "status": true,
    "code": 5105210000,
    "name": 1098,
    "description": "Шерсть и тонкий или грубый волос животных, подвергнутые кардо- или гребнечесанию (включая шерсть, подвергнутую гребнечесанию, в отрезках):->гребенная лента шерстяная и прочая шерсть, подвергнутая гребнечесанию:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1100,
    "status": true,
    "code": 5105290000,
    "name": 1099,
    "description": "Шерсть и тонкий или грубый волос животных, подвергнутые кардо- или гребнечесанию (включая шерсть, подвергнутую гребнечесанию, в отрезках):->гребенная лента шерстяная и прочая шерсть, подвергнутая гребнечесанию:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1101,
    "status": true,
    "code": 5105310000,
    "name": 1100,
    "description": "Шерсть и тонкий или грубый волос животных, подвергнутые кардо- или гребнечесанию (включая шерсть, подвергнутую гребнечесанию, в отрезках):->тонкий волос животных, подвергнутый кардо- или гребнечесанию:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1102,
    "status": true,
    "code": 5105390000,
    "name": 1101,
    "description": "Шерсть и тонкий или грубый волос животных, подвергнутые кардо- или гребнечесанию (включая шерсть, подвергнутую гребнечесанию, в отрезках):->тонкий волос животных, подвергнутый кардо- или гребнечесанию:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1103,
    "status": true,
    "code": 5105400000,
    "name": 1102,
    "description": "Шерсть и тонкий или грубый волос животных, подвергнутые кардо- или гребнечесанию (включая шерсть, подвергнутую гребнечесанию, в отрезках):->тонкий волос животных, подвергнутый кардо- или гребнечесанию:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2164,
    "status": true,
    "code": 5106101000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-13 14:13:23.666119",
    "updated_at": "2022-04-13 14:13:23.666135"
  },
  {
    "id": 1104,
    "status": true,
    "code": 5201001000,
    "name": 1103,
    "description": "Шерсть и тонкий или грубый волос животных, подвергнутые кардо- или гребнечесанию (включая шерсть, подвергнутую гребнечесанию, в отрезках):->Волокно хлопковое, не подвергнутое кардо- или гребнечесанию:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1105,
    "status": true,
    "code": 5201009000,
    "name": 1104,
    "description": "Шерсть и тонкий или грубый волос животных, подвергнутые кардо- или гребнечесанию (включая шерсть, подвергнутую гребнечесанию, в отрезках):->Волокно хлопковое, не подвергнутое кардо- или гребнечесанию:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1106,
    "status": true,
    "code": 5202100000,
    "name": 1105,
    "description": "Отходы хлопкового волокна (включая прядильные отходы и расщипанное сырье):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.366048"
  },
  {
    "id": 1107,
    "status": true,
    "code": 5202910000,
    "name": 1106,
    "description": "Отходы хлопкового волокна (включая прядильные отходы и расщипанное сырье):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.368419"
  },
  {
    "id": 1108,
    "status": true,
    "code": 5202990000,
    "name": 1107,
    "description": "Отходы хлопкового волокна (включая прядильные отходы и расщипанное сырье):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "True",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2022-10-07 18:57:41.642358"
  },
  {
    "id": 1109,
    "status": true,
    "code": 5203000000,
    "name": 1108,
    "description": "Отходы хлопкового волокна (включая прядильные отходы и расщипанное сырье):->прочие:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2360,
    "status": true,
    "code": 5204200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-03 08:52:05.066016",
    "updated_at": "2023-02-03 08:52:05.066025"
  },
  {
    "id": 1125,
    "status": true,
    "code": 5205110000,
    "name": 1124,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2332,
    "status": true,
    "code": 5205110002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-18 15:43:39.414449",
    "updated_at": "2023-01-18 15:43:39.414462"
  },
  {
    "id": 1126,
    "status": true,
    "code": 5205120000,
    "name": 1125,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2263,
    "status": true,
    "code": 5205120002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 10:14:26.555383",
    "updated_at": "2023-01-05 10:14:26.555395"
  },
  {
    "id": 2316,
    "status": true,
    "code": 5205120006,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-10 18:29:30.059217",
    "updated_at": "2023-01-10 18:29:30.059227"
  },
  {
    "id": 1127,
    "status": true,
    "code": 5205130000,
    "name": 1126,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2277,
    "status": true,
    "code": 5205130002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 15:39:08.078007",
    "updated_at": "2023-01-05 15:39:08.078017"
  },
  {
    "id": 1128,
    "status": true,
    "code": 5205140000,
    "name": 1127,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2278,
    "status": true,
    "code": 5205140002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 15:41:09.613707",
    "updated_at": "2023-01-05 15:41:09.613717"
  },
  {
    "id": 1129,
    "status": true,
    "code": 5205151000,
    "name": 1128,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1130,
    "status": true,
    "code": 5205159000,
    "name": 1129,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1131,
    "status": true,
    "code": 5205210000,
    "name": 1130,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1132,
    "status": true,
    "code": 5205220000,
    "name": 1131,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2291,
    "status": true,
    "code": 5205220002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-06 14:50:01.534674",
    "updated_at": "2023-01-06 14:50:01.534686"
  },
  {
    "id": 1133,
    "status": true,
    "code": 5205230000,
    "name": 1132,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2279,
    "status": true,
    "code": 5205230002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 15:46:04.510064",
    "updated_at": "2023-01-05 15:46:04.510078"
  },
  {
    "id": 1134,
    "status": true,
    "code": 5205240000,
    "name": 1133,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2292,
    "status": true,
    "code": 5205240002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-06 14:52:11.217123",
    "updated_at": "2023-01-06 14:52:11.217135"
  },
  {
    "id": 1135,
    "status": true,
    "code": 5205260000,
    "name": 1134,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1136,
    "status": true,
    "code": 5205270000,
    "name": 1135,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1137,
    "status": true,
    "code": 5205280000,
    "name": 1136,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1138,
    "status": true,
    "code": 5205310000,
    "name": 1137,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1139,
    "status": true,
    "code": 5205320000,
    "name": 1138,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2334,
    "status": true,
    "code": 5205320002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-18 18:33:56.489645",
    "updated_at": "2023-01-18 18:33:56.489660"
  },
  {
    "id": 2335,
    "status": true,
    "code": 5205320006,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-19 14:18:29.339548",
    "updated_at": "2023-01-19 14:18:29.339558"
  },
  {
    "id": 1140,
    "status": true,
    "code": 5205330000,
    "name": 1139,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2343,
    "status": true,
    "code": 5205330002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-26 15:00:24.129997",
    "updated_at": "2023-01-26 15:00:24.130011"
  },
  {
    "id": 1141,
    "status": true,
    "code": 5205340000,
    "name": 1140,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2361,
    "status": true,
    "code": 5205340002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-03 15:58:30.757828",
    "updated_at": "2023-02-03 15:58:30.757839"
  },
  {
    "id": 1142,
    "status": true,
    "code": 5205350000,
    "name": 1141,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1143,
    "status": true,
    "code": 5205410000,
    "name": 1142,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1144,
    "status": true,
    "code": 5205420000,
    "name": 1143,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1145,
    "status": true,
    "code": 5205430000,
    "name": 1144,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1146,
    "status": true,
    "code": 5205440000,
    "name": 1145,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1147,
    "status": true,
    "code": 5205460000,
    "name": 1146,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1148,
    "status": true,
    "code": 5205470000,
    "name": 1147,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1149,
    "status": true,
    "code": 5205480000,
    "name": 1148,
    "description": "Пряжа хлопчатобумажная (кроме швейных ниток), содержащая хлопковых волокон 85 мас.% или более, не расфасованная для розничной продажи",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1462,
    "status": true,
    "code": 5206110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:13:07.337426",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2369,
    "status": true,
    "code": 5206110008,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-14 11:20:36.927501",
    "updated_at": "2023-02-14 11:20:36.927511"
  },
  {
    "id": 1296,
    "status": true,
    "code": 5206120000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:42:23.129922",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2325,
    "status": true,
    "code": 5206120002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-13 20:32:00.694039",
    "updated_at": "2023-01-13 20:32:00.694050"
  },
  {
    "id": 1247,
    "status": true,
    "code": 5206130000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:46.736189",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1380,
    "status": true,
    "code": 5206140000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:54:44.201043",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1982,
    "status": true,
    "code": 5206220000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-28 15:33:35.790621",
    "updated_at": "2021-04-28 15:33:35.790631"
  },
  {
    "id": 1841,
    "status": true,
    "code": 5206230000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-24 17:20:36.751426",
    "updated_at": "2020-11-24 17:20:36.751436"
  },
  {
    "id": 1856,
    "status": true,
    "code": 5206240000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-09 16:34:56.020297",
    "updated_at": "2020-12-09 16:34:56.020307"
  },
  {
    "id": 1397,
    "status": true,
    "code": 5206320000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:58:31.286913",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1369,
    "status": true,
    "code": 5206330000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:52:19.444184",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1216,
    "status": true,
    "code": 5208111000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 16:50:45.140024",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1223,
    "status": true,
    "code": 5208119000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:14.523026",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1203,
    "status": true,
    "code": 5208121600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 12:08:14.012469",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1236,
    "status": true,
    "code": 5208121900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:04.232639",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1313,
    "status": true,
    "code": 5208129600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:44:00.993421",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1205,
    "status": true,
    "code": 5208129900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 14:08:42.385115",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1349,
    "status": true,
    "code": 5208130000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:49:32.037735",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1284,
    "status": true,
    "code": 5208190000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:45.222852",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1220,
    "status": true,
    "code": 5208211000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:07.664760",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1250,
    "status": true,
    "code": 5208219000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:52.265128",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1249,
    "status": true,
    "code": 5208221600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:52.258598",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1290,
    "status": true,
    "code": 5208221900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:50.294823",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2090,
    "status": true,
    "code": 520822600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-11-27 12:40:47.991570",
    "updated_at": "2021-11-27 12:40:47.991585"
  },
  {
    "id": 1426,
    "status": true,
    "code": 5208229600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:05:21.656828",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1393,
    "status": true,
    "code": 5208229900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:57:12.684000",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1505,
    "status": true,
    "code": 5208230000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-07 12:08:56.180221",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1234,
    "status": true,
    "code": 5208290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:58.103722",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1554,
    "status": true,
    "code": 5208310000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-06 20:48:55.385457",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1353,
    "status": true,
    "code": 5208321600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:49:57.505532",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1738,
    "status": true,
    "code": 5208321900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-02 17:05:52.593212",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1448,
    "status": true,
    "code": 5208329600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:09:00.151784",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1494,
    "status": true,
    "code": 5208329900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-29 14:59:36.529252",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1675,
    "status": true,
    "code": 5208390000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-21 13:04:07.610683",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2206,
    "status": true,
    "code": 5208410000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-07-16 21:01:09.647558",
    "updated_at": "2022-07-16 21:01:09.647567"
  },
  {
    "id": 1805,
    "status": true,
    "code": 5208420000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-21 17:08:41.121571",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2246,
    "status": true,
    "code": 5208490000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-11-16 14:54:13.207814",
    "updated_at": "2022-11-16 14:54:13.207824"
  },
  {
    "id": 1289,
    "status": true,
    "code": 5208510000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:50.281172",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1264,
    "status": true,
    "code": 5208520000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:40:12.920538",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1382,
    "status": true,
    "code": 5208599000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:54:48.710837",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1410,
    "status": true,
    "code": 5209110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:01:08.753338",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1266,
    "status": true,
    "code": 5209120000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:40:26.391528",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1222,
    "status": true,
    "code": 5209190000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:12.703889",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2245,
    "status": true,
    "code": 5209210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-11-16 14:54:13.191121",
    "updated_at": "2022-11-16 14:54:13.191130"
  },
  {
    "id": 1800,
    "status": true,
    "code": 5209220000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-17 15:57:22.214864",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1371,
    "status": true,
    "code": 5209290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:53:08.145710",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1257,
    "status": true,
    "code": 5209310000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:39:14.633480",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1381,
    "status": true,
    "code": 5209320000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:54:44.698866",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1785,
    "status": true,
    "code": 5209390000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-29 18:33:23.892850",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1374,
    "status": true,
    "code": 5209420000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:53:22.066722",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1528,
    "status": true,
    "code": 5209590000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-20 18:29:16.461291",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1782,
    "status": true,
    "code": 5210110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-29 16:02:20.301144",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1794,
    "status": true,
    "code": 5210210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-08 15:09:54.869160",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1275,
    "status": true,
    "code": 5211120000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:18.936499",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1971,
    "status": true,
    "code": 5211320000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-07 17:27:02.299875",
    "updated_at": "2021-04-07 17:27:02.299884"
  },
  {
    "id": 1173,
    "status": true,
    "code": 5211420000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1854,
    "status": true,
    "code": 5212239000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-09 11:42:30.401917",
    "updated_at": "2020-12-09 11:42:30.401934"
  },
  {
    "id": 1110,
    "status": true,
    "code": 5301100000,
    "name": 1109,
    "description": "Лен-сырец или лен обработанный, но не подвергнутый прядению; очесы и отходы льна (включая прядильные отходы и расщипанное сырье):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1111,
    "status": true,
    "code": 5301210000,
    "name": 1110,
    "description": "Лен-сырец или лен обработанный, но не подвергнутый прядению; очесы и отходы льна (включая прядильные отходы и расщипанное сырье):->лен мятый, трепаный, чесаный или обработанный каким-либо другим способом, но не подвергнутый прядению:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1112,
    "status": true,
    "code": 5301290000,
    "name": 1111,
    "description": "Лен-сырец или лен обработанный, но не подвергнутый прядению; очесы и отходы льна (включая прядильные отходы и расщипанное сырье):->лен мятый, трепаный, чесаный или обработанный каким-либо другим способом, но не подвергнутый прядению:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1113,
    "status": true,
    "code": 5301300000,
    "name": 1112,
    "description": "Лен-сырец или лен обработанный, но не подвергнутый прядению; очесы и отходы льна (включая прядильные отходы и расщипанное сырье):->лен мятый, трепаный, чесаный или обработанный каким-либо другим способом, но не подвергнутый прядению:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1114,
    "status": true,
    "code": 5302100000,
    "name": 1113,
    "description": "Пенька (Cannabis sativa L.), сырец или обработанная, но не подвергнутая прядению; очесы и отходы пеньки (включая прядильные отходы и расщипанное сырье):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.374004"
  },
  {
    "id": 1115,
    "status": true,
    "code": 5302900000,
    "name": 1114,
    "description": "Пенька (Cannabis sativa L.), сырец или обработанная, но не подвергнутая прядению; очесы и отходы пеньки (включая прядильные отходы и расщипанное сырье):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2021-04-21 13:40:33.376125"
  },
  {
    "id": 1116,
    "status": true,
    "code": 5303100000,
    "name": 1115,
    "description": "Джутовое волокно и другие текстильные лубяные волокна (кроме льна, пеньки и рами), в виде сырца или обработанные, но не подвергнутые прядению; очесы и отходы этих волокон (включая прядильные отходы и расщипанное сырье):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1117,
    "status": true,
    "code": 5303900000,
    "name": 1116,
    "description": "Джутовое волокно и другие текстильные лубяные волокна (кроме льна, пеньки и рами), в виде сырца или обработанные, но не подвергнутые прядению; очесы и отходы этих волокон (включая прядильные отходы и расщипанное сырье):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1118,
    "status": true,
    "code": 5305000000,
    "name": 1117,
    "description": "Джутовое волокно и другие текстильные лубяные волокна (кроме льна, пеньки и рами), в виде сырца или обработанные, но не подвергнутые прядению; очесы и отходы этих волокон (включая прядильные отходы и расщипанное сырье):",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1305,
    "status": true,
    "code": 5307200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:42:50.987470",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1600,
    "status": true,
    "code": 5308909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-22 12:18:57.774676",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1487,
    "status": true,
    "code": 5401109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-22 10:49:27.991120",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1991,
    "status": true,
    "code": 5402200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-17 16:08:34.513454",
    "updated_at": "2021-05-17 16:08:34.513465"
  },
  {
    "id": 1758,
    "status": true,
    "code": 5402310000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-18 11:23:08.933768",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1759,
    "status": true,
    "code": 5402330000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-18 11:23:08.950847",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2038,
    "status": true,
    "code": 5402340000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-19 21:00:06.883321",
    "updated_at": "2021-08-19 21:00:06.883332"
  },
  {
    "id": 2185,
    "status": true,
    "code": 5407613000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-05-31 18:03:14.307855",
    "updated_at": "2022-05-31 18:03:14.307864"
  },
  {
    "id": 2225,
    "status": true,
    "code": 5408320000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-09-06 21:59:14.460926",
    "updated_at": "2022-09-06 21:59:14.460936"
  },
  {
    "id": 2144,
    "status": true,
    "code": 5408340000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-03-24 15:06:31.541521",
    "updated_at": "2022-03-24 15:06:31.541536"
  },
  {
    "id": 1411,
    "status": true,
    "code": 5503200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:01:51.370730",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2076,
    "status": true,
    "code": 5509110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-10-25 13:30:59.778614",
    "updated_at": "2021-10-25 13:30:59.778630"
  },
  {
    "id": 2047,
    "status": true,
    "code": 5509120000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-28 16:21:43.744522",
    "updated_at": "2021-08-28 16:21:43.744531"
  },
  {
    "id": 1812,
    "status": true,
    "code": 5509210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-27 18:45:00.629008",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1642,
    "status": true,
    "code": 5509410000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-04 17:12:38.955525",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1324,
    "status": true,
    "code": 5509530000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:44:42.581330",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1626,
    "status": true,
    "code": 5509620000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-30 15:58:57.899723",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1917,
    "status": true,
    "code": 5509690000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-06 13:47:34.262549",
    "updated_at": "2021-02-06 13:47:34.262559"
  },
  {
    "id": 1463,
    "status": true,
    "code": 5509920000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:13:07.344201",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1627,
    "status": true,
    "code": 5510110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-30 15:58:57.909944",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1750,
    "status": true,
    "code": 5510120000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-10 15:24:44.521598",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1840,
    "status": true,
    "code": 5510200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-24 14:21:23.823104",
    "updated_at": "2020-11-24 14:21:23.823118"
  },
  {
    "id": 1619,
    "status": true,
    "code": 5510300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-27 12:08:47.109183",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1811,
    "status": true,
    "code": 5510900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-23 14:21:10.952361",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1667,
    "status": true,
    "code": 5511200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-16 16:11:28.627820",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1970,
    "status": true,
    "code": 5514210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-04-07 17:27:02.233779",
    "updated_at": "2021-04-07 17:27:02.233790"
  },
  {
    "id": 1458,
    "status": true,
    "code": 5515199000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:12:07.330899",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1214,
    "status": true,
    "code": 5601211000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 16:50:43.615378",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1242,
    "status": true,
    "code": 5601219000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:36.280113",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1386,
    "status": true,
    "code": 5601290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:56:20.104680",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1427,
    "status": true,
    "code": 5601300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:05:47.460267",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1774,
    "status": true,
    "code": 5602210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-26 13:24:09.811244",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2247,
    "status": true,
    "code": 5602900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-11-19 11:30:05.951704",
    "updated_at": "2022-11-19 11:30:05.951717"
  },
  {
    "id": 1536,
    "status": true,
    "code": 5603119000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-25 15:59:07.730076",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2132,
    "status": true,
    "code": 5603121000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-02-10 16:21:03.255223",
    "updated_at": "2022-02-10 16:21:03.255237"
  },
  {
    "id": 1501,
    "status": true,
    "code": 5603149000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-05 15:48:56.666353",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1227,
    "status": true,
    "code": 5603939000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:34.730917",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1352,
    "status": true,
    "code": 5603941009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:49:57.495949",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1240,
    "status": true,
    "code": 5603949000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:21.217122",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1713,
    "status": true,
    "code": 5604100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-17 15:59:41.564641",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2037,
    "status": true,
    "code": 5605000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-19 21:00:06.844516",
    "updated_at": "2021-08-19 21:00:06.844529"
  },
  {
    "id": 1449,
    "status": true,
    "code": 5607509000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:10:56.340393",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1339,
    "status": true,
    "code": 5607909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:47:23.764115",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1736,
    "status": true,
    "code": 5609000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-29 15:45:00.564043",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1119,
    "status": true,
    "code": 5701109000,
    "name": 1118,
    "description": "Узелковые ковры и прочие текстильные напольные покрытия, готовые или неготовые:->из шерсти или тонкого волоса животных:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1291,
    "status": true,
    "code": 5702429000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:50.384168",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2220,
    "status": true,
    "code": 5702490000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-22 15:15:34.158438",
    "updated_at": "2022-08-22 15:15:34.158448"
  },
  {
    "id": 1120,
    "status": true,
    "code": 5703100000,
    "name": 1119,
    "description": "Ковры и прочие текстильные напольные покрытия тафтинговые, готовые или неготовые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1121,
    "status": true,
    "code": 5703908000,
    "name": 1120,
    "description": "Ковры и прочие текстильные напольные покрытия тафтинговые, готовые или неготовые:",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2112,
    "status": true,
    "code": 5705003000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-24 13:40:14.207884",
    "updated_at": "2021-12-24 13:40:14.207896"
  },
  {
    "id": 1360,
    "status": true,
    "code": 5801230000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:51:01.594005",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1347,
    "status": true,
    "code": 5801270000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:49:17.687206",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2297,
    "status": true,
    "code": 5802100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-06 16:17:01.479667",
    "updated_at": "2023-01-06 16:17:01.479678"
  },
  {
    "id": 2168,
    "status": true,
    "code": 5802110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-04-20 13:53:25.667116",
    "updated_at": "2022-04-20 13:53:25.667126"
  },
  {
    "id": 1209,
    "status": true,
    "code": 5802190000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 14:08:44.692808",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1881,
    "status": true,
    "code": 5804101000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-16 17:53:19.367730",
    "updated_at": "2020-12-16 17:53:19.367739"
  },
  {
    "id": 1657,
    "status": true,
    "code": 5806310000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-10 17:30:44.474603",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1431,
    "status": true,
    "code": 5806329000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:06:45.848532",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1235,
    "status": true,
    "code": 5807101000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:03.604101",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1587,
    "status": true,
    "code": 5807109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-16 14:48:50.037154",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1701,
    "status": true,
    "code": 5808900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-30 21:16:55.116179",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1665,
    "status": true,
    "code": 5810929000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-15 22:52:12.619776",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1258,
    "status": true,
    "code": 5901100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:39:15.910084",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2128,
    "status": true,
    "code": 5901900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-02-03 16:42:38.991325",
    "updated_at": "2022-02-03 16:42:38.991338"
  },
  {
    "id": 1596,
    "status": true,
    "code": 5903109009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-20 20:28:50.651005",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1533,
    "status": true,
    "code": 5903209000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-22 17:09:19.398654",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1519,
    "status": true,
    "code": 5903909900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-13 15:48:53.911381",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1523,
    "status": true,
    "code": 5911400000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-15 10:48:46.062526",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1330,
    "status": true,
    "code": 6001100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:45:49.731233",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1229,
    "status": true,
    "code": 6001210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:37.199750",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1810,
    "status": true,
    "code": 6001290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-22 19:17:39.304590",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1277,
    "status": true,
    "code": 6001910000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:21.915840",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1806,
    "status": true,
    "code": 6001920000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-22 11:30:12.987250",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2034,
    "status": true,
    "code": 6001990000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-14 20:21:56.830002",
    "updated_at": "2021-08-14 20:21:56.830015"
  },
  {
    "id": 1855,
    "status": true,
    "code": 600410000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-09 12:36:57.830632",
    "updated_at": "2020-12-09 12:36:57.830641"
  },
  {
    "id": 1279,
    "status": true,
    "code": 6004100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:35.401674",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1219,
    "status": true,
    "code": 6005210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:04.926800",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1213,
    "status": true,
    "code": 6006210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 15:23:04.556948",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1212,
    "status": true,
    "code": 6006220000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 15:22:59.675791",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1412,
    "status": true,
    "code": 6006230000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:02:01.225242",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1228,
    "status": true,
    "code": 6006240000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:37.185602",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1471,
    "status": true,
    "code": 6006410000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:19:04.130505",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1221,
    "status": true,
    "code": 6006420000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:08.180433",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2056,
    "status": true,
    "code": 6006900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-15 21:23:45.327487",
    "updated_at": "2021-09-15 21:23:45.327499"
  },
  {
    "id": 2241,
    "status": true,
    "code": 602905000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-28 13:43:24.717556",
    "updated_at": "2022-10-28 13:43:24.717568"
  },
  {
    "id": 1552,
    "status": true,
    "code": 6101209000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-04 15:49:03.140038",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1625,
    "status": true,
    "code": 6102209000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-30 14:58:54.147949",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1506,
    "status": true,
    "code": 6103220000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-07 17:19:04.398997",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2119,
    "status": true,
    "code": 6103230000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-01-18 20:21:12.093925",
    "updated_at": "2022-01-18 20:21:12.093940"
  },
  {
    "id": 1515,
    "status": true,
    "code": 6103320000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-11 14:08:50.112767",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2001,
    "status": true,
    "code": 6103330000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-26 18:29:56.597287",
    "updated_at": "2021-05-26 18:29:56.597296"
  },
  {
    "id": 2010,
    "status": true,
    "code": 6103390000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-07 12:10:25.889959",
    "updated_at": "2021-06-07 12:10:25.889971"
  },
  {
    "id": 2064,
    "status": true,
    "code": 6103420000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-25 19:13:15.848401",
    "updated_at": "2021-09-25 19:13:15.848410"
  },
  {
    "id": 1224,
    "status": true,
    "code": 6103420001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:14.985927",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1375,
    "status": true,
    "code": 6103420009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:53:25.537585",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1334,
    "status": true,
    "code": 6103430001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:46:06.892032",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1790,
    "status": true,
    "code": 6103430009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-06 09:08:30.627361",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1745,
    "status": true,
    "code": 6103490001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-06 20:47:44.134983",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2339,
    "status": true,
    "code": 6103620000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-21 16:52:10.324528",
    "updated_at": "2023-01-21 16:52:10.324543"
  },
  {
    "id": 1566,
    "status": true,
    "code": 6104130000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-10 14:28:58.888820",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1786,
    "status": true,
    "code": 6104192000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-01 19:48:11.596114",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1366,
    "status": true,
    "code": 6104220000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:52:10.885937",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2051,
    "status": true,
    "code": 6104230000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-31 18:06:04.125392",
    "updated_at": "2021-08-31 18:06:04.125407"
  },
  {
    "id": 1514,
    "status": true,
    "code": 6104320000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-11 14:08:50.094390",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1317,
    "status": true,
    "code": 6104420000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:44:27.684979",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1710,
    "status": true,
    "code": 6104430000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-13 16:44:49.391185",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1567,
    "status": true,
    "code": 6104440000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-11 19:28:45.306703",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1488,
    "status": true,
    "code": 6104520000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-22 14:19:48.116108",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1984,
    "status": true,
    "code": 6104530000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-01 14:53:17.939176",
    "updated_at": "2021-05-01 14:53:17.939189"
  },
  {
    "id": 1568,
    "status": true,
    "code": 6104590000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-11 19:28:45.331875",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2062,
    "status": true,
    "code": 6104610001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-21 12:23:50.194518",
    "updated_at": "2021-09-21 12:23:50.194528"
  },
  {
    "id": 1210,
    "status": true,
    "code": 6104620000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 15:14:56.341492",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1404,
    "status": true,
    "code": 6104630000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:00:29.355302",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2028,
    "status": true,
    "code": 6104690001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-07-07 16:43:18.828765",
    "updated_at": "2021-07-07 16:43:18.828774"
  },
  {
    "id": 1569,
    "status": true,
    "code": 6104690002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-11 19:28:45.339120",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1677,
    "status": true,
    "code": 6104920000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-22 16:45:54.908725",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1225,
    "status": true,
    "code": 6105100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:33.196696",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1848,
    "status": true,
    "code": 6105201000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-28 13:26:03.816046",
    "updated_at": "2020-11-28 13:26:03.816057"
  },
  {
    "id": 1246,
    "status": true,
    "code": 6106100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:43.719665",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1282,
    "status": true,
    "code": 6107110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:42.443370",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2061,
    "status": true,
    "code": 6107190000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-21 12:23:50.141998",
    "updated_at": "2021-09-21 12:23:50.142011"
  },
  {
    "id": 1304,
    "status": true,
    "code": 6107210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:42:48.207270",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1379,
    "status": true,
    "code": 6107910000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:53:59.125653",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2045,
    "status": true,
    "code": 6107990000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-26 15:30:14.379550",
    "updated_at": "2021-08-26 15:30:14.379561"
  },
  {
    "id": 1323,
    "status": true,
    "code": 6108210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:44:41.608023",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1658,
    "status": true,
    "code": 6108220000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-14 09:18:51.117569",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1311,
    "status": true,
    "code": 6108290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:43:36.997696",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1308,
    "status": true,
    "code": 6108310000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:43:20.160864",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1631,
    "status": true,
    "code": 6108390000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-03 08:38:40.312646",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1267,
    "status": true,
    "code": 6108910000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:40:28.168687",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1544,
    "status": true,
    "code": 6108920000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-30 14:39:03.649134",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1335,
    "status": true,
    "code": 6108990000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:46:10.251529",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2138,
    "status": true,
    "code": 610910000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-03-09 13:56:39.553907",
    "updated_at": "2022-03-09 13:56:39.553917"
  },
  {
    "id": 1215,
    "status": true,
    "code": 6109100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 16:50:44.936533",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1261,
    "status": true,
    "code": 6109902000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:40:07.967154",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1659,
    "status": true,
    "code": 6109909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-14 09:18:51.142503",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1666,
    "status": true,
    "code": 6110111000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-16 13:51:51.914896",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1301,
    "status": true,
    "code": 6110201000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:42:41.134136",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1244,
    "status": true,
    "code": 6110209100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:41.123813",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1256,
    "status": true,
    "code": 6110209900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:39:13.502670",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1346,
    "status": true,
    "code": 6110309100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:49:13.951037",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1345,
    "status": true,
    "code": 6110309900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:49:13.673402",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1890,
    "status": true,
    "code": 6110909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-18 14:38:04.126339",
    "updated_at": "2020-12-18 14:38:04.126353"
  },
  {
    "id": 1545,
    "status": true,
    "code": 6111201000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-30 16:29:05.767101",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1237,
    "status": true,
    "code": 6111209000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:38:05.602202",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1403,
    "status": true,
    "code": 6111309000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:00:29.349524",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1408,
    "status": true,
    "code": 6112110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:00:47.721530",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1779,
    "status": true,
    "code": 6112120000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-28 18:07:37.965933",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1726,
    "status": true,
    "code": 6112190000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-21 15:56:11.551561",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1668,
    "status": true,
    "code": 6112319000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-17 17:58:02.778380",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1760,
    "status": true,
    "code": 6112419000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-18 16:20:39.726287",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1262,
    "status": true,
    "code": 6114200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:40:08.181279",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1278,
    "status": true,
    "code": 6115290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:28.660565",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1746,
    "status": true,
    "code": 6115940000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-08 11:11:37.557932",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1230,
    "status": true,
    "code": 6115950000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:43.680756",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1676,
    "status": true,
    "code": 6115969900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-22 09:15:17.759259",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1724,
    "status": true,
    "code": 6115990000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-21 11:02:32.114348",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1824,
    "status": true,
    "code": 6116108000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-06 16:52:19.986639",
    "updated_at": "2020-11-06 16:52:19.986652"
  },
  {
    "id": 1888,
    "status": true,
    "code": 6116910000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-17 11:41:56.734433",
    "updated_at": "2020-12-17 11:41:56.734444"
  },
  {
    "id": 1414,
    "status": true,
    "code": 6116920000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:02:15.826736",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1295,
    "status": true,
    "code": 6117900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:42:21.398057",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1719,
    "status": true,
    "code": 6201110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-18 11:56:34.437687",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1816,
    "status": true,
    "code": 6201129000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-29 18:01:36.445632",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1720,
    "status": true,
    "code": 6201131000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-18 11:56:34.460186",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1757,
    "status": true,
    "code": 6201139000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-17 17:38:57.609703",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1595,
    "status": true,
    "code": 6201190000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-20 20:28:50.642707",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2072,
    "status": true,
    "code": 6201390000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-10-10 16:09:37.909855",
    "updated_at": "2021-10-10 16:09:37.909871"
  },
  {
    "id": 2285,
    "status": true,
    "code": 6201400000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 16:56:50.225248",
    "updated_at": "2023-01-05 16:56:50.225264"
  },
  {
    "id": 2366,
    "status": true,
    "code": 6201900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-14 09:22:29.124624",
    "updated_at": "2023-02-14 09:22:29.124633"
  },
  {
    "id": 1466,
    "status": true,
    "code": 6201920000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:14:20.446918",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1254,
    "status": true,
    "code": 6201930000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:39:09.118083",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1469,
    "status": true,
    "code": 6201990000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:17:08.097688",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1400,
    "status": true,
    "code": 6202131000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:59:37.542001",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1807,
    "status": true,
    "code": 6202139000,
    "name": "q",
    "description": "q",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-22 13:31:19.463280",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2286,
    "status": true,
    "code": 6202400009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-05 16:56:50.240504",
    "updated_at": "2023-01-05 16:56:50.240519"
  },
  {
    "id": 2365,
    "status": true,
    "code": 6202900009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-11 15:55:30.819475",
    "updated_at": "2023-02-11 15:55:30.819484"
  },
  {
    "id": 1780,
    "status": true,
    "code": 6202920000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-28 18:07:37.998675",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1315,
    "status": true,
    "code": 6202930000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:44:17.736636",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2248,
    "status": true,
    "code": 6202990000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-11-22 13:47:41.617615",
    "updated_at": "2022-11-22 13:47:41.617625"
  },
  {
    "id": 1392,
    "status": true,
    "code": 6203120000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:57:09.676629",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1815,
    "status": true,
    "code": 6203191000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-29 18:01:36.383497",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1730,
    "status": true,
    "code": 6203199000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-26 19:33:59.615146",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1344,
    "status": true,
    "code": 6203221000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:48:30.028932",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1819,
    "status": true,
    "code": 6203231000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-03 14:10:02.282804",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1822,
    "status": true,
    "code": 6203238000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-05 16:14:40.330389",
    "updated_at": "2020-11-05 16:14:40.330400"
  },
  {
    "id": 2080,
    "status": true,
    "code": 6203310000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-11-10 19:24:49.748289",
    "updated_at": "2021-11-10 19:24:49.748301"
  },
  {
    "id": 1570,
    "status": true,
    "code": 6203339000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-11 19:28:45.356491",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2081,
    "status": true,
    "code": 6203411000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-11-10 19:24:49.784327",
    "updated_at": "2021-11-10 19:24:49.784337"
  },
  {
    "id": 1274,
    "status": true,
    "code": 6203423100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:13.536033",
    "updated_at": "2021-05-15 00:51:43.641968"
  },
  {
    "id": 1413,
    "status": true,
    "code": 6203423500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:02:12.933076",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1461,
    "status": true,
    "code": 6203429000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:12:43.895594",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1316,
    "status": true,
    "code": 6203431900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:44:17.831394",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2146,
    "status": true,
    "code": 6203433900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-03-24 19:25:01.639921",
    "updated_at": "2022-03-24 19:25:01.639931"
  },
  {
    "id": 2254,
    "status": true,
    "code": 6203491900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-12-10 17:59:45.474838",
    "updated_at": "2022-12-10 17:59:45.474849"
  },
  {
    "id": 1894,
    "status": true,
    "code": 6203499000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-26 11:14:19.217799",
    "updated_at": "2020-12-26 11:14:19.217808"
  },
  {
    "id": 2099,
    "status": true,
    "code": 6204120000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-09 16:48:15.545411",
    "updated_at": "2021-12-09 16:48:15.545420"
  },
  {
    "id": 2237,
    "status": true,
    "code": 6204130000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-20 17:29:57.929168",
    "updated_at": "2022-10-20 17:29:57.929178"
  },
  {
    "id": 2107,
    "status": true,
    "code": 6204191000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-16 19:00:04.703163",
    "updated_at": "2021-12-16 19:00:04.703172"
  },
  {
    "id": 2065,
    "status": true,
    "code": 6204199000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-30 12:10:16.874415",
    "updated_at": "2021-09-30 12:10:16.874427"
  },
  {
    "id": 2209,
    "status": true,
    "code": 6204228000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-07-22 21:00:09.985621",
    "updated_at": "2022-07-22 21:00:09.985630"
  },
  {
    "id": 1823,
    "status": true,
    "code": 6204238000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-05 16:14:40.351459",
    "updated_at": "2020-11-05 16:14:40.351470"
  },
  {
    "id": 2123,
    "status": true,
    "code": 6204329000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-01-28 17:56:06.268518",
    "updated_at": "2022-01-28 17:56:06.268529"
  },
  {
    "id": 2201,
    "status": true,
    "code": 6204339000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-07-02 15:45:36.442045",
    "updated_at": "2022-07-02 15:45:36.442054"
  },
  {
    "id": 1607,
    "status": true,
    "code": 6204420000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-24 01:28:47.690159",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1571,
    "status": true,
    "code": 6204430000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-11 19:28:45.372425",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1889,
    "status": true,
    "code": 6204520000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-18 13:42:52.382203",
    "updated_at": "2020-12-18 13:42:52.382212"
  },
  {
    "id": 1572,
    "status": true,
    "code": 6204530000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-11 19:28:45.383143",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1389,
    "status": true,
    "code": 6204623100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:56:34.332345",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1776,
    "status": true,
    "code": 6204623900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-28 14:48:30.670009",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2218,
    "status": true,
    "code": 6204625900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-18 13:22:54.904339",
    "updated_at": "2022-08-18 13:22:54.904351"
  },
  {
    "id": 1464,
    "status": true,
    "code": 6204629000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:13:14.901656",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1761,
    "status": true,
    "code": 6204631800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-19 17:56:05.233939",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2147,
    "status": true,
    "code": 6204633900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-03-24 19:25:01.674104",
    "updated_at": "2022-03-24 19:25:01.674113"
  },
  {
    "id": 2066,
    "status": true,
    "code": 6204691800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-30 12:10:16.934422",
    "updated_at": "2021-09-30 12:10:16.934434"
  },
  {
    "id": 1527,
    "status": true,
    "code": 6205200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-20 14:38:50.220969",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2181,
    "status": true,
    "code": 6205300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-05-25 12:32:07.553742",
    "updated_at": "2022-05-25 12:32:07.553757"
  },
  {
    "id": 1573,
    "status": true,
    "code": 6205908009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-11 19:28:45.398491",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1520,
    "status": true,
    "code": 6206300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-13 16:08:48.148510",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1630,
    "status": true,
    "code": 6206400000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-02 19:18:39.474663",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2098,
    "status": true,
    "code": 6207210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-09 16:48:15.401629",
    "updated_at": "2021-12-09 16:48:15.401643"
  },
  {
    "id": 1362,
    "status": true,
    "code": 6207910000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:51:25.382792",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1893,
    "status": true,
    "code": 6207991000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-26 11:14:19.137843",
    "updated_at": "2020-12-26 11:14:19.137851"
  },
  {
    "id": 1513,
    "status": true,
    "code": 6208190001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-10 00:48:43.970556",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1768,
    "status": true,
    "code": 6208220000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-23 14:20:02.482538",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1987,
    "status": true,
    "code": 6208290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-09 13:37:54.164876",
    "updated_at": "2021-05-09 13:37:54.164885"
  },
  {
    "id": 1328,
    "status": true,
    "code": 6208910000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:45:13.505229",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1769,
    "status": true,
    "code": 6208920000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-23 14:20:02.495336",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1419,
    "status": true,
    "code": 6209200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:03:50.897133",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1314,
    "status": true,
    "code": 6209300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:44:17.727093",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1732,
    "status": true,
    "code": 6210109800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-27 15:35:48.073858",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1518,
    "status": true,
    "code": 6210400000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-13 12:58:43.586522",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1592,
    "status": true,
    "code": 6211110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-19 15:09:03.471814",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1913,
    "status": true,
    "code": 6211321000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-30 11:49:59.995257",
    "updated_at": "2021-01-30 11:49:59.995265"
  },
  {
    "id": 1504,
    "status": true,
    "code": 6211329000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-05 23:08:49.121317",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1259,
    "status": true,
    "code": 6211331000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:39:25.824661",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1698,
    "status": true,
    "code": 6211333100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-30 09:03:36.366733",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1787,
    "status": true,
    "code": 6211339000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-03 21:42:47.887347",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1755,
    "status": true,
    "code": 6211421000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-15 17:53:11.562920",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1507,
    "status": true,
    "code": 6211429000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-07 17:28:45.815690",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1997,
    "status": true,
    "code": 6211431000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-21 17:45:55.181915",
    "updated_at": "2021-05-21 17:45:55.181926"
  },
  {
    "id": 1253,
    "status": true,
    "code": 6211439000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:39:08.947046",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1651,
    "status": true,
    "code": 6212109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-10 10:24:43.533467",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1265,
    "status": true,
    "code": 6213200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:40:14.429647",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1998,
    "status": true,
    "code": 6213900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-21 17:45:55.194669",
    "updated_at": "2021-05-21 17:45:55.194679"
  },
  {
    "id": 1937,
    "status": true,
    "code": 6214900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-11 00:35:07.697238",
    "updated_at": "2021-03-11 00:35:07.697247"
  },
  {
    "id": 1294,
    "status": true,
    "code": 6216000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:42:19.356040",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1556,
    "status": true,
    "code": 6217100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-06 23:58:54.801532",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1391,
    "status": true,
    "code": 6301301000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:57:00.121824",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1814,
    "status": true,
    "code": 6301309000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-28 14:12:21.786495",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1875,
    "status": true,
    "code": 6301401000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-13 16:25:52.205321",
    "updated_at": "2020-12-13 16:25:52.205331"
  },
  {
    "id": 1298,
    "status": true,
    "code": 6302100001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:42:32.404998",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1884,
    "status": true,
    "code": 6302100009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-16 21:04:01.113393",
    "updated_at": "2020-12-16 21:04:01.113403"
  },
  {
    "id": 1310,
    "status": true,
    "code": 6302210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:43:26.252992",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1920,
    "status": true,
    "code": 6302229000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-13 17:49:34.739174",
    "updated_at": "2021-02-13 17:49:34.739183"
  },
  {
    "id": 1233,
    "status": true,
    "code": 6302310000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:55.181135",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2324,
    "status": true,
    "code": 6302310009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-13 17:38:34.125810",
    "updated_at": "2023-01-13 17:38:34.125828"
  },
  {
    "id": 1895,
    "status": true,
    "code": 6302329000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-26 11:14:19.257812",
    "updated_at": "2020-12-26 11:14:19.257822"
  },
  {
    "id": 1938,
    "status": true,
    "code": 6302399000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-13 14:59:37.196010",
    "updated_at": "2021-03-13 14:59:37.196021"
  },
  {
    "id": 1365,
    "status": true,
    "code": 6302510000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:51:59.364609",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1207,
    "status": true,
    "code": 6302600000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 14:08:44.670337",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1208,
    "status": true,
    "code": 6302910000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 14:08:44.682561",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1541,
    "status": true,
    "code": 6302939000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-29 13:09:06.386925",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1880,
    "status": true,
    "code": 6303910000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-16 17:53:19.353508",
    "updated_at": "2020-12-16 17:53:19.353517"
  },
  {
    "id": 1270,
    "status": true,
    "code": 6304191000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:40:38.716650",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1714,
    "status": true,
    "code": 6304930000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-17 15:59:41.621728",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2050,
    "status": true,
    "code": 6305331001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-31 15:49:16.460417",
    "updated_at": "2021-08-31 15:49:16.460430"
  },
  {
    "id": 2207,
    "status": true,
    "code": 6305339000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-07-16 21:01:09.730506",
    "updated_at": "2022-07-16 21:01:09.730516"
  },
  {
    "id": 1522,
    "status": true,
    "code": 6305390000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-13 18:08:41.268735",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1850,
    "status": true,
    "code": 6305900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-01 14:09:40.433117",
    "updated_at": "2020-12-01 14:09:40.433128"
  },
  {
    "id": 1907,
    "status": true,
    "code": 6307101000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-15 14:14:42.081798",
    "updated_at": "2021-01-15 14:14:42.081812"
  },
  {
    "id": 1808,
    "status": true,
    "code": 6307103000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-22 17:59:52.914918",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1737,
    "status": true,
    "code": 6307109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-02 15:48:43.332472",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1490,
    "status": true,
    "code": 6307901000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-04-24 16:39:25.537785",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1293,
    "status": true,
    "code": 6307909800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:42:19.349982",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1673,
    "status": true,
    "code": 6308000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-20 16:05:55.795799",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1999,
    "status": true,
    "code": 6310100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-21 21:00:32.549010",
    "updated_at": "2021-05-21 21:00:32.549023"
  },
  {
    "id": 2067,
    "status": true,
    "code": 6310900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-10-05 17:16:55.823889",
    "updated_at": "2021-10-05 17:16:55.823904"
  },
  {
    "id": 1547,
    "status": true,
    "code": 6401990000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-30 23:19:02.150287",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1565,
    "status": true,
    "code": 6403511500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-10 11:58:55.367729",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1778,
    "status": true,
    "code": 6403519100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-28 18:07:37.876591",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1644,
    "status": true,
    "code": 6403911100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-06 12:53:51.147353",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1905,
    "status": true,
    "code": 6403911600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-14 17:13:32.472282",
    "updated_at": "2021-01-14 17:13:32.472296"
  },
  {
    "id": 1906,
    "status": true,
    "code": 6403911800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-14 17:13:32.487999",
    "updated_at": "2021-01-14 17:13:32.488008"
  },
  {
    "id": 1777,
    "status": true,
    "code": 6403919600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-28 17:35:16.806420",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1538,
    "status": true,
    "code": 6403993100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-28 15:59:06.587704",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1729,
    "status": true,
    "code": 6403999100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-25 18:20:47.285731",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1532,
    "status": true,
    "code": 6403999600,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-22 14:59:16.177294",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1645,
    "status": true,
    "code": 6403999800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-06 12:53:51.199330",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1531,
    "status": true,
    "code": 6404199000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-20 19:49:11.204152",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1797,
    "status": true,
    "code": 6404209000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-15 17:33:58.579807",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1454,
    "status": true,
    "code": 6405100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:12:07.306215",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1773,
    "status": true,
    "code": 6405209100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-25 18:01:42.452670",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1548,
    "status": true,
    "code": 6405901000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-30 23:19:02.234400",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1690,
    "status": true,
    "code": 6406201000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-27 21:11:15.598872",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1716,
    "status": true,
    "code": 6505001000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-17 18:51:21.011201",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1226,
    "status": true,
    "code": 6505003000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:33.203045",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1255,
    "status": true,
    "code": 6505009000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:39:10.647067",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1516,
    "status": true,
    "code": 6506108000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-12 14:38:41.361692",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1502,
    "status": true,
    "code": 6506999090,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-05 17:08:55.345893",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1954,
    "status": true,
    "code": 6703000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-29 13:49:24.846677",
    "updated_at": "2021-03-29 13:49:24.846687"
  },
  {
    "id": 1624,
    "status": true,
    "code": 6802210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-30 12:38:42.825407",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1621,
    "status": true,
    "code": 6802910000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-29 11:18:59.974037",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2194,
    "status": true,
    "code": 6805100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-09 14:44:23.203694",
    "updated_at": "2022-06-09 14:44:23.203703"
  },
  {
    "id": 1196,
    "status": true,
    "code": 6806100001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1622,
    "status": true,
    "code": 6806900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-29 11:18:59.979949",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1648,
    "status": true,
    "code": 6809900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-06 17:31:47.394517",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1747,
    "status": true,
    "code": 6812930000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-08 16:48:53.241285",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1846,
    "status": true,
    "code": 6907229009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-26 18:35:07.079321",
    "updated_at": "2020-11-26 18:35:07.079330"
  },
  {
    "id": 1367,
    "status": true,
    "code": 6910900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:52:11.571638",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1691,
    "status": true,
    "code": 6911100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-27 21:11:15.627043",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1416,
    "status": true,
    "code": 6912002100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:02:25.897859",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1300,
    "status": true,
    "code": 6912002500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:42:32.500922",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1342,
    "status": true,
    "code": 6912002900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:48:15.694196",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1351,
    "status": true,
    "code": 6912008500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:49:52.300280",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1387,
    "status": true,
    "code": 6912008900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:56:20.111318",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1912,
    "status": true,
    "code": 6913901000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-26 09:51:23.549900",
    "updated_at": "2021-01-26 09:51:23.549911"
  },
  {
    "id": 2232,
    "status": true,
    "code": 6913909800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-11 10:56:10.553381",
    "updated_at": "2022-10-11 10:56:10.553390"
  },
  {
    "id": 1711,
    "status": true,
    "code": 6914900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-15 16:22:20.097868",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2256,
    "status": true,
    "code": 7005108000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-12-22 15:30:53.757814",
    "updated_at": "2022-12-22 15:30:53.757832"
  },
  {
    "id": 2088,
    "status": true,
    "code": 7007111009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-11-25 14:46:31.807943",
    "updated_at": "2021-11-25 14:46:31.807957"
  },
  {
    "id": 1439,
    "status": true,
    "code": 7007212009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:07:45.348734",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2089,
    "status": true,
    "code": 7007290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-11-25 14:49:27.103011",
    "updated_at": "2021-11-25 14:49:27.103025"
  },
  {
    "id": 1684,
    "status": true,
    "code": 7009920000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-27 10:49:52.743118",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1695,
    "status": true,
    "code": 7010901000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-29 21:26:01.813352",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2321,
    "status": true,
    "code": 7017100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-12 16:45:24.058259",
    "updated_at": "2023-01-12 16:45:24.058270"
  },
  {
    "id": 2322,
    "status": true,
    "code": 7017900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-12 16:45:24.064717",
    "updated_at": "2023-01-12 16:45:24.064726"
  },
  {
    "id": 1653,
    "status": true,
    "code": 7019310000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-10 10:25:03.237308",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1834,
    "status": true,
    "code": 7019590000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-16 17:39:25.992438",
    "updated_at": "2020-11-16 17:39:25.992451"
  },
  {
    "id": 2251,
    "status": true,
    "code": 709300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-12-02 16:54:14.719267",
    "updated_at": "2022-12-02 16:54:14.719277"
  },
  {
    "id": 2250,
    "status": true,
    "code": 709601000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-12-02 16:54:14.696730",
    "updated_at": "2022-12-02 16:54:14.696739"
  },
  {
    "id": 1853,
    "status": true,
    "code": 709999000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-08 21:11:30.923254",
    "updated_at": "2020-12-08 21:11:30.923267"
  },
  {
    "id": 1916,
    "status": true,
    "code": 712200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-03 14:38:16.852253",
    "updated_at": "2021-02-03 14:38:16.852261"
  },
  {
    "id": 1915,
    "status": true,
    "code": 712905000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-03 14:38:16.806108",
    "updated_at": "2021-02-03 14:38:16.806117"
  },
  {
    "id": 1914,
    "status": true,
    "code": 712909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-03 14:38:16.707361",
    "updated_at": "2021-02-03 14:38:16.707372"
  },
  {
    "id": 2191,
    "status": true,
    "code": 713109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-02 14:11:08.229655",
    "updated_at": "2022-06-02 14:11:08.229665"
  },
  {
    "id": 1926,
    "status": true,
    "code": 713200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-16 17:53:35.501311",
    "updated_at": "2021-02-16 17:53:35.501320"
  },
  {
    "id": 2109,
    "status": true,
    "code": 713310001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-17 15:01:49.709845",
    "updated_at": "2021-12-17 15:01:49.709854"
  },
  {
    "id": 2192,
    "status": true,
    "code": 713900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-02 14:11:08.238670",
    "updated_at": "2022-06-02 14:11:08.238679"
  },
  {
    "id": 2200,
    "status": true,
    "code": 7210708000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-30 12:06:46.837799",
    "updated_at": "2022-06-30 12:06:46.837812"
  },
  {
    "id": 1744,
    "status": true,
    "code": 7308300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-05 11:23:43.038685",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1833,
    "status": true,
    "code": 7308909809,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-14 18:22:53.649921",
    "updated_at": "2020-11-14 18:22:53.649930"
  },
  {
    "id": 2196,
    "status": true,
    "code": 7317006000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-16 15:00:26.558353",
    "updated_at": "2022-06-16 15:00:26.558363"
  },
  {
    "id": 1276,
    "status": true,
    "code": 7318129000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:19.620817",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2208,
    "status": true,
    "code": 7318141000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-07-20 15:20:50.140088",
    "updated_at": "2022-07-20 15:20:50.140101"
  },
  {
    "id": 1450,
    "status": true,
    "code": 7318149900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:11:09.246480",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1697,
    "status": true,
    "code": 7321119000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-29 21:26:01.836122",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2231,
    "status": true,
    "code": 7321190000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-10 18:10:36.285487",
    "updated_at": "2022-10-10 18:10:36.285496"
  },
  {
    "id": 1539,
    "status": true,
    "code": 7321890000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-28 22:19:04.230222",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1521,
    "status": true,
    "code": 7321900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-13 16:48:49.500496",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2364,
    "status": true,
    "code": 7323100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-11 10:39:31.028432",
    "updated_at": "2023-02-11 10:39:31.028440"
  },
  {
    "id": 1418,
    "status": true,
    "code": 7323910000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:03:29.995800",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1682,
    "status": true,
    "code": 7323920000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-24 18:05:10.253104",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1199,
    "status": true,
    "code": 7323930000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-20 11:19:44.315332",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1434,
    "status": true,
    "code": 7323990000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:06:45.874134",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2350,
    "status": true,
    "code": 7324210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-29 19:41:32.004442",
    "updated_at": "2023-01-29 19:41:32.004451"
  },
  {
    "id": 2116,
    "status": true,
    "code": 7326200001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-01-13 16:25:54.364036",
    "updated_at": "2022-01-13 16:25:54.364052"
  },
  {
    "id": 1799,
    "status": true,
    "code": 7326200002,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-15 17:33:58.672319",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1555,
    "status": true,
    "code": 7326904000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-06 23:58:54.787159",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1770,
    "status": true,
    "code": 7326909807,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-25 09:33:19.579454",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2135,
    "status": true,
    "code": 7408191000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-02-16 11:22:12.906475",
    "updated_at": "2022-02-16 11:22:12.906487"
  },
  {
    "id": 2129,
    "status": true,
    "code": 7411101000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-02-08 18:46:54.669802",
    "updated_at": "2022-02-08 18:46:54.669814"
  },
  {
    "id": 1672,
    "status": true,
    "code": 7604109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-20 16:05:55.778667",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1271,
    "status": true,
    "code": 7604210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:02.316740",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1272,
    "status": true,
    "code": 7604299000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:02.325926",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1599,
    "status": true,
    "code": 7606129900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-21 17:38:50.377907",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1424,
    "status": true,
    "code": 7610100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:04:35.907350",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1497,
    "status": true,
    "code": 7610909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-01 11:49:24.294729",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1924,
    "status": true,
    "code": 802119000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-16 17:53:35.441366",
    "updated_at": "2021-02-16 17:53:35.441376"
  },
  {
    "id": 1929,
    "status": true,
    "code": 802129000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-16 17:53:35.618698",
    "updated_at": "2021-02-16 17:53:35.618707"
  },
  {
    "id": 2026,
    "status": true,
    "code": 804209000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-07-06 16:57:23.569736",
    "updated_at": "2021-07-06 16:57:23.569745"
  },
  {
    "id": 2027,
    "status": true,
    "code": 805501000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-07-06 16:57:23.592634",
    "updated_at": "2021-07-06 16:57:23.592642"
  },
  {
    "id": 1922,
    "status": true,
    "code": 806201000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-16 17:53:35.404756",
    "updated_at": "2021-02-16 17:53:35.404765"
  },
  {
    "id": 1928,
    "status": true,
    "code": 806203000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-16 17:53:35.560718",
    "updated_at": "2021-02-16 17:53:35.560727"
  },
  {
    "id": 1921,
    "status": true,
    "code": 806209000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-16 17:53:35.174478",
    "updated_at": "2021-02-16 17:53:35.174488"
  },
  {
    "id": 2193,
    "status": true,
    "code": 807110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-08 12:58:31.141583",
    "updated_at": "2022-06-08 12:58:31.141597"
  },
  {
    "id": 2052,
    "status": true,
    "code": 809309000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-09-06 12:08:55.465496",
    "updated_at": "2021-09-06 12:08:55.465510"
  },
  {
    "id": 1629,
    "status": true,
    "code": 8101999000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-02 16:38:44.435805",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1281,
    "status": true,
    "code": 8102990000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:38.123186",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1923,
    "status": true,
    "code": 813100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-16 17:53:35.414798",
    "updated_at": "2021-02-16 17:53:35.414807"
  },
  {
    "id": 1925,
    "status": true,
    "code": 813200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-16 17:53:35.491391",
    "updated_at": "2021-02-16 17:53:35.491400"
  },
  {
    "id": 1897,
    "status": true,
    "code": 813300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-09 21:48:37.411228",
    "updated_at": "2021-01-09 21:48:37.411236"
  },
  {
    "id": 2025,
    "status": true,
    "code": 813401000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-07-06 16:57:23.529959",
    "updated_at": "2021-07-06 16:57:23.529976"
  },
  {
    "id": 1752,
    "status": true,
    "code": 813409500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-12 15:33:20.663218",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2108,
    "status": true,
    "code": 813501900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-17 15:01:49.650741",
    "updated_at": "2021-12-17 15:01:49.650750"
  },
  {
    "id": 1927,
    "status": true,
    "code": 813509100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-16 17:53:35.535067",
    "updated_at": "2021-02-16 17:53:35.535076"
  },
  {
    "id": 2020,
    "status": true,
    "code": 8201100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-26 16:28:32.981055",
    "updated_at": "2021-06-26 16:28:32.981064"
  },
  {
    "id": 2021,
    "status": true,
    "code": 8201300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-26 16:28:33.002529",
    "updated_at": "2021-06-26 16:28:33.002538"
  },
  {
    "id": 1361,
    "status": true,
    "code": 8201400000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:51:03.643911",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2106,
    "status": true,
    "code": 8205510090,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-15 18:35:53.990170",
    "updated_at": "2021-12-15 18:35:53.990179"
  },
  {
    "id": 1598,
    "status": true,
    "code": 8205598099,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-20 20:28:50.685662",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1605,
    "status": true,
    "code": 8211910001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-23 22:08:48.475926",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1472,
    "status": true,
    "code": 8211920000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-28 17:49:46.451981",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1633,
    "status": true,
    "code": 8212200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-03 08:38:40.329504",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1886,
    "status": true,
    "code": 8215201000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-16 21:04:01.136552",
    "updated_at": "2020-12-16 21:04:01.136561"
  },
  {
    "id": 1597,
    "status": true,
    "code": 8215991000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-20 20:28:50.675465",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1878,
    "status": true,
    "code": 8215999000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-16 10:13:15.665513",
    "updated_at": "2020-12-16 10:13:15.665527"
  },
  {
    "id": 2079,
    "status": true,
    "code": 8301401900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-11-04 16:09:13.300476",
    "updated_at": "2021-11-04 16:09:13.300507"
  },
  {
    "id": 2073,
    "status": true,
    "code": 8302300009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-10-11 11:19:25.237534",
    "updated_at": "2021-10-11 11:19:25.237546"
  },
  {
    "id": 1634,
    "status": true,
    "code": 8302411000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-03 08:38:40.348905",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1882,
    "status": true,
    "code": 8302415000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-16 17:53:19.386130",
    "updated_at": "2020-12-16 17:53:19.386139"
  },
  {
    "id": 1593,
    "status": true,
    "code": 8302419000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-20 13:28:51.990012",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1654,
    "status": true,
    "code": 8303009000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-10 10:25:03.243289",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1459,
    "status": true,
    "code": 8308200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:12:07.344493",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1696,
    "status": true,
    "code": 8309909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-29 21:26:01.821358",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1766,
    "status": true,
    "code": 8311100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-09-21 17:20:51.422042",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2242,
    "status": true,
    "code": 8406909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-10-31 17:22:59.343973",
    "updated_at": "2022-10-31 17:22:59.343984"
  },
  {
    "id": 2048,
    "status": true,
    "code": 8411822001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-30 18:09:29.873599",
    "updated_at": "2021-08-30 18:09:29.873609"
  },
  {
    "id": 2215,
    "status": true,
    "code": 8413602000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-09 15:14:30.191148",
    "updated_at": "2022-08-09 15:14:30.191163"
  },
  {
    "id": 2368,
    "status": true,
    "code": 8414598000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-14 09:22:29.191355",
    "updated_at": "2023-02-14 09:22:29.191364"
  },
  {
    "id": 2224,
    "status": true,
    "code": 8414801900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-31 13:01:07.295904",
    "updated_at": "2022-08-31 13:01:07.295916"
  },
  {
    "id": 2212,
    "status": true,
    "code": 8414900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-05 18:05:59.768624",
    "updated_at": "2022-08-05 18:05:59.768639"
  },
  {
    "id": 1990,
    "status": true,
    "code": 8415109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-16 19:21:19.241684",
    "updated_at": "2021-05-16 19:21:19.241698"
  },
  {
    "id": 1820,
    "status": true,
    "code": 8416208000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-03 14:56:56.821922",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2096,
    "status": true,
    "code": 8417209000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-05 13:46:02.735912",
    "updated_at": "2021-12-05 13:46:02.735921"
  },
  {
    "id": 1455,
    "status": true,
    "code": 8418690009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:12:07.311502",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1836,
    "status": true,
    "code": 8421230000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-18 09:55:22.282134",
    "updated_at": "2020-11-18 09:55:22.282146"
  },
  {
    "id": 2347,
    "status": true,
    "code": 8436210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-29 19:41:31.942463",
    "updated_at": "2023-01-29 19:41:31.942479"
  },
  {
    "id": 1456,
    "status": true,
    "code": 8438809900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:12:07.316598",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2113,
    "status": true,
    "code": 8442502000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-28 17:40:54.232669",
    "updated_at": "2021-12-28 17:40:54.232678"
  },
  {
    "id": 1432,
    "status": true,
    "code": 8447900009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:06:45.854406",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1832,
    "status": true,
    "code": 8452290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-14 18:22:53.618244",
    "updated_at": "2020-11-14 18:22:53.618253"
  },
  {
    "id": 2367,
    "status": true,
    "code": 8462290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-02-14 09:22:29.174332",
    "updated_at": "2023-02-14 09:22:29.174340"
  },
  {
    "id": 1457,
    "status": true,
    "code": 8465912000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:12:07.321735",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1435,
    "status": true,
    "code": 8480600000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:06:45.888824",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1632,
    "status": true,
    "code": 8506101100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-03 08:38:40.323107",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1204,
    "status": true,
    "code": 8512200001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 14:08:41.335742",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1390,
    "status": true,
    "code": 8512200009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:56:37.906778",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1734,
    "status": true,
    "code": 8516601010,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-29 12:05:44.210553",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1200,
    "status": true,
    "code": 8516797000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-20 11:19:44.445763",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1430,
    "status": true,
    "code": 8535100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:06:45.842680",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1583,
    "status": true,
    "code": 8544429009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-15 19:48:41.353183",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1405,
    "status": true,
    "code": 8708109009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:00:43.269873",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2133,
    "status": true,
    "code": 8708291000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-02-10 18:06:21.162643",
    "updated_at": "2022-02-10 18:06:21.162653"
  },
  {
    "id": 1731,
    "status": true,
    "code": 8708299001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-08-27 15:32:28.787709",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1309,
    "status": true,
    "code": 8708299009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:43:21.434811",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1406,
    "status": true,
    "code": 8708709909,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:00:43.276230",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2091,
    "status": true,
    "code": 8708923509,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-02 14:38:21.804939",
    "updated_at": "2021-12-02 14:38:21.804953"
  },
  {
    "id": 1551,
    "status": true,
    "code": 8708999709,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-03 16:59:03.586064",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1935,
    "status": true,
    "code": 8716200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-09 13:52:30.838303",
    "updated_at": "2021-03-09 13:52:30.838312"
  },
  {
    "id": 1683,
    "status": true,
    "code": 8716800000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-24 18:05:10.276190",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2210,
    "status": true,
    "code": 9002200000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-08-04 17:11:09.302695",
    "updated_at": "2022-08-04 17:11:09.302709"
  },
  {
    "id": 1671,
    "status": true,
    "code": 9004909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-20 16:05:55.763973",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2189,
    "status": true,
    "code": 902100001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-02 14:11:07.987535",
    "updated_at": "2022-06-02 14:11:07.987544"
  },
  {
    "id": 2187,
    "status": true,
    "code": 902100009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-02 14:11:07.896621",
    "updated_at": "2022-06-02 14:11:07.896631"
  },
  {
    "id": 2190,
    "status": true,
    "code": 902300001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-02 14:11:08.021252",
    "updated_at": "2022-06-02 14:11:08.021262"
  },
  {
    "id": 2188,
    "status": true,
    "code": 902300009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-06-02 14:11:07.963408",
    "updated_at": "2022-06-02 14:11:07.963417"
  },
  {
    "id": 1788,
    "status": true,
    "code": 904220000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-04 18:19:40.572178",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2349,
    "status": true,
    "code": 9105290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-29 19:41:31.987390",
    "updated_at": "2023-01-29 19:41:31.987400"
  },
  {
    "id": 1591,
    "status": true,
    "code": 9202908009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-19 11:39:04.871139",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2131,
    "status": true,
    "code": 9401200001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-02-10 11:17:10.842293",
    "updated_at": "2022-02-10 11:17:10.842309"
  },
  {
    "id": 1936,
    "status": true,
    "code": 9401300001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-10 10:27:25.328205",
    "updated_at": "2021-03-10 10:27:25.328215"
  },
  {
    "id": 2009,
    "status": true,
    "code": 9401400000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-06-04 18:31:54.155402",
    "updated_at": "2021-06-04 18:31:54.155411"
  },
  {
    "id": 1299,
    "status": true,
    "code": 9401610000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:42:32.491907",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1395,
    "status": true,
    "code": 9401690000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:58:05.726971",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1623,
    "status": true,
    "code": 9401710009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-29 17:08:59.880507",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1372,
    "status": true,
    "code": 9401790009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:53:12.846470",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1892,
    "status": true,
    "code": 9401800009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-24 14:35:22.344520",
    "updated_at": "2020-12-24 14:35:22.344531"
  },
  {
    "id": 1306,
    "status": true,
    "code": 9401903000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:43:09.770874",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1849,
    "status": true,
    "code": 9403105100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-28 19:17:21.797095",
    "updated_at": "2020-11-28 19:17:21.797111"
  },
  {
    "id": 2044,
    "status": true,
    "code": 9403105800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-26 12:50:18.065282",
    "updated_at": "2021-08-26 12:50:18.065291"
  },
  {
    "id": 1557,
    "status": true,
    "code": 9403109800,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-06 23:58:54.812083",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1417,
    "status": true,
    "code": 9403208000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:03:16.527516",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1373,
    "status": true,
    "code": 9403301900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:53:13.411460",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2043,
    "status": true,
    "code": 9403309900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-26 12:50:18.006092",
    "updated_at": "2021-08-26 12:50:18.006101"
  },
  {
    "id": 1617,
    "status": true,
    "code": 9403409000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-25 16:38:50.550553",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1453,
    "status": true,
    "code": 9403500000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:12:07.295001",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1396,
    "status": true,
    "code": 9403601000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:58:05.737303",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2042,
    "status": true,
    "code": 9403603000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-08-26 12:50:17.851150",
    "updated_at": "2021-08-26 12:50:17.851162"
  },
  {
    "id": 1553,
    "status": true,
    "code": 9403609000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-05 15:59:05.509645",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1558,
    "status": true,
    "code": 9403700008,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-06 23:58:54.818834",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1409,
    "status": true,
    "code": 9403830000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:00:52.114759",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1826,
    "status": true,
    "code": 9403890000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-11-11 11:34:49.899489",
    "updated_at": "2020-11-11 11:34:49.899497"
  },
  {
    "id": 1218,
    "status": true,
    "code": 9403903000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:37:02.874619",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1616,
    "status": true,
    "code": 9403909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-24 10:18:56.020895",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2312,
    "status": true,
    "code": 9403910000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-09 14:43:00.737528",
    "updated_at": "2023-01-09 14:43:00.737538"
  },
  {
    "id": 1656,
    "status": true,
    "code": 9404299000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-10 16:33:09.699242",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1813,
    "status": true,
    "code": 9404300000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-28 14:12:21.755823",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1798,
    "status": true,
    "code": 9404901000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-10-15 17:33:58.644847",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1537,
    "status": true,
    "code": 9404909000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-05-27 11:39:10.122655",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1885,
    "status": true,
    "code": 9405109809,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-16 21:04:01.124833",
    "updated_at": "2020-12-16 21:04:01.124842"
  },
  {
    "id": 1559,
    "status": true,
    "code": 9405602000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-06 23:58:54.825189",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1376,
    "status": true,
    "code": 9405990009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:53:44.949843",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1286,
    "status": true,
    "code": 9406109000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:41:46.112827",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1602,
    "status": true,
    "code": 9503001000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-23 22:08:48.447101",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1428,
    "status": true,
    "code": 9503003500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 18:06:45.822180",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1932,
    "status": true,
    "code": 9503004100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-02-22 19:12:27.200790",
    "updated_at": "2021-02-22 19:12:27.200798"
  },
  {
    "id": 2348,
    "status": true,
    "code": 9503008100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-29 19:41:31.978444",
    "updated_at": "2023-01-29 19:41:31.978454"
  },
  {
    "id": 1388,
    "status": true,
    "code": 9503009500,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-27 17:56:20.117256",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1670,
    "status": true,
    "code": 9503009900,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-20 16:05:55.746337",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1891,
    "status": true,
    "code": 9505900000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-12-24 14:35:22.274902",
    "updated_at": "2020-12-24 14:35:22.274925"
  },
  {
    "id": 1217,
    "status": true,
    "code": 9603100000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-25 16:50:47.816200",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2030,
    "status": true,
    "code": 9603210000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-07-23 13:44:57.205763",
    "updated_at": "2021-07-23 13:44:57.205777"
  },
  {
    "id": 1585,
    "status": true,
    "code": 9603409000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-15 19:48:41.368672",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1584,
    "status": true,
    "code": 9603909100,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-15 19:48:41.358731",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2104,
    "status": true,
    "code": 9606290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-15 18:35:53.962946",
    "updated_at": "2021-12-15 18:35:53.962954"
  },
  {
    "id": 2105,
    "status": true,
    "code": 9607110000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-15 18:35:53.972707",
    "updated_at": "2021-12-15 18:35:53.972715"
  },
  {
    "id": 2102,
    "status": true,
    "code": 9607190000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-15 18:35:53.929869",
    "updated_at": "2021-12-15 18:35:53.929877"
  },
  {
    "id": 2103,
    "status": true,
    "code": 9607209000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-12-15 18:35:53.953079",
    "updated_at": "2021-12-15 18:35:53.953088"
  },
  {
    "id": 2125,
    "status": true,
    "code": 9608101000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-02-01 12:22:00.532445",
    "updated_at": "2022-02-01 12:22:00.532454"
  },
  {
    "id": 1910,
    "status": true,
    "code": 9617000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-01-23 11:10:16.591544",
    "updated_at": "2021-01-23 11:10:16.591559"
  },
  {
    "id": 1689,
    "status": true,
    "code": 9618000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-07-27 21:11:15.574800",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 2117,
    "status": true,
    "code": 9619005001,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-01-15 15:22:19.281550",
    "updated_at": "2022-01-15 15:22:19.281559"
  },
  {
    "id": 2004,
    "status": true,
    "code": 9619005009,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2021-05-31 19:52:59.191475",
    "updated_at": "2021-05-31 19:52:59.191486"
  },
  {
    "id": 2150,
    "status": true,
    "code": 9619007101,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-03-30 21:07:00.299349",
    "updated_at": "2022-03-30 21:07:00.299358"
  },
  {
    "id": 2149,
    "status": true,
    "code": 9619008101,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2022-03-30 21:07:00.219113",
    "updated_at": "2022-03-30 21:07:00.219124"
  },
  {
    "id": 1178,
    "status": true,
    "code": 9620000007,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-03-06 07:39:28.229881",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1590,
    "status": true,
    "code": 9703000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2020-06-18 22:28:52.937451",
    "updated_at": "2020-11-04 12:19:24.580956"
  },
  {
    "id": 1955,
    "status": true,
    "code": 9705000000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "True",
    "lab_analyzer_price": 0,
    "added_at": "2021-03-30 12:30:16.424038",
    "updated_at": "2021-04-21 13:40:33.276059"
  },
  {
    "id": 2342,
    "status": true,
    "code": 9705290000,
    "name": "",
    "description": "",
    "gtk_color_status": 3,
    "is_lab": "False",
    "is_high_risked": "False",
    "lab_analyzer_price": 0,
    "added_at": "2023-01-26 11:54:55.682283",
    "updated_at": "2023-01-26 11:54:55.682295"
  }
]