import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import fileDownload from 'js-file-download'
import '../atb/atb.css'
import http from '../../utils/axiosinterceptors'

const Fond = ({ startDate, endDate }) => {

    const [withStat, setWithStat] = useState(false)
    const [loading, setLoading] = useState(false)
    const [tog, setTog] = useState('fcc')


    const submitForm = (e) => {
        e.preventDefault()
        setLoading(true)
        // free_fss
        let mod = ''
        if (tog === 'fcc') {
            mod = 'import'
        } else if (tog === 'akd') {
            mod = 'export'
        } else if (tog === 'free') {
            mod = 'free_fss'
        }
        http.post(`api/report/fond`, {}, {
            params: {
                mode: mod,
                save: tog === 'free' ? false : withStat,
                from_date: startDate ? startDate : null,
                end_date: endDate ? endDate : null
            },
            responseType: "blob"
        })
            .then(res => {
                fileDownload(res.data, 'hisobot.zip')
            }).catch(err => {
                toast.error("Xatolik", {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }).finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h1>Fond</h1>
            <div className='atb-component'>
                <form onSubmit={submitForm}>
                    <div className='toggle three-toggle'>
                        <div className={tog === 'free' ? 'active-tog' : ''} onClick={() => setTog('free')}>Free FSS</div>
                        <div className={tog === 'fcc' ? 'active-tog' : ''} onClick={() => setTog('fcc')}>FSS (export)</div>
                        <div className={tog === 'akd' ? 'active-tog' : ''} onClick={() => setTog('akd')}>AKD (import)</div>
                    </div>

                    <div className='btn-wrap'>
                        {
                            tog === 'free' ? null : (
                                <input type='checkbox' title='Statistika bilan' checked={withStat} value={withStat} onChange={() => setWithStat(!withStat)} />
                            )
                        }
                        <button type='submit' className='load' disabled={loading}>
                            {loading ? <Spinner animation="border" /> : null}
                            {loading ? null : 'Yuklab olish'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Fond