import axios from "axios"
import { BASE_URL } from "../constants/constants"


const token = document.cookie?.split(';')[1]?.split('=')[1]

axios.defaults.baseURL = BASE_URL

const http = axios.create({
    baseURL: BASE_URL,
    timeout: 120000,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // 'Access-Control-Allow-Origin': '*',
    }
})

http.interceptors.request.use(
    (config) => {
        config.baseURL = BASE_URL
        config.headers = {
            'Authorization': `Bearer ${token}`
        }
        return config
    }
)

http.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log('error - ', error)
        return Promise.reject(error);
    }
)

export default http