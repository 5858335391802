import axios from 'axios'
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import './login.css'


const Login = () => {

    const [loading, setLoading] = useState(false)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const sumbitLogin = (e) => {
        e.preventDefault()
        setLoading(true)

        axios.post("api/auth/user/login", {
            login: username,
            password
        }).then(res => {
            const EXPIRES_DATE = (new Date(Date.now() + 86400 * 1000)).toUTCString();

            if (res.data.access_token) {
                document.cookie = `role=${res.data.role};expires=${EXPIRES_DATE}`
                document.cookie = `token=${res.data.access_token};expires=${EXPIRES_DATE}`
                window.location.reload()
            } else {
                toast.error(res.data.detail, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }
        }).catch(err => {
            toast.error(err.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }).finally(() => setLoading(false))
    }

    return (
        <div className='login-component'>
            <form onSubmit={sumbitLogin}>
                <div>
                    <label>Login</label>
                    <input type='text' value={username} onChange={(e) => setUsername(e.target.value)} required placeholder='Login' />
                </div>
                <div>
                    <label>Password</label>
                    <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} required placeholder='Password' />
                </div>
                <button type='submit' className='load' disabled={loading}>
                    {loading ? <Spinner animation="border" /> : null}
                    {loading ? null : 'Kirish'}
                </button>
            </form>
        </div>
    )
}

export default Login