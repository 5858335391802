import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import fileDownload from 'js-file-download'
import '../atb/atb.css'
import http from '../../utils/axiosinterceptors'

const HisobotNumber = ({ startDate, endDate }) => {

    const [loading, setLoading] = useState(false)
    const [tog, setTog] = useState('fcc')


    const submitForm = (e) => {
        e.preventDefault()
        setLoading(true)

        http.post(`api/report/count`, {}, {
            params: {
                mode: tog === "fcc" ? "import" : "export",
                from_date: startDate ? startDate : null,
                end_date: endDate ? endDate : null
            },
            responseType: "blob"
        })
            .then(res => {
                fileDownload(res.data, 'hisobot.zip')
            }).catch(err => {
                toast.error("Xatolik", {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }).finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h1>Hisobot</h1>
            <div className='atb-component'>
                <form onSubmit={submitForm}>
                    <div className='toggle'>
                        <div className={tog === 'fcc' ? 'active-tog' : ''} onClick={() => setTog('fcc')}>FSS (export)</div>
                        <div className={tog === 'akd' ? 'active-tog' : ''} onClick={() => setTog('akd')}>AKD (import)</div>
                    </div>

                    <div className='btn-wrap'>
                        <button type='submit' className='load' disabled={loading}>
                            {loading ? <Spinner animation="border" /> : null}
                            {loading ? null : 'Yuklab olish'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default HisobotNumber