import React, { useState } from 'react'
import http from '../../utils/axiosinterceptors'
import fileDownload from 'js-file-download'
import { toast } from 'react-toastify'
import { Spinner } from 'react-bootstrap'

const StatistikHisobot = ({ startDate, endDate }) => {
    const [isCountry, setIsCountry] = useState(false)
    const [loading, setLoading] = useState(false)
    const [tog, setTog] = useState('FSS')


    const submitForm = (e) => {
        e.preventDefault()
        setLoading(true)

        http.get(`api/report/statistic`, {
            params: {
                mode: tog,
                is_country: isCountry,
                from_date: startDate ? startDate : null,
                end_date: endDate ? endDate : null
            },
            responseType: "blob"
        })
            .then(res => {
                fileDownload(res.data, 'hisobot.zip')
            }).catch(err => {
                toast.error("Xatolik", {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }).finally(() => {
                setLoading(false)
            })
    }


    return (
        <div>
            <h1>Statistik hisobot</h1>
            <div className='atb-component'>
                <form onSubmit={submitForm}>
                    <div className='toggle'>
                        <div className={tog === 'FSS' ? 'active-tog' : ''} onClick={() => setTog('FSS')}>FSS (export)</div>
                        <div className={tog === 'AKD' ? 'active-tog' : ''} onClick={() => setTog('AKD')}>AKD (import)</div>
                    </div>

                    <div className='btn-wrap'>
                        {
                            tog === 'free' ? null : (
                                <input type='checkbox' title='' checked={isCountry} value={isCountry} onChange={() => setIsCountry(!isCountry)} />
                            )
                        }
                        <button type='submit' className='load' disabled={loading}>
                            {loading ? <Spinner animation="border" /> : null}
                            {loading ? null : 'Yuklab olish'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default StatistikHisobot